import produce from 'immer';

export default function preProcessingReducer(state, action) {
  if (action.error) {
    return state;
  } else {
    switch (action.type) {
      case 'PREPROCESSING_SET_PROCESSOR':
        const apps = state.main.availableApps;
        return produce(state, draft => {
          draft.cameraConfig.preProcessingProcessor = action.file;
          if (apps.find(app => app === state.main.current)) {
            draft[state.main.current].pendingSave = true;
          }
        });
      default:
        return state;
    }
  }
}
