import React from 'react';
import GeneralCamConfigContent from './generalCamConfigContent';
import { Select, TextField } from 'react-md';
import InputRange from 'react-input-range';

export default class IPCamConfig extends React.Component {
  toastId = null;

  getCameraData(newParamsObject) {
    let content = { data: {} };
    content.data.type = 'IPCam';
    content.data.url = this.props.IPCamUrl;
    content.data.interval = this.props.IPCamInterval;
    content.data = { ...content.data, ...newParamsObject };
    return content;
  }
  setCamera(buttonToLoad) {
    this.props.setCamera(this.props.camName, this.getCameraData(), 1, buttonToLoad);
  }

  render() {
    const { nonImageCameraSpeed } = this.props;
    const content = (
      <div id='IPCamConfigContent'>
        <TextField
          className='w-100 camera-card-text-field'
          id='externalIPField'
          label='Endereço IP'
          value={this.props.IPCamUrl}
          onChange={event => this.props.setParentState({ IPCamUrl: event.target.value })}
        />
        <div className='w-100 flex flex-center flex-wrap'>
          <span className='w-100 flex flex-start capabilities-span' style={{ alignItems: 'center' }}>
            Quantidade de quadros por segundo
          </span>
          <InputRange
            id='IPFPS'
            labelContainer='IPFPS'
            name='IPFPS'
            formatLabel={value => `${nonImageCameraSpeed[value]?.label || 'Média'}`}
            minValue={0}
            maxValue={4}
            value={
              nonImageCameraSpeed.findIndex(element => this.props.IPCamInterval === element.interval) === -1
                ? 2
                : nonImageCameraSpeed.findIndex(element => this.props.IPCamInterval === element.interval)
            }
            onChange={value => this.props.setParentState({ IPCamInterval: nonImageCameraSpeed[value]?.interval || 2 })}
            onChangeComplete={value =>
              this.props.applyCameraConfig(1, { ...this.getCameraData({ interval: nonImageCameraSpeed[value]?.interval || 2 }), name: this.props.camName })
            }
          />

          {/*VERSAO EM DROPDOWN*/}

          {/*<Select*/}
          {/*  id='IPCameraVisualizerSpeedSelect'*/}
          {/*  className='w-100'*/}
          {/*  label='Tempo de visualização do quadro'*/}
          {/*  options={nonImageCameraSpeed.map(element => element.label)}*/}
          {/*  value={nonImageCameraSpeed.find(element => element.interval === this.props.IPCamInterval)?.label || 'Médio'}*/}
          {/*  onChange={value => {*/}
          {/*    const speed = nonImageCameraSpeed.find(element => element.label === value)?.interval || 15;*/}
          {/*    this.props.setParentState({ IPCamInterval: speed });*/}
          {/*    this.props.applyCameraConfig(1, { ...this.getCameraData({ interval: speed }), name: this.props.camName });*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
      </div>
    );

    return (
      <GeneralCamConfigContent
        parentContent={content}
        cameraType='Câmera IP'
        camVariable='IPCam'
        camName={this.props.camName}
        setParentState={content => this.props.setParentState(content)}
        cameraToParent={buttonToLoad => this.setCamera(buttonToLoad)}
        deleteCamera={() => this.props.deleteCamera(this.props.camName)}
        allCameras={this.props.allCameras}
        applyCameraConfig={() => this.props.applyCameraConfig(1, { ...this.getCameraData(), name: this.props.camName })}
        loadingButtons={this.props.loadingButtons}
        card={1}
        cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
      />
    );
  }
}
