import produce from 'immer';
import { toast } from 'react-toastify';

export default function appFaceReducer(state, action) {
  if (action.error) {
    toast.error(`Ocorreu um erro. Tente novamente.`);
    // return produce(state, draft => {
    // });
    return state;
  } else {
    switch (action.type) {
      case 'APPFACE_SAVE_FACE':
        let newMatFaces = [];
        let newBase64Faces = [];
        const newPerson = {
          name: action.file.personName,
          matFaces: [],
          base64Faces: [],
        };
        const newPeople = [...state.appFace.people];
        const personIndex = state.appFace.people.findIndex(person => person.name === action.file.personName);
        if (personIndex !== -1) {
          newMatFaces = [...state.appFace.people[personIndex].matFaces];
          newMatFaces.push(action.file.matFace);
          newBase64Faces = [...state.appFace.people[personIndex].base64Faces];
          newBase64Faces.push(action.file.base64Face);
          newPerson.matFaces = newMatFaces;
          newPerson.base64Faces = newBase64Faces;
          newPeople[personIndex] = newPerson;
        } else {
          newPerson.matFaces.push(action.file.matFace);
          newPerson.base64Faces.push(action.file.base64Face);
          newPeople.push(newPerson);
        }
        return produce(state, draft => {
          draft.appFace.people = newPeople;
          draft.appFace.pendingSave = true;
        });
      case 'APPFACE_REMOVE_FACE':
        const myNewPeople = [...state.appFace.people];
        const myNewPerson = { ...myNewPeople[action.file.personIndex] };

        let myNewMatFaces = [...state.appFace.people[action.file.personIndex].matFaces];
        let myNewBase64Faces = [...state.appFace.people[action.file.personIndex].base64Faces];
        myNewMatFaces.splice(action.file.faceIndex, 1);
        myNewBase64Faces.splice(action.file.faceIndex, 1);

        myNewPerson.matFaces = myNewMatFaces;
        myNewPerson.base64Faces = myNewBase64Faces;

        myNewPeople[action.file.personIndex] = myNewPerson;
        return produce(state, draft => {
          draft.appFace.people = myNewPeople;
          draft.appFace.pendingSave = true;
        });

      default:
        return state;
    }
  }
}
