import React from 'react';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, Form, TextField } from 'react-md';
import { bindActionCreators } from 'redux';
import * as AppBarActions from '../../../actions/appBar';
import * as mainActions from '../../../actions/main';
import { connect } from 'react-redux';
import { CircularProgress } from '@react-md/progress';
import { toast } from 'react-toastify';

class NewAppDialog extends React.Component {
  toastId = null;
  spellError = 'O nome do novo App Config nao deve ter espaços nem barra.';
  alreadyExistsError = 'Já existe um App Config com este nome.';
  constructor(props) {
    super(props);
    this.state = {
      appName: '',
    };
  }
  closeDialog() {
    this.setState({ appName: '' }, () => this.props.handleNewAppDialog(false));
  }
  createApp() {
    if (!!this.props.appConfigs.find(appConfig => appConfig.name === this.state.appName)) {
      toast.isActive(this.toastId) ? toast.update(this.toastId, { render: this.alreadyExistsError }) : (this.toastId = toast.error(this.alreadyExistsError));
    } else if (!!this.state.appName.replace(/\s+/g, '') && !this.state.appName.includes('/') && !this.state.appName.includes(' ')) {
      const nameToSave = this.state.appName;
      this.setState({ appName: '' }, () => {
        this.props.setLoadingButton(1, this.props.current);
        this.props.setCreatingAppConfig(true);
        this.props.createAppConfig(null, nameToSave);
      });
    } else {
      toast.isActive(this.toastId) ? toast.update(this.toastId, { render: this.spellError }) : (this.toastId = toast.error(this.spellError));
    }
  }
  render() {
    return (
      <>
        <Dialog
          style={{ width: '350px' }}
          id='newAppDialog'
          visible={this.props.newAppDialogEnabled}
          onRequestClose={() => this.closeDialog()}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader className='flex flex-center'>
            <DialogTitle>Nova configuração</DialogTitle>
          </DialogHeader>
          <DialogContent>
            <Form>
              <TextField
                id='nameField'
                autoComplete='off'
                label='nome'
                type='text'
                theme='underline'
                onChange={event => this.setState({ appName: event.target.value })}
                onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.createApp() : null)}
              />
            </Form>
          </DialogContent>
          <DialogFooter className='flex flex-wrap'>
            <div className='w-100 flex' style={{ justifyContent: 'space-around' }}>
              <Button disabled={this.props.creatingAppConfig} style={{ width: '48%', height: '36px' }} theme='error' themeType='contained' onClick={() => this.closeDialog()}>
                cancelar
              </Button>
              {this.props.creatingAppConfig ? (
                <div style={{ height: '36px', width: '48%', backgroundColor: '#cccccc', borderRadius: '0.5rem' }}>
                  <CircularProgress id='newAppDialogCircularProgress' centered={true} className='determinate-example-circular h-100 applyCamProgress' color='white' />
                </div>
              ) : (
                <Button style={{ width: '48%', height: '36px' }} theme='primary' themeType='contained' onClick={() => this.createApp()}>
                  criar
                </Button>
              )}
            </div>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  newAppDialogEnabled: state.main.newAppDialogEnabled,
  creatingAppConfig: state.main.creatingAppConfig,
  current: state.main.current,
  appConfigs: state[state.main.current + 'SelectApp'].apps,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...AppBarActions, ...mainActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewAppDialog);
