import * as lodash from 'lodash';

export class QualityIdentification {
  constructor(data) {
    this.data = lodash.cloneDeep(data);
    this.tabType = 'identification';
    // this.maxDisappeared = 10;
    this.lastFrameObjects = {};
    this.nextId = 0;
    this.redRectangle = new window.cv.Scalar(224, 32, 32, 255);
    this.greenRectangle = new window.cv.Scalar(32, 224, 32, 255);
    this.passedIds = {};
  }

  serialize() {
    return this.data;
  }
  binarization(mat, content) {
    switch (content.currentBinarizationMethod) {
      case 'rgbBinarization':
        let lowRed = content.red.min;
        let lowGreen = content.green.min;
        let lowBlue = content.blue.min;
        let highRed = content.red.max;
        let highGreen = content.green.max;
        let highBlue = content.blue.max;

        let lowScalar = new window.cv.Scalar(lowRed, lowGreen, lowBlue, 255);
        let highScalar = new window.cv.Scalar(highRed, highGreen, highBlue, 255);

        let low = new window.cv.Mat(mat.rows, mat.cols, mat.type(), lowScalar);
        let high = new window.cv.Mat(mat.rows, mat.cols, mat.type(), highScalar);

        window.cv.inRange(mat, low, high, mat);
        low.delete();
        high.delete();
        return mat;
      default:
        window.cv.cvtColor(mat, mat, window.cv.COLOR_RGBA2GRAY, 0);
        return mat;
    }
  }

  filtering(mat, content) {
    function getMorphologicalStructure(type, sizeX, sizeY) {
      switch (type) {
        case 'rectangle':
          return window.cv.getStructuringElement(window.cv.MORPH_RECT, new window.cv.Size(sizeX, sizeY));
        case 'ellipsis':
          return window.cv.getStructuringElement(window.cv.MORPH_ELLIPSE, new window.cv.Size(sizeX, sizeY));
        case 'cross':
          return window.cv.getStructuringElement(window.cv.MORPH_CROSS, new window.cv.Size(sizeX, sizeY));
        default:
          return window.cv.getStructuringElement(window.cv.MORPH_RECT, new window.cv.Size(3, 3));
      }
    }

    let anchor = new window.cv.Point(-1, -1);
    let kernel = getMorphologicalStructure(content.currentMorphologicalElement, content.morphologicalSizeX, content.morphologicalSizeY);

    switch (content.currentMorphologicalOperator) {
      case 'erosion':
        window.cv.erode(mat, mat, kernel, anchor, 1, window.cv.BORDER_CONSTANT, window.cv.morphologyDefaultBorderValue());
        kernel.delete();
        return mat;
      case 'dilation':
        window.cv.dilate(mat, mat, kernel, anchor, 1, window.cv.BORDER_CONSTANT, window.cv.morphologyDefaultBorderValue());
        kernel.delete();
        return mat;
      case 'opening':
        window.cv.morphologyEx(mat, mat, window.cv.MORPH_OPEN, kernel, anchor, 1, window.cv.BORDER_CONSTANT, window.cv.morphologyDefaultBorderValue());
        kernel.delete();
        return mat;
      case 'closing':
        window.cv.morphologyEx(mat, mat, window.cv.MORPH_CLOSE, kernel, anchor, 1, window.cv.BORDER_CONSTANT, window.cv.morphologyDefaultBorderValue());
        kernel.delete();
        return mat;
      default:
        kernel.delete();
        return mat;
    }
  }

  detection(mat, content) {
    const linePoints = this.getLinePosition(content.currentMovementDirection, content.linePosition, mat.rows, mat.cols);
    this.trackingObjects(mat, content.minimalArea, linePoints);
    window.cv.line(mat, linePoints[0], linePoints[1], new window.cv.Scalar(32, 32, 224, 255), 2);
    return mat;
  }

  getLinePosition(movementDirection, linePosition, rows, cols) {
    switch (movementDirection) {
      case 'upDown':
        return [new window.cv.Point(0, Math.floor((rows * linePosition) / 100)), new window.cv.Point(cols, Math.floor((rows * linePosition) / 100))];
      case 'leftRight':
        return [new window.cv.Point(Math.floor((cols * linePosition) / 100), 0), new window.cv.Point(Math.floor((cols * linePosition) / 100), rows)];
      case 'downUp':
        return [new window.cv.Point(0, Math.floor(rows - (rows * linePosition) / 100)), new window.cv.Point(cols, Math.floor(rows - (rows * linePosition) / 100))];
      case 'rightLeft':
        return [new window.cv.Point(Math.floor(cols - (cols * linePosition) / 100), 0), new window.cv.Point(Math.floor(cols - (cols * linePosition) / 100), rows)];
      default:
        return [new window.cv.Point(0, Math.floor(rows * 0.5)), new window.cv.Point(cols, Math.floor(rows * 0.5))];
    }
  }

  trackingObjects(mat, minArea, linePoints) {
    let contours = new window.cv.MatVector();
    let hierarchy = new window.cv.Mat();
    window.cv.findContours(mat, contours, hierarchy, window.cv.RETR_EXTERNAL, window.cv.CHAIN_APPROX_SIMPLE);
    window.cv.cvtColor(mat, mat, window.cv.COLOR_GRAY2RGBA);

    const currentFrameCentroids = [];
    const currentFrameRects = [];

    if (contours.size() > 0) {
      for (let i = 0; i < contours.size(); ++i) {
        let cnt = contours.get(i);
        let cntArea = window.cv.contourArea(cnt, false);
        if (cntArea > ((minArea / 100) * mat.cols * mat.rows).toFixed(2)) {
          let rect = window.cv.boundingRect(cnt);
          let point1 = new window.cv.Point(rect.x, rect.y);
          let point2 = new window.cv.Point(rect.x + rect.width, rect.y + rect.height);
          cnt.delete();
          currentFrameCentroids.push({ x: Math.round(rect.x + rect.width / 2), y: Math.round(rect.y + rect.height / 2) });
          currentFrameRects.push({ point1: point1, point2: point2 });
        }
      }

      if (Object.keys(this.lastFrameObjects).length === 0) {
        this.nextId = 0;
        currentFrameCentroids.forEach((centroid, index) => {
          this.lastFrameObjects[index] = { centroid: centroid, rect: currentFrameRects[index], disappeared: 0 };
        });
        this.nextId = currentFrameCentroids.length;
      } else {
        this.matchCentroids(mat, currentFrameCentroids, currentFrameRects, linePoints);
      }
    } else {
      this.lastFrameObjects = {};
    }
    contours.delete();
    hierarchy.delete();
  }

  matchCentroids(mat, currentFrameCentroids, currentFrameRects, linePoints) {
    function calcDistance(lastCentroid, currentFrameCentroids, detection) {
      let minorDistanceX = Infinity;
      let minorDistanceY = Infinity;
      let matchIndex = -1;
      currentFrameCentroids.forEach((someCurrentCentroid, index) => {
        let currentDistanceX = Math.abs(lastCentroid.x - someCurrentCentroid.x);
        let currentDistanceY = Math.abs(lastCentroid.y - someCurrentCentroid.y);
        if (currentDistanceX <= minorDistanceX && currentDistanceY <= minorDistanceY) {
          minorDistanceX = currentDistanceX;
          minorDistanceY = currentDistanceY;
          matchIndex = index;
        }
      });
      return minorDistanceX <= Math.round((detection.maxDistanceX * mat.cols) / 100) && minorDistanceY <= Math.round((detection.maxDistanceY * mat.rows) / 100)
        ? matchIndex
        : false;
    }

    const lastFramesCopy = lodash.cloneDeep(this.lastFrameObjects);
    const currentFrameHasMoreElements = Object.keys(lastFramesCopy).length < currentFrameCentroids.length;
    const matchedCentroidsIndexes = [];

    Object.keys(lastFramesCopy).forEach((key, index) => {
      const matchIndex = calcDistance(lastFramesCopy[key].centroid, currentFrameCentroids, this.data.detection);
      if (matchIndex !== false) {
        lastFramesCopy[key].centroid = currentFrameCentroids[matchIndex];
        lastFramesCopy[key].rect = currentFrameRects[matchIndex];
        const greenSquare = Object.keys(this.passedIds).find(passedId => passedId === key);
        window.cv.rectangle(
          mat,
          currentFrameRects[matchIndex].point1,
          currentFrameRects[matchIndex].point2,
          !!greenSquare ? this.greenRectangle : this.redRectangle,
          2,
          window.cv.LINE_AA,
          0
        );
        // window.cv.circle(mat, linePoints[0], 6, [255, 255, 0, 255], 3);
        // window.cv.circle(mat, linePoints[1], 6, [255, 255, 0, 255], 3);
        matchedCentroidsIndexes.push(matchIndex);
      } else {
        delete lastFramesCopy[key];
        delete this.passedIds[key];
      }
    });

    let unmatchedIndexes = [];

    if (currentFrameCentroids.length > 0) {
      unmatchedIndexes = Array.from(Array(currentFrameCentroids.length).keys()).filter((element, index) => !matchedCentroidsIndexes.find(matchedEl => matchedEl === index));
    }

    //new Centroid at scene
    if (currentFrameHasMoreElements) {
      unmatchedIndexes.forEach((element, index) => {
        if (!Object.keys(lastFramesCopy).find((key, index) => lastFramesCopy[key].centroid === currentFrameCentroids[element])) {
          lastFramesCopy[this.nextId] = { centroid: currentFrameCentroids[element], rect: currentFrameRects[element] };
          this.nextId++;
        }
      });
    }

    this.capturePassedObject(lastFramesCopy, linePoints);
  }

  capturePassedObject(lastFramesCopy, linePoints) {
    function checkPassedObject(movementDirection, oldCentroid, newCentroid) {
      switch (movementDirection) {
        case 'upDown':
          return oldCentroid.y <= linePoints[0].y && newCentroid.y >= linePoints[0].y;
        case 'leftRight':
          return oldCentroid.x <= linePoints[0].x && newCentroid.x >= linePoints[0].x;
        case 'downUp':
          return oldCentroid.y >= linePoints[0].y && newCentroid.y <= linePoints[0].y;
        case 'rightLeft':
          return oldCentroid.x >= linePoints[0].x && newCentroid.x <= linePoints[0].x;
        default:
          return false;
      }
    }

    if (Object.keys(this.lastFrameObjects).length > 0) {
      Object.keys(this.lastFrameObjects).forEach((key, index) => {
        if (lastFramesCopy.hasOwnProperty(key)) {
          if (checkPassedObject(this.data.detection.currentMovementDirection, this.lastFrameObjects[key].centroid, lastFramesCopy[key].centroid)) {
            this.passedIds[key] = true;
          }
        }
      });
      this.lastFrameObjects = lastFramesCopy;
    }
  }

  process(mat) {
    if (this.data && !!mat) {
      if (this.data.binarization.active) {
        mat = this.binarization(mat, this.data.binarization);
      }
      if (this.data.binarization.active && this.data.filtering.active) {
        mat = this.filtering(mat, this.data.filtering);
      }
      if (this.data.binarization.active && this.data.detection.active) {
        mat = this.detection(mat, this.data.detection);
      }
    }
    return mat;
  }
}
