import React from 'react';
import { ListItem } from 'react-md';
import './menu.scss';
import { AppsFontIcon, CloudDownloadFontIcon, CameraEnhanceFontIcon, PaymentFontIcon } from '@react-md/material-icons';

export default class MenuRoutes extends React.Component {
  routes = {
    apps: { name: 'Aplicações', unlogged: true },
    cameraConfig: { name: 'Câmera', unlogged: false },
    localConfig: { name: 'Deploy', unlogged: true },
    licenseGroups: { name: 'Grupos / Licenças', unlogged: true },
  };
  icons = [<AppsFontIcon />, <CameraEnhanceFontIcon />, <CloudDownloadFontIcon />, <PaymentFontIcon />];
  constructor(props) {
    super(props);
    this.state = {
      route: 'apps',
    };
  }

  render() {
    return (
      <React.Fragment>
        {Object.keys(this.routes).map((key, index) =>
          this.routes[key].unlogged || this.props.logged ? (
            <ListItem
              className={`menu-item  ${this.props.current.toLowerCase() === key.toLowerCase() ? 'menu-item-selected' : ''}`}
              key={index}
              leftAddon={this.icons[index]}
              onClick={() => {
                if (key === 'cameraConfig' && !this.props.camerasFetched) {
                } else {
                  if (this.props.logged) {
                    this.setState({ route: key });

                    if (window.history.pushState) {
                      window.history.pushState({}, null, '/');
                    } else {
                      window.location.href = '/';
                    }

                    this.props.setCurrent(key);
                  }
                }
              }}
            >
              {this.routes[key].name}
            </ListItem>
          ) : null
        )}
      </React.Fragment>
    );
  }
}
