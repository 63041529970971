import axios from 'axios';
import { toast } from 'react-toastify';

export function storeThumb(images) {
  return {
    type: 'APPGRID_STORE_THUMB',
    file: images,
  };
}

export function storeGrid(index, grid) {
  return {
    type: 'APPGRID_STORE_GRID',
    file: { index: index, grid: grid },
  };
}
export function removeThumb(index) {
  return {
    type: 'APPGRID_REMOVE_THUMB',
    file: index,
  };
}

export function markThemAll(grid) {
  return {
    type: 'APPGRID_MARK_THEM_ALL',
    file: grid,
  };
}

export function startTrain(warmStart) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    const apiKey = state.appGrid.apiKey;
    const totalImages = state.appGrid.thumbs.length;
    const currentAppName = state[`appGridSelectApp`].current?.name;

    let postProcessingData = null;
    if (state.cameraConfig.postProcessingProcessor) {
      postProcessingData = state.cameraConfig.postProcessingProcessor.serialize();
      postProcessingData.stateZero.active = true;
      postProcessingData.stateOne.active = false;
      postProcessingData.stateTwo.active = false;
      postProcessingData.stateThree.active = false;
    }
    const defaultObject = {
      camera: state.cameraConfig?.currentCamera?.name || 'default',
      apptype: 'appGrid',
      preprocessing: !!state.cameraConfig.preProcessingProcessor ? { data: state.cameraConfig.preProcessingProcessor.serialize() } : { data: null },
      customdata: {
        appGrid: {
          thumbs: state.appGrid.thumbs,
          grids: state.appGrid.grids,
          modelUrl: state.appGrid.modelUrl,
        },
        appFace: {
          people: null,
        },
        appCode: {
          scriptContent: null,
        },
        appClass: {
          classes: state.appClass.classes,
          modelUrl: '',
        },
      },
      postprocessing: { data: postProcessingData },
      output: !!state.cameraConfig.outputProcessor ? { data: state.cameraConfig.outputProcessor.serialize() } : { data: null },
    };

    try {
      // Start Train  \\
      const startTrainResponse = await axios
        .post(`${url}/api/grid/start_train`, {
          api_key: apiKey,
          total_images: totalImages,
          train_type: 'grid',
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPGRID_START_TRAIN', file: err, error: true });
          return Promise.reject('StartTrainError');
        });
      dispatch({ type: 'APPGRID_START_TRAIN', file: startTrainResponse.data });

      //  Send Images \\
      for (const [index, image] of state.appGrid.thumbs.entries()) {
        const sendImageResponse = await axios
          .post(`${url}/api/grid/send_image`, {
            api_key: apiKey,
            train_key: startTrainResponse.data.train_key,
            img: image.split(',')[1],
            mask: state.appGrid.grids[index],
          })
          .catch(err => {
            console.warn(err);
            dispatch({ type: 'APPGRID_SEND_IMAGE', file: err, error: true });
            return Promise.reject('SendImageError');
          });
        dispatch({ type: 'APPGRID_SEND_IMAGE', file: sendImageResponse.data });
      }

      // Prepare \\
      const prepareResponse = await axios
        .post(`${url}/api/grid/prepare`, {
          api_key: apiKey,
          train_key: startTrainResponse.data.train_key,
          model_params: state.appGrid.modelParams,
          dataset_params: state.appGrid.datasetParams,
          warm_start: warmStart,
          model_url: !!warmStart ? state.appGrid.modelUrl : null,
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPGRID_PREPARE', file: err, error: true });
          return Promise.reject('PrepareError');
        });
      dispatch({ type: 'APPGRID_PREPARE', file: prepareResponse.data });

      // Epoch  \\
      var epochResponse = await axios
        .post(`${url}/api/grid/train_epoch`, {
          api_key: apiKey,
          train_key: startTrainResponse.data.train_key,
          nepochs: state.appGrid.numEpochs,
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPGRID_EPOCHS_TRAINING', file: err, error: true });
          return Promise.reject('EpochError');
        });
      dispatch({ type: 'APPGRID_EPOCHS_TRAINING', file: epochResponse.data });

      // wait for done \\
      function modelPoll(fn, timeout = 2000, interval = 100) {
        const endTime = Number(new Date()) + timeout;
        // interval = interval || 100;

        const checkCondition = (resolve, reject) => {
          let ajax = fn();
          ajax.then(response => {
            if (response.data.model_data.model_state === 'done') {
              resolve(response.data);
            } else if (response.data.model_data.model_state === 'failed_model') {
              reject(new Error('Modelo falhou no treinamento'));
            } else if (Number(new Date()) < endTime) {
              // console.log(`Model not done, waiting ${interval}`);
              // console.log(`${response.data.model_data.epoch}`);
              // updating current epoch for state
              dispatch({ type: 'APPGRID_MODEL_STATUS_TRAINING', file: response.data.model_data });
              setTimeout(checkCondition, interval, resolve, reject);
            } else {
              reject(new Error('Tempo limite alcançado'));
            }
          });
        };
        return new Promise(checkCondition);
      }
      const modelStatusResponse = await modelPoll(
        () => {
          return axios.post(`${url}/api/grid/model_status`, {
            api_key: apiKey,
            train_key: startTrainResponse.data.train_key,
          });
        },
        6000000,
        1000
      )
        .then(res => {
          dispatch({ type: 'APPGRID_MODEL_STATUS_TRAINING', file: res.model_data });
          return res;
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPGRID_MODEL_STATUS_TRAINING', file: err, error: true });
          return Promise.reject('ModelStatusError');
        });
      // Finish Train \\
      const finishTrainResponse = await axios
        .post(`${url}/api/grid/finish`, {
          api_key: apiKey,
          train_key: startTrainResponse.data.train_key,
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPGRID_FINISH_TRAINING', file: err, error: true });
          return Promise.reject('FinishTrainingError');
        });
      dispatch({ type: 'APPGRID_FINISH_TRAINING', file: finishTrainResponse.data });

      if (currentAppName) {
        defaultObject.customdata.appGrid.modelUrl = finishTrainResponse.data.url;
        const customAppConfigResponse = axios.put(`${state.appGrid.url}/api/appconfig/appGrid/${currentAppName}`, defaultObject).catch(err => {
          dispatch({
            type: 'APPGRID_SAVE_GRID_AT_BACKGROUND',
            file: err,
            error: true,
          });
        });
        dispatch({ type: 'APPGRID_SAVE_GRID_AT_BACKGROUND', file: true });
      }
    } catch (e) {
      console.warn(e);
    }
  };
}

export function setGridModelUrl(url) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPGRID_SET_GRID_MODEL_URL',
      file: url,
    });
  };
}

export function setGridParams(lr, freeze_percent, batch_norm, batch_size, numEpochs) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPGRID_SET_PARAMS',
      file: {
        modelParams: { lr: lr, freeze_percent: freeze_percent, batch_norm: batch_norm ? 1 : 0 },
        datasetParams: { batch_size: batch_size },
        numEpochs: numEpochs,
      },
    });
  };
}

export function updateThumbData(updateData) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPGRID_UPDATE_THUMB_DATA',
      file: updateData,
    });
  };
}

export function removeThumbData() {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPGRID_REMOVE_THUMB_DATA',
      file: true,
    });
  };
}

export function resetModelParams() {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPGRID_RESET_MODEL_PARAMS',
      file: true,
    });
  };
}

export function setGridPreTraining(param) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPGRID_SET_GRID_PRE_TRAINING',
      file: param,
      needsConfirmation: true,
    });
  };
}

export function getLiteModelUrl(appType, name) {
  return async (dispatch, getState) => {
    let liteModelToastId = null;
    toast.isActive(liteModelToastId)
      ? toast.update(liteModelToastId, { render: 'Requisição em andamento.', type: 'info' })
      : (liteModelToastId = toast.info('Requisição em andamento.'));
    const state = getState();
    const data = { app_name: name };
    if (appType === 'clf') {
      const totalClasses = state.appClass.classes.filter(element => element.images.length > 0);
      data.n_classes = totalClasses.length;
    }

    const modelLiteResponse = await axios
      .post(`${state.appGrid.url}/api/${appType}/get_lite_model`, data)
      .then(res => {
        fetch(res.data.url).then(response => {
          response.blob().then(blob => {
            toast.isActive(liteModelToastId)
              ? toast.update(liteModelToastId, { render: 'Modelo gerado com sucesso.', type: 'success' })
              : (liteModelToastId = toast.success('Modelo gerado com sucesso.'));
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'model.tflite';
            a.click();
          });
        });
        return res;
      })
      .catch(err => {
        console.warn(err);
        dispatch({ type: 'APPGRID_EPOCHS_TRAINING', file: err, error: true });
        return Promise.reject('DownloadError');
      });

    // dispatch({ type: 'APPGRID_EPOCHS_TRAINING', file: modelLiteResponse.data });
  };
}
