import styled, { keyframes } from 'styled-components';
import { merge, fadeIn, fadeInLeft, slideInLeft, slideInRight, slideInDown } from 'react-animations';

// import { bounceOut, bounce, bounceIn, bounceInDown, bounceInLeft, bounceInRight, bounceInUp, bounceOutDown, bounceOutLeft, bounceOutRight,  bounceOutUp,   fadeIn, fadeInDown,   fadeInDownBig,   fadeInLeft,  fadeInLeftBig,  fadeInRight,  fadeInRightBig,  fadeInUp,  fadeInUpBig,  fadeOut,   fadeOutDown,   fadeOutDownBig, fadeOutLeft,   fadeOutLeftBig,   fadeOutRight,   fadeOutRightBig,   fadeOutUp,   fadeOutUpBig,   flash, flip,   flipInX,   flipInY,   flipOutX,  flipOutY,  headShake, hinge,  jello,   lightSpeedIn,  lightSpeedOut,  pulse,  rollIn,  rollOut,  rotateIn,  rotateInDownLeft,  rotateInDownRight,  rotateInUpLeft,  rotateInUpRight,  rotateOut,  rotateOutDownLeft,  rotateOutDownRight,  rotateOutUpLeft,  rotateOutUpRight,  rubberBand,  shake,  slideInDown,  slideInLeft,  slideInRight,  slideInUp,  slideOutDown,  slideOutLeft,   slideOutRight,  slideOutUp,  swing,  tada,  wobble,  zoomIn,  zoomInDown,  zoomInLeft,  zoomInRight,   zoomInUp,  zoomOut,  zoomOutDown,  zoomOutLeft,  zoomOutRight,  zoomOutUp } from 'react-animations';

// BUILD THE ANIMATION IF YOU NEED TO USE IT
// const bounceOutAnimation = keyframes`${bounceOut}`;
// export const BounceOut = styled.div`animation: 1s ${bounceOutAnimation};`;

const fadeInAnimation = keyframes`${fadeIn}`;
export const FadeIn = styled.div`
  animation: 750ms ${fadeInAnimation};
`;

const fadeInLeftAnimation = keyframes`${fadeInLeft}`;
export const FadeInLeft = styled.div`
  animation: 750ms ${fadeInLeftAnimation};
`;

const slideInLeftAnimation = keyframes`${slideInLeft}`;
export const SlideInLeft = styled.div`
  animation: 750ms ${slideInLeftAnimation};
`;

const slideInRightAnimation = keyframes`${slideInRight}`;
export const SlideInRight = styled.div`
  animation: 750ms ${slideInRightAnimation};
`;

const slideInDownAnimation = keyframes`${slideInDown}`;
export const SlideInDown = styled.div`
  animation: 750ms ${slideInDownAnimation};
`;
