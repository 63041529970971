import React from 'react';
import { TextField, Button } from 'react-md';
import { toast } from 'react-toastify';

export default class IntegerConfig extends React.Component {
  integerConfigNumberInputToastId = null;
  integerConfigNumberInputToast = toast;

  constructor(props) {
    super(props);
    this.state = {};
  }

  validateField(number) {
    if (typeof Number(number.trim()) === 'number' || number.trim() === '') {
      if ((number >= this.props.min || number >= 0) && (!this.props.max || number <= this.props.max)) {
        this.props.setDialogState({ integerValue: Number(number) || this.props.min || 0 });
      } else {
        if (!toast.isActive(this.integerConfigNumberInputToastId)) {
          this.integerConfigNumberInputToastId = this.integerConfigNumberInputToast.error('Valores fora do limite.');
        }
      }
    } else if (!toast.isActive(this.integerConfigNumberInputToastId)) {
      this.integerConfigNumberInputToastId = this.integerConfigNumberInputToast.error('Valor incoerente.');
    }
  }

  render() {
    return (
      <div className='flex flex-wrap config-box first-config-box'>
        <h3 className='flex w-100 flex-center' style={{ margin: '4px 0px 14px' }}>
          Condição para ativação
        </h3>
        <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
          Operador
          <Button
            className='post-processing-config-height'
            style={{ backgroundColor: '#223f54', color: 'white', width: '130px', height: '36px' }}
            onClick={() => this.props.setDialogState({ integerRule: (this.props.integerRule + 1) % this.props.maskRules.length })}
          >
            {this.props.maskRules[this.props.integerRule]}
          </Button>
        </div>
        <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
          Valor
          <TextField
            className='post-processing-config-height'
            style={{ width: '130px' }}
            id='integerValue'
            type='number'
            min={this.props.min || 0}
            max={this.props.max}
            step={1}
            theme='outline'
            placeholder={null}
            label={null}
            value={this.props.integerValue.toString()}
            onChange={event => this.validateField(event.target.value)}
          />
        </div>
      </div>
    );
  }
}
