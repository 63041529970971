import * as tf from '@tensorflow/tfjs';

tf.ENV.set('WEBGL_FORCE_F16_TEXTURES', true);
tf.enableProdMode();

class MobileNetNormalization extends tf.layers.Layer {
  constructor() {
    super({});
  }
  static get className() {
    return 'MobileNetNormalization';
  }
  computeOutputShape(inputShape) {
    return inputShape;
  }
  call(inputs, kwargs) {
    this.invokeCallHook(inputs, kwargs);
    return tf.sub(tf.div(tf.cast(inputs[0], 'float32'), tf.scalar(127.5)), tf.scalar(1));
  }
}

class FaceNetScaling extends tf.layers.Layer {
  constructor(config) {
    super(config);
    this.scale = config.scale;
  }
  static get className() {
    return 'FaceNetScaling';
  }
  computeOutputShape(inputShape) {
    return inputShape;
  }
  call(inputs, kwargs) {
    this.invokeCallHook(inputs, kwargs);
    return tf.mul(inputs[0], tf.scalar(this.scale, 'float32'));
  }
}

tf.serialization.SerializationMap.register(MobileNetNormalization);
tf.serialization.SerializationMap.register(FaceNetScaling);
