import React from 'react';
import { bindActionCreators } from 'redux';
import { setThumbData } from '../../../../actions/appClass';
import { connect } from 'react-redux';

class SampleSectionThumbs extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.currentThumbIndex !== this.props.index && nextProps.currentThumbIndex !== this.props.index) {
      if (this.props.thumb !== nextProps.thumb) {
        return true;
      }
      return false;
    }
    return true;
  }

  render() {
    return (
      <div
        className='fitted'
        id={`appClassThumb-${this.props.index}`}
        style={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => this.props.setThumbData(this.props.index, this.props.thumb)}
      >
        <img
          alt='appClass thumb'
          className='thumb-image-four flex'
          style={{
            maxWidth: '100px',
            maxHeight: '100px',
            height: 'auto',
            border: this.props.currentThumbIndex === this.props.index && this.props.showingImageClassIndex === this.props.currentClassIndex ? '3px solid black' : 'none',
          }}
          src={this.props.thumb}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentThumbIndex: state.appClass.currentThumbIndex,
  currentThumbImg: state.appClass.currentThumbImg,
  showingImageClassIndex: state.appClass.showingImageClassIndex,
  currentClassIndex: state.appClass.currentClassIndex,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setThumbData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SampleSectionThumbs);
