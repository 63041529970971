import React from 'react';
import { Dialog, DialogHeader, DialogContent, DialogTitle, DialogFooter, Button, CircularProgress, Select } from 'react-md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './cameraDialog.scss';
import { changeCamera } from '../../actions/cameraConfig';
import { toast } from 'react-toastify';

class CameraDialog extends React.Component {
  toastId = null;
  msg = 'Selecione uma câmera para continuar.';
  constructor(props) {
    super(props);
    this.state = {
      cameraNameToSave: props.currentCamera?.name || '',
      loading: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cameraAppliedSuccess < this.props.cameraAppliedSuccess) {
      this.props.close();
    } else if (prevProps.cameraAppliedSuccess > this.props.cameraAppliedSuccess) {
      this.setState({ loading: false });
    }
  }

  render() {
    // <div style={{ margin: '30px auto 0', textAlign: 'center', maxWidth: '300px' }}>
    //   <a style={{ color: '#b73e11', fontWeight: '600' }} href={process.env.PUBLIC_URL + '/pdf/Manual _VLC-RTSP _Mvisia.pdf'} download>
    //     Clique aqui para visualizar instruções de acesso a câmeras IP via protocolo RTSP
    //   </a>
    // </div>

    return (
      <>
        <Dialog
          id='simple-dialog-camera'
          visible={true}
          onRequestClose={() => {
            if (!this.state.loading) {
              this.props.close();
            }
          }}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader>
            <DialogTitle className='w-100 flex flex-center'>Seleção de câmera</DialogTitle>
          </DialogHeader>
          <DialogContent>
            <Select
              className='w-100 camera-card-text-field'
              disabled={this.props.allCameras.length < 1}
              listboxStyle={{ maxHeight: '300px' }}
              theme='outline'
              id='cameraDialogCameraSelect'
              options={this.props.allCameras.map(camera => camera.name)}
              value={this.state.cameraNameToSave}
              name='cameraDialogSelect'
              label='Câmera'
              onChange={value => this.setState({ cameraNameToSave: value })}
              anchor={{ x: 'center', y: 'below' }}
            />
            <DialogFooter className='flex flex-between' style={{ padding: '0' }}>
              <Button disabled={this.state.loading} className='upper-button' theme='error' themeType='contained' onClick={() => this.props.close()}>
                cancelar
              </Button>
              {this.state.loading ? (
                <div className='upper-button flex flex-center' style={{ borderRadius: '0.5rem', cursor: 'not-allowed', height: '44px', backgroundColor: '#cccccc' }}>
                  <CircularProgress
                    id={`cameraDialogSaveButton`}
                    className='determinate-example-circular appbar-action'
                    centered={true}
                    style={{ borderColor: 'white', height: '85%' }}
                  />
                </div>
              ) : (
                <Button
                  className='upper-button'
                  theme='primary'
                  themeType='contained'
                  onClick={() => {
                    if (this.state.cameraNameToSave !== '') {
                      const camera = this.props.allCameras.find(cameraName => cameraName.name === this.state.cameraNameToSave);
                      // this.props.close();
                      this.setState({ loading: true });
                      this.props.changeCamera(camera);
                    } else {
                      toast.isActive(this.toastId) ? toast.update(this.toastId, { render: this.msg }) : (this.toastId = toast.error(this.msg));
                    }
                  }}
                >
                  salvar
                </Button>
              )}
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  allCameras: state.cameraConfig.allCameras,
  cameraAppliedSuccess: state.cameraConfig.cameraAppliedSuccess,
  currentCamera: state.cameraConfig.currentCamera,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeCamera,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CameraDialog);
