import React from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
let tokenToastId = null;
let dataToastId = null;

export function setOutputProcessor(data) {
  return (dispatch, getState) => {
    dispatch({
      type: 'OUTPUT_SET_PROCESSOR',
      file: data,
    });
  };
}

export function sendEmailNotification(data) {
  return (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    axios
      .post(`${url}/api/user/email_notification`, data)
      .then(res => {
        dispatch({
          type: 'OUTPUT_SEND_EMAIL_NOTIFICATION',
          file: res,
        });
      })
      .catch(err => {
        dispatch({
          type: 'OUTPUT_SEND_EMAIL_NOTIFICATION',
          file: err,
          error: true,
        });
      });
  };
}

export function getHttpToken(tokenRequestUrl, tokenRequestData, dataToSend, http) {
  try {
    let headers = {};
    return async (dispatch, getState) => {
      const state = getState();
      const httpTokenResponse = await state.main.httpOutputAxios[http.httpTokenRequestType.toLowerCase()](tokenRequestUrl, tokenRequestData).catch(err => {
        dispatch({
          type: 'OUTPUT_GET_HTTP_TOKEN',
          file: err,
          error: true,
        });
        const msg = (
          <div className='w-100'>
            <div className='w-100'>Erro ao buscar token http.</div>
            {err.response ? (
              <>
                <br />
                <div className='w-100' style={{ marginBottom: '4px' }}>
                  Código: {err.response?.status}
                </div>
                <div className='w-100'>Erro: {err.response?.statusText}</div>
              </>
            ) : null}
          </div>
        );
        toast.isActive(tokenToastId) ? toast.update(tokenToastId, { render: msg }) : (tokenToastId = toast.error(msg));
        return Promise.reject(false);
      });
      headers.Authorization = `${http.httpAuthType} ${httpTokenResponse.data[http.httpTokenAlias]}`;
      await state.main.httpOutputAxios[http.httpRequestType.toLowerCase()](`${http.httpAddress}`, dataToSend, { headers: headers }).catch(err => {
        dispatch({
          type: 'OUTPUT_SEND_HTTP_NOTIFICATION',
          file: err,
          error: true,
        });
        const msg = (
          <div className='w-100'>
            <div className='w-100'>Erro ao enviar saída via http.</div>
            {err.response ? (
              <>
                <br />
                <div className='w-100' style={{ marginBottom: '4px' }}>
                  Código: {err.response?.status}
                </div>
                <div className='w-100'>Erro: {err.response?.statusText}</div>
              </>
            ) : null}
          </div>
        );
        toast.isActive(dataToastId) ? toast.update(dataToastId, { render: msg }) : (dataToastId = toast.error(msg));
      });
      dispatch({
        type: 'OUTPUT_SEND_HTTP_NOTIFICATION',
        file: null,
      });
      return Promise.resolve(true);
    };
  } catch (e) {
    return false;
  }
}

export function sendNoAuthHttpData(http, dataToSend) {
  return (dispatch, getState) => {
    const state = getState();

    state.main.httpOutputAxios[http.httpRequestType.toLowerCase()](`${http.httpAddress}`, dataToSend)
      .then(res => {
        dispatch({
          type: 'OUTPUT_SEND_HTTP_NOTIFICATION',
          file: true,
        });
      })
      .catch(err => {
        dispatch({
          type: 'OUTPUT_SEND_HTTP_NOTIFICATION',
          file: err,
          error: true,
        });
        const msg = (
          <div className='w-100'>
            <div className='w-100'>Erro ao enviar saída via http.</div>
            {err.response ? (
              <>
                <br />
                <div className='w-100' style={{ marginBottom: '4px' }}>
                  Código: {err.response?.status}
                </div>
                <div className='w-100'>Erro: {err.response?.statusText}</div>
              </>
            ) : null}
          </div>
        );
        toast.isActive(dataToastId) ? toast.update(dataToastId, { render: msg }) : (dataToastId = toast.error(msg));
      });
  };
}
