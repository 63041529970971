import produce from 'immer';
// import { toast } from 'react-toastify';

export default function appCodeReducer(state, action) {
  if (action.error) {
    return state;
  } else {
    switch (action.type) {
      case 'APPCODE_SET_SCRIPT_CONTENT':
        return produce(state, draft => {
          draft.appCode.scriptContent = action.file;
          draft.appCode.pendingSave = true;
        });
      default:
        return state;
    }
  }
}
