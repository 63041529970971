import ReactGA from 'react-ga';

export function changeCurrent(current) {
  ReactGA.pageview('plataforma/' + current);
  return {
    type: 'MAIN_CHANGE_CURRENT',
    file: current,
    needsConfirmation: true,
  };
}

export function handleMenu(param) {
  return {
    type: 'MAIN_HANDLE_MENU',
    file: param,
  };
}
export function handleNewAppDialog(param) {
  return {
    type: 'MAIN_HANDLE_NEW_APP_DIALOG',
    file: param,
  };
}

export function setCreatingAppConfig(param) {
  return {
    type: 'MAIN_SET_CREATING_APP_CONFIG',
    file: param,
  };
}

export function setInterceptorInstance(param) {
  return {
    type: 'MAIN_SET_INTERCEPTOR_INSTANCE',
    file: param,
  };
}

export function setHttpOutputAxios(httpOutputAxios) {
  return {
    type: 'MAIN_SET_HTTP_OUTPUT_AXIOS',
    file: httpOutputAxios,
  };
}

export function finishTutorial() {
  return {
    type: 'MAIN_FINISH_TUTORIAL',
    file: true,
  };
}

export function setOnlineState(online) {
  return {
    type: 'MAIN_SET_ONLINE_STATE',
    file: online,
  };
}

export function setConnectedApp(connected) {
  return {
    type: 'MAIN_SET_CONNECTED_APP',
    file: connected,
  };
}

export function setConfirmationInfo(confirmationDialogVisible, confirmationDialogText, confirmationDialogAnswer) {
  return {
    type: 'MAIN_SET_CONFIRMATION_INFO',
    file: { confirmationDialogVisible, confirmationDialogText, confirmationDialogAnswer },
  };
}
