import React from 'react';
import { Form, Select } from 'react-md';

export default class Notification extends React.Component {
  positions = ['top-left', 'top-center', 'top-right', 'bottom-left', 'bottom-center', 'bottom-right'];
  positionsBR = ['Esquerda superior', 'Centro superior', 'Direita superior', 'Esquerda inferior', 'Centro inferior', 'Direita inferior'];

  render() {
    return (
      <div className='tab-content-wrapper'>
        <Form className='w-100'>
          <Select
            disabled={!this.props.active}
            style={{ height: '56px', margin: '16px 0' }}
            theme='outline'
            id='notificationDropdown'
            options={this.positionsBR}
            value={this.props.notificationPosition[Object.keys(this.props.notificationPosition)[0]]}
            name='notificationDropdown'
            label='Posição da notificação'
            onChange={val => this.props.setDialogState({ notificationPosition: { [this.positions[this.positionsBR.findIndex(el => el === val)]]: val } })}
            disableMovementChange={true}
          />
        </Form>
      </div>
    );
  }
}
