import React from 'react';
import { cvMatToTensor, updateCanvasDimension } from '../../../camera';
import { Schedule } from '../../../schedule';
import * as tf from '@tensorflow/tfjs';
import * as faceapi from 'face-api.js';
import * as lodash from 'lodash';

export default class EPIEngine extends React.Component {
  cameraSchedule = null;

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.canvas = document.createElement('canvas');
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
    };
    this.initDefaultCam();
  }

  initDefaultCam() {
    this.props.setCamera({ name: '///', data: { type: 'systemCam', id: null, interval: null } });
  }

  proc = async () => {
    let data = {
      timestamp: { type: this.props.cameraRef?.type, value: this.props.cameraRef?.getTimestamp() },
    };

    let out = this.props.cameraRef?.captureStreamImg();
    if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }

    if (this.props.preProcessingProcessor) {
      out = this.props.preProcessingProcessor.process(out);
      if (this.props.preProcessingProcessor.data.roi && (this.state.cols !== out.cols || this.state.rows !== out.rows)) {
        this.setState({ cols: out.cols, rows: out.rows });
      }
    }

    if (!lodash.has(this, 'fdopts') || !lodash.has(this, 'clfmodel')) {
      window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
      window.cv.imshow(this.canvasRef.current, out);
      out.delete();
      return;
    }

    window.cv.imshow(this.canvas, out);

    let detection = await faceapi.detectSingleFace(this.canvas, this.fdopts);
    let postProcessingResponse = null;

    if (detection !== undefined) {
      let point1 = new window.cv.Point(detection.box.x, detection.box.y);
      let point2 = new window.cv.Point(detection.box.x + detection.box.width, detection.box.y + detection.box.height);
      let roi_rect = new window.cv.Rect(Math.floor(detection.box.x), Math.floor(detection.box.y), Math.floor(detection.box.width), Math.floor(detection.box.height));

      if (roi_rect.x + roi_rect.width < this.canvasRef.current?.width - 2 && roi_rect.y + roi_rect.height < this.canvasRef.current?.height - 2) {
        let roi = out.roi(roi_rect);
        window.cv.cvtColor(roi, roi, window.cv.COLOR_BGR2RGB);
        window.cv.resize(roi, roi, new window.cv.Size(128, 128), 0, 0, window.cv.INTER_CUBIC);

        let clf = tf.tidy(() => {
          let tensor = cvMatToTensor(roi);
          return this.clfmodel.predict(tf.expandDims(tensor, 0));
        });
        let clfval = await clf.data();
        if (clfval[1] < 0.5) window.cv.rectangle(out, point1, point2, [255, 0, 0, 255], 2);
        else window.cv.rectangle(out, point1, point2, [0, 255, 0, 255], 2);
        clf.dispose();

        // mask on
        // console.log(clfval[1] > 0.5)

        data.hasFace = true;
        data.hasEPI = clfval[1] > 0.5;

        roi.delete();
      }
    } else {
      data.hasFace = false;
      data.hasEPI = false;
    }

    data.img = out;

    if (!!this.props.postProcessingProcessor) {
      postProcessingResponse = this.props.postProcessingProcessor.process(data);
    }
    if (this.props.outputProcessor) {
      this.props.outputProcessor.process(postProcessingResponse, this.props.activeCircle, data);
    }
    if (!!this.props.postProcessingProcessor) {
      this.props.setActiveCircle(postProcessingResponse?.activeState);
    }

    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
    window.cv.imshow(this.canvasRef.current, out);
    out.delete();

    if (!!this.allowApplySettings) {
      this.props.applyVideoSettings(this.settingsToSave);
      this.allowApplySettings = false;
      this.settingsToSave = {};
    }
  };

  async componentDidMount() {
    await faceapi.nets.ssdMobilenetv1.loadFromUri(`${process.env.PUBLIC_URL}/facedetection`).catch(err => console.log('KKKKKKKKKK' + err));
    this.fdopts = new faceapi.SsdMobilenetv1Options();
    window.canvas = this.canvas;
    this.clfmodel = await tf.loadLayersModel(`${process.env.PUBLIC_URL}/facedetection/model.json`, { strict: false });
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!!nextProps.settings && JSON.stringify(nextProps.settings) !== JSON.stringify(this.props.settings) && !this.allowApplySettings) {
      this.allowApplySettings = true;
      this.settingsToSave = { ...nextProps.settings };
    }
    if (this.state.cols !== nextState.cols || this.state.rows !== nextState.rows) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (!!this.props.preProcessingProcessor?.data.roi && !nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension();
      return true;
    }
    if (!this.props.preProcessingProcessor?.data.roi && !!nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (this.canvasRef.current?.width > 0 && this.canvasRef.current?.height > 0 && this.props.cameraRef?.type === nextProps.cameraRef?.type) {
      if (this.props.setActiveCircle !== nextProps.setActiveCircle) {
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cameraRef?.type !== this.props.cameraRef?.type) {
      this.setCanvasDimension();
      if (!!this.props.cameraRef) {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
        this.cameraSchedule = new Schedule(this.proc, this.props.cameraRef?.getInterval());
      } else {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
      }
    }
  }

  setCanvasDimension(preProcessWidth, preProcessHeight) {
    const newDimensions = updateCanvasDimension(this.props.cameraRef, this.state.canvasWidth, this.state.canvasHeight, preProcessWidth, preProcessHeight);
    this.setState(newDimensions);
  }

  render() {
    return <canvas className='w-100' style={{ height: 'auto', objectFit: 'contain' }} ref={this.canvasRef} width={this.state.canvasWidth} height={this.state.canvasHeight} />;
  }
}
