import React from 'react';
import Loading from '../loading';
import AppGrid from './appGrid/appGrid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AppCode from './appCode/appCode';
import AppFace from './appFace/appFace';
import AppEPI from './appEPI/appEPI';
import Apps from './apps/apps';
import './app.scss';
import Login from './login/login';
import { WebglError } from './webglError/webglError';
import * as tf from '@tensorflow/tfjs';
import AppClass from './appClass/appClass';
import LocalConfig from './localConfig/localConfig';
import { toast } from 'react-toastify';
import { setOnlineState, changeCurrent } from '../../actions/main';
import CameraConfig from './cameraConfig/cameraConfig';
import AppQuality from './appQuality/appQuality';
import LicenseGroups from './license/licenseGroups';

class App extends React.Component {
  apps = [
    { name: 'appClass', path: <AppClass /> },
    { name: 'appGrid', path: <AppGrid /> },
    { name: 'appEPI', path: <AppEPI /> },
    { name: 'appFace', path: <AppFace /> },
    { name: 'appCode', path: <AppCode /> },
    { name: 'localConfig', path: <LocalConfig /> },
    { name: 'cameraConfig', path: <CameraConfig /> },
    { name: 'appQuality', path: <AppQuality /> },
    { name: 'licenseGroups', path: <LicenseGroups /> },
  ];
  constructor(props) {
    super(props);
    this.state = {};
    // old method to detect offline - it is still usefull since wee need to know from where to fetch apps at localConfig
  }

  setOfflineByUrl(offlineMode) {
    if (offlineMode) {
      this.props.setOnlineState(false);
      return true;
    }
    this.props.setOnlineState(true);
    return false;
  }

  detectWebGLContext() {
    return tf.ENV.get('WEBGL_RENDER_FLOAT32_CAPABLE');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.applicationIsOnline === true && this.props.applicationIsOnline === false) {
      toast.warn('Conexão desabilitada. Modo inferência ativado.');
    }
  }

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    const hasOfflineApp = urlParams.get('offline');
    const hasId = urlParams.get('id');
    const offlineAppType = urlParams.get('appType');
    const offlineAppConfig = urlParams.get('appConfig');

    const offlineMode = this.setOfflineByUrl(hasOfflineApp && hasId && offlineAppType && offlineAppConfig);

    if (!this.props.cvloaded) return <Loading />;

    if (offlineMode) {
      // if offline, main page will be LocalConfig
      const nextAppIndex = this.apps.findIndex(app => app.name === offlineAppType);
      if (nextAppIndex > -1) {
        this.props.changeCurrent(offlineAppType);
        return <div className='main-content flex flex-center'>{this.apps[nextAppIndex].path}</div>;
      }
      return (
        <div className='main-content flex flex-center'>
          <Apps />
        </div>
      );
    }
    if (!this.props.accessToken) {
      if (this.detectWebGLContext()) {
        return (
          <div className='main-content flex flex-center'>
            <Login />
          </div>
        );
      } else {
        return (
          <div className='main-content flex flex-center'>
            <WebglError />
          </div>
        );
      }
    }
    let appToRender = (
      <div className='main-content flex flex-center'>
        <Apps />
      </div>
    );
    this.apps.some((app, index) => {
      if (this.props.current === app.name) {
        appToRender = <div className='main-content flex flex-center'>{app.path}</div>;
        return true;
      }
      return false;
    });
    return appToRender;
  }
}

const mapStateToProps = state => ({
  current: state.main.current,
  cvloaded: state.main.cvloaded,
  accessToken: state.user.accessToken,
  applicationIsOnline: state.main.applicationIsOnline,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setOnlineState, changeCurrent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
