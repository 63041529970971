import React from 'react';
import { Select } from 'react-md';
import InputRange from 'react-input-range';
import { SlideInLeft, SlideInRight } from '../../../../animation';
import { QualityIdentification } from '../../qualityEngineClasses/qualityIdentificationClass';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setIdentificationValuesAtStore, setTabRef } from '../../../../../actions/appQuality';
import * as lodash from 'lodash';

class IdentificationTab extends React.Component {
  animationComponent = SlideInRight;

  // minimalAreaToastId = null;
  // resetAfterErrorToastId = null;

  minimalAreaMin = 0.2;
  minimalAreaMax = 100;

  linePositionMin = 0;
  linePositionMax = 100;

  maxDistanceYMin = 0;
  maxDistanceYMax = 100;

  maxDistanceXMin = 0;
  maxDistanceXMax = 100;

  morphologicalSizeXMin = 1;
  morphologicalSizeXMax = 49;

  morphologicalSizeYMin = 1;
  morphologicalSizeYMax = 49;

  binarizationMethods = [
    { label: 'Cor (RGB)', value: 'rgbBinarization' },
    { label: 'Subtração de Fundo', value: 'backgroundSubtractionBinarization' },
    { label: 'Grid', value: 'gridBinarization' },
  ];

  morphologicalOperators = [
    { label: 'Erosão', value: 'erosion' },
    { label: 'Dilatação', value: 'dilation' },
    { label: 'Abertura', value: 'opening' },
    { label: 'Fechamento', value: 'closing' },
  ];

  morphologicalElements = [
    { label: 'Retângulo', value: 'rectangle' },
    { label: 'Elipse', value: 'ellipsis' },
    { label: 'Cruz', value: 'cross' },
  ];

  movementDirection = [
    { label: 'Cima-Baixo', value: 'upDown' },
    { label: 'Esquerda-Direita', value: 'leftRight' },
    { label: 'Baixo-Cima', value: 'downUp' },
    { label: 'Direita-Esquerda', value: 'rightLeft' },
  ];

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     // fixingFieldTimeout: null,
  //     // fixingFieldId: '',
  //   };
  // }

  // validatePositiveIntField(number, key, defaultValue, min, max, toastIdVar, elementId) {
  //   if (number.trim() === '') {
  //     if (!this.state.fixingFieldTimeout) {
  //       this.emptyFieldHandler(number, key, defaultValue, elementId);
  //     }
  //   } else if (typeof Number(number.trim()) === 'number' && Number.isInteger(Number(number))) {
  //     if (number >= min && number <= max) {
  //       clearTimeout(this.state.fixingFieldTimeout);
  //       this.setState({ [key]: Number(number) || defaultValue, fixingFieldTimeout: null, fixingFieldId: '', trigger: this.state.trigger * -1 });
  //     } else {
  //       const msg = 'Valor fora do limite.';
  //       toast.isActive(this[toastIdVar]) ? toast.update(this[toastIdVar], { type: 'error', render: msg }) : (this[toastIdVar] = toast.error(msg));
  //     }
  //   } else if (!toast.isActive(this[toastIdVar])) {
  //     const msg = 'Valor incoerente.';
  //     toast.isActive(this[toastIdVar]) ? toast.update(this[toastIdVar], { type: 'error', render: msg }) : (this[toastIdVar] = toast.error(msg));
  //   }
  // }

  // emptyFieldHandler(number, key, defaultValue, elementId) {
  //   if (!this.state.fixingFieldTimeout) {
  //     this.setState({
  //       [key]: Number(number),
  //       fixingFieldTimeout: setTimeout(this.resetAfterError.bind(this), 4000, number, key, defaultValue),
  //       fixingFieldId: elementId,
  //     });
  //   }
  // }

  // resetAfterError(number, key, defaultValue) {
  //   clearTimeout(this.state.fixingFieldTimeout);
  //   this.setState({ fixingFieldTimeout: null, [key]: defaultValue, fixingFieldId: '' });
  //   const msg = 'O valor inserido é incompatível e foi redefinido para o padrão.';
  //   toast.isActive(this.resetAfterErrorToastId) ? toast.update(this.resetAfterErrorToastId, { type: 'error', render: msg }) : (this.resetAfterErrorToastId = toast.error(msg));
  // }

  // validateOddIntField(number, key, defaultValue, min, max, toastIdVar, elementId) {
  //   if (number.trim() === '') {
  //     if (!this.state.fixingFieldTimeout) {
  //       this.emptyFieldHandler(number, key, defaultValue, elementId);
  //     }
  //   } else if (typeof Number(number.trim()) === 'number' && Number.isInteger(Number(number))) {
  //     if (number >= min && number <= max && Number(number) % 2 !== 0) {
  //       clearTimeout(this.state.fixingFieldTimeout);
  //       this.setState({ [key]: Number(number) || defaultValue, fixingFieldTimeout: null, fixingFieldId: '' });
  //     } else if (Number(number) % 2 !== 0 || !number) {
  //       const msg = 'Valor fora do limite.';
  //       toast.isActive(this[toastIdVar]) ? toast.update(this[toastIdVar], { type: 'error', render: msg }) : (this[toastIdVar] = toast.error(msg));
  //     } else {
  //       const msg = 'Este campo é destinado somente a valores ímpares.';
  //       toast.isActive(this[toastIdVar]) ? toast.update(this[toastIdVar], { type: 'error', render: msg }) : (this[toastIdVar] = toast.error(msg));
  //     }
  //   } else if (!toast.isActive(this[toastIdVar])) {
  //     const msg = 'Valor incoerente.';
  //     toast.isActive(this[toastIdVar]) ? toast.update(this[toastIdVar], { type: 'error', render: msg }) : (this[toastIdVar] = toast.error(msg));
  //   }
  // }

  getParams() {
    return {
      binarization: this.props.identification.binarization,
      filtering: this.props.identification.filtering,
      detection: this.props.identification.detection,
    };
  }

  updateDataAtStore(key, data) {
    if (data !== null) {
      this.props.setIdentificationValuesAtStore(key, data);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentIndex !== this.props.myIndex && this.props.currentIndex === this.props.myIndex) {
      this.props.setTabRef(new QualityIdentification(this.getParams()), 2);
    }
    if (!lodash.isEqual(prevProps.identification, this.props.identification)) {
      this.props.setTabRef(new QualityIdentification(this.getParams()), 2);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.currentIndex !== nextProps.currentIndex) {
      if (this.props.currentIndex < this.props.myIndex) {
        //RIGHT-LEFT ANIMATION
        this.animationComponent = SlideInRight;
      } else if (this.props.currentIndex > this.props.myIndex) {
        //LEFT-RIGHT ANIMATION
        this.animationComponent = SlideInLeft;
      }
    }
    return true;
  }

  render() {
    const components = {
      rgbBinarization: (
        <RgbBinarization
          red={this.props.identification.binarization.red}
          green={this.props.identification.binarization.green}
          blue={this.props.identification.binarization.blue}
          setColorAtParent={colorObject => {
            this.updateDataAtStore('binarization', { ...this.props.identification.binarization, ...colorObject });
          }}
        />
      ),
      backgroundSubtractionBinarization: <BackgroundSubtractionBinarization />,
      gridBinarization: <GridBinarization />,
    };
    const AnimationComponent = this.animationComponent;
    return (
      <div className='tab-content'>
        <AnimationComponent>
          <div className='w-100 flex flex-wrap' style={{ justifyContent: 'space-between', minHeight: '310px' }}>
            <div className='quality-binarization'>
              <div className='w-100 flex flex-center capabilities-span' style={{ margin: '18px 0', position: 'relative' }}>
                <div className='w-100 h-100 flex flex-center'>Binarizaçao</div>
                <div style={{ position: 'absolute', right: 0 }}>
                  <Toggle
                    id={`toggleBinarization`}
                    checked={this.props.identification.binarization.active}
                    icons={false}
                    onChange={event => {
                      this.updateDataAtStore('binarization', { ...this.props.identification.binarization, active: event.target.checked });
                    }}
                  />
                </div>
              </div>
              <div className='conditional-wrapper'>
                <Select
                  className='w-100'
                  listboxStyle={{ maxHeight: '300px' }}
                  theme='outline'
                  id='qualityAnalysisBinarizationMethodSelect'
                  options={this.binarizationMethods}
                  value={this.props.identification.binarization.currentBinarizationMethod}
                  name='qualityAnalysisBinarizationMethodSelect'
                  label='Tipo de Segmentação'
                  onChange={value => {
                    if (!!value) {
                      this.updateDataAtStore('binarization', {
                        ...this.props.identification.binarization,
                        currentBinarizationMethod: value,
                      });
                    }
                  }}
                  anchor={{ x: 'center', y: 'below' }}
                />
                {components[this.props.identification.binarization.currentBinarizationMethod]}
              </div>
            </div>

            <div className='flex flex-column quality-morphology' style={{ justifyContent: 'space-between' }}>
              <div className='w-100 flex flex-center capabilities-span' style={{ margin: '18px 0', position: 'relative' }}>
                <div className='w-100 h-100 flex flex-center'>Filtragem</div>
                <div style={{ position: 'absolute', right: 0 }}>
                  <Toggle
                    id={`toggleFiltering`}
                    checked={this.props.identification.filtering.active}
                    icons={false}
                    onChange={event => this.updateDataAtStore('filtering', { ...this.props.identification.filtering, active: event.target.checked })}
                  />
                </div>
              </div>
              <Select
                className='w-100'
                listboxStyle={{ maxHeight: '300px' }}
                theme='outline'
                id='qualityAnalysisMorphologicalOperators'
                options={this.morphologicalOperators}
                value={this.props.identification.filtering.currentMorphologicalOperator}
                name='qualityAnalysisMorphologicalOperators'
                label='Operação Morfológica'
                onChange={value => {
                  if (!!value) {
                    this.updateDataAtStore('filtering', {
                      ...this.props.identification.filtering,
                      currentMorphologicalOperator: value,
                    });
                  }
                }}
                anchor={{ x: 'center', y: 'below' }}
              />

              <Select
                className='w-100 qualityAnalysisSeparator'
                listboxStyle={{ maxHeight: '300px' }}
                theme='outline'
                id='qualityAnalysisMorphologicalElement'
                options={this.morphologicalElements}
                value={this.props.identification.filtering.currentMorphologicalElement}
                name='qualityAnalysisMorphologicalElements'
                label='Elemento Morfológico'
                onChange={value => {
                  if (!!value) {
                    this.updateDataAtStore('filtering', {
                      ...this.props.identification.filtering,
                      currentMorphologicalElement: value,
                    });
                  }
                }}
                anchor={{ x: 'center', y: 'below' }}
              />

              <div id='qualityAnalysisMorphologicalSizeXContainer' className='w-100 flex' style={{ margin: '42px 0' }}>
                <span className='common-slider-title' style={{ width: '90px' }}>
                  Tamanho X
                </span>
                <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                  <InputRange
                    id='qualityAnalysisMorphologicalSizeX'
                    labelContainer='Tamanho X'
                    name='qualityAnalysisMorphologicalSizeX'
                    formatLabel={value => `${value}`}
                    step={1}
                    minValue={this.morphologicalSizeXMin}
                    maxValue={this.morphologicalSizeXMax}
                    value={this.props.identification.filtering.morphologicalSizeX}
                    onChange={value => {
                      let newValue = value % 2 === 0 ? value + 1 : value;
                      this.updateDataAtStore('filtering', { ...this.props.identification.filtering, morphologicalSizeX: newValue });
                    }}
                  />
                </div>
              </div>

              <div id='qualityAnalysisMorphologicalSizeYContainer' className='w-100 flex' style={{ marginBottom: '42px' }}>
                <span className='common-slider-title' style={{ width: '90px' }}>
                  Tamanho Y
                </span>
                <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                  <InputRange
                    id='qualityAnalysisMorphologicalSizeY'
                    labelContainer='Tamanho Y'
                    name='qualityAnalysisMorphologicalSizeY'
                    formatLabel={value => `${value}`}
                    step={1}
                    minValue={this.morphologicalSizeYMin}
                    maxValue={this.morphologicalSizeYMax}
                    value={this.props.identification.filtering.morphologicalSizeY}
                    onChange={value => {
                      let newValue = value % 2 === 0 ? value + 1 : value;
                      this.updateDataAtStore('filtering', { ...this.props.identification.filtering, morphologicalSizeY: newValue });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='w-100 flex flex-center capabilities-span' style={{ margin: '18px 0', position: 'relative' }}>
            <div className='w-100 h-100 flex flex-center'>Detecção de objetos</div>
            <div style={{ position: 'absolute', right: 0 }}>
              <Toggle
                id={`toggleDetection`}
                checked={this.props.identification.detection.active}
                icons={false}
                onChange={event => this.updateDataAtStore('detection', { ...this.props.identification.detection, active: event.target.checked })}
              />
            </div>
          </div>

          {/*<TextField*/}
          {/*  className='qualityAnalysisSeparator'*/}
          {/*  id='qualityAnalysisMinimalAreaSelect'*/}
          {/*  disabled={this.state.fixingFieldId !== '' && this.state.fixingFieldId !== 'qualityAnalysisMinimalAreaSelect'}*/}
          {/*  type='number'*/}
          {/*  min={this.minimalAreaMin}*/}
          {/*  max={this.minimalAreaMax}*/}
          {/*  step={1}*/}
          {/*  theme='outline'*/}
          {/*  placeholder={null}*/}
          {/*  label='Área mínima (px)'*/}
          {/*  value={this.state.minimalArea.toString()}*/}
          {/*  onChange={event =>*/}
          {/*    this.validatePositiveIntField(*/}
          {/*      event.target.value,*/}
          {/*      'minimalArea',*/}
          {/*      1,*/}
          {/*      this.minimalAreaMin,*/}
          {/*      this.minimalAreaMax,*/}
          {/*      'minimalAreaToastId',*/}
          {/*      'qualityAnalysisMinimalAreaSelect'*/}
          {/*    )*/}
          {/*  }*/}
          {/*/>*/}

          <div id='qualityAnalysisLinePositionContainer' className='w-100 flex' style={{ margin: '28px 0 42px 0' }}>
            <span className='common-slider-title' style={{ width: '190px' }}>
              Área mínima (%)
            </span>
            <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 190px)' }}>
              <InputRange
                id='qualityAnalysisMinimalAreaSelect'
                labelContainer='Área mínima (%)'
                name='qualityAnalysisMinimalAreaSelect'
                formatLabel={value => `${value.toFixed(2)}`}
                step={0.2}
                minValue={this.minimalAreaMin}
                maxValue={this.minimalAreaMax}
                value={this.props.identification.detection.minimalArea}
                onChange={value => this.updateDataAtStore('detection', { ...this.props.identification.detection, minimalArea: value })}
              />
            </div>
          </div>

          <Select
            className='w-100 qualityAnalysisSeparator'
            listboxStyle={{ maxHeight: '300px' }}
            theme='outline'
            id='qualityAnalysisMovementDirection'
            options={this.movementDirection}
            value={this.props.identification.detection.currentMovementDirection}
            name='qualityAnalysisMovementDirection'
            label='Direção do Movimento'
            onChange={value => {
              if (!!value) {
                this.updateDataAtStore('detection', {
                  ...this.props.identification.detection,
                  currentMovementDirection: value,
                });
              }
            }}
            anchor={{ x: 'center', y: 'below' }}
          />

          <div id='qualityAnalysisLinePositionContainer' className='w-100 flex' style={{ margin: '28px 0 42px 0' }}>
            <span className='common-slider-title' style={{ width: '190px' }}>
              Posição da linha (%)
            </span>
            <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 190px)' }}>
              <InputRange
                id='qualityAnalysisLinePosition'
                labelContainer='Posição da linha'
                name='qualityAnalysisLinePosition'
                formatLabel={value => `${value}`}
                step={1}
                minValue={this.linePositionMin}
                maxValue={this.linePositionMax}
                value={this.props.identification.detection.linePosition}
                onChange={value => this.updateDataAtStore('detection', { ...this.props.identification.detection, linePosition: value })}
              />
            </div>
          </div>

          <div id='qualityAnalysisMaxDistanceXDistanceXContainer' className='w-100 flex' style={{ margin: '42px 0' }}>
            <span className='common-slider-title' style={{ width: '190px' }}>
              Distância horizontal máxima entre frames (%)
            </span>
            <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 190px)' }}>
              <InputRange
                id='qualityAnalysisMaxDistanceX'
                labelContainer='Distância mínima X'
                name='qualityAnalysisMaxDistanceX'
                formatLabel={value => `${value}`}
                step={1}
                minValue={this.maxDistanceXMin}
                maxValue={this.maxDistanceXMax}
                value={this.props.identification.detection.maxDistanceX}
                onChange={value => this.updateDataAtStore('detection', { ...this.props.identification.detection, maxDistanceX: value })}
              />
            </div>
          </div>

          <div id='qualityAnalysisMaxDistanceYContainer' className='w-100 flex' style={{ margin: '42px 0' }}>
            <span className='common-slider-title' style={{ width: '190px' }}>
              Distância vertical máxima entre frames (%)
            </span>
            <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 190px)' }}>
              <InputRange
                id='qualityAnalysisMaxDistanceY'
                labelContainer='Distância mínima Y'
                name='qualityAnalysisMaxDistanceY'
                formatLabel={value => `${value}`}
                step={1}
                minValue={this.maxDistanceYMin}
                maxValue={this.maxDistanceYMax}
                value={this.props.identification.detection.maxDistanceY}
                onChange={value => this.updateDataAtStore('detection', { ...this.props.identification.detection, maxDistanceY: value })}
              />
            </div>
          </div>
        </AnimationComponent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  identification: state.appQuality.identification,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setIdentificationValuesAtStore,
      setTabRef,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(IdentificationTab);

class RgbBinarization extends React.Component {
  render() {
    return (
      <div className='conditional-child' id='rgbBinarizationWrapper'>
        <SlideInLeft>
          {['red', 'green', 'blue'].map((color, index) => {
            return (
              <div id={`${color}BinarizationSlider`} className='w-100 flex' key={index} style={{ marginBottom: '42px' }}>
                <span className='h-100 flex flex-start' style={{ alignItems: 'flex-start', width: '80px', fontWeight: 'bold', color: '#616161' }}>
                  {color.toUpperCase()}
                </span>
                <InputRange
                  id={`${color}ColorRange`}
                  labelContainer={color}
                  name={`${color}-ColorRange`}
                  formatLabel={value => `${value}`}
                  step={1}
                  minValue={0}
                  maxValue={255}
                  value={this.props[color]}
                  onChange={value => {
                    let newValue = { ...value };
                    if (value.min < 0) {
                      newValue.min = 0;
                    } else if (value.max > 255) {
                      newValue.max = 255;
                    }
                    this.props.setColorAtParent({ [color]: newValue });
                  }}
                />
              </div>
            );
          })}
        </SlideInLeft>
      </div>
    );
  }
}

class BackgroundSubtractionBinarization extends React.Component {
  render() {
    return <div className='w-100' />;
  }
}

class GridBinarization extends React.Component {
  render() {
    return <div className='w-100' />;
  }
}
