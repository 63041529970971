import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, createStore } from 'redux';
import { defaultState } from './reducers/state';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import appReducer from './reducers/index';
import App from './components/contents/app';
import './common.scss';
import { Configuration } from 'react-md';
import { toast, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AppBarComponent from './components/contents/appBar/appBar';
import 'react-input-range/lib/css/index.css';
import 'react-image-gallery/styles/scss/image-gallery.scss';
// DO NOT REMOVE. IS BEING USED
import * as LambdaDefs from './lambda';
// I'M SERIOUS
import ReactGA from 'react-ga';
import { AppSizeListener } from '@react-md/utils';

const storeUpdate = () => {
  const state = store.getState();
  sessionStorage.setItem('content', state.current);
};

const urlParams = new URLSearchParams(window.location.search);

if (localStorage.getItem('trial') === 'over') {
  defaultState.main.tutorialDone = true;
}

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');

if (!!accessToken) {
  defaultState.user.logged = true;
  defaultState.user.accessToken = accessToken;
  defaultState.user.refreshToken = refreshToken;
}

for (let p of urlParams) {
  if (p[0] === 'app') defaultState.main.current = p[1];
}

ReactGA.initialize('UA-58966194-1');
ReactGA.pageview('plataforma/' + defaultState.main.current);

// update cv loaded state
var cvReady = () => {
  store.dispatch({ type: 'MAIN_CV_LOADED' });
};
// makes update function visible in index.html
window.cvReady = cvReady;

toast.configure({
  autoClose: 4000,
  hideProgressBar: false,
  newestOnTop: true,
  transition: Zoom,
});

let confirmationInterval = null;

const checkConfirmationAnswer = (store, next, action) => {
  const resetButtonLoadingTypes = ['APPBAR_APP_CONFIG_CHANGE_CONTROL', 'APPBAR_APP_CONFIG_DELETION_CONTROL'];
  const state = store.getState();
  if (state.main.confirmationDialogAnswer === true) {
    window.clearInterval(confirmationInterval);
    next(action);
    store.dispatch({
      type: 'MAIN_SET_CONFIRMATION_INFO',
      file: {
        confirmationDialogVisible: false,
        confirmationDialogText: state.main.confirmationDialogText,
        confirmationDialogAnswer: null,
      },
    });
  } else if (state.main.confirmationDialogAnswer === false) {
    window.clearInterval(confirmationInterval);
    store.dispatch({
      type: 'MAIN_SET_CONFIRMATION_INFO',
      file: {
        confirmationDialogVisible: false,
        confirmationDialogText: state.main.confirmationDialogText,
        confirmationDialogAnswer: null,
      },
    });
    if (resetButtonLoadingTypes.find(type => type === action.type)) {
      store.dispatch({
        type: 'APPBAR_RESET_APP_BUTTON_LOADING',
        file: { data: { current: state.main.current } },
      });
    }
  }
};

const confirmationMiddleware = store => next => action => {
  const needsPendingSave = ['MAIN_CHANGE_CURRENT', 'APPBAR_APP_CONFIG_CHANGE_CONTROL'];
  const pendingSave = needsPendingSave.find(type => type === action.type);
  // watch cameraConfig...
  if (
    !!store.getState().main.current &&
    (!!store.getState().main.availableApps.find(someApp => store.getState().main.current === someApp) || store.getState().main.current === 'cameraConfig') &&
    !!action.needsConfirmation &&
    (!pendingSave || store.getState()[store.getState().main.current].pendingSave === true)
  ) {
    let file = {};
    switch (action.type) {
      case 'MAIN_CHANGE_CURRENT':
      case 'APPBAR_APP_CONFIG_CHANGE_CONTROL':
        file = {
          confirmationDialogVisible: true,
          confirmationDialogText: 'Alguns dados podem não estar salvos. Deseja realmente continuar?',
          confirmationDialogAnswer: store.getState().main.confirmationDialogAnswer,
        };
        break;
      default:
        file = {
          confirmationDialogVisible: true,
          confirmationDialogText: 'Esta ação é irreversível. Deseja continuar?',
          confirmationDialogAnswer: store.getState().main.confirmationDialogAnswer,
        };
        break;
    }

    store.dispatch({
      type: 'MAIN_SET_CONFIRMATION_INFO',
      file: file,
    });
    confirmationInterval = setInterval(checkConfirmationAnswer, 300, store, next, action);
  } else {
    next(action);
  }
};

export const store = createStore(appReducer, defaultState, composeWithDevTools(applyMiddleware(thunk, confirmationMiddleware)));
store.subscribe(storeUpdate);

console.log('##############');
console.log(defaultState.appGrid.url);

ReactDOM.render(
  <Provider store={store}>
    <Configuration>
      <AppSizeListener>
        <div className='full-page'>
          <AppBarComponent />
          <App />
        </div>
      </AppSizeListener>
    </Configuration>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
