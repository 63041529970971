import './license.scss';
import React from 'react';
import { bindActionCreators } from 'redux';
import { getGroups, cleanLicensesInfo, createLicense } from '../../../actions/licenses';
import { connect } from 'react-redux';
import { Button, Card, CardContent, CircularProgress } from 'react-md';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import LicenseList from './licenseList';
import { CreateLicenseDialog } from './createLicenseDialog';
import NoGroupMessage from './noGroupMessage';

const licensePadding = 12;
let licenseWidthUpdateIntervalControl = 0;

class LicenseGroups extends React.Component {
  groupsContent = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      licenseWidth: 0,
      creationDialogVisible: false,
      groupId: -1,
    };
    this.onTouchMoveAtCardContent = this.onTouchMoveAtCardContent.bind(this);
    this.cancelCreation = this.cancelCreation.bind(this);
  }

  openCreationLicenseDialog(groupId) {
    this.setState({ creationDialogVisible: true, groupId });
  }

  cancelCreation() {
    this.setState({ creationDialogVisible: false });
  }

  createLicense(camId, HWId, group, date) {
    const { selected, groupId } = this.state;
    this.props.createLicense(groupId, selected, camId, HWId, group, date);
    this.cancelCreation();
  }

  handleLicenseWidth(licenseWidth) {
    if (licenseWidthUpdateIntervalControl % 4 === 0) {
      this.setState({ licenseWidth });
    }
    licenseWidthUpdateIntervalControl += 1;
  }

  onTouchMoveAtCardContent(event) {
    event.stopPropagation();
  }

  componentDidMount() {
    this.props.getGroups();
  }

  componentWillUnmount() {
    this.props.cleanLicensesInfo();
  }

  render() {
    const { licenseWidth, creationDialogVisible, selected } = this.state;
    const { groupsList, licensesList, user } = this.props;
    const groupsContentHasScroll = this.groupsContent.current?.clientHeight < this.groupsContent.current?.scrollHeight;
    return (
      <div className='w-100 h-100 flex flex-center' style={{ position: 'relative' }} id='groupsID'>
        {!groupsList ? (
          groupsList === undefined ? (
            <div className='w-100 h-100 flex flex-center' style={{ position: 'absolute' }}>
              <CircularProgress id='fetchingGroups' />
            </div>
          ) : (
            <div className='flex flex-center' style={{ width: '200px', height: '200px' }}>
              <img alt='groups request error' className='w-100 h-100' style={{ objectFit: 'contain' }} src={process.env.PUBLIC_URL + '/img/error.svg'} />
            </div>
          )
        ) : groupsList.length === 0 ? (
          <NoGroupMessage user={user} />
        ) : (
          <div className='license-awesome-slider-container'>
            <CreateLicenseDialog
              group={groupsList[selected]}
              creationDialogVisible={creationDialogVisible}
              onCancel={this.cancelCreation}
              onConfirm={(camId, HWId, group, date) => this.createLicense(camId, HWId, group, date)}
            />
            <AwesomeSlider
              transitionDelay={100}
              animation='foldOutAnimation'
              fillParent={true}
              selected={this.state.selected}
              onTransitionEnd={event => this.setState({ selected: event.currentIndex })}
            >
              {groupsList.map((group, groupIndex) => {
                return (
                  <Card className='group-card flex flex-column' key={group.id} style={{ background: 'transparent' }}>
                    <div className='group-card-icon-container'>
                      <img src={`${process.env.PUBLIC_URL}/img/group.svg`} className='w-100 h-100' alt='logo do grupo' />
                    </div>
                    <div className='w-100 group-card-title text-ellipsis'>{group.name}</div>
                    <div className='magic-container'>
                      <div
                        className='license-rows-header flex'
                        style={{
                          width: `${Math.floor(licenseWidth + licensePadding)}px`,
                          padding: groupsContentHasScroll ? '0 22px 0 12px' : '0 12px',
                        }}
                      >
                        <div className='license-col col1'>
                          <div>Criador</div>
                        </div>
                        <div className='license-col col2'>
                          <div>Camera Id</div>
                        </div>
                        <div className='license-col col3'>
                          <div>Hardware Id</div>
                        </div>
                        <div className='license-col col4'>
                          <div>Validade</div>
                        </div>
                        <div className='license-col col5'>
                          <div>Licença</div>
                        </div>
                        <div className='license-col col7' style={{ width: '20%' }}>
                          <div>
                            <Button className='new-license-button' theme='secondary' themeType='contained' onClick={() => this.openCreationLicenseDialog(group.id)}>
                              nova licença
                            </Button>
                          </div>
                        </div>
                      </div>
                      <CardContent
                        ref={this.groupsContent}
                        className='groups-content grown'
                        style={{ width: `${Math.floor(licenseWidth + licensePadding)}px` }}
                        onTouchMove={this.onTouchMoveAtCardContent}
                      >
                        <LicenseList groupId={group.id} groupIndex={groupIndex} getLicenseWidth={licenseWidth => this.handleLicenseWidth(licenseWidth)} />
                      </CardContent>
                    </div>
                    <div className='group-footer'>
                      <div className='flex flex-wrap'>
                        <span className='w-100 flex flex-center group-owner-title'>Proprietário</span>
                        <span className='text-ellipsis w-100 group-owner-value'>{group.owner.email}</span>
                      </div>
                      <hr className='license-footer-divider' />
                      <>
                        {licensesList ? (
                          <div className='flex flex-wrap'>
                            <span className='w-100 flex flex-center group-owner-title'>Licenças</span>
                            <span className='text-ellipsis w-100 group-owner-value'>{`${String(licensesList[groupIndex].length ?? '0')?.padStart(2, '0')}/${
                              group.membership.max_licenses
                            }`}</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    </div>
                  </Card>
                );
              })}
            </AwesomeSlider>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  groupsList: state.licenses.groupsList,
  licensesList: state.licenses.licensesList,
  user: state.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getGroups,
      cleanLicensesInfo,
      createLicense,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LicenseGroups);
