import axios from 'axios';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

// export function signup(body) {
//   return (dispatch, getState) => {
//     const url = getState().appGrid.url;
//     axios
//       .post(`${url}/api/user/signup`, body)
//       .then(res => {
//         axios
//           .post(`${url}/api/user/auth`)
//           .then(res => {
//             //  ???
//           })
//           .catch();
//       })
//       .catch();
//   };
// }

export function login(body) {
  return (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    axios
      .post(`${url}/api/user/auth`, body)
      .then(res => {
        dispatch({
          type: 'USER_LOGIN',
          file: res,
        });
      })
      .catch(err => {
        dispatch({
          type: 'USER_LOGIN',
          file: err,
          error: true,
        });
      });
  };
}

export function refreshToken() {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const url = state.appGrid.url;
      const refreshToken = state.user.refreshToken;
      const refreshResponse = await axios.post(`${url}/api/user/refresh`, { refresh: refreshToken }).catch(err => {
        dispatch({
          type: 'USER_REFRESH',
          file: err,
          error: true,
        });
        return Promise.reject(false);
      });
      dispatch({
        type: 'USER_REFRESH',
        file: refreshResponse,
      });
      return Promise.resolve(true);
    } catch (e) {
      console.warn(e);
      return false;
    }
  };
}

export function setAccessToken(token) {
  return {
    type: 'USER_SET_ACCESS_TOKEN',
    file: token,
  };
}

export function fetchUserInfo() {
  return (dispatch, getState) => {
    const url = getState().appGrid.url;
    axios
      .get(`${url}/api/user`)
      .then(res => {
        ReactGA.set({ userId: res.data.uuid });
        dispatch({
          type: 'USER_FETCH_USER_INFO',
          file: res,
        });
      })
      .catch(err => {
        dispatch({
          type: 'USER_FETCH_USER_INFO',
          file: err,
          error: true,
        });
      });
  };
}

export function logoutUser() {
  return (dispatch, getStore) => {
    const state = getStore();
    ReactGA.set({ userId: '' });
    try {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      axios.interceptors.request.eject(state.main.interceptorInstance);
      toast.info(`Até mais ${state.user.firstName}!`);
      dispatch({
        type: 'USER_LOGOUT_USER',
        file: true,
      });
    } catch (e) {
      console.warn(e);
      toast.error('Não foi possível encerrar a sessão. Tente novamente.');
      dispatch({
        type: 'USER_LOGOUT_USER',
        file: true,
        error: true,
      });
    }
  };
}

export function storeImage(image, suffix, apptype) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;

    let data = {
      img: image.split(',')[1],
      suffix: suffix,
      apptype: apptype,
    };
    await axios
      .post(`${url}/api/store_image`, data)
      .then(res => {
        toast.info('Imagem enviada!');
      })
      .catch(err => {
        toast.error(`Falha no envio da imagem: ${err}`);
      });
  };
}
