import React from 'react';
import { Card, CardContent } from '@react-md/card';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeCurrent } from '../../../actions/main';
import './apps.scss';
import ReactResizeDetector from 'react-resize-detector';
import { fetchCameras } from '../../../actions/cameraConfig';

class Apps extends React.Component {
  apps = [
    { name: 'Classificação de imagens', src: process.env.PUBLIC_URL + '/img/tigerFinal.png', path: 'appClass', needStaff: false },
    { name: 'Aplicação em grade', src: process.env.PUBLIC_URL + '/img/grid-girl.png', path: 'appGrid', needStaff: false },
    { name: 'Detecção de máscaras', src: process.env.PUBLIC_URL + '/img/covidMask.png', path: 'appEPI', needStaff: false },
    { name: 'Análise de qualidade', src: process.env.PUBLIC_URL + '/img/approved2.png', path: 'appQuality', needStaff: true },
    { name: 'Reconhecimento facial', src: process.env.PUBLIC_URL + '/img/face.png', path: 'appFace', needStaff: true },
    { name: 'Geração de código', src: process.env.PUBLIC_URL + '/img/code.png', path: 'appCode', needStaff: true },
  ];
  appWidth = 300;
  appMargin = 20;

  componentDidMount() {
    this.props.fetchCameras();
  }

  render() {
    const noStaffNeed = this.apps.map(element => (element.needStaff ? 0 : 1)).reduce((accum, curr) => accum + curr);
    const showingApps = this.props.user.isStaff ? this.apps.length : noStaffNeed;
    return (
      <Card className='flex text-center flex-column curve-border apps-container'>
        <CardContent className='flex flex-column flex-between h-100'>
          <div className='apps-title'>Escolha sua aplicação</div>
          <ReactResizeDetector>
            {({ width, height }) => {
              const rows = Math.ceil(this.apps.length / Math.floor(width / this.appWidth));
              const maxColumns = Math.floor(width / this.appWidth) > showingApps ? showingApps : Math.floor(width / this.appWidth);

              return (
                <div
                  className='w-100 flex flex-wrap flex-center scroll'
                  style={{
                    height: 'calc(100% - 50px)',
                    fontSize: '18px',
                  }}
                >
                  <div className='flex flex-wrap cards-inner-container' style={{ width: `${this.appWidth * maxColumns}px` }}>
                    {Array.from({ length: this.apps.length }, (_, i) =>
                      !this.apps[i].needStaff || this.props.user.isStaff ? (
                        <Card
                          key={i}
                          className='inner-card'
                          style={{ maxHeight: `${Math.floor(height / rows) - this.appMargin}px` }}
                          onClick={() => this.props.changeCurrent(this.apps[i].path)}
                        >
                          <div className='inner-card-title'>{this.apps[i].name}</div>
                          <CardContent className='inner-card-content'>
                            <div className='flex flex-center-bottom app-image-container'>
                              <img src={this.apps[i].src} />
                            </div>
                          </CardContent>
                        </Card>
                      ) : null
                    )}
                  </div>
                </div>
              );
            }}
          </ReactResizeDetector>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => bindActionCreators({ changeCurrent, fetchCameras }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Apps);
