import React from 'react';
import { Button, TextField } from 'react-md';
import { toast } from 'react-toastify';

export default class Blur extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kernel0: props.blur ? props.blur[0].toString() : '3',
      kernel1: props.blur ? props.blur[1].toString() : '3',
      sigmaX: props.blur ? props.blur[2].toString() : '1.5',
      sigmaY: props.blur ? props.blur[3].toString() : '1.5',
    };
  }
  removeTool() {
    this.props.setDialogState(null);
  }
  sendDataToDialog() {
    if (
      this.state.kernel0 < 2 ||
      this.state.kernel1 < 2 ||
      this.state.kernel0 > 30 ||
      this.state.kernel1 > 30 ||
      this.state.sigmaX < 0 ||
      this.state.sigmaY < 0 ||
      this.state.sigmaX > 5 ||
      this.state.sigmaY > 5 ||
      this.state.kernel0 % 2 === 0 ||
      this.state.kernel1 % 2 === 0
    ) {
      toast.error('Valores fora dos permitidos.');
    } else {
      this.props.setDialogState([Number(this.state.kernel0), Number(this.state.kernel1), Number(this.state.sigmaX), Number(this.state.sigmaY)]);
    }
  }
  render() {
    return (
      <>
        <div className='flex flex-wrap '>
          <div className='w-100'>
            <div className='w-100 text-center' style={{ fontWeight: '600', color: 'dimgray', margin: '4px 0' }}>
              Kernel Size
            </div>
            <div className='flex w-100 flex-center'>
              <TextField
                style={{ margin: '4px 4px 4px 0', flexGrow: '1' }}
                id='kernel0'
                type='number'
                min={3}
                max={30}
                step={2}
                theme='outline'
                placeholder='Ex: 3'
                label='Val0'
                value={this.state.kernel0}
                onChange={event => this.setState({ kernel0: event.target.value })}
              />
              x
              <TextField
                style={{ margin: '4px 0 4px 4px', flexGrow: '1' }}
                id='kernel1'
                type='number'
                min={3}
                max={30}
                step={2}
                theme='outline'
                placeholder='Ex: 3'
                label='Val1'
                value={this.state.kernel1}
                onChange={event => this.setState({ kernel1: event.target.value })}
              />
            </div>
          </div>
          <div className='w-100 flex flex-wrap '>
            {/*<div className='w-100 text-center' style={{fontWeight: '600', color: '#737373', margin: '4px 0'}}>Sigma</div>*/}
            <div className='flex w-100 flex-center'>
              <TextField
                style={{ margin: '8px 4px 4px 0', flexGrow: '1' }}
                id='sigmaX'
                type='number'
                min={0}
                max={5}
                step={0.1}
                theme='outline'
                placeholder='Ex: 0'
                label='SigmaX'
                value={this.state.sigmaX}
                onChange={event => this.setState({ sigmaX: event.target.value })}
              />
              -
              <TextField
                style={{ margin: '8px 0 4px 4px', flexGrow: '1' }}
                id='sigmaY'
                type='number'
                min={0}
                max={5}
                step={0.1}
                theme='outline'
                placeholder='Ex: 0'
                label='SigmaY'
                value={this.state.sigmaY}
                onChange={event => this.setState({ sigmaY: event.target.value })}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-between w-100'>
          <Button style={{ width: '49%' }} theme='error' themeType='contained' disabled={this.props.blur === null} onClick={() => this.removeTool()}>
            Remover
          </Button>
          <Button style={{ width: '49%' }} theme='secondary' themeType='contained' onClick={() => this.sendDataToDialog()}>
            Aplicar
          </Button>
        </div>
      </>
    );
  }
}
