import React from 'react';
import './appClass.scss';
import ClassSection from './sections/classSection';
import SampleSection from './sections/sampleSection';
import DetailSection from './sections/detailSection';
import { bindActionCreators } from 'redux';
import { resetCurrentAppConfig } from '../../../actions/appBar';
import { setPreProcessingProcessor } from '../../../actions/preProcessing';
import { setPostProcessingProcessor } from '../../../actions/postProcessing';
import { setOutputProcessor } from '../../../actions/output';
import { dismissOldCam, resetCameraConfig } from '../../../actions/cameraConfig';
import { resetData, resetModelParams } from '../../../actions/appClass';
import { connect } from 'react-redux';

class AppClass extends React.Component {
  componentWillUnmount() {
    this.props.resetCurrentAppConfig('appClass');
    this.props.setPreProcessingProcessor(null);
    this.props.setPostProcessingProcessor(null);
    this.props.setOutputProcessor(null);
    this.props.resetModelParams();
    this.props.dismissOldCam();
    this.props.resetCameraConfig();
  }

  render() {
    return (
      <div className='background'>
        <div className='w-100 h-100 flex flex-between'>
          <ClassSection />
          <SampleSection />
          <DetailSection />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetCurrentAppConfig,
      setPreProcessingProcessor,
      setPostProcessingProcessor,
      setOutputProcessor,
      dismissOldCam,
      resetData,
      resetModelParams,
      resetCameraConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppClass);
