import React from 'react';
import { Form, TextField } from 'react-md';
import { TextArea } from '@react-md/form';

export default class Email extends React.Component {
  render() {
    return (
      <div className='tab-content-wrapper'>
        <Form className='w-100'>
          {/*<TextField*/}
          {/*  disabled={!this.props.active}*/}
          {/*  style={{ margin: '12px 0 0 0', pointerEvents: this.props.active ? 'all' : 'none' }}*/}
          {/*  className={`fields ${!!this.props.emailFieldError.find(el => el.path === 'Endereco') && this.props.active ? 'error-field' : ''}`}*/}
          {/*  id='address'*/}
          {/*  label='Endereço de email'*/}
          {/*  type='email'*/}
          {/*  theme='outline'*/}
          {/*  autoComplete='off'*/}
          {/*  value={this.props.active ? this.props.address : ''}*/}
          {/*  onChange={event => (this.props.active ? this.props.setDialogState({ emailAddress: event.target.value }) : null)}*/}
          {/*  onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}*/}
          {/*  onBlur={() => this.props.validateEmailFields()}*/}
          {/*/>*/}
          {/*<div className={`${this.props.emailFieldError.find(el => el.path === 'Endereco')?.error ? 'error-info' : 'hidden-error'}`}>*/}
          {/*  {this.props.active && this.props.emailFieldError.find(el => el.path === 'Endereco')?.error}*/}
          {/*</div>*/}

          {/*Temporary email field*/}

          <TextField
            disabled={true}
            style={{ margin: '16px 0 0 0', color: '#9e9e9e', pointerEvents: 'none' }}
            className={`fields ${!!this.props.emailFieldError.find(el => el.path === 'Endereco') && this.props.active ? 'error-field' : ''}`}
            id='address'
            label='Endereço de email'
            type='email'
            theme='outline'
            autoComplete='off'
            value={this.props.address}
            onChange={event => console.log('')}
          />
          <div style={{ margin: '12px 0' }} />
          <TextField
            disabled={!this.props.active}
            style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
            className={`fields ${!!this.props.emailFieldError.find(el => el.path === 'Assunto') && this.props.active ? 'error-field' : ''}`}
            id='title'
            label='Assunto'
            type='text'
            theme='outline'
            autoComplete='off'
            value={this.props.active ? this.props.title : ''}
            onChange={event => (this.props.active ? this.props.setDialogState({ emailTitle: event.target.value }) : null)}
            onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
            onBlur={() => this.props.validateEmailFields()}
          />
          <div className={`${this.props.emailFieldError.find(el => el.path === 'Assunto')?.error ? 'error-info' : 'hidden-error'}`}>
            {this.props.active && this.props.emailFieldError.find(el => el.path === 'Assunto')?.error}
          </div>
          <TextArea
            disabled={!this.props.active}
            className={`fields ${!!this.props.emailFieldError.find(el => el.path === 'Conteudo') && this.props.active ? 'error-field' : ''}`}
            id='configurable-textarea'
            key={`emailBody`}
            label='Conteúdo'
            rows={6}
            maxRows={-1}
            resize='auto'
            animate='height'
            value={this.props.active ? this.props.content : ''}
            onChange={event => (this.props.active ? this.props.setDialogState({ emailContent: event.target.value }) : null)}
            onBlur={() => this.props.validateEmailFields()}
          />
          <div className={`${this.props.emailFieldError.find(el => el.path === 'Conteudo')?.error ? 'error-info' : 'hidden-error'}`}>
            {this.props.active && this.props.emailFieldError.find(el => el.path === 'Conteudo')?.error}
          </div>
        </Form>
      </div>
    );
  }
}
