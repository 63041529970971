export class Schedule {
  constructor(callback, interval) {
    this.interval = interval;
    this.running = true;
    this.callback = callback;
    this.wrapper();
  }
  wrapper = async () => {
    let begin = Date.now();
    try {
      // const start = window.performance.now();
      await this.callback();
      // const end = window.performance.now();
      // console.log((end-start).toFixed(0))
    } catch (err) {
      console.log(err);
    }
    let delay = this.interval - (Date.now() - begin);
    if (delay < 0) delay = 0;
    if (this.running) setTimeout(this.wrapper, delay);
  };
}
