import produce from 'immer';
import { PreProcessingProcessor } from '../components/preProcessing/preProcessingProcessor';
import { PostProcessingProcessor } from '../components/postProcessing/postProcessingProcessor';
import { OutputProcessor } from '../components/output/outputProcessor';

export default function localConfigReducer(state, action) {
  if (action.error) {
    // TODO: show remaining space so user can know if issue is related to lack of storage
    return produce(state, draft => {
      draft.main.localLoadingApp = -1;
      draft.main.localLoadingButton = -1;
      draft.main.localDataBaseUpdated *= -1;
    });
  } else {
    switch (action.type) {
      case 'LOCALCONFIG_CHANGE_APP_SAVE_LOCAL_APPCONFIG':
        return produce(state, draft => {
          draft[action.file.data.apptype + 'SelectApp'].lastAppConfigLocallySaved = action.file.data;
          draft.main.localDataBaseUpdated *= -1;
          draft.main.localLoadingApp = -1;
          draft.main.localLoadingButton = -1;
        });
      case 'LOCALCONFIG_DELETE_LOCAL_APP_CONFIG':
        return produce(state, draft => {
          draft.main.localDataBaseUpdated *= -1;
          draft.main.localLoadingApp = -1;
          draft.main.localLoadingButton = -1;
        });
      case 'LOCALCONFIG_SET_LOADING_BUTTON':
        return produce(state, draft => {
          draft.main.localLoadingApp = action.file.appIndex;
          draft.main.localLoadingButton = action.file.buttonIndex;
        });
      case 'LOCALCONFIG_FETCH_OFFLINE_APPS':
        const selectApps = Object.keys(state).filter(key => key.endsWith('SelectApp'));
        const offlineFromLink = action.file.setOfflineFromLink ? true : state.main.offlineFromLink;

        return produce(state, draft => {
          selectApps.forEach(selectApp => {
            const appType = selectApp.split('SelectApp')[0];
            const appConfigsToStore = [];
            if (action.file.localAppConfigs[appType]) {
              action.file.localAppConfigs[appType].forEach(appConfig => {
                appConfigsToStore.push(appConfig);
              });
            }
            draft[appType + 'SelectApp'].apps = appConfigsToStore;
          });
          draft.main.offlineFromLink = offlineFromLink;
        });
      case 'LOCALCONFIG_CHANGE_OFFLINE_APPCONFIG':
        const selectApp = action.file.current + 'SelectApp';
        let newApp = state[selectApp].apps[action.file.appIndex];
        const responseApp = action.file.current;
        const newForbiddenRoutes = [...state.main.forbiddenTokenRoutes];

        if (newApp.output.data?.http.active) {
          if (!newForbiddenRoutes.find(el => el === newApp.output.data.http.httpTokenAddress)) {
            newForbiddenRoutes.push(newApp.output.data.http.httpTokenAddress);
          }
          if (!newForbiddenRoutes.find(el => el === newApp.output.data.http.httpAddress)) {
            newForbiddenRoutes.push(newApp.output.data.http.httpAddress);
          }
        }
        return produce(state, draft => {
          draft[responseApp] = { ...draft[responseApp], ...newApp.customdata[responseApp] };
          draft.cameraConfig.newCameraRef = action.file.cameraRef;
          draft.cameraConfig.preProcessingProcessor = newApp.preprocessing.data ? new PreProcessingProcessor(newApp.preprocessing.data) : null;
          draft.cameraConfig.postProcessingProcessor = newApp.postprocessing.data ? new PostProcessingProcessor(newApp.postprocessing.data) : null;
          draft.cameraConfig.outputProcessor = newApp.output.data ? new OutputProcessor(newApp.output.data) : null;
          draft[selectApp].current = newApp;
          draft.savingApp[action.file.current] = [0, 0, 0, 0];
          draft.main.forbiddenTokenRoutes = newForbiddenRoutes;

          draft.appGrid.noCapture = true;
          draft.appGrid.currentThumbImg = '';
          draft.appGrid.currentThumbIndex = -1;

          draft.appClass.noCapture = true;
          draft.appClass.currentThumbImg = '';
          draft.appClass.currentThumbIndex = -1;
          draft.appClass.currentClassIndex = 0;
          draft.appClass.showingImageClassIndex = -1;
        });
      case 'LOCALCONFIG_SET_OFFLINE_FROM_LINK':
        return produce(state, draft => {
          draft.main.offlineFromLink = action.file;
        });
      case 'LOCALCONFIG_ADD_ROUTE_TO_FORBIDDEN':
        return produce(state, draft => {
          if (!state.main.forbiddenTokenRoutes.find(route => route === action.file)) {
            draft.main.forbiddenTokenRoutes.push(action.file);
          }
        });
      default:
        return state;
    }
  }
}
