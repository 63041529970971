import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DropdownMenu, MoreVertSVGIcon } from 'react-md';
import { Card, CardContent } from '@react-md/card';
import TrainingDialog from './trainingDialog';
import ReactGA from 'react-ga';
import TrainingParameters from './trainingParameters';
import { startTrain, setGridPreTraining, getLiteModelUrl } from '../../../actions/appGrid';
import { startClassTrain, setClassPreTraining } from '../../../actions/appClass';
import { toast } from 'react-toastify';

class ModelSubSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modelParametersModalEnabled: false,
      lastWarmStart: -1,
      dialogEnabled: false,
      trainingStarted: false,
    };
  }
  trainModel(warmStart) {
    if (this.props.appType === 'appGrid') {
      ReactGA.event({
        category: 'Grid',
        action: 'Train',
      });
      this.setState({ lastWarmStart: warmStart }, () => this.props.setGridPreTraining());
    } else if (this.props.appType === 'appClass') {
      ReactGA.event({
        category: 'CLF',
        action: 'Train',
      });
      this.setState({ lastWarmStart: warmStart }, () => this.props.setClassPreTraining());
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.gridPreTraining !== this.props.gridPreTraining && !!this.props.gridPreTraining) {
      this.props.startTrain(this.state.lastWarmStart);
      this.setState({ dialogEnabled: true, trainingStarted: true });
    }
    if (prevProps.classPreTraining !== this.props.classPreTraining && !!this.props.classPreTraining) {
      this.props.startClassTrain(this.state.lastWarmStart);
      this.setState({ dialogEnabled: true, trainingStarted: true });
    }
  }

  downloadModel() {
    if (!this.props.currentApp) {
      toast.error('Favor selecionar um app config salvo para fazer o download');
    } else if (this.props.appType === 'appGrid') {
      this.props.getLiteModelUrl('grid', this.props.currentApp.name);
    } else if (this.props.appType === 'appClass') {
      this.props.getLiteModelUrl('clf', this.props.currentApp.name);
    }
  }

  render() {
    const showingItems = [
      { children: 'Parâmetros de modelo', onClick: () => this.setState({ modelParametersModalEnabled: true }) },
      { children: 'Baixar modelo', onClick: () => this.downloadModel() },
    ];
    return (
      <>
        <TrainingParameters appType={this.props.appType} enabled={this.state.modelParametersModalEnabled} close={() => this.setState({ modelParametersModalEnabled: false })} />
        <Card className='curve-border-2 flex flex-column w-100' style={{ justifyContent: 'flex-end', marginTop: '20px' }}>
          <div className='title-card-text w-100 flex flex-between'>
            Modelo
            <DropdownMenu id='modelOptions' buttonType='icon' aria-label='modelOptions' items={this.props.user.isStaff ? showingItems : [showingItems[0]]}>
              <MoreVertSVGIcon />
            </DropdownMenu>
          </div>
          <CardContent style={{ display: 'grid', gridTemplateColumns: '48% 48%', placeContent: 'flex-end space-between' }}>
            <TrainingDialog
              training={this.props.training}
              thumbsLength={this.props.thumbsLength}
              sentImages={this.props.sentImages}
              trainModel={() => this.trainModel(0)}
              someError={this.props.someError}
              currentEpochs={this.props.currentEpochs}
              numEpochs={this.props.numEpochs}
              buttonName='treinar'
              modelUrl={true}
              modelClassNames={this.props.modelClassNames}
              activeClassNames={this.props.activeClassNames}
              appType={this.props.appType}
              currentAccuracy={this.props.currentAccuracy}
              dialogEnabled={this.state.dialogEnabled}
              handleDialogVisibility={param => this.setState({ dialogEnabled: param })}
              trainingStarted={this.state.trainingStarted}
              handleTrainingStatus={param => this.setState({ trainingStarted: param })}
            />
            <TrainingDialog
              training={this.props.training}
              thumbsLength={this.props.thumbsLength}
              sentImages={this.props.sentImages}
              trainModel={() => this.trainModel(1)}
              someError={this.props.someError}
              currentEpochs={this.props.currentEpochs}
              numEpochs={this.props.numEpochs}
              buttonName='refinar'
              modelUrl={this.props.modelUrl}
              modelClassNames={this.props.modelClassNames}
              activeClassNames={this.props.activeClassNames}
              appType={this.props.appType}
              currentAccuracy={this.props.currentAccuracy}
              dialogEnabled={this.state.dialogEnabled}
              handleDialogVisibility={param => this.setState({ dialogEnabled: param })}
              trainingStarted={this.state.trainingStarted}
              handleTrainingStatus={param => this.setState({ trainingStarted: param })}
            />
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  thumbsLength: ownProps.appType === 'appGrid' ? state.appGrid.thumbs.length : state.appClass.classes.map(element => element.images.length).reduce((accum, curr) => accum + curr),
  training: state[ownProps.appType].training,
  sentImages: state[ownProps.appType].sentImages,
  someError: state[ownProps.appType].someError,
  currentEpochs: state[ownProps.appType].epochs,
  numEpochs: state[ownProps.appType].numEpochs,
  modelUrl: state[ownProps.appType].modelUrl,
  currentAccuracy: state[ownProps.appType].currentAccuracy,
  modelClassNames: state.appClass.modelClassNames,
  activeClassNames: state.appClass.classes.filter(element => element.images.length > 0),
  gridPreTraining: state.appGrid.preTraining,
  classPreTraining: state.appClass.preTraining,
  user: state.user,
  currentApp: state[ownProps.appType + 'SelectApp'].current,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      startTrain,
      startClassTrain,
      setGridPreTraining,
      setClassPreTraining,
      getLiteModelUrl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ModelSubSection);
