import * as React from 'react';
import { FileInput, SrOnly } from 'react-md';
import { bindActionCreators } from 'redux';
import * as appGridActions from '../../../actions/appGrid';
import { connect } from 'react-redux';

class FileHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  async handleChange(event) {
    const files = [...event.currentTarget.files];
    let filesSize = 0;
    if (this.props.type === 'video') {
      filesSize = files[0].size * 0.000001024;
      this.props.setText(`1 novo arquivo adicionado (${Math.round(filesSize)} MB)`);
      this.props.setVideoInput(files[0]);
    } else if (this.props.type === 'image') {
      if (FileReader && files.length) {
        const newFiles = [];
        for (const [index, file] of files.entries()) {
          newFiles.push(file);
          filesSize += file.size * 0.000001024;
        }
        const imageSufix = files.length > 1 ? 'ns' : 'm';
        const addSufix = files.length > 1 ? 's' : '';
        this.props.setText(`${files.length} image${imageSufix} adicionada${addSufix}`);
        this.props.setImagesInput(newFiles);
      }
    }
  }

  render() {
    return (
      <>
        <FileInput
          className='flex'
          style={{ borderRadius: '0', margin: '20px', display: 'flex' }}
          id={`${this.props.type}FileInput`}
          onChange={(event, x) => this.handleChange(event)}
          theme='primary'
          themeType='flat'
          buttonType='text'
          multiple={this.props.type === 'image'}
          accept={this.props.type === 'image' ? 'image/*' : 'video/*'}
        >
          <SrOnly />
          {this.props.type === 'image' ? 'carregar imagens' : 'carregar vídeo'}
        </FileInput>
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ ...appGridActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FileHandler);
