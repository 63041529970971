import React from 'react';
import './signup.scss';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { changeCurrent } from '../../../actions/main';

class Signup extends React.Component {
  render() {
    return (
      <>
        <iframe style={{ border: 'none', height: '100%' }} title='Cadastro' className='signup-iframe w-100' src={`${this.props.url}/api/user/signup`} />
      </>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ changeCurrent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
