import { Card, CardContent } from '@react-md/card';
import React from 'react';
import { bindActionCreators } from 'redux';
import * as appGridActions from '../../../../actions/appGrid';
import { connect } from 'react-redux';
import Thumb from './thumb';
import ModelSubSection from '../../modelSubSection/modelSubSection';

class DataSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // options parameter
      modelParametersModalEnabled: false,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    // thumb created
    if (prevProps.thumbs.length < this.props.thumbs.length) {
      if (this.props.interactWithTour) {
        setTimeout(this.props.nextStep, 0);
      }
    }
  }
  render() {
    return (
      <>
        <div className={`h-100 flex flex-column flex-between scroll appGrid-data-section hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}>
          <Card
            className='flex curve-border-2 text-center flex-column w-100 grown'
            style={{ height: '80%', maxHeight: 'calc(100% - 158px)', overflow: 'hidden', padding: '0 0 8px 0' }}
          >
            <div className='title-card-text w-100 flex flex-between'>Imagens Capturadas</div>
            <CardContent
              className='flex flex-wrap flex-center'
              style={{
                height: 'calc(100% - 70px)',
                overflow: 'auto',
                alignContent: 'flex-start',
                padding: '12px',
              }}
            >
              {this.props.thumbs.map((element, index) => {
                return (
                  <div key={index} className='flex flex-center-top flex-wrap' style={{ width: '100px', height: '100px', margin: '6px' }}>
                    <Thumb thumb={element} index={index} grid={this.props.grids[index]} interactWithTour={this.props.interactWithTour} nextStep={() => this.props.nextStep()} />
                  </div>
                );
              })}
            </CardContent>
          </Card>
          <ModelSubSection appType='appGrid' />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  thumbs: state.appGrid.thumbs,
  grids: state.appGrid.grids,
  training: state.appGrid.training,
  applicationIsOnline: state.main.applicationIsOnline,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...appGridActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataSection);
