import React from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'react-md';
import { Checkbox } from '@react-md/form';
import { storeImage } from '../../../actions/user';

class SaveDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selected: false, has_mask: false };
  }

  send = () => {
    this.props.storeImage(this.props.img, this.state.has_mask ? 'with_mask' : 'without_mask', 'epi');
    this.props.close();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // check if reopened dialog to set selected checkbox back to default value.
    if (this.props.enabled && !prevProps.enabled) this.setState({ selected: false });
  }

  render() {
    return (
      <Dialog id='simple-dialog-training' visible={this.props.enabled} onRequestClose={() => this.props.close()} aria-labelledby='dialog-title' className='curve-border-2'>
        <DialogHeader className='flex flex-center'>
          <DialogTitle className='w-100 flex flex-center'>Reclassificação de Imagem</DialogTitle>
        </DialogHeader>
        <DialogContent>
          <div style={{ textAlign: 'center', color: 'dimgrey', fontWeight: '600' }}>
            <br />
            <img width='300' height='200' style={{ backgroundColor: 'black', objectFit: 'contain' }} src={this.props.img} />
            <br />
            <br />
            <Checkbox id='imageHasMask' className='grid-checkbox' onChange={() => this.setState({ has_mask: !this.state.has_mask })} label='Imagem possui máscara' />
            <Checkbox
              id='termsAgreed'
              className='grid-checkbox'
              onChange={() => this.setState({ selected: true })}
              label='Concordo que imagens enviadas poderão ser acessíveis pela MVISIA.'
            />
          </div>
        </DialogContent>
        <DialogFooter>
          <Button className='w-100' theme='error' themeType='contained' onClick={this.props.close} style={{ margin: '12px' }}>
            Fechar
          </Button>
          <Button className='w-100' theme='primary' themeType='contained' onClick={this.send} style={{ margin: '12px' }} disabled={!this.state.selected}>
            Enviar
          </Button>
        </DialogFooter>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => {
  return {
    storeImage: (image, suffix, apptype) => dispatch(storeImage(image, suffix, apptype)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveDialog);
