import React from 'react';
import './webglError.scss';
import { Card, CardContent } from 'react-md';

export function WebglError(props) {
  return (
    <Card className='flex flex-wrap webgl-card'>
      <div className='w-100 title-card-text flex flex-center' style={{ textAlign: 'center' }}>
        Dispositivo não suportado
      </div>
      <CardContent className=' w-100 flex flex-wrap webgl-card-content'>
        <div className='flex flex-wrap webgl-text-content'>
          <div className='w-100'>Olá usuário! Infelizmente não é possível rodar nossas aplicações neste dispositivo.</div>
          <div className='w-100'>Você pode utilizar a plataforma em outro dispositivo, como um computador ou celular, com suporte a WebGL2.</div>
          <div className='w-100'>
            Caso tenha mais duvidas entre em contato conosco no email:{' '}
            <a style={{ color: '#de3c00' }} href='mailto:contato@mvisia.com.br'>
              contato@mvisia.com.br
            </a>
          </div>
        </div>
        <div className='flex w-100 webgl-image-content'>
          <img alt='no webgl error' className='w-100 h-100' style={{ objectFit: 'contain' }} src={process.env.PUBLIC_URL + '/img/error.svg'} />
        </div>
      </CardContent>
    </Card>
  );
}
