import * as lodash from 'lodash';

export class QualityPreprocessing {
  constructor(data) {
    this.tabType = 'preprocessing';
    this.data = lodash.cloneDeep(data);
  }

  serialize() {
    return this.data;
  }

  processBlur(mat, content) {
    let ksize = new window.cv.Size(content.kernel0, content.kernel1);
    window.cv.GaussianBlur(mat, mat, ksize, content.sigmaX, content.sigmaY, window.cv.BORDER_DEFAULT);
    return mat;
  }

  processClahe(mat, content) {
    window.cv.cvtColor(mat, mat, window.cv.COLOR_RGBA2GRAY, 0);
    let tileGridSize = new window.cv.Size(content.grid0, content.grid1);
    let clahe = new window.cv.CLAHE(content.clipLimit, tileGridSize);
    clahe.apply(mat, mat);
    clahe.delete();
    return mat;
  }

  processRoi(mat, content) {
    let rect = new window.cv.Rect(
      Math.floor(content.roiCoordinates[0] * mat.cols),
      Math.floor(content.roiCoordinates[1] * mat.rows),
      Math.floor((content.roiCoordinates[2] - content.roiCoordinates[0]) * mat.cols),
      Math.floor((content.roiCoordinates[3] - content.roiCoordinates[1]) * mat.rows)
    );
    let newReference = mat.roi(rect);
    mat.delete();
    return newReference;
  }

  process(mat) {
    if (this.data && !!mat) {
      if (this.data.roi.active && this.data.roi.roiCoordinates.length > 0) {
        mat = this.processRoi(mat, this.data.roi);
      }
      if (this.data.blur.active) {
        mat = this.processBlur(mat, this.data.blur);
      }
      if (this.data.clahe.active) {
        mat = this.processClahe(mat, this.data.clahe);
        window.cv.cvtColor(mat, mat, window.cv.COLOR_GRAY2RGBA);
      }
    }
    return mat;
  }
}
