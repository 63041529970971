import { bindActionCreators } from 'redux';
import { Card, CardContent } from '@react-md/card';
import { Checkbox } from '@react-md/form';
import { Button } from '@react-md/button';
import React from 'react';
import { connect } from 'react-redux';
import GridMarking from './gridMarking';
import ReactResizeDetector from 'react-resize-detector';
import { removeThumb } from '../../../../actions/appGrid';

class MarkingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: 0,
      gridChecked: false,
      markFullGridTrigger: -1,
      markAllImagesChecked: false,
      markAllImagesTrigger: -1,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentThumbIndex !== this.props.currentThumbIndex) {
      this.setState({ markAllImagesChecked: false });
    }
  }

  removeThumb() {
    this.props.removeThumb(this.props.currentThumbIndex);
  }

  render() {
    return (
      <>
        <Card className={`flex curve-border-2 text-center flex-column h-100 scroll appGrid-marking-section hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}>
          <div className='title-card-text w-100 flex flex-between'>Rotulação</div>
          <CardContent className='flex grown' style={{ flexWrap: 'wrap', height: 'calc(100% - 70px)' }}>
            <div className='curve-border-2 full-size card-content-content'>
              <div>
                <Checkbox
                  id='gridCheckbox'
                  className='grid-checkbox'
                  style={{ margin: '0' }}
                  checked={this.state.gridChecked}
                  onChange={val => this.setState({ gridChecked: val.target.checked })}
                  name='grid'
                  label='Linhas de grade'
                  disabled={this.props.noCapture}
                  theme='primary'
                />
                <Checkbox
                  id='markAllImagesCheckbox'
                  className='grid-checkbox'
                  style={{ margin: '0' }}
                  checked={this.state.markAllImagesChecked}
                  onChange={val => this.setState({ markAllImagesChecked: val.target.checked })}
                  name='grid'
                  label='Aplicação geral'
                  disabled={this.props.noCapture}
                  theme='primary'
                />
              </div>
              <ReactResizeDetector>
                {({ width, height }) => (
                  <div className='flex w-100 flex-center fourthStep sixthStep' style={{ height: 'calc(100% - 182px)', background: 'black' }}>
                    <GridMarking
                      gridChecked={this.state.gridChecked}
                      markAllImagesChecked={this.state.markAllImagesChecked}
                      gridArray={this.props.grids[this.props.currentThumbIndex]}
                      markFullGridTrigger={this.state.markFullGridTrigger}
                      markAllImagesTrigger={this.state.markAllImagesTrigger}
                      color={this.state.color}
                      unlockProceedButton={() => {
                        if (this.props.interactWithTour) {
                          this.props.unlockProceedButton();
                        }
                      }}
                      interactWithTour={this.props.interactWithTour}
                      parentsWidth={width}
                      parentsHeight={height}
                    />
                  </div>
                )}
              </ReactResizeDetector>
              <div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '18% 18% 18% 18% 18%',
                    justifyItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    onClick={() => {
                      if (this.state.color !== 0) this.setState({ color: 0 });
                    }}
                    className='colored-circle'
                    style={{
                      background: 'rgba(255, 255, 255, 0.65)',
                      border: this.state.color === 0 ? '4px solid black' : '1px solid black',
                    }}
                  />
                  <div
                    id='fifthStep'
                    onClick={() => {
                      if (this.state.color !== 1) this.setState({ color: 1 });
                      if (this.props.interactWithTour) this.props.nextStep();
                    }}
                    className='colored-circle'
                    style={{
                      background: 'rgba(0, 32, 128, 0.65)',
                      border: this.state.color === 1 ? '4px solid black' : '1px solid black',
                    }}
                  />
                  <div
                    id='thirdStep'
                    onClick={() => {
                      if (this.state.color !== 2) this.setState({ color: 2 });
                      if (this.props.interactWithTour) this.props.nextStep();
                    }}
                    className='colored-circle'
                    style={{
                      background: 'rgba(152, 11, 11, 0.65)',
                      border: this.state.color === 2 ? '4px solid black' : '1px solid black',
                    }}
                  />
                  <div
                    onClick={() => {
                      if (this.state.color !== 3) this.setState({ color: 3 });
                    }}
                    className='colored-circle'
                    style={{
                      background: 'rgba(15, 134, 15, 0.65)',
                      border: this.state.color === 3 ? '4px solid black' : '1px solid black',
                    }}
                  />
                  <div
                    onClick={() => {
                      if (this.state.color !== 4) this.setState({ color: 4 });
                    }}
                    className='colored-circle'
                    style={{
                      background: 'rgba(222, 222, 18, 0.65)',
                      border: this.state.color === 4 ? '4px solid black' : '1px solid black',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '32% 32% 32%',
                    justifyContent: 'space-between',
                    margin: '10px 0 0 0',
                  }}
                >
                  <Button
                    disabled={this.props.noCapture}
                    onClick={() => this.setState({ markFullGridTrigger: this.state.markFullGridTrigger * -1 })}
                    className='grown'
                    theme='primary'
                    themeType='contained'
                  >
                    Preencher Imagem
                  </Button>
                  <Button
                    disabled={!this.state.markAllImagesChecked}
                    onClick={() => this.setState({ markAllImagesTrigger: this.state.markAllImagesTrigger * -1 })}
                    className='grown'
                    theme='primary'
                    themeType='contained'
                  >
                    Aplicar a todos
                  </Button>
                  <Button disabled={this.props.currentThumbImg === ''} onClick={() => this.removeThumb()} className='grown' theme='primary' themeType='contained'>
                    Excluir
                  </Button>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  grids: state.appGrid.grids,
  postProcessingProcessor: state.cameraConfig.postProcessingProcessor,
  noCapture: state.appGrid.noCapture,
  currentThumbImg: state.appGrid.currentThumbImg,
  currentThumbIndex: state.appGrid.currentThumbIndex,
  applicationIsOnline: state.main.applicationIsOnline,
});

const mapDispatchToProps = dispatch => bindActionCreators({ removeThumb }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarkingSection);
