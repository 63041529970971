import React from 'react';
import FileHandler from '../../fileHandler';
import GeneralCamConfigContent from './generalCamConfigContent';
// import { Select } from 'react-md';
import InputRange from 'react-input-range';

const imageCameraSpeed = [
  { interval: 0.25, label: 'Muito alto' },
  { interval: 0.5, label: 'Alto' },
  { interval: 1, label: 'Médio' },
  { interval: 4, label: 'Baixo' },
  { interval: 8, label: 'Muito baixo' },
];

export default class ImagesCamConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      responseText: '',
    };
  }

  getCameraData(newParamsObject) {
    const content = { data: {} };
    content.data.type = 'imagesCam';
    content.data.interval = this.props.imagesCamInterval;
    content.data = { ...content.data, ...newParamsObject };
    return content;
  }
  setCamera(buttonToLoad) {
    this.props.setCamera(this.props.camName, this.getCameraData(), 2, buttonToLoad);
  }
  render() {
    const content = (
      <div id='imagesCamConfigContent'>
        <div className='w-100 flex flex-center flex-wrap'>
          <span className='w-100 flex flex-start capabilities-span' style={{ alignItems: 'center' }}>
            Tempo de visualização da imagem
          </span>
          <InputRange
            id='imagesFPS'
            labelContainer='imagesFPS'
            name='imagesFPS'
            formatLabel={value => `${imageCameraSpeed[value]?.label || 'Médio'}`}
            minValue={0}
            maxValue={4}
            value={
              imageCameraSpeed.findIndex(element => this.props.imagesCamInterval === element.interval) === -1
                ? 2
                : imageCameraSpeed.findIndex(element => this.props.imagesCamInterval === element.interval)
            }
            onChange={value => this.props.setParentState({ imagesCamInterval: imageCameraSpeed[value]?.interval || 2 })}
            onChangeComplete={value => this.props.applyCameraConfig(2, { ...this.getCameraData({ interval: imageCameraSpeed[value]?.interval || 2 }), name: this.props.camName })}
          />

          {/*VERSAO EM DROPDOWN*/}

          {/*<Select*/}
          {/*  id='cameraVisualizerSpeedSelect'*/}
          {/*  className='w-100'*/}
          {/*  label='Tempo de visualização da imagem'*/}
          {/*  options={imageCameraSpeed.map(element => element.label)}*/}
          {/*  value={imageCameraSpeed.find(element => element.interval === this.props.imagesCamInterval)?.label || 'Médio'}*/}
          {/*  onChange={value => {*/}
          {/*    const speed = imageCameraSpeed.find(element => element.label === value)?.interval || 2;*/}
          {/*    this.props.setParentState({ imagesCamInterval: speed });*/}
          {/*    this.props.applyCameraConfig(2, { ...this.getCameraData({ interval: speed }), name: this.props.camName });*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
        <FileHandler
          type='image'
          setText={responseText => this.setState({ responseText: responseText })}
          setImagesInput={files => {
            this.setState({ files: files });
            this.props.saveTemporaryFiles('image', files);
          }}
        />
        <div className='w-100' style={{ textAlign: 'center' }}>
          {this.state.responseText}
        </div>
        <div className='w-100' style={{ textAlign: 'center', backgroundColor: 'moccasin' }}>
          Formatos aceitos: JPEG | PNG | BMP | ICO | WEBP
        </div>
      </div>
    );

    return (
      <GeneralCamConfigContent
        parentContent={content}
        cameraType='Câmera de Imagens'
        camVariable='imagesCam'
        camName={this.props.camName}
        setParentState={content => this.props.setParentState(content)}
        cameraToParent={buttonToLoad => this.setCamera(buttonToLoad)}
        deleteCamera={() => this.props.deleteCamera(this.props.camName)}
        allCameras={this.props.allCameras}
        applyCameraConfig={() => this.props.applyCameraConfig(2, { ...this.getCameraData(), name: this.props.camName })}
        loadingButtons={this.props.loadingButtons}
        card={2}
        files={this.state.files.length > 0}
        cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
      />
    );
  }
}
