import React from 'react';
import './trainingDialog.scss';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'react-md';
import { toast } from 'react-toastify';

export default class TrainingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // trainingStarted: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps.sentImages !== prevProps.thumbsLength && this.props.sentImages === this.props.thumbsLength) ||
      (this.props.someError !== prevProps.someError && this.props.someError)
    ) {
      // this.setState({ trainingStarted: false });
      this.props.handleTrainingStatus(false);
    }
  }

  render() {
    let differentClassesToastId = null;
    const differentClassesMsg = `O modelo original possui ${this.props.modelClassNames?.length} ${
      this.props.activeClassNames?.length === 1 ? 'classe ativa' : 'classes ativas'
    }, enquanto as configurações atuais possuem ${this.props.activeClassNames?.length}. Estes valores devem ser iguais.`;
    let minimumClassesToTrainToastId = null;
    const minimumClassesToTrainMsg = `É necessário utilizar pelo menos duas classes para realizar um treinamento.`;

    return (
      <>
        <Button
          id='eighthStep'
          disabled={this.props.training || this.props.trainingStarted || this.props.thumbsLength < 12 || !this.props.modelUrl}
          onClick={() => {
            if (!!this.props.modelUrl) {
              switch (this.props.appType) {
                case 'appClass':
                  if (
                    this.props.buttonName === 'refinar' &&
                    !!this.props.modelClassNames?.length &&
                    !!this.props.activeClassNames?.length &&
                    this.props.modelClassNames?.length !== this.props.activeClassNames?.length
                  ) {
                    toast.isActive(differentClassesToastId)
                      ? toast.update(differentClassesToastId, { render: differentClassesMsg, autoClose: 4000 })
                      : (differentClassesToastId = toast.error(differentClassesMsg));
                  } else if (this.props.activeClassNames?.length < 2) {
                    toast.isActive(minimumClassesToTrainToastId)
                      ? toast.update(minimumClassesToTrainToastId, { render: minimumClassesToTrainMsg, autoClose: 4000 })
                      : (minimumClassesToTrainToastId = toast.error(minimumClassesToTrainMsg));
                  } else {
                    this.props.trainModel();
                  }
                  break;
                case 'appGrid':
                  this.props.trainModel();
                  break;
                default:
                  break;
              }
            }
          }}
          theme='primary'
          themeType='contained'
          className='grown'
        >
          {this.props.buttonName}
        </Button>
        <Dialog
          id='simple-dialog-training'
          visible={this.props.dialogEnabled}
          onRequestClose={() => this.props.handleDialogVisibility(false)}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader>
            <DialogTitle className='w-100 flex flex-center'>Etapa de treinamento</DialogTitle>
          </DialogHeader>
          <DialogContent>
            <div style={{ textAlign: 'center', color: 'dimgrey', fontWeight: '600' }}>
              {this.props.training ? (
                this.props.sentImages === this.props.thumbsLength ? (
                  [
                    <div key='epoch-status' className='w-100 flex'>
                      {this.props.currentEpochs < 0 ? (
                        <div className='w-100 flex'>
                          <p className='w-100'>Preparando o treinamento</p>
                        </div>
                      ) : this.props.currentEpochs === 0 ? (
                        <div className='w-100 flex'>
                          <p className='w-100'>Imagens na fila de treinamento</p>
                        </div>
                      ) : (
                        <div className='w-100 flex flex-wrap'>
                          <p className='w-100'>Imagens em treinamento</p>
                          <p className='w-100'>{`Epoca: ${this.props.currentEpochs} de ${this.props.numEpochs}`}</p>
                          <p className='w-100'>{Number(this.props.currentAccuracy) ? `Acurácia: ${Number(this.props.currentAccuracy).toFixed(2)}` : null}</p>
                        </div>
                      )}
                    </div>,
                    <div key='loading' className='loading'>
                      <div className='dot' />
                      <div className='dot' />
                      <div className='dot' />
                      <div className='dot' />
                      <div className='dot' />
                    </div>,
                  ]
                ) : (
                  <div className='w-100 flex flex-wrap'>
                    <p className='w-100'>Enviando imagens</p>
                    <div className='w-100'>
                      {this.props.sentImages}/{this.props.thumbsLength}
                    </div>
                  </div>
                )
              ) : this.props.trainingStarted ? (
                'Iniciando treinamento'
              ) : (
                <div className='w-100'>
                  <div className='w-100'>Treinamento Finalizado</div>
                  <div className='w-100'>{Number(this.props.currentAccuracy) ? `Acurácia: ${Number(this.props.currentAccuracy).toFixed(2)}` : null}</div>
                </div>
              )}
            </div>
          </DialogContent>
          <DialogFooter>
            <Button className='w-100' theme='primary' themeType='contained' onClick={() => this.props.handleDialogVisibility(false)} style={{ margin: '12px' }}>
              Fechar
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}
