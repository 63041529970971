import React from 'react';
import './login.scss';
import { Button, DialogFooter, TextField, Password, Form } from 'react-md';
import { bindActionCreators } from 'redux';
import * as userActions from '../../../actions/user';
import * as mainActions from '../../../actions/main';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setLocale } from 'yup';
import yupLocation from '../../yupLocation';
import { Card, CardContent } from '@react-md/card';
import Signup from '../signup/signup';
import ForgotPassword from '../forgotPassword/forgotPassword';

let yup = require('yup');

setLocale(yupLocation);

class Login extends React.Component {
  schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().min(8),
  });

  constructor(props) {
    super(props);
    this.state = {
      title: 'login',
      email: '',
      password: '',
    };
  }
  sendUserData() {
    if (this.state.title === 'login') {
      this.schema
        .validate({
          email: this.state.email,
          password: this.state.password,
        })
        .then(data => {
          this.props.login(data);
        })
        .catch(data => {
          if (!!data.errors) {
            toast.error(data.errors[0]);
          }
        });
    }
  }
  render() {
    let content = null;
    switch (this.state.title) {
      case 'signup':
        content = <Signup url={this.props.url} />;
        break;
      case 'resetPassword':
        content = <ForgotPassword url={this.props.url} />;
        break;
      case 'login':
        content = (
          <div className='flex flex-wrap w-100' style={{ alignContent: 'space-between', overflow: 'auto' }}>
            <div className='w-100' style={{ height: 'fit-content' }}>
              <div className='flex flex-center title-card-text'>Entrar</div>
              <Form className='flex flex-wrap' style={{ height: 'fit-content', width: '86%', margin: '0 auto' }}>
                <TextField
                  className='w-100'
                  id='emailField'
                  label='Email'
                  type='text'
                  theme='underline'
                  onChange={event => {
                    this.setState({ email: event.target.value });
                  }}
                  onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.sendUserData() : null)}
                />
                <Password
                  className='w-100'
                  visibilityStyle={{ color: '#223f54' }}
                  id='passwordField'
                  label='Senha'
                  theme='underline'
                  onChange={event => this.setState({ password: event.target.value })}
                  onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.sendUserData() : null)}
                />
              </Form>
            </div>
            <div className='w-100 flex flex-wrap' style={{ justifyContent: 'space-around' }}>
              <Button className='login-button' theme='primary' themeType='contained' onClick={() => this.sendUserData()}>
                Login
              </Button>
            </div>
          </div>
        );
        break;
      default:
        content = null;
        break;
    }
    return (
      <>
        <Card id='login' className='curve-border-2 login-card flex flex-column'>
          <CardContent className='flex grown' style={{ maxHeight: 'calc(100% - 120px)' }}>
            {content}
          </CardContent>
          <hr style={{ height: '2px', width: '64%', border: 'none', margin: '0 auto', backgroundColor: '#1a9c90' }} />
          <DialogFooter className='flex flex-wrap'>
            <div className='w-100 flex flex-center flex-wrap signup-container'>
              {this.state.title === 'login' ? (
                <>
                  <div className='w-100 flex flex-center'>
                    <div>Não possui conta?</div>{' '}
                    <div className='signup' onClick={() => this.setState({ title: 'signup' })}>
                      {' '}
                      Cadastre-se
                    </div>
                  </div>
                  <div className='signup forgot-password' onClick={() => this.setState({ title: 'resetPassword' })}>
                    Esqueceu sua senha?
                  </div>{' '}
                </>
              ) : (
                <div className='w-100 flex flex-center'>
                  <div>Já possui conta?</div>{' '}
                  <div className='signup' onClick={() => this.setState({ title: 'login' })}>
                    {' '}
                    Fazer login
                  </div>
                </div>
              )}
            </div>
          </DialogFooter>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  url: state.appGrid.url,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...userActions, ...mainActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
