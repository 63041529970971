import axios from 'axios';

export function getLicenses(url, groupId) {
  return axios.get(`${url}/api/work-group/${groupId}/licenses`);
}

export function getGroups() {
  return async (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    try {
      const groupsResponse = await axios.get(`${url}/api/work-group`);
      const licensesPromisesArray = [];

      const groups = groupsResponse.data;
      const groupsLength = groups.length;
      if (groupsLength < 1) {
        dispatch({
          type: 'LICENSES_GET_GROUPS',
          file: { groups: [], licenses: [] },
        });
        return;
      }

      groups.forEach(group => {
        licensesPromisesArray.push(getLicenses(url, group.id));
      });

      const licenses = Promise.all(licensesPromisesArray);

      licenses
        .then(res => {
          const licensesData = res.map(request => request.data);

          dispatch({
            type: 'LICENSES_GET_GROUPS',
            file: { groups: groups, licenses: licensesData },
          });
        })
        .catch(err => {
          dispatch({
            type: 'LICENSES_GET_GROUPS',
            file: { groups: null, licenses: null },
            error: true,
          });
        });
    } catch (e) {
      console.warn('ERR', e);
      dispatch({
        type: 'LICENSES_GET_GROUPS',
        file: { groups: null, licenses: null },
        error: true,
      });
    }
  };
}

export function cleanLicensesInfo() {
  return (dispatch, getState) => {
    dispatch({
      type: 'LICENSES_CLEAN_LICENSE_INFO',
      file: true,
    });
  };
}

export function deleteLicense(groupId, groupIndex, license) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    const licenseList = state.licenses.licensesList[groupIndex];
    const licenseToDeleteIndex = licenseList.findIndex(licenseFromList => license.key === licenseFromList.key);

    axios
      .delete(`${url}/api/work-group/${groupId}/licenses`, { data: license })
      .then(res => {
        dispatch({
          type: 'LICENSES_DELETE_LICENSE',
          file: { groupIndex, licenseToDeleteIndex },
        });
      })
      .catch(err => {
        dispatch({
          type: 'LICENSES_DELETE_LICENSE',
          file: false,
          error: true,
        });
      });
  };
}

export function createLicense(groupId, groupIndex, camId, HWId, group, date) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    const data = { cam_id: Number(camId), hw_id: Number(HWId), work_group: group, created_by: { email: state.user.email } };
    if (date) {
      data.expires_at = date;
    }
    axios
      .post(`${url}/api/work-group/${groupId}/licenses`, data)
      .then(res => {
        dispatch({
          type: 'LICENSES_CREATE_LICENSE',
          file: { groupIndex, newLicense: res.data },
        });
      })
      .catch(err => {
        dispatch({
          type: 'LICENSES_CREATE_LICENSE',
          file: {},
          error: true,
        });
      });
  };
}
