import React from 'react';
import { Card, DropdownMenu, MoreVertSVGIcon } from 'react-md';
import CameraDialog from '../../../cameraDialog/cameraDialog';
import { CardContent } from '@react-md/card';
import CodeEngine from '../codeEngine';
import { bindActionCreators } from 'redux';
import * as appGridActions from '../../../../actions/appGrid';
import * as appBarActions from '../../../../actions/appBar';
import { connect } from 'react-redux';
import PreProcessingDialog from '../../../preProcessing/preProcessingDialog';
import { MobileOnly, DesktopOnly } from '@react-md/utils';
import { changeCamera } from '../../../../actions/cameraConfig';

class StreamSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraModalEnabled: false,
      preProcessingModalEnabled: false,
    };
  }

  applyVideoSettings(settingsToSave) {
    if (!!this.props.cameraRef && this.props.cameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.cameraRef.applySettings(settingsToSave);
    }
  }

  render() {
    const dropItems = !this.props.cameraRef
      ? [{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]
      : [
          { children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) },
          { children: 'Pré processamento', onClick: () => this.setState({ preProcessingModalEnabled: true }) },
        ];
    return (
      <>
        {this.state.cameraModalEnabled ? (
          <CameraDialog saveThumbsFromImageCam={false} enabled={this.state.cameraModalEnabled} close={() => this.setState({ cameraModalEnabled: false })} />
        ) : null}
        <PreProcessingDialog enabled={this.state.preProcessingModalEnabled} close={() => this.setState({ preProcessingModalEnabled: false })} />
        <Card className='w-100 flex flex-wrap flex-column' style={{ margin: '0 0 2%', alignContent: 'flex-start', height: '70%' }}>
          <div className='title-card-text w-100 flex flex-between'>
            <MobileOnly>
              Inferência
              <DropdownMenu
                id='camOptions'
                buttonType='icon'
                aria-label='camOptions'
                items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
              >
                <MoreVertSVGIcon />
              </DropdownMenu>
            </MobileOnly>
            <DesktopOnly>
              Exibição
              <DropdownMenu id='camOptions' buttonType='icon' aria-label='camOptions' items={dropItems}>
                <MoreVertSVGIcon />
              </DropdownMenu>
            </DesktopOnly>
          </div>
          <CardContent className='flex flex-center w-100 grown' style={{ height: 'calc(100% - 70px)' }}>
            <div className='flex w-100 h-100' style={{ backgroundColor: 'black' }}>
              <div className='flex w-100' style={{ maxHeight: '100%', backgroundColor: 'black' }}>
                <CodeEngine
                  runningScript={this.props.runningScript}
                  scriptContent={this.props.scriptContent}
                  scripLogs={logs => this.props.scriptLogs(logs)}
                  cameraRef={this.props.cameraRef}
                  setCamera={camera => this.props.changeCamera(camera)}
                  preProcessingProcessor={this.props.preProcessingProcessor}
                  settings={this.props.settings}
                  applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  cameraRef: state.cameraConfig.newCameraRef,
  preProcessingProcessor: state.cameraConfig.preProcessingProcessor,
  settings: state.cameraConfig.settings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...appGridActions,
      ...appBarActions,
      changeCamera,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StreamSection);
