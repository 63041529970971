import React from 'react';
import { Rnd } from 'react-rnd';
import '../preProcessingDialog.scss';

export default class Roi extends React.Component {
  parentContainer = null;
  constructor(props) {
    super(props);
    this.state = {
      cropX: 0,
      cropY: 0,
      cropWidth: 100,
      cropHeight: 50,
      imageWidth: null,
      imageHeight: null,
      containerWidth: '100%',
      containerHeight: '100%',
    };
  }
  getCoordinates(x, y, w, h) {
    const y1 = Number(y / this.state.imageHeight).toFixed(2);
    const y2 = Number((y + h) / this.state.imageHeight).toFixed(2);
    const x1 = Number(x / this.state.imageWidth).toFixed(2);
    const x2 = Number((x + w) / this.state.imageWidth).toFixed(2);
    return [x1, y1, x2, y2];
  }
  updateRoi(x, y, w, h) {
    let value = this.getCoordinates(x, y, w, h);
    if (x !== this.state.cropX || y !== this.state.cropY || w !== this.state.cropWidth || h !== this.state.cropHeight) {
      this.props.coord(value);
      this.setState({ cropX: x, cropY: y, cropWidth: w, cropHeight: h });
    }
  }
  componentDidMount() {
    this.parentContainer = document.getElementById('roiContainer');
    this.setState({
      imageWidth: this.parentContainer.offsetWidth,
      imageHeight: this.parentContainer.offsetHeight,
    });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.parentContainer.offsetWidth !== this.state.imageWidth || this.parentContainer.offsetHeight !== this.state.imageHeight) {
      this.setState({
        imageWidth: this.parentContainer.offsetWidth,
        imageHeight: this.parentContainer.offsetHeight,
      });
    }
  }

  componentWillUnmount() {
    this.props.coord([null, null, null, null]);
  }
  render() {
    return (
      <div id='roiContainer' className='w-100 h-100' style={{ position: 'absolute', top: 0, left: 0 }}>
        <Rnd
          className='roi'
          bounds='parent'
          size={{
            width: this.state.cropWidth,
            height: this.state.cropHeight,
          }}
          position={{ x: this.state.cropX, y: this.state.cropY }}
          onDrag={(e, d) => {
            e.preventDefault();
            e.stopPropagation();
            this.updateRoi(d.x, d.y, this.state.cropWidth, this.state.cropHeight);
          }}
          onDragStop={(e, d) => {
            this.setState(() => ({ cropX: d.x, cropY: d.y }));
          }}
          onResize={(e, direction, ref, delta, position) => {
            this.updateRoi(position.x, position.y, ref.offsetWidth, ref.offsetHeight);
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            this.setState(() => ({
              cropX: position.x,
              cropY: position.y,
              cropWidth: ref.offsetWidth,
              cropHeight: ref.offsetHeight,
            }));
          }}
        />
      </div>
    );
  }
}
