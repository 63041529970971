import React from 'react';
import './detailSection.scss';
import { Card, CardContent, Button, Select } from 'react-md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { deleteThumb, changeThumbClass } from '../../../../actions/appClass';
import ModelSubSection from '../../modelSubSection/modelSubSection';
import { toast } from 'react-toastify';

class DetailSection extends React.Component {
  toastId = null;
  msg = 'Existem classes ativas adiante. Não é possível limpar a classe atual.';
  render() {
    const dropdownQuantity =
      this.props.classesImagesLength.findIndex(
        (element, index) => element !== 0 && (this.props.classesImagesLength[index + 1] === 0 || this.props.classesImagesLength[index + 1] === undefined)
      ) + 2;
    return (
      <div className={`h-100 flex curve-border-2 flex-column flex-between appClass-detail-section hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}>
        <Card
          className='flex curve-border-2 text-center flex-column w-100 scroll appClass-detail-section'
          style={{ height: '80%', overflow: 'hidden', maxHeight: 'calc(100% - 158px)' }}
        >
          <div className='title-card-text w-100 flex flex-between'>
            {this.props.noCapture ? (
              'Detalhes'
            ) : (
              <div className='flex w-100'>
                <div className='text-ellipsis' style={{ width: '50%' }}>
                  {this.props.classNames[this.props.showingImageClassIndex]}
                </div>
                <div style={{ width: '50%', textAlign: 'right' }}>{`${this.props.currentThumbIndex + 1}/${this.props.classesImagesLength[this.props.showingImageClassIndex]}`}</div>
              </div>
            )}{' '}
          </div>
          <CardContent className='w-100 flex flex-column' style={{ height: 'calc(100% - 70px)' }}>
            <div className='w-100 flex detail-thumb-container grown'>
              {this.props.noCapture ? (
                <img alt='no thumb selected' className='w-100 h-100 detail-thumb' src={`${process.env.PUBLIC_URL}/img/noImg.png`} />
              ) : (
                <img alt='current thumb selected' className='w-100 h-100 detail-thumb' src={this.props.currentThumbImg} />
              )}
            </div>
            <div className='w-100 flex flex-between detail-buttons-container'>
              <Select
                disabled={this.props.noCapture || this.props.showingImageClassIndex !== this.props.currentClassIndex}
                className='flex detail-select'
                style={{ margin: '20px 0' }}
                theme='outline'
                id='imageClassChange'
                labelKey='label'
                valueKey='value'
                options={this.props.classNames.slice(0, dropdownQuantity)}
                value={this.props.classNames[this.props.showingImageClassIndex]}
                name='imageClassChange'
                label='Trocar Classe'
                onChange={newClassName => {
                  const newClassIndex = this.props.classNames.findIndex(element => element === newClassName);
                  if (newClassIndex > -1 && this.props.showingImageClassIndex !== newClassIndex) {
                    if (newClassIndex > this.props.currentClassIndex && this.props.classesImagesLength[this.props.currentClassIndex] === 1) {
                      toast.isActive(this.toastId) ? toast.update(this.toastId, { render: this.msg }) : (this.toastId = toast.error(this.msg));
                    } else {
                      this.props.changeThumbClass(this.props.currentThumbIndex, this.props.showingImageClassIndex, Number(newClassIndex));
                    }
                  }
                }}
                disableMovementChange={true}
              />
              <Button
                disabled={this.props.noCapture || this.props.showingImageClassIndex !== this.props.currentClassIndex}
                theme='primary'
                themeType='contained'
                className='detail-button'
                onClick={() => {
                  const block = this.props.classesImagesLength.find(
                    (len, index) =>
                      this.props.classesImagesLength[this.props.currentClassIndex] === 1 && index > this.props.currentClassIndex && this.props.classesImagesLength[index] > 0
                  );
                  if (block !== undefined) {
                    toast.isActive(this.toastId) ? toast.update(this.toastId, { render: this.msg }) : (this.toastId = toast.error(this.msg));
                  } else {
                    this.props.deleteThumb();
                  }
                }}
              >
                excluir
              </Button>
            </div>
          </CardContent>
        </Card>
        <ModelSubSection appType='appClass' />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  noCapture: state.appClass.noCapture,
  currentThumbIndex: state.appClass.currentThumbIndex,
  currentThumbImg: state.appClass.currentThumbImg,
  currentClassIndex: state.appClass.currentClassIndex,
  showingImageClassIndex: state.appClass.showingImageClassIndex,
  classNames: state.appClass.classes.map(element => element.name),
  classesImagesLength: state.appClass.classes.map(element => element.images.length),
  applicationIsOnline: state.main.applicationIsOnline,
});

const mapDispatchToProps = dispatch => bindActionCreators({ deleteThumb, changeThumbClass }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailSection);
