import React from 'react';
import { TextField } from 'react-md';
import { toast } from 'react-toastify';

export default class TimeFilter extends React.Component {
  timeFilterNumberInputToastId = null;
  timeFilterNumberInputToast = toast;

  validateField(number, key) {
    if (typeof Number(number.trim()) === 'number' || number.trim() === '') {
      if (number >= 0 && ((key === 'minimumFrames' && number <= this.props.latestFrames) || (key === 'latestFrames' && number >= this.props.minimumFrames))) {
        this.props.setDialogState({ [key]: Number(number) || 0 });
      } else {
        if (!toast.isActive(this.timeFilterNumberInputToastId)) {
          this.timeFilterNumberInputToastId = this.timeFilterNumberInputToast.error('Valores fora do limite.');
        }
      }
    } else if (!toast.isActive(this.timeFilterNumberInputToastId)) {
      this.timeFilterNumberInputToastId = this.timeFilterNumberInputToast.error('Valor incoerente.');
    }
  }
  render() {
    return (
      <div className='flex flex-wrap config-box'>
        <h3 className='flex w-100 flex-center' style={{ margin: '4px 0' }}>
          Frames
        </h3>
        <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
          Nos últimos
          <TextField
            className='post-processing-config-height'
            style={{ width: '130px' }}
            id='totalFramesQuantity'
            type='number'
            min={1}
            step={1}
            theme='outline'
            placeholder={null}
            label={null}
            value={this.props.latestFrames.toString()}
            onChange={event => this.validateField(event.target.value, 'latestFrames')}
          />
        </div>
        <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
          Pelo menos
          <TextField
            className='post-processing-config-height'
            style={{ width: '130px' }}
            id='minimumFramesQuantity'
            type='number'
            min={1}
            step={1}
            theme='outline'
            placeholder={null}
            label={null}
            value={this.props.minimumFrames.toString()}
            onChange={event => this.validateField(event.target.value, 'minimumFrames')}
          />
        </div>
      </div>
    );
  }
}
