import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/fold-out-animation.css';
import SystemCamConfig from './cameras/systemCamConfig';
import IPCamConfig from './cameras/IPCamConfig';
import { bindActionCreators } from 'redux';
import {
  saveCamera,
  deleteCamera,
  setLoadingButton,
  changeCamera,
  setSettings,
  deleteCameraControl,
  saveTemporaryFiles,
  setCardSaveDisabled,
} from '../../../../actions/cameraConfig';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ImagesCamConfig from './cameras/imagesCamConfig';
import VideoCamConfig from './cameras/videoCamConfig';

const nonImageCameraSpeed = [
  { interval: 2, label: 'Muito baixa' },
  { interval: 6, label: 'Baixa' },
  { interval: 15, label: 'Média' },
  { interval: 20, label: 'Alta' },
  { interval: 30, label: 'Muito Alta' },
];

class CameraParams extends React.Component {
  cameraToBeDeleted = '';
  toastId = '';
  constructor(props) {
    super(props);
    this.state = {
      needsToRefresh: true,
      selected: null,
      //systemCam
      systemCamName: '',
      systemCamInterval: 15,
      //IPCam
      IPCamUrl: '',
      IPCamInterval: 15,
      IPCamName: '',
      //imagesCam
      imagesCamName: '',
      imagesCamInterval: 1,
      //videoCam
      videoCamName: '',
      videoCamInterval: 15,
      videoCamFrameRate: 1.0,
    };
  }

  setCamera(name, content, card, buttonToLoad) {
    this.props.setLoadingButton(card, buttonToLoad);
    this.props.saveCamera(name, content);
    this.props.setCardSaveDisabled(card, true);
  }

  applyCameraConfig(card, cameraData) {
    this.props.setLoadingButton(card, 1);
    this.props.changeCamera(cameraData);
    this.props.setCardSaveDisabled(card, false);
  }

  startDeleteFlow(cameraName) {
    if (cameraName !== 'default') {
      this.cameraToBeDeleted = cameraName;
      this.props.deleteCameraControl(true);
    } else {
      const msg = 'Não é possível deletar a câmera "default". Ela é a câmera padrão do sistema.';
      toast.isActive(this.toastId) ? toast.update(this.toastId, { type: 'error', render: msg, autoClose: 4000 }) : (this.toastId = toast.error(msg, { autoClose: 4000 }));
    }
  }

  setParentState(content, position) {
    this.setState(content);
    this.props.setCardSaveDisabled(position, false);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentCamera !== null && JSON.stringify(prevProps.currentCamera) !== JSON.stringify(this.props.currentCamera)) {
      let objectToSave = {
        systemCamName: this.props.currentCamera.name,
        IPCamName: this.props.currentCamera.name,
        imagesCamName: this.props.currentCamera.name,
        videoCamName: this.props.currentCamera.name,
      };
      if (this.props.currentCamera?.data?.type === 'systemCam') {
        objectToSave.systemCamInterval = this.props.currentCamera.data.interval;
        if (this.state.selected !== 0) {
          objectToSave.selected = 0;
        }
        this.setState(objectToSave);
      } else if (this.props.currentCamera?.data?.type === 'IPCam') {
        objectToSave.IPCamInterval = this.props.currentCamera.data?.interval;
        objectToSave.IPCamUrl = this.props.currentCamera.data?.url;
        if (this.state.selected !== 1) {
          objectToSave.selected = 1;
        }
        this.setState(objectToSave);
      } else if (this.props.currentCamera?.data?.type === 'imagesCam') {
        objectToSave.imagesCamInterval = this.props.currentCamera.data?.interval;
        if (this.state.selected !== 2) {
          objectToSave.selected = 2;
        }
        this.setState(objectToSave);
      } else if (this.props.currentCamera?.data?.type === 'videoCam') {
        objectToSave.videoCamInterval = this.props.currentCamera.data?.interval;
        objectToSave.videoCamFrameRate = this.props.currentCamera.data?.frameRate || 1.0;
        if (this.state.selected !== 3) {
          objectToSave.selected = 3;
        }
        this.setState(objectToSave);
      } else {
        if (this.state.selected !== 0) {
          this.setState({ selected: 0 });
        }
      }
    }
    if (prevProps.cameraDeletionControl !== this.props.cameraDeletionControl && !!this.props.cameraDeletionControl) {
      this.props.deleteCamera(this.cameraToBeDeleted);
    }

    if (prevState.selected !== this.state.selected && prevState.selected === null && this.state.needsToRefresh) {
      this.setState({ needsToRefresh: false });
    }
  }

  componentWillUnmount() {
    this.setState({
      selected: null,
      //systemCam
      systemCamName: '',
      systemCamInterval: 15,
      //IPCam
      IPCamUrl: '',
      IPCamInterval: 15,
      IPCamName: '',
      //imagesCam
      imagesCamName: '',
      imagesCamInterval: 1,
      //videoCam
      videoCamName: '',
      videoCamInterval: 15,
      videoCamFrameRate: 1.0,
    });
  }

  render() {
    return !this.state.needsToRefresh ? (
      <div className='camera-params small-params'>
        <AwesomeSlider animation='foldOutAnimation' fillParent={true} selected={this.state.selected} onTransitionEnd={event => this.setState({ selected: event.currentIndex })}>
          <div style={{ background: 'transparent' }} className='w-100 h-100 flex flex-center small-card-container-params'>
            <SystemCamConfig
              camName={this.state.systemCamName}
              systemCamInterval={this.state.systemCamInterval}
              allCameras={this.props.allCameras}
              setParentState={content => this.setParentState(content, 0)}
              setCamera={(name, content, card, buttonToLoad) => this.setCamera(name, content, card, buttonToLoad)}
              deleteCamera={cameraName => this.startDeleteFlow(cameraName)}
              applyCameraConfig={(card, cameraData) => this.applyCameraConfig(card, cameraData)}
              loadingButtons={this.props.loadingButtons}
              capabilities={this.props.capabilities}
              settings={this.props.settings}
              setSettings={settingsObj => this.props.setSettings(settingsObj)}
              currentCamera={this.props.currentCamera}
              cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
              nonImageCameraSpeed={nonImageCameraSpeed}
            />
          </div>
          <div style={{ background: 'transparent' }} className='w-100 h-100 flex flex-center small-card-container-params'>
            <IPCamConfig
              camName={this.state.IPCamName}
              IPCamUrl={this.state.IPCamUrl}
              IPCamInterval={this.state.IPCamInterval}
              allCameras={this.props.allCameras}
              setParentState={content => this.setParentState(content, 1)}
              setCamera={(name, content, card, buttonToLoad) => this.setCamera(name, content, card, buttonToLoad)}
              deleteCamera={cameraName => this.startDeleteFlow(cameraName)}
              applyCameraConfig={(card, cameraData) => this.applyCameraConfig(card, cameraData)}
              loadingButtons={this.props.loadingButtons}
              cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
              nonImageCameraSpeed={nonImageCameraSpeed}
            />
          </div>
          <div style={{ background: 'transparent' }} className='w-100 h-100 flex flex-center small-card-container-params'>
            <ImagesCamConfig
              camName={this.state.imagesCamName}
              imagesCamInterval={this.state.imagesCamInterval}
              allCameras={this.props.allCameras}
              setParentState={content => this.setParentState(content, 2)}
              setCamera={(name, content, card, buttonToLoad) => this.setCamera(name, content, card, buttonToLoad)}
              deleteCamera={cameraName => this.startDeleteFlow(cameraName)}
              applyCameraConfig={(card, cameraData) => this.applyCameraConfig(card, cameraData)}
              loadingButtons={this.props.loadingButtons}
              saveTemporaryFiles={(type, files) => this.props.saveTemporaryFiles(type, files)}
              cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
            />
          </div>
          <div style={{ background: 'transparent' }} className='w-100 h-100 flex flex-center small-card-container-params'>
            <VideoCamConfig
              camName={this.state.videoCamName}
              videoCamInterval={this.state.videoCamInterval}
              videoCamFrameRate={this.state.videoCamFrameRate}
              allCameras={this.props.allCameras}
              setParentState={content => this.setParentState(content, 3)}
              setCamera={(name, content, card, buttonToLoad) => this.setCamera(name, content, card, buttonToLoad)}
              deleteCamera={cameraName => this.startDeleteFlow(cameraName)}
              applyCameraConfig={(card, cameraData) => this.applyCameraConfig(card, cameraData)}
              loadingButtons={this.props.loadingButtons}
              saveTemporaryFiles={(type, files) => this.props.saveTemporaryFiles(type, files)}
              newCameraRef={this.props.newCameraRef}
              cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
              nonImageCameraSpeed={nonImageCameraSpeed}
            />
          </div>
        </AwesomeSlider>
      </div>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = state => ({
  currentCamera: state.cameraConfig.currentCamera,
  allCameras: state.cameraConfig.allCameras,
  loadingButtons: state.cameraConfig.loadingButtons,
  capabilities: state.cameraConfig.capabilities,
  settings: state.cameraConfig.settings,
  cameraDeletionControl: state.cameraConfig.cameraDeletionControl,
  newCameraRef: state.cameraConfig.newCameraRef,
  cameraCardSaveDisabled: state.cameraConfig.cameraCardSaveDisabled,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveCamera,
      deleteCamera,
      setLoadingButton,
      changeCamera,
      setSettings,
      deleteCameraControl,
      saveTemporaryFiles,
      setCardSaveDisabled,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CameraParams);
