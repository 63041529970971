import React from 'react';

export default class DatabaseTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: 3,
    };
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentIndex !== this.props.myIndex && this.props.currentIndex === this.props.myIndex) {
      this.setState({ counter: this.state.counter + 10 });
    }
  }

  render() {
    return <div className='tab-content'>{this.state.counter}</div>;
  }
}
