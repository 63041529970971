import React from 'react';
import { Schedule } from '../../../schedule';
import { updateCanvasDimension } from '../../../camera';

export default class CodeEngine extends React.Component {
  cameraSchedule = null;
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
    };
    this.initDefaultCam();
  }

  initDefaultCam() {
    this.props.setCamera({ name: '///', data: { type: 'systemCam', id: null, interval: null } });
  }

  proc = () => {
    let out = this.props.cameraRef?.captureStreamImg();
    if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }
    if (this.props.preProcessingProcessor) {
      out = this.props.preProcessingProcessor.process(out);
      if (this.props.preProcessingProcessor.data.roi && (this.state.cols !== out.cols || this.state.rows !== out.rows)) {
        this.setState({ cols: out.cols, rows: out.rows });
      }
    }
    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
    window.cv.imshow(this.canvasRef.current, out);
    out.delete();

    if (!!this.allowApplySettings) {
      this.props.applyVideoSettings(this.settingsToSave);
      this.allowApplySettings = false;
      this.settingsToSave = {};
    }
  };

  setCanvasDimension(preProcessWidth, preProcessHeight) {
    const newDimensions = updateCanvasDimension(this.props.cameraRef, this.state.canvasWidth, this.state.canvasHeight, preProcessWidth, preProcessHeight);
    this.setState(newDimensions);
  }

  runCodeScript() {
    // const words = [
    //   'break', 'case', 'catch', 'class', 'const', 'continue',
    //   'debugger', 'default', 'delete', 'do', 'else', 'export',
    //   'extends', 'finally', 'for', 'function', 'if', 'import',
    //   'in', 'instanceof', 'new', 'return', 'super', 'switch',
    //   'this', 'throw', 'try', 'typeof', 'var', 'void', 'while', 'with', 'yield']
    let cv = window.cv;
    let logs = [];
    let img = null;
    const readImage = () => {
      let out = this.props.cameraRef?.captureStreamImg();
      if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
        this.setCanvasDimension();
      }
      if (out === null) {
        return null;
      }
      if (this.props.preProcessingProcessor) {
        out = this.props.preProcessingProcessor.process(out);
        if (this.props.preProcessingProcessor.data.roi && (this.state.cols !== out.cols || this.state.rows !== out.rows)) {
          this.setState({ cols: out.cols, rows: out.rows });
        }
      }
      return out;
    };
    const writeImage = out => {
      try {
        window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
        window.cv.imshow(this.canvasRef.current, out);
      } catch (e) {
        console.warn(e);
      }
    };
    const loopSchedule = (functionName, fps) => {
      this.setStream(functionName, 1000 / fps);
    };
    // const checkStructure = (params) => {
    //   console.log('checkStructure', params)
    //   let passed = true;
    //   params.every((el, index) => {
    //     try{
    //       JSON.stringify(params[el])
    //       return true
    //     } catch(e){
    //       passed = false
    //       return false
    //     }
    //   })
    //   console.log('PASSED', passed)
    //   return passed;
    // }
    const console = {
      log: (...params) => {
        // if(checkStructure(params)){
        //   logs.push({ method: 'log', data: params });
        // } else{
        //   toast.error('Log incorreto.');
        // }
        logs.push({ method: 'log', data: params });
      },
      error: (...params) => {
        // if(checkStructure(params)){
        //   logs.push({ method: 'error', data: params });
        // } else{
        //   toast.error('Log incorreto.');
        // }
        logs.push({ method: 'error', data: params });
      },
      warn: (...params) => {
        // if(checkStructure(params)){
        //   logs.push({ method: 'warn', data: params });
        // } else{
        //   toast.error('Log incorreto.');
        // }
        logs.push({ method: 'warn', data: params });
      },
    };
    const logRegister = () => {
      this.props.scripLogs(logs);
      logs = [];
    };
    try {
      const scriptFunction = new Function('readImage', 'writeImage', 'loopSchedule', 'console', 'logRegister', 'img', this.props.scriptContent);
      scriptFunction(readImage, writeImage, loopSchedule, console, logRegister, img);
    } catch (e) {
      logs.push({ method: 'error', data: [e.message] });
      logRegister();
      this.setStream(this.proc, this.props.cameraRef?.getInterval());
    }
  }

  setStream(func, interval) {
    if (!!this.props.cameraRef) {
      if (this.cameraSchedule) {
        this.cameraSchedule.running = false;
      }
      this.cameraSchedule = new Schedule(func, interval);
    } else {
      if (this.cameraSchedule) {
        this.cameraSchedule.running = false;
      }
    }
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!!nextProps.settings && JSON.stringify(nextProps.settings) !== JSON.stringify(this.props.settings) && !this.allowApplySettings) {
      this.allowApplySettings = true;
      this.settingsToSave = { ...nextProps.settings };
    }
    if (this.state.cols !== nextState.cols || this.state.rows !== nextState.rows) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (!!this.props.preProcessingProcessor?.data.roi && !nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension();
      return true;
    }
    if (!this.props.preProcessingProcessor?.data.roi && !!nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (this.canvasRef.current?.width > 0 && this.canvasRef.current?.height > 0 && this.props.cameraRef?.type === nextProps.cameraRef?.type) {
      if (this.props.runningScript !== nextProps.runningScript) {
        return true;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cameraRef !== this.props.cameraRef && !this.props.runningScript) {
      this.setStream(this.proc, this.props.cameraRef?.getInterval());
    } else if (prevProps.cameraRef !== this.props.cameraRef) {
      this.runCodeScript();
    } else if (prevProps.scriptContent !== this.props.scriptContent && this.props.runningScript) {
      this.runCodeScript();
    } else if (prevProps.runningScript !== this.props.runningScript && !this.props.runningScript) {
      this.setStream(this.proc, this.props.cameraRef?.getInterval());
    } else if (prevProps.runningScript !== this.props.runningScript && this.props.runningScript) {
      this.runCodeScript();
    }
  }

  render() {
    return <canvas className='w-100' style={{ height: 'auto', objectFit: 'contain' }} ref={this.canvasRef} width={this.state.canvasWidth} height={this.state.canvasHeight} />;
  }
}
