import React from 'react';
import { SlideInLeft, SlideInRight } from '../../../../animation';
import InputRange from 'react-input-range';
import { QualityPreprocessing } from '../../qualityEngineClasses/qualityPreprocessingClass';
import { Button } from 'react-md';
import Toggle from 'react-toggle';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setPreprocessingValuesAtStore, setTemporaryRoi, setTabRef } from '../../../../../actions/appQuality';
import * as lodash from 'lodash';

class PreprocessingTab extends React.Component {
  animationComponent = SlideInRight;

  kernel0Min = 3;
  kernel0Max = 29;
  kernel1Min = 3;
  kernel1Max = 29;
  sigmaXMin = 0;
  sigmaXMax = 5;
  sigmaYMin = 0;
  sigmaYMax = 5;

  grid0Min = 2;
  grid0Max = 30;
  grid1Min = 2;
  grid1Max = 30;
  clipLimitMin = 0;
  clipLimitMax = 5;

  constructor(props) {
    super(props);
    this.state = {};
  }

  getParams() {
    return {
      blur: this.props.preprocessing.blur,
      clahe: this.props.preprocessing.clahe,
      roi: this.props.preprocessing.roi,
    };
  }

  updateDataAtStore(key, data) {
    if (data !== null) {
      this.props.setPreprocessingValuesAtStore(key, data);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.currentIndex !== this.props.myIndex && this.props.currentIndex === this.props.myIndex) {
      this.props.setTabRef(new QualityPreprocessing(this.getParams()), 1);
    }
    if (!lodash.isEqual(prevProps.preprocessing, this.props.preprocessing)) {
      this.props.setTabRef(new QualityPreprocessing(this.getParams()), 1);
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.currentIndex !== nextProps.currentIndex) {
      if (this.props.currentIndex < this.props.myIndex) {
        //RIGHT-LEFT ANIMATION
        this.animationComponent = SlideInRight;
      } else if (this.props.currentIndex > this.props.myIndex) {
        //LEFT-RIGHT ANIMATION
        this.animationComponent = SlideInLeft;
      }
    }
    return true;
  }

  render() {
    const AnimationComponent = this.animationComponent;
    return (
      <div className='tab-content'>
        <AnimationComponent>
          <div className='w-100 flex flex-wrap' style={{ justifyContent: 'space-between' }}>
            <div className='quality-binarization'>
              <div className='w-100 flex flex-center capabilities-span' style={{ margin: '18px 0', position: 'relative' }}>
                <div className='w-100 h-100 flex flex-center'>Borramento</div>
                <div style={{ position: 'absolute', right: 0 }}>
                  <Toggle
                    id={`toggleBlur`}
                    checked={this.props.preprocessing.blur.active}
                    icons={false}
                    onChange={event => this.updateDataAtStore('blur', { ...this.props.preprocessing.blur, active: event.target.checked })}
                  />
                </div>
              </div>

              <div className='w-100 full-wrapper' style={{ padding: '0 12px', height: '300px' }}>
                <div id='qualityAnalysisBlurKernel0Container' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Kernel 0
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisBlurKernel0'
                      labelContainer='Blur Kernel 0'
                      name='qualityAnalysisBlurKernel0'
                      formatLabel={value => `${value}`}
                      step={1}
                      minValue={this.kernel0Min}
                      maxValue={this.kernel0Max}
                      value={this.props.preprocessing.blur.kernel0}
                      onChange={value => {
                        let newValue = value % 2 === 0 ? value + 1 : value;
                        this.updateDataAtStore('blur', { ...this.props.preprocessing.blur, kernel0: newValue });
                      }}
                    />
                  </div>
                </div>

                <div id='qualityAnalysisBlurKernel1Container' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Kernel 1
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisBlurKernel1'
                      labelContainer='Blur Kernel 1'
                      name='qualityAnalysisBlurKernel1'
                      formatLabel={value => `${value}`}
                      step={1}
                      minValue={this.kernel1Min}
                      maxValue={this.kernel1Max}
                      value={this.props.preprocessing.blur.kernel1}
                      onChange={value => {
                        let newValue = value % 2 === 0 ? value + 1 : value;
                        this.updateDataAtStore('blur', { ...this.props.preprocessing.blur, kernel1: newValue });
                      }}
                    />
                  </div>
                </div>

                <div id='qualityAnalysisBlurSigmaXContainer' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Sigma X
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisBlurSigmaX'
                      labelContainer='Blur Sigma X'
                      name='qualityAnalysisBlurSigmaX'
                      formatLabel={value => `${value.toFixed(2)}`}
                      step={0.1}
                      minValue={this.sigmaXMin}
                      maxValue={this.sigmaXMax}
                      value={this.props.preprocessing.blur.sigmaX}
                      onChange={value => this.updateDataAtStore('blur', { ...this.props.preprocessing.blur, sigmaX: value })}
                    />
                  </div>
                </div>

                <div id='qualityAnalysisBlurSigmaYContainer' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Sigma Y
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisBlurSigmaY'
                      labelContainer='Blur Sigma Y'
                      name='qualityAnalysisBlurSigmaY'
                      formatLabel={value => `${value.toFixed(2)}`}
                      step={0.1}
                      minValue={this.sigmaYMin}
                      maxValue={this.sigmaYMax}
                      value={this.props.preprocessing.blur.sigmaY}
                      onChange={value => this.updateDataAtStore('blur', { ...this.props.preprocessing.blur, sigmaY: value })}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='quality-binarization'>
              <div className='w-100 flex flex-center capabilities-span' style={{ margin: '18px 0', position: 'relative' }}>
                <div className='w-100 h-100 flex flex-center'>Clahe</div>
                <div style={{ position: 'absolute', right: 0 }}>
                  <Toggle
                    id={`toggleClahe`}
                    checked={this.props.preprocessing.clahe.active}
                    icons={false}
                    onChange={event => this.updateDataAtStore('clahe', { ...this.props.preprocessing.clahe, active: event.target.checked })}
                  />
                </div>
              </div>
              <div className='w-100 full-wrapper' style={{ padding: '0 12px', height: '300px' }}>
                <div id='qualityAnalysisClaheGrid0Container' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Grid 0
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisClaheGrid0'
                      labelContainer='Clahe Grid 0'
                      name='qualityAnalysisClaheGrid0'
                      formatLabel={value => `${value}`}
                      step={1}
                      minValue={this.grid0Min}
                      maxValue={this.grid0Max}
                      value={this.props.preprocessing.clahe.grid0}
                      onChange={value => {
                        let newValue = value % 2 === 0 ? value + 1 : value;
                        this.updateDataAtStore('clahe', { ...this.props.preprocessing.clahe, grid0: newValue });
                      }}
                    />
                  </div>
                </div>

                <div id='qualityAnalysisClaheGrid1Container' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Grid 1
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisClaheGrid1'
                      labelContainer='Clahe Grid 1'
                      name='qualityAnalysisClaheGrid1'
                      formatLabel={value => `${value}`}
                      step={1}
                      minValue={this.grid1Min}
                      maxValue={this.grid1Max}
                      value={this.props.preprocessing.clahe.grid1}
                      onChange={value => {
                        let newValue = value % 2 === 0 ? value + 1 : value;
                        this.updateDataAtStore('clahe', { ...this.props.preprocessing.clahe, grid1: newValue });
                      }}
                    />
                  </div>
                </div>

                <div id='qualityAnalysisClaheClipLimitContainer' className='w-100 flex' style={{ margin: '42px 0' }}>
                  <span className='common-slider-title' style={{ width: '90px' }}>
                    Clip Limit
                  </span>
                  <div className='quality-identification-slider-container' style={{ width: 'calc(100% - 90px)' }}>
                    <InputRange
                      id='qualityAnalysisClaheClipLimit'
                      labelContainer='Clip Limit'
                      name='qualityAnalysisClaheClipLimit'
                      formatLabel={value => `${value.toFixed(2)}`}
                      step={1}
                      minValue={this.clipLimitMin}
                      maxValue={this.clipLimitMax}
                      value={this.props.preprocessing.clahe.clipLimit}
                      onChange={value => this.updateDataAtStore('clahe', { ...this.props.preprocessing.clahe, clipLimit: value })}
                    />
                  </div>
                </div>
              </div>
            </div>

            {this.props.preprocessingRef !== null && (
              <div className='quality-binarization w-100 flex flex-wrap flex-center'>
                <div className='flex flex-center capabilities-span' style={{ width: '60%', margin: '18px 0', position: 'relative' }}>
                  <div className='w-100 h-100 flex flex-center'>Roi</div>
                  <div style={{ position: 'absolute', right: 0 }}>
                    <Toggle id={`toggleRoi`} disabled={true} icons={false} onChange={() => null} checked={!!this.props.temporaryRoi.blockedRoi} />
                  </div>
                </div>
                <div className='full-wrapper' style={{ width: '60%', padding: '10px' }}>
                  <QualityPreprocessingRoiAxis roiCoordinates={this.props.preprocessing.roi.roiCoordinates} />
                  <div className='w-100 flex-center' style={{ marginTop: '16px' }}>
                    <Button
                      className='w-100'
                      style={{ marginBottom: '4px' }}
                      theme='primary'
                      themeType='contained'
                      disabled={this.props.preprocessing.roi.roiCoordinates.length < 1 || this.props.temporaryRoi.blockedRoi}
                      onClick={() => {
                        this.updateDataAtStore('roi', { ...this.props.preprocessing.roi, active: true });
                        this.props.setTemporaryRoi(null, true);
                      }}
                    >
                      Cortar
                    </Button>
                    <Button
                      className='w-100'
                      theme='error'
                      themeType='contained'
                      onClick={() => {
                        this.updateDataAtStore('roi', { ...this.props.preprocessing.roi, active: false });
                        this.props.setTemporaryRoi({ roiX: 0, roiY: 0, roiWidth: 100, roiHeight: 50 }, false);
                      }}
                    >
                      Desfazer
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </AnimationComponent>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  preprocessing: state.appQuality.preprocessing,
  temporaryRoi: state.appQuality.temporaryRoi,
  preprocessingRef: state.appQuality.tabsRefs[1],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPreprocessingValuesAtStore,
      setTabRef,
      setTemporaryRoi,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PreprocessingTab);

class QualityPreprocessingRoiAxis extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          margin: '0 auto',
          width: '250px',
          height: '190px',
          fontWeight: 'bold',
          fontSize: '15px',
        }}
      >
        <div className='quality-roi-grid'>
          <div id='g1' className='flex-center' style={{ position: 'relative' }}>
            <div
              style={{
                width: '0',
                height: '0',
                position: 'absolute',
                top: '20%',
                right: '0px',
                transform: 'translate(9px, 0px)',
                borderRight: '8px solid transparent',
                borderLeft: '8px solid transparent',
                borderBottom: '16px solid blue',
              }}
            />
            <div
              style={{
                width: '0',
                height: '0',
                position: 'absolute',
                left: '20%',
                bottom: '0px',
                transform: 'translate(0px, 9px)',
                borderRight: '16px solid green',
                borderTop: '8px solid transparent',
                borderBottom: '8px solid transparent',
              }}
            />
          </div>

          <div
            id='g2'
            style={{
              color: 'blue',
              position: 'relative',
              padding: '20px 0 0 14px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {this.props.roiCoordinates[1]}
            <div
              className='w-100'
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                height: '60%',
                borderLeft: '3px solid blue',
              }}
            />
          </div>

          <div id='g3' className='flex-center' />

          <div
            id='g4'
            style={{
              color: 'green',
              position: 'relative',
              padding: '12px 0 0 10%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
            }}
          >
            {this.props.roiCoordinates[0]}
            <div
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                width: '60%',
                borderTop: '3px solid green',
              }}
            />
          </div>

          <div id='g5' className='flex flex-center full-size' style={{ border: '3px dashed red', margin: 'auto' }}>
            {'Ratio'}
          </div>

          <div
            id='g6'
            style={{
              color: 'green',
              position: 'relative',
              padding: '0 10% 12px 0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '60%',
                borderBottom: '3px solid green',
              }}
            />
            {this.props.roiCoordinates[2]}
          </div>

          <div id='g7' className='flex-center' />

          <div
            id='g8'
            style={{
              color: 'blue',
              position: 'relative',
              padding: '0 14px 20px 0',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <div
              className='w-100'
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                height: '60%',
                borderRight: '3px solid blue',
              }}
            />
            {this.props.roiCoordinates[3]}
          </div>

          <div id='g9' className='flex-center' style={{ position: 'relative' }}>
            <div
              style={{
                width: '0',
                height: '0',
                position: 'absolute',
                bottom: '20%',
                left: '0px',
                transform: 'translate(-9px, 0px)',
                borderLeft: '8px solid transparent',
                borderRight: '8px solid transparent',
                borderTop: '16px solid blue',
              }}
            />
            <div
              style={{
                width: '0',
                height: '0',
                position: 'absolute',
                right: '20%',
                top: '0px',
                transform: 'translate(0px,-9px)',
                borderLeft: '16px solid green',
                borderTop: '8px solid transparent',
                borderBottom: '8px solid transparent',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
