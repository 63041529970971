import * as tf from '@tensorflow/tfjs';
import axios from 'axios';
import { toast } from 'react-toastify';
import { cameraDeserialize } from '../camera';

async function loadAndSaveData(appType, modelUrl, appConfigName, appConfigData, user) {
  const modelResponse = await tf.loadLayersModel(modelUrl, { strict: false }).catch(err => {
    console.log('fetchModelError', err);
    return false;
  });
  try {
    await modelResponse.save(`indexeddb://${user.uuid}/${appType}/${appConfigName}`);
  } catch (e) {
    console.log(e);
    return false;
  }
  try {
    appConfigData.user = { uuid: user.uuid, firstName: user.firstName };
    appConfigData.customdata[appType].modelUrl = `indexeddb://${user.uuid}/${appType}/${appConfigName}`;
    localStorage.setItem(`${user.uuid}/${appType}/${appConfigName}`, JSON.stringify(appConfigData));
    return true;
  } catch (e) {
    await tf.io.removeModel(`indexeddb://${user.uuid}/${appType}/${appConfigName}`);
    localStorage.removeItem(`${user.uuid}/${appType}/${appConfigName}`);
    console.log(e);
    return false;
  }
}

function createDataToSave(appType, originalData, allCameras) {
  const dataToSave = { ...originalData };
  const cameraToSave = allCameras.find(camera => camera.name === dataToSave.camera);
  let cameraData = { type: 'systemCam', id: null, interval: null };
  if (!!cameraToSave && !!cameraToSave.data && Object.keys(cameraToSave.data).length > 0) {
    cameraData = cameraToSave.data;
  }
  dataToSave.camera = cameraData;
  switch (appType) {
    case 'appGrid':
      dataToSave.customdata[appType].thumbs = [];
      dataToSave.customdata[appType].grids = [];
      break;
    case 'appClass':
      dataToSave.customdata[appType].classes.forEach((el, index) => {
        dataToSave.customdata[appType].classes[index].images = [];
      });
      break;
    //TODO: Add other classes later. AppFace will need some modifications, since predict needs images from store
    default:
      return dataToSave;
  }
  return dataToSave;
}

export function saveLocalAppConfig(appType, appConfigName) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const appConfigResponse = await axios.get(`${getState().appGrid.url}/api/appconfig/${appType}/${appConfigName}`).catch(err => {
        dispatch({
          type: 'LOCALCONFIG_CHANGE_APP_SAVE_LOCAL_APPCONFIG',
          file: err,
          error: true,
        });
      });

      const appConfigData = createDataToSave(appType, appConfigResponse.data, state.cameraConfig.allCameras);
      const modelUrl = appConfigResponse.data.customdata[appType].modelUrl;

      if (!!modelUrl) {
        const dataResponse = await loadAndSaveData(appType, modelUrl, appConfigName, appConfigData, getState().user);
        if (dataResponse) {
          dispatch({ type: 'LOCALCONFIG_CHANGE_APP_SAVE_LOCAL_APPCONFIG', file: appConfigResponse });
          toast.success('Dados salvos com sucesso.');
        } else {
          dispatch({ type: 'LOCALCONFIG_CHANGE_APP_SAVE_LOCAL_APPCONFIG', file: true, error: true });
          toast.error('Não foi possível salvar os dados localmente.');
        }
      } else {
        dispatch({ type: 'LOCALCONFIG_CHANGE_APP_SAVE_LOCAL_APPCONFIG', file: true, error: true });
        toast.error('Não existe modelo treinado para este App Config.');
      }
    } catch (e) {
      console.log('error', e);
      if (typeof e === 'string') {
        toast.error(e);
      }
    }
  };
}

export function deleteLocalAppConfig(appType, appConfigName) {
  return async (dispatch, getState) => {
    let error = false;
    try {
      const storedApps = await tf.io.listModels();
      const modelPathToDelete = Object.keys(storedApps).find(key => key.includes(`/${getState().user.uuid}/${appType}/${appConfigName}`));
      if (!modelPathToDelete) {
        error = true;
      }
      localStorage.removeItem(`${getState().user.uuid}/${appType}/${appConfigName}`);
      if (error) {
        dispatch({ type: 'LOCALCONFIG_DELETE_LOCAL_APP_CONFIG', file: true, error: true });
        toast.error('App Config. não encontrado no banco de dados local.');
        return;
      }
      await tf.io.removeModel(modelPathToDelete);
      toast.success('Dados removidos com sucesso.');
      dispatch({ type: 'LOCALCONFIG_DELETE_LOCAL_APP_CONFIG', file: true });
    } catch (e) {
      console.log(e);
      if (typeof e === 'string') {
        toast.error(e);
      }
      dispatch({ type: 'LOCALCONFIG_DELETE_LOCAL_APP_CONFIG', file: true, error: true });
    }
  };
}

export function setLoadingButton(appIndex, buttonIndex) {
  return (dispatch, getState) => {
    dispatch({ type: 'LOCALCONFIG_SET_LOADING_BUTTON', file: { appIndex, buttonIndex } });
  };
}

export function fetchOfflineApps(setOfflineFromLink) {
  return async (dispatch, getState) => {
    const localAppConfigs = {};
    const storedModels = await tf.io.listModels();
    let someError = false;
    Object.keys(storedModels).forEach(path => {
      try {
        const appConfig = JSON.parse(localStorage.getItem(path.split('//')[1]));
        appConfig.name = path.split('//')[1].split('/')[2];
        if (Array.isArray(localAppConfigs[appConfig.apptype])) {
          localAppConfigs[appConfig.apptype].push(appConfig);
        } else {
          localAppConfigs[appConfig.apptype] = [];
          localAppConfigs[appConfig.apptype].push(appConfig);
        }
      } catch (e) {
        console.log('error', e);
        someError = true;
      }
    });
    if (someError) {
      dispatch({ type: 'LOCALCONFIG_FETCH_OFFLINE_APPS', file: localAppConfigs, error: true });
      toast.error('Não foi possível buscar alguns dos dados locais.');
      return;
    }
    dispatch({ type: 'LOCALCONFIG_FETCH_OFFLINE_APPS', file: { localAppConfigs, setOfflineFromLink } });
  };
}

export function changeOfflineAppConfig(current, appName, appIndex) {
  return (dispatch, getState) => {
    cameraDeserialize(getState()[current + 'SelectApp'].apps[appIndex])
      .then(cameraRef => {
        dispatch({
          type: 'LOCALCONFIG_CHANGE_OFFLINE_APPCONFIG',
          file: { current, appName, appIndex, cameraRef },
        });
      })
      .catch(errorMessage => {
        toast.error(errorMessage);
        dispatch({ type: 'LOCALCONFIG_CHANGE_OFFLINE_APPCONFIG', file: { current, appName, appIndex }, error: true });
      });
  };
}

export function setOfflineFromLink(param) {
  return (dispatch, getState) => {
    dispatch({ type: 'LOCALCONFIG_SET_OFFLINE_FROM_LINK', file: param });
  };
}

export function addRouteToForbidden(route) {
  return (dispatch, getState) => {
    dispatch({ type: 'LOCALCONFIG_ADD_ROUTE_TO_FORBIDDEN', file: route });
  };
}
