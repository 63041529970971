import produce from 'immer';
import * as lodash from 'lodash';

function compare(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export default function cameraConfigReducer(state, action) {
  if (action.error) {
    return produce(state, draft => {
      draft.cameraConfig.loadingButtons = [
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
      ];
    });
  } else {
    let emptyLoading = lodash.cloneDeep(state.cameraConfig.loadingButtons);
    emptyLoading.fill([0, 0, 0]);

    switch (action.type) {
      case 'CAMERACONFIG_SAVE_CAMERA':
        const create = state.cameraConfig.allCameras.findIndex(camera => camera.name === action.file.data.name);
        return produce(state, draft => {
          if (create === -1) {
            draft.cameraConfig.allCameras.push(action.file.data);
          } else {
            draft.cameraConfig.allCameras[create] = action.file.data;
          }
          draft.cameraConfig.loadingButtons = emptyLoading;
          draft.cameraConfig.cameraToastId = action.file.cameraToastId;
        });
      case 'CAMERACONFIG_FETCH_CAMERAS':
        let newOrderedCameras = action.file.data.sort(compare);
        const defaultIndex = newOrderedCameras.findIndex(el => el.name === 'default');
        if (defaultIndex !== 0 && defaultIndex !== -1) {
          newOrderedCameras = [...newOrderedCameras.splice(defaultIndex, 1), ...newOrderedCameras];
        }
        return produce(state, draft => {
          draft.cameraConfig.allCameras = newOrderedCameras;
          draft.cameraConfig.camerasFetched = true;
        });
      case 'CAMERACONFIG_CHANGE_CAMERA':
        return produce(state, draft => {
          if (action.file.newCameraRef !== null) {
            draft.cameraConfig.newCameraRef = action.file.newCameraRef;
            if (action.file.imagesToUpload.length > 0) {
              if (action.file.current === 'appGrid') {
                let newThumbs = [...state.appGrid.thumbs];
                newThumbs = newThumbs.concat(action.file.imagesToUpload);
                let newGrids = [...state.appGrid.grids];
                const arraysToPush = action.file.imagesToUpload.map(() => Array(state.appGrid.gridSize * state.appGrid.gridSize).fill(0));
                newGrids = newGrids.concat(arraysToPush);

                draft.appGrid.thumbs = newThumbs;
                draft.appGrid.grids = newGrids;
                draft.appGrid.pendingSave = true;
              } else if (action.file.current === 'appClass') {
                let newThumbs = [...state.appClass.classes[state.appClass.currentClassIndex].images];
                newThumbs = newThumbs.concat(action.file.imagesToUpload);
                draft.appClass.classes[state.appClass.currentClassIndex].images = newThumbs;
                draft.appClass.pendingSave = true;
              }
            }
          }
          if (action.file.newCamera?.data?.type === 'systemCam' && !!action.file.newCamera?.data?.settings) {
            draft.cameraConfig.settings = action.file.newCamera.data.settings;
          }
          if (!action.file.cameraError) {
            draft.cameraConfig.cameraAppliedSuccess++;
          } else {
            draft.cameraConfig.cameraAppliedSuccess--;
          }
          draft.cameraConfig.cameraError = action.file.cameraError;
          draft.cameraConfig.currentCamera = action.file.newCamera;
          draft.cameraConfig.loadingButtons = emptyLoading;
          draft.cameraConfig.cameraToastId = action.file.cameraToastId;
        });
      case 'CAMERACONFIG_DELETE_CAMERA':
        const cameras = [...state.cameraConfig.allCameras];
        const cameraIndex = cameras.findIndex(camera => camera.name === action.file.data.cameraName);
        if (cameraIndex !== -1) {
          cameras.splice(cameraIndex, 1);
        }
        return produce(state, draft => {
          draft.cameraConfig.allCameras = cameras;
          draft.cameraConfig.loadingButtons = emptyLoading;
          draft.cameraConfig.newCameraRef = action.file.data.newCameraRef;
          draft.cameraConfig.currentCamera = { name: '', data: { type: 'systemCam', id: null } };
          draft.cameraConfig.capabilities = null;
          draft.cameraConfig.settings = null;
          draft.cameraConfig.alreadyGotCapabilities = false;
          draft.cameraConfig.cameraDeletionControl = false;
          draft.cameraConfig.cameraToastId = action.file.cameraToastId;
        });
      case 'CAMERACONFIG_SET_LOADING_BUTTON':
        const newLoading = lodash.cloneDeep(state.cameraConfig.loadingButtons);
        newLoading[action.file.card][action.file.button] = 1;
        return produce(state, draft => {
          draft.cameraConfig.loadingButtons = newLoading;
        });
      case 'CAMERACONFIG_SET_CAPABILITIES':
        return produce(state, draft => {
          if (!!action.file.capabilities && Object.keys(action.file.capabilities).length > 0) {
            draft.cameraConfig.alreadyGotCapabilities = true;
          }
          draft.cameraConfig.capabilities = action.file.capabilities;
          draft.cameraConfig.settings = action.file.settings;
        });
      case 'CAMERACONFIG_SET_SETTINGS':
        return produce(state, draft => {
          draft.cameraConfig.settings = action.file;
        });
      case 'CAMERACONFIG_RESET_CAMERA_CONFIG':
        return produce(state, draft => {
          draft.cameraConfig.currentCamera = null;
          draft.cameraConfig.newCameraRef = null;
          draft.cameraConfig.pendingSave = false;
          draft.cameraConfig.cameraError = false;
          draft.cameraConfig.loadingButtons = [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
          ];
          draft.cameraConfig.capabilities = null;
          draft.cameraConfig.settings = null;
          draft.cameraConfig.alreadyGotCapabilities = false;
          draft.cameraConfig.cameraCardSaveDisabled = [true, true, true, true];
        });
      case 'CAMERACONFIG_DISMISS_OLD_CAM':
        if (!!state.cameraConfig.cameraRef) {
          state.cameraConfig.newCameraRef.dismissCam();
        }
        return produce(state, draft => {
          draft.cameraConfig.newCameraRef = null;
        });

      case 'CAMERACONFIG_CAMERA_DELETION_CONTROL':
        return produce(state, draft => {
          draft.cameraConfig.cameraDeletionControl = action.file;
        });
      case 'CAMERACONFIG_SAVE_TEMPORARY_FILES':
        return produce(state, draft => {
          if (action.file.type === 'image') {
            draft.cameraConfig.temporaryInputImages = action.file.files;
          } else if (action.file.type === 'video') {
            draft.cameraConfig.temporaryVideoInput = action.file.files;
          }
        });
      case 'CAMERACONFIG_SET_CARD_SAVE_DISABLED':
        const { position, value } = action.file;
        const newDisabledArray = [...state.cameraConfig.cameraCardSaveDisabled];
        newDisabledArray[position] = value;
        return produce(state, draft => {
          draft.cameraConfig.cameraCardSaveDisabled = newDisabledArray;
        });
      case 'CAMERACONFIG_SET_ALL_CARD_SAVE_DISABLED':
        return produce(state, draft => {
          draft.cameraConfig.cameraCardSaveDisabled = action.file;
        });
      default:
        return state;
    }
  }
}
