import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Card, DropdownMenu, MoreVertSVGIcon, CardContent } from 'react-md';
import CameraDialog from '../../cameraDialog/cameraDialog';
import PreProcessingDialog from '../../preProcessing/preProcessingDialog';
import PostProcessingDialog from '../../postProcessing/postProcessingDialog';
import OutputDialog from '../../output/outputDialog';
import SaveDialog from './save';
import * as mainActions from '../../../actions/main';
import * as appBarActions from '../../../actions/appBar';
import { captureImage } from '../../../camera';
import EPIEngine from './EPIengine';
import * as preProcessingActions from '../../../actions/preProcessing';
import * as postProcessingActions from '../../../actions/postProcessing';
import * as outputActions from '../../../actions/output';
import { MobileOnly, DesktopOnly } from '@react-md/utils';
import { changeCamera, dismissOldCam, resetCameraConfig } from '../../../actions/cameraConfig';

class AppEPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraModalEnabled: false,
      preProcessingModalEnabled: false,
      postProcessingModalEnabled: false,
      outputModalEnabled: false,
      saveModalEnabled: false,
      activeCircle: 'StateZero',
      saveImage: null,
    };
  }

  saveImage = () => {
    let img = this.props.cameraRef?.captureStreamImg();
    let b64img = captureImage(img);
    this.setState({ saveImage: b64img, saveModalEnabled: true });
    img.delete();
  };

  applyVideoSettings(settingsToSave) {
    if (!!this.props.cameraRef && this.props.cameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.cameraRef.applySettings(settingsToSave);
    }
  }

  componentWillUnmount() {
    this.props.resetCurrentAppConfig('appEPI');
    this.props.setPreProcessingProcessor(null);
    this.props.setPostProcessingProcessor(null);
    this.props.setOutputProcessor(null);
    this.props.dismissOldCam();
    this.props.resetCameraConfig();
  }

  render() {
    const dropItems = !this.props.cameraRef
      ? [{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]
      : [
          { children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) },
          { children: 'Pré-processamento', onClick: () => this.setState({ preProcessingModalEnabled: true }) },
          { children: 'Condições de Detecção', onClick: () => this.setState({ postProcessingModalEnabled: true }) },
          { children: 'Configurações de saída', onClick: () => this.setState({ outputModalEnabled: true }) },
          { children: 'Classificação Errada?', onClick: this.saveImage },
        ];

    return (
      <>
        {this.state.cameraModalEnabled ? <CameraDialog enabled={this.state.cameraModalEnabled} close={() => this.setState({ cameraModalEnabled: false })} /> : null}
        <SaveDialog enabled={this.state.saveModalEnabled} img={this.state.saveImage} close={() => this.setState({ saveModalEnabled: false })} />
        <PreProcessingDialog enabled={this.state.preProcessingModalEnabled} close={() => this.setState({ preProcessingModalEnabled: false })} />
        <PostProcessingDialog
          // gridMask: {label: 'Máscara', type: 'gridArray'},
          // hasFace: {label: 'Tem rosto', type: 'bool'},
          // hasEPI: {label: 'Tem epi', type: 'bool'},
          // hasLALA: {label: 'Tem LALA', type: 'bool'},
          // classIndex: {label: 'Número da Classe', type: 'integer', max: 5, min: 1}
          // classIndex: {label: 'Número da Classe', type: 'integer'} => min value will be 0 and max value will be unlimited

          activeCircle={this.state.activeCircle}
          varOptions={{ hasFace: { label: 'Tem rosto', type: 'bool' }, hasEPI: { label: 'Tem EPI', type: 'bool' } }}
          enabled={this.state.postProcessingModalEnabled}
          close={() => this.setState({ postProcessingModalEnabled: false })}
        />
        <OutputDialog enabled={this.state.outputModalEnabled} close={() => this.setState({ outputModalEnabled: false })} />
        <div className='background flex flex-between'>
          <div className='flex h-100 flex-column' style={{ width: 'calc(100% - 8px)' }}>
            <Card className='full-size flex flex-wrap flex-column'>
              <div className='title-card-text w-100 flex flex-between'>
                <MobileOnly>
                  Inferência
                  <DropdownMenu
                    id='camOptions'
                    buttonType='icon'
                    aria-label='camOptions'
                    items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
                  >
                    <MoreVertSVGIcon />
                  </DropdownMenu>
                </MobileOnly>
                <DesktopOnly>
                  Exibição
                  <DropdownMenu id='camOptions' buttonType='icon' aria-label='camOptions' items={dropItems}>
                    <MoreVertSVGIcon />
                  </DropdownMenu>
                </DesktopOnly>
              </div>
              <CardContent className='flex w-100 grown' style={{ height: 'calc(100% - 70px)', alignItems: 'center' }}>
                <div className='w-100 h-100 flex flex-center' style={{ background: 'black' }}>
                  <div className='flex w-100 h-100' style={{ backgroundColor: 'black' }}>
                    <EPIEngine
                      cameraRef={this.props.cameraRef}
                      preProcessingProcessor={this.props.preProcessingProcessor}
                      postProcessingProcessor={this.props.postProcessingProcessor}
                      outputProcessor={this.props.outputProcessor}
                      activeCircle={this.state.activeCircle}
                      setActiveCircle={newActiveCircle => this.setState({ activeCircle: newActiveCircle })}
                      setCamera={camera => this.props.changeCamera(camera)}
                      settings={this.props.settings}
                      applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  cameraRef: state.cameraConfig.newCameraRef,
  preProcessingProcessor: state.cameraConfig.preProcessingProcessor,
  postProcessingProcessor: state.cameraConfig.postProcessingProcessor,
  outputProcessor: state.cameraConfig.outputProcessor,
  settings: state.cameraConfig.settings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...mainActions,
      ...appBarActions,
      ...preProcessingActions,
      ...postProcessingActions,
      ...outputActions,
      dismissOldCam,
      resetCameraConfig,
      changeCamera,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppEPI);
