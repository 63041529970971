import React from 'react';
import { Schedule } from '../../../schedule';
import { toast } from 'react-toastify';
import * as faceapi from 'face-api.js';
import * as lodash from 'lodash';
import * as tf from '@tensorflow/tfjs';
import { updateCanvasDimension } from '../../../camera';

export default class FaceEngine extends React.Component {
  cameraSchedule = null;
  capture = false;
  model = null;
  claheToast = null;
  // showRect = false;
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.canvas = document.createElement('canvas');
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
    };
    this.initDefaultCam();
  }
  initDefaultCam() {
    this.props.setCamera({ name: '///', data: { type: 'systemCam', id: null, interval: null } });
  }

  proc = async () => {
    let out = this.props.cameraRef?.captureStreamImg();
    if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }

    if (this.props.preProcessingProcessor) {
      out = this.props.preProcessingProcessor.process(out);
      if (this.props.preProcessingProcessor.data.roi && (this.state.cols !== out.cols || this.state.rows !== out.rows)) {
        this.setState({ cols: out.cols, rows: out.rows });
      }
    }

    if (!lodash.has(this, 'fdopts') || !lodash.has(this, 'model')) {
      window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
      window.cv.imshow(this.canvasRef.current, out);
      out.delete();
      return;
    }

    window.cv.imshow(this.canvas, out);
    let detection = await faceapi.detectSingleFace(this.canvas, this.fdopts);

    if (detection != null && !this.props.preProcessingProcessor?.data.clahe) {
      this.props.faceDetected(true);
      let point1 = new window.cv.Point(detection.box.x, detection.box.y);
      let point2 = new window.cv.Point(detection.box.x + detection.box.width, detection.box.y + detection.box.height);
      let roi_rect = new window.cv.Rect(Math.floor(detection.box.x), Math.floor(detection.box.y), Math.floor(detection.box.width), Math.floor(detection.box.height));
      let roi = out.roi(roi_rect);
      window.cv.resize(roi, roi, new window.cv.Size(160, 160), 0, 0, window.cv.INTER_AREA);
      window.cv.rectangle(out, point1, point2, [255, 0, 0, 255], 2);
      const featureArray = await this.getFeaturesArrayFromModel(roi);
      if (this.props.testing) {
        let closest = 10;
        let closestName = 'Sem resultados compatíveis.';
        this.props.people.forEach(person => {
          let closestFaceSinglePerson = person.matFaces.reduce((old, current) =>
            this.euclideanDistance(featureArray, current) < this.euclideanDistance(featureArray, old) ? current : old
          );
          const closestDistance = this.euclideanDistance(featureArray, closestFaceSinglePerson);
          if (closestDistance < closest && closestDistance <= this.props.threshold) {
            closest = closestDistance;
            closestName = person.name;
          }
        });
        this.props.updateFoundFace(closestName);
        // console.log('Name, distance:');
        // console.log(closestName, closest);
      } else if (this.capture) {
        if (!!this.model) {
          this.props.saveFace(this.props.personName, featureArray, this.props.cameraRef?.capture(roi));
          this.capture = false;
        } else {
          this.capture = false;
          this.props.resetPicTrigger();
          toast.error('Erro de carregamento do modelo.');
        }
      }
      roi.delete();
    } else if (this.props.preProcessingProcessor?.data.clahe) {
      const msg = 'É necessário desabilitar o Clahe para realizar a detecção de rostos.';
      toast.isActive(this.claheToast) ? toast.update(this.claheToast, { render: msg }) : (this.claheToast = toast.error(msg));
      this.props.faceDetected(false);
    } else {
      this.props.faceDetected(false);
    }

    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
    window.cv.imshow(this.canvasRef.current, out);
    out.delete();

    if (!!this.allowApplySettings) {
      this.props.applyVideoSettings(this.settingsToSave);
      this.allowApplySettings = false;
      this.settingsToSave = {};
    }
  };

  async componentDidMount() {
    const toastId = toast.info('Carregando a aplicação.');
    await faceapi.nets.ssdMobilenetv1.loadFromUri(`${process.env.PUBLIC_URL}/facedetection`).catch(err => console.log('KKKKKKKKKK' + err));
    this.model = await tf.loadLayersModel(tf.io.browserHTTPRequest(`${process.env.PUBLIC_URL}/facenet/model.json`));
    this.fdopts = new faceapi.SsdMobilenetv1Options();
    toast.isActive(toastId) ? toast.update(toastId, { render: 'Aplicação carregada.', autoClose: 3000 }) : (this.toastId = toast.success('Aplicação carregada.'));
  }

  async getFeaturesArrayFromModel(mat) {
    if (mat.type() === window.cv.CV_8UC4) window.cv.cvtColor(mat, mat, window.cv.COLOR_BGRA2BGR);
    let data = new Float32Array(mat.data);
    let output = tf.tidy(() => {
      let tin = tf.tensor(data, [mat.rows, mat.cols, 3], 'float32');
      let moments = tf.moments(tin);
      tin = tin.expandDims(0);
      let std = moments.variance.sqrt();
      let std_adj = tf.maximum(std, tf.scalar(1.0 / Math.sqrt(160 * 160 * 3)));
      tin = tf.div(tf.sub(tin, moments.mean), std_adj);
      let tout = this.model.predict(tin).dataSync();
      let norm = tf.sqrt(tf.sum(tf.square(tout)));
      return tf.div(tout, norm);
    });
    let outputdata = await output.data();
    output.dispose();
    return outputdata;
  }

  euclideanDistance(a, b) {
    return (
      a
        .map((el, index) => Math.abs(el - b[index]) ** 2) // square the difference
        .reduce((sum, now) => sum + now) ** // sum
      (1 / 2)
    );
  }

  setCanvasDimension(preProcessWidth, preProcessHeight) {
    const newDimensions = updateCanvasDimension(this.props.cameraRef, this.state.canvasWidth, this.state.canvasHeight, preProcessWidth, preProcessHeight);
    this.setState(newDimensions);
  }

  setStream(func, interval) {
    if (!!this.props.cameraRef) {
      if (this.cameraSchedule) {
        this.cameraSchedule.running = false;
      }
      this.cameraSchedule = new Schedule(func, interval);
    } else {
      if (this.cameraSchedule) {
        this.cameraSchedule.running = false;
      }
    }
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const personIndex = nextProps.people.findIndex(person => person.name === this.props.personName);

    if (!!nextProps.settings && JSON.stringify(nextProps.settings) !== JSON.stringify(this.props.settings) && !this.allowApplySettings) {
      this.allowApplySettings = true;
      this.settingsToSave = { ...nextProps.settings };
    }

    if (this.props.picTrigger !== nextProps.picTrigger && nextProps.picTrigger) {
      this.capture = true;
      return false;
    }
    if (nextProps.people[personIndex]?.matFaces.length > this.props.people[personIndex]?.matFaces.length || (!this.props.people[personIndex] && !!nextProps.people[personIndex])) {
      this.props.resetPicTrigger();
      return false;
    }

    if (this.state.cols !== nextState.cols || this.state.rows !== nextState.rows) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (!!this.props.preProcessingProcessor?.data.roi && !nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension();
      return true;
    }
    if (!this.props.preProcessingProcessor?.data.roi && !!nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cameraRef !== this.props.cameraRef) {
      this.setStream(this.proc, this.props.cameraRef?.getInterval());
    }
  }

  render() {
    return <canvas className='w-100' style={{ height: 'auto', objectFit: 'contain' }} ref={this.canvasRef} width={this.state.canvasWidth} height={this.state.canvasHeight} />;
  }
}
