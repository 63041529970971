import React from 'react';
import { Card } from '@react-md/card';
import '../preProcessingDialog.scss';
import { Button } from 'react-md';

export default class RoiAxis extends React.Component {
  removeTool() {
    this.props.setDialogState(null);
  }
  sendDataToDialog() {
    this.props.setDialogState(this.props.roiCoordinates.find(el => el !== null) === undefined ? null : [...this.props.roiCoordinates]);
  }
  render() {
    return (
      <>
        <Card
          style={{
            background: '#ececec',
            display: 'flex',
            flexWrap: 'wrap',
            margin: '0 auto',
            width: '250px',
            height: '190px',
          }}
        >
          <div className='roi-grid'>
            <div id='g1' className='flex-center' style={{ position: 'relative' }}>
              <div
                style={{
                  width: '0',
                  height: '0',
                  position: 'absolute',
                  top: '20%',
                  right: '0px',
                  transform: 'translate(9px, 0px)',
                  borderRight: '8px solid transparent',
                  borderLeft: '8px solid transparent',
                  borderBottom: '16px solid blue',
                }}
              />
              <div
                style={{
                  width: '0',
                  height: '0',
                  position: 'absolute',
                  left: '20%',
                  bottom: '0px',
                  transform: 'translate(0px, 9px)',
                  borderRight: '16px solid green',
                  borderTop: '8px solid transparent',
                  borderBottom: '8px solid transparent',
                }}
              />
            </div>

            <div
              id='g2'
              style={{
                color: 'blue',
                position: 'relative',
                padding: '20px 0 0 14px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {this.props.roiCoordinates[1]}
              <div
                className='w-100'
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  height: '60%',
                  borderLeft: '3px solid blue',
                }}
              />
            </div>

            <div id='g3' className='flex-center' />

            <div
              id='g4'
              style={{
                color: 'green',
                position: 'relative',
                padding: '12px 0 0 30%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              {this.props.roiCoordinates[0]}
              <div
                style={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  width: '60%',
                  borderTop: '3px solid green',
                }}
              />
            </div>

            <div id='g5' className='flex flex-center full-size' style={{ border: '3px dashed red', margin: 'auto' }}>
              {'Ratio'}
            </div>

            <div
              id='g6'
              style={{
                color: 'green',
                position: 'relative',
                padding: '0 30% 12px 0',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '0',
                  width: '60%',
                  borderBottom: '3px solid green',
                }}
              />
              {this.props.roiCoordinates[2]}
            </div>

            <div id='g7' className='flex-center' />

            <div
              id='g8'
              style={{
                color: 'blue',
                position: 'relative',
                padding: '0 14px 20px 0',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div
                className='w-100'
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  height: '60%',
                  borderRight: '3px solid blue',
                }}
              />
              {this.props.roiCoordinates[3]}
            </div>

            <div id='g9' className='flex-center' style={{ position: 'relative' }}>
              <div
                style={{
                  width: '0',
                  height: '0',
                  position: 'absolute',
                  bottom: '20%',
                  left: '0px',
                  transform: 'translate(-9px, 0px)',
                  borderLeft: '8px solid transparent',
                  borderRight: '8px solid transparent',
                  borderTop: '16px solid blue',
                }}
              />
              <div
                style={{
                  width: '0',
                  height: '0',
                  position: 'absolute',
                  right: '20%',
                  top: '0px',
                  transform: 'translate(0px,-9px)',
                  borderLeft: '16px solid green',
                  borderTop: '8px solid transparent',
                  borderBottom: '8px solid transparent',
                }}
              />
            </div>
          </div>
        </Card>
        <div className='flex flex-between w-100'>
          <Button style={{ width: '49%' }} theme='error' themeType='contained' disabled={this.props.roi === null} onClick={() => this.removeTool()}>
            Remover
          </Button>
          <Button
            style={{ width: '49%' }}
            theme='secondary'
            themeType='contained'
            onClick={() => this.sendDataToDialog()}
            disabled={this.props.roiCoordinates.find(el => el === null) === null}
          >
            Aplicar
          </Button>
        </div>
      </>
    );
  }
}
