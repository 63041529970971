import React from 'react';
import { Button, Card, DropdownMenu, MoreVertSVGIcon, CardContent } from 'react-md';
import CameraDialog from '../../../../cameraDialog/cameraDialog';
import FaceEngine from '../../faceEngine';
import InputRange from 'react-input-range';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './faceSection.scss';
import FaceAutocomplete from './faceAutocomplete';
import { bindActionCreators } from 'redux';
import * as appFaceActions from '../../../../../actions/appFace';
import * as appBarActions from '../../../../../actions/appBar';
import PreProcessingDialog from '../../../../preProcessing/preProcessingDialog';
import { MobileOnly, DesktopOnly } from '@react-md/utils';
import { changeCamera } from '../../../../../actions/cameraConfig';
import GridEngine from '../../../appGrid/gridEngine';

class FaceSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cameraModalEnabled: false,
      threshold: 1.0,
      testing: false,
      picTrigger: false,
      faceDetected: false,
      personName: '',
      foundFace: '',
      preProcessingModalEnabled: false,
    };
  }
  applyVideoSettings(settingsToSave) {
    if (!!this.props.cameraRef && this.props.cameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.cameraRef.applySettings(settingsToSave);
    }
  }

  render() {
    const dropItems = !this.props.cameraRef
      ? [{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]
      : [
          { children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) },
          { children: 'Pré processamento', onClick: () => this.setState({ preProcessingModalEnabled: true }) },
        ];
    return (
      <>
        {this.state.cameraModalEnabled ? (
          <CameraDialog saveThumbsFromImageCam={false} enabled={this.state.cameraModalEnabled} close={() => this.setState({ cameraModalEnabled: false })} />
        ) : null}
        <PreProcessingDialog enabled={this.state.preProcessingModalEnabled} close={() => this.setState({ preProcessingModalEnabled: false })} />
        <Card className='full-size flex flex-wrap flex-column' style={{ alignContent: 'flex-start' }}>
          <div className='title-card-text w-100 flex flex-between'>
            <MobileOnly>
              Inferência
              <DropdownMenu
                id='camOptions'
                buttonType='icon'
                aria-label='camOptions'
                items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
              >
                <MoreVertSVGIcon />
              </DropdownMenu>
            </MobileOnly>
            <DesktopOnly>
              Exibição
              <DropdownMenu id='camOptions' buttonType='icon' aria-label='camOptions' items={dropItems}>
                <MoreVertSVGIcon />
              </DropdownMenu>
            </DesktopOnly>
          </div>
          <CardContent className='flex flex-column w-100 scroll fill-when-small' style={{ height: 'calc(100% - 70px)', alignItems: 'center', justifyContent: 'space-between' }}>
            <div className='flex w-100 grown' style={{ height: 'calc(100% - 240px)', backgroundColor: 'black' }}>
              <FaceEngine
                resetPicTrigger={() => this.setState({ picTrigger: false })}
                faceDetected={detected => this.setState({ faceDetected: detected })}
                testingModel={this.state.testing}
                picTrigger={this.state.picTrigger}
                personName={this.state.personName}
                testing={this.state.testing}
                threshold={this.state.threshold}
                updateFoundFace={personName => this.setState({ foundFace: personName })}
                cameraRef={this.props.cameraRef}
                matFaces={this.props.matFaces}
                people={this.props.people}
                saveFace={(personName, featureArray, roi) => this.props.saveFace(personName, featureArray, roi)}
                preProcessingProcessor={this.props.preProcessingProcessor}
                setCamera={camera => this.props.changeCamera(camera)}
                settings={this.props.settings}
                applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
              />
            </div>
            <div className='flex flex-center flex-wrap w-100'>
              {!!this.state.testing ? (
                <>
                  <div className='w-100 title-card-text flex flex-center'>Reconhecimento</div>
                  <div style={{ width: '90%' }}>
                    <InputRange
                      labelContainer='lalala'
                      name='Threshold'
                      formatLabel={value => `${value}`}
                      step={0.01}
                      maxValue={2.0}
                      minValue={0.0}
                      value={Number(this.state.threshold)}
                      onChange={value => this.setState({ threshold: Number(value).toFixed(2) })}
                    />
                  </div>
                </>
              ) : null}
              <div className='w-100 flex flex-center' style={{ margin: '40px 0px 16px' }}>
                {!this.state.testing ? (
                  <FaceAutocomplete setPersonName={name => this.setState({ personName: name })} />
                ) : (
                  <div className='w-100 text-center' style={{ color: 'dimgray', fontWeight: '600', borderBottom: '3px solid #1a9c90', padding: '10px', borderRadius: '0.25rem' }}>
                    {this.state.foundFace}
                  </div>
                )}
              </div>
              <div className='w-100 flex flex-between flex-wrap'>
                <Button
                  disabled={this.state.testing || this.state.picTrigger || !this.state.faceDetected}
                  className='upper-button hide-when-small'
                  style={{ height: '44px' }}
                  theme='secondary'
                  themeType='contained'
                  onClick={() => {
                    this.state.personName.trim().length > 0 ? this.setState({ picTrigger: true }) : toast.error('Digite um nome para poder efetuar o registro.');
                  }}
                >
                  registrar face
                </Button>
                <Button
                  className='upper-button fill-when-small'
                  style={{ height: '44px' }}
                  theme='primary'
                  themeType='contained'
                  onClick={() => this.setState({ testing: !this.state.testing })}
                >
                  {this.state.testing ? 'interromper' : 'rodar modelo'}
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  cameraRef: state.cameraConfig.newCameraRef,
  matFaces: state.appFace.matFaces,
  people: state.appFace.people,
  preProcessingProcessor: state.cameraConfig.preProcessingProcessor,
  settings: state.cameraConfig.settings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...appFaceActions,
      ...appBarActions,
      changeCamera,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FaceSection);
