import React from 'react';
import './appQuality.scss';
import QualityPreview from './sections/qualityPreview';
import QualityOptions from './sections/qualityOptions';
import { bindActionCreators } from 'redux';
import { resetCurrentAppConfig } from '../../../actions/appBar';
import { setPreProcessingProcessor } from '../../../actions/preProcessing';
import { setPostProcessingProcessor } from '../../../actions/postProcessing';
import { setOutputProcessor } from '../../../actions/output';
import { dismissOldCam, resetCameraConfig } from '../../../actions/cameraConfig';
import { connect } from 'react-redux';

class AppQuality extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
    };
  }
  componentWillUnmount() {
    this.props.resetCurrentAppConfig('appQuality');
    this.props.setPreProcessingProcessor(null);
    this.props.setPostProcessingProcessor(null);
    this.props.setOutputProcessor(null);
    this.props.dismissOldCam();
    this.props.resetCameraConfig();
  }

  render() {
    return (
      <div className='full-size flex flex-wrap'>
        <QualityPreview currentTab={this.state.currentTab} />
        <QualityOptions indexChanged={tab => this.setState({ currentTab: tab })} />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetCurrentAppConfig,
      setPreProcessingProcessor,
      setPostProcessingProcessor,
      setOutputProcessor,
      dismissOldCam,
      resetCameraConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppQuality);
