import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, Select } from 'react-md';
import React from 'react';
import './preProcessingDialog.scss';
import Roi from './options/roi';
import RoiAxis from './options/roiAxis';
import Clahe from './options/clahe';
import Blur from './options/blur';
import PreProcessingEngine from './preProcessingEngine';
import { bindActionCreators } from 'redux';
import * as preProcessingActions from '../../actions/preProcessing';
import { connect } from 'react-redux';
import { PreProcessingProcessor } from './preProcessingProcessor';

class PreProcessingDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: ['Recorte', 'Borramento', 'Equalização'],
      option: 'Recorte',
      roiCoordinates: [null, null, null, null],
      // content from tools to save at store
      roi: null,
      clahe: null,
      blur: null,
      preProcessingProcessorInstance: null,
    };
  }
  async applyChangedLocally(obj) {
    const preProcessingData = { roi: this.state.roi, clahe: this.state.clahe, blur: this.state.blur, ...obj };
    const preProcessingProcessorInstance = new PreProcessingProcessor(preProcessingData);
    this.setState({ ...obj, preProcessingProcessorInstance: preProcessingProcessorInstance }, () => {
      this.saveChanges();
    });
  }
  discardChanges() {
    this.setState({ roi: null, clahe: null, blur: null, option: 'Recorte', preProcessingProcessorInstance: null });
    this.props.setPreProcessingProcessor(null);
    this.props.close();
  }
  saveChanges() {
    // this.setState({ option: 'Recorte' });
    this.props.setPreProcessingProcessor(this.state.preProcessingProcessorInstance);
    // this.props.close();
  }
  close() {
    this.setState({ option: 'Recorte' });
    this.props.close();
  }
  applyVideoSettings(settingsToSave) {
    if (!!this.props.cameraRef && this.props.cameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.cameraRef.applySettings(settingsToSave);
    }
  }

  render() {
    let content;
    switch (this.state.option) {
      case 'Recorte':
        content = <RoiAxis roi={this.state.roi} roiCoordinates={this.state.roiCoordinates} setDialogState={val => this.applyChangedLocally({ roi: val })} />;
        break;
      case 'Equalização':
        content = <Clahe clahe={this.state.clahe} setDialogState={val => this.applyChangedLocally({ clahe: val })} />;
        break;
      case 'Borramento':
        content = <Blur blur={this.state.blur} setDialogState={val => this.applyChangedLocally({ blur: val })} />;
        break;
      default:
        content = null;
    }
    return (
      <>
        <Dialog
          id='simple-dialog-preprocessing'
          visible={this.props.enabled}
          onRequestClose={() => {
            this.setState({ option: 'Recorte' });
            this.props.close();
          }}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader className='flex flex-center'>
            <DialogTitle className='w-100 flex flex-center'>Configurações de Pré Processamento</DialogTitle>
          </DialogHeader>
          <DialogContent className='flex' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='pre-processing-options flex flex-wrap' style={{ width: '250px', height: '300px', margin: '0 10px', alignContent: 'space-between' }}>
              <Select
                className='w-100'
                theme='outline'
                id='preProcessingOptions'
                options={this.state.options}
                value={this.state.option}
                name='Pré Processamento'
                label='Ferramentas'
                onChange={val => this.setState({ option: val })}
                disableMovementChange={true}
              />
              {content}
            </div>
            <div className='pre-processing-preview' style={{ width: 'auto', height: '300px', margin: 'auto', position: 'relative' }}>
              <PreProcessingEngine
                divStyles={{ height: '100%', width: 'auto' }}
                cameraRef={this.props.cameraRef}
                canvasStyles={{ height: '100%', width: 'auto' }}
                processor={this.state.preProcessingProcessorInstance}
                settings={this.props.settings}
                applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
              />
              {this.state.option === 'Recorte' ? <Roi coord={value => this.setState({ roiCoordinates: value })} /> : null}
            </div>
          </DialogContent>
          <DialogFooter>
            <div className='w-100 flex' style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <Button className='w-100' theme='primary' themeType='contained' onClick={() => this.close()}>
                  fechar
                </Button>
              </div>
            </div>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  current: state.main.current,
  cameraRef: state.cameraConfig.newCameraRef,
  preProcessingProcessor: state.cameraConfig.preProcessingProcessor,
  settings: state.cameraConfig.preProcessingProcessor,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...preProcessingActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreProcessingDialog);
