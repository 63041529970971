import React from 'react';
import { Card } from 'react-md';
import { CardContent } from '@react-md/card';
import { bindActionCreators } from 'redux';
import './sampleSection.scss';
import { connect } from 'react-redux';
import SampleSectionThumbs from './sampleSectionThumbs';

class SampleSection extends React.Component {
  render() {
    return (
      <Card
        className={`flex curve-border-2 text-center flex-column h-100 appClass-sample-section hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}
        style={{ overflow: 'hidden', padding: '0 0 8px 0' }}
      >
        <div className='title-card-text w-100 flex flex-between'>
          <div className='text-ellipsis'>{this.props.classes[this.props.currentClassIndex].name}</div>
        </div>
        <CardContent
          className='flex flex-wrap flex-center'
          style={{
            height: 'calc(100% - 70px)',
            overflow: 'auto',
            alignContent: 'flex-start',
            padding: '12px',
          }}
        >
          {this.props.classes[this.props.currentClassIndex].images.map((element, index) => {
            return (
              <div key={index} className='flex flex-center-top flex-wrap' style={{ width: '100px', height: '100px', margin: '6px' }}>
                <SampleSectionThumbs thumb={element} index={index} />
              </div>
            );
          })}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  classes: state.appClass.classes,
  currentClassIndex: state.appClass.currentClassIndex,
  applicationIsOnline: state.main.applicationIsOnline,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SampleSection);
