import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, TextField, Checkbox } from 'react-md';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { setGridParams } from '../../../actions/appGrid';
import { setClassParams } from '../../../actions/appClass';

let defaultState = {
  lr: 1e-4,
  freeze_percent: 0.73,
  batch_norm: false,
  batch_size: 4,
  numEpochs: 15,
  outOfBoundsParams: {
    lr: false,
    freeze_percent: false,
    batch_size: false,
    numEpochs: false,
  },
};

class TrainingParameters extends React.Component {
  outOfBoundInputtedValueToastId = -1;

  constructor(props) {
    super(props);
    this.state = defaultState;
    this.cancel = this.cancel.bind(this);
    this.clearErrorInput = this.clearErrorInput.bind(this);
  }

  clearErrorInput() {
    this.setState({
      outOfBoundsParams: {
        lr: false,
        freeze_percent: false,
        batch_size: false,
        numEpochs: false,
      },
    });
  }

  cancel() {
    this.props.close();
  }

  modelParamsOutOfRange() {
    let error = false;
    const outOfBoundsParams = { ...this.state.outOfBoundsParams };
    const { lr, freeze_percent, batch_size, numEpochs } = this.state;

    if (!(numEpochs >= 1 && numEpochs <= 30)) {
      error = true;
      outOfBoundsParams.numEpochs = true;
      const msg = '"Number of Epochs" deve ter valor entre 1 e 30';
      toast.isActive(this.outOfBoundInputtedValueToastId)
        ? toast.update(this.outOfBoundInputtedValueToastId, { render: msg })
        : (this.outOfBoundInputtedValueToastId = toast.error(msg));
    } else if (!(lr >= 1e-6 && lr <= 1)) {
      error = true;
      outOfBoundsParams.lr = true;
      const msg = '"Learning rate" deve ter valor entre 0.000001 e 1';
      toast.isActive(this.outOfBoundInputtedValueToastId)
        ? toast.update(this.outOfBoundInputtedValueToastId, { render: msg })
        : (this.outOfBoundInputtedValueToastId = toast.error(msg));
    } else if (!(freeze_percent >= 0 && freeze_percent <= 1)) {
      error = true;
      outOfBoundsParams.freeze_percent = true;
      const msg = '"Freeze percent" deve ter valor entre 0 e 1';
      toast.isActive(this.outOfBoundInputtedValueToastId)
        ? toast.update(this.outOfBoundInputtedValueToastId, { render: msg })
        : (this.outOfBoundInputtedValueToastId = toast.error(msg));
    } else if (!(batch_size >= 4 && batch_size <= 16)) {
      error = true;
      outOfBoundsParams.batch_size = true;
      const msg = '"Batch size" deve ter valor entre 4 e 16';
      toast.isActive(this.outOfBoundInputtedValueToastId)
        ? toast.update(this.outOfBoundInputtedValueToastId, { render: msg })
        : (this.outOfBoundInputtedValueToastId = toast.error(msg));
    }
    this.setState({ outOfBoundsParams });
    return error;
  }

  saveParams() {
    if (this.modelParamsOutOfRange()) {
      return;
    }
    if (this.props.appType === 'appGrid') {
      this.props.setGridParams(this.state.lr, this.state.freeze_percent, this.state.batch_norm, this.state.batch_size, this.state.numEpochs);
    } else if (this.props.appType === 'appClass') {
      this.props.setClassParams(this.state.lr, this.state.freeze_percent, this.state.batch_norm, this.state.batch_size, this.state.numEpochs);
    }
    this.props.close();
    toast.success('Parâmetros salvos com sucesso.');
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.enabled !== true && this.props.enabled === true) {
      this.setState({
        numEpochs: this.props.numEpochs ?? 15,
        lr: this.props.modelParams.lr ?? 1e-4,
        freeze_percent: this.props.modelParams.freeze_percent ?? 0.73,
        batch_norm: this.props.modelParams.batch_norm ?? false,
        batch_size: this.props.datasetParams.batch_size ?? 4,
      });
    }
  }

  render() {
    return (
      <Dialog
        style={{ position: 'center', right: '0' }}
        id='trainingParameterDialog'
        visible={this.props.enabled}
        onRequestClose={() => this.props.close()}
        aria-labelledby='dialog-title'
        className='curve-border-2'
      >
        <DialogHeader className='flex flex-center'>
          <DialogTitle className='w-100 flex flex-center'>Escolha de parâmetros do modelo</DialogTitle>
        </DialogHeader>
        <DialogContent className='flex flex-column flex-wrap' style={{ justifyContent: 'space-between', alignItems: 'center', width: '688px', height: '438px' }}>
          <div className='flex flex-wrap config-box first-config-box'>
            <h3 className='flex w-100 flex-center' style={{ margin: '4px 0px 14px' }}>
              Parâmetros de modelo
            </h3>
            <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
              Number of Epochs
              <TextField
                className={`post-processing-config-height ${this.state.outOfBoundsParams.numEpochs ? 'error-input' : ''}`}
                style={{ width: '130px' }}
                id='numEpochs'
                type='number'
                min={1}
                step={1}
                max={30}
                placeholder={'15'}
                theme='outline'
                label={null}
                value={this.state.numEpochs.toString()}
                onChange={event => {
                  this.setState({ numEpochs: event.target.value });
                }}
                onFocus={this.clearErrorInput}
              />
            </div>
            <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
              Learning rate
              <TextField
                className={`post-processing-config-height ${this.state.outOfBoundsParams.lr ? 'error-input' : ''}`}
                style={{ width: '130px' }}
                id='learningRate'
                type='number'
                min={1e-6}
                step={1e-6}
                max={1.0}
                placeholder={'1e-4'}
                theme='outline'
                label={null}
                value={this.state.lr.toString()}
                onChange={event => {
                  this.setState({ lr: event.target.value });
                }}
                onFocus={this.clearErrorInput}
              />
            </div>
            <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
              Freeze percent
              <TextField
                className={`post-processing-config-height ${this.state.outOfBoundsParams.freeze_percent ? 'error-input' : ''}`}
                style={{ width: '130px' }}
                id='learningRate'
                type='number'
                min={0}
                step={0.001}
                max={1.0}
                placeholder={'0.73'}
                theme='outline'
                label={null}
                value={this.state.freeze_percent.toString()}
                onChange={event => {
                  this.setState({ freeze_percent: event.target.value });
                }}
                onFocus={this.clearErrorInput}
              />
            </div>
            <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
              Batch Normalization
              <Checkbox
                style={{ color: 'blue' }}
                theme='outline'
                id='gridCheckbox'
                className='grid-checkbox'
                checked={this.state.batch_norm}
                name='Batch Normalization'
                // label='Batch Normalization'
                onChange={val => this.setState({ batch_norm: val.target.checked })}
                disableMovementChange={true}
              />
            </div>
            <h3 className='flex w-100 flex-center' style={{ margin: '4px 0px 14px' }}>
              Parâmetros de banco de dados
            </h3>
            <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
              Batch size
              <TextField
                className={`post-processing-config-height ${this.state.outOfBoundsParams.batch_size ? 'error-input' : ''}`}
                style={{ width: '130px' }}
                id='learningRate'
                type='number'
                min={4}
                step={1}
                max={16}
                placeholder={'0'}
                theme='outline'
                label={null}
                value={this.state.batch_size.toString()}
                onChange={event => {
                  this.setState({ batch_size: event.target.value });
                }}
                onFocus={this.clearErrorInput}
              />
            </div>
          </div>
        </DialogContent>
        <DialogFooter>
          <div className='w-100 flex' style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
            <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
              <Button className='upper-button' style={{ color: 'white' }} theme='error' themeType='contained' onClick={this.cancel}>
                cancelar
              </Button>
              <Button className='upper-button' theme='primary' themeType='contained' onClick={() => this.saveParams()}>
                salvar
              </Button>
            </div>
          </div>
        </DialogFooter>
      </Dialog>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  modelParams: state[ownProps.appType].modelParams,
  datasetParams: state[ownProps.appType].datasetParams,
  numEpochs: state[ownProps.appType].numEpochs,
});

const mapDispatchToProps = dispatch => bindActionCreators({ setGridParams, setClassParams }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TrainingParameters);
