import produce from 'immer';

export default function userReducer(state, action) {
  if (action.error) {
    switch (action.type) {
      case 'USER_LOGIN':
      case 'USER_REFRESH':
        return produce(state, draft => {
          draft.user.logged = false;
          draft.user.accessToken = '';
          draft.user.refreshToken = '';
          draft.user.firstName = '';
          draft.user.isStaff = false;
          draft.user.email = '';
          draft.user.expirationDate = '';
        });
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case 'USER_LOGIN':
        return produce(state, draft => {
          draft.user.logged = true;
          draft.user.accessToken = action.file.data.access;
          draft.user.refreshToken = action.file.data.refresh;
          draft.main.menuEnabled = false;
          draft.main.interceptorInstance = action.file.data.interceptor;
          draft.user.expirationDate = new Date(action.file.data.account_expiration).toLocaleDateString('pt-br');
        });
      case 'USER_SET_ACCESS_TOKEN':
        return produce(state, draft => {
          draft.user.logged = true;
          draft.user.accessToken = action.file;
        });
      case 'USER_REFRESH':
        return produce(state, draft => {
          draft.user.logged = true;
          draft.user.accessToken = action.file.data.access;
          draft.main.interceptorInstance = action.file.data.interceptor;
        });
      case 'USER_FETCH_USER_INFO':
        return produce(state, draft => {
          draft.user.isStaff = action.file.data.is_staff;
          draft.user.email = action.file.data.email;
          draft.user.firstName = action.file.data.first_name;
          draft.user.uuid = action.file.data.uuid;
        });
      case 'USER_LOGOUT_USER':
        return produce(state, draft => {
          draft.user.logged = false;
          draft.user.accessToken = '';
          draft.user.refreshToken = '';
          draft.user.firstName = '';
          draft.user.email = '';
          draft.user.isStaff = false;
          draft.appGrid.thumbs = [];
          draft.appGrid.grids = [];
          state.main.availableApps.forEach(appName => {
            draft[appName + 'SelectApp'].current = null;
            draft[appName + 'SelectApp'].apps = [];
            draft[appName + 'SelectApp'].lastAppConfigLocallySaved = null;
          });
          draft.main.current = 'apps';
          draft.user.expirationDate = '';
        });
      default:
        return state;
    }
  }
}
