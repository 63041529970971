import React from 'react';
import './appBar.scss';
import { Button } from 'react-md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DropdownMenu } from '@react-md/menu';
import { ArrowDropDownSVGIcon } from '@react-md/material-icons';
import { CircularProgress } from '@react-md/progress';
import { handleNewAppDialog } from '../../../actions/main';
import { createAppConfig, setLoadingButton, deleteAppConfig, fetchAppConfig, changeAppConfig, appConfigDeletionControl, appConfigChangeControl } from '../../../actions/appBar';
import { changeOfflineAppConfig, fetchOfflineApps, setOfflineFromLink } from '../../../actions/localConfig';
import { toast } from 'react-toastify';

import NewAppDialog from './newAppDialog';

class AppBarButtons extends React.Component {
  appDropdown = [];
  // actions = ['novo', 'salvar', 'excluir']; => moved to render
  onClickCallBacks = [
    index => this.props.handleNewAppDialog(true),
    index => {
      this.props.setLoadingButton(index + 1, this.props.current);
      this.props.createAppConfig(null, null);
    },
    index => {
      this.props.setLoadingButton(index + 1, this.props.current);
      this.props.appConfigDeletionControl(true);
    },
  ];
  constructor(props) {
    super(props);
    this.state = {
      option: 'App Config.',
      lastAppIndex: -1,
      lastAppName: '',
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props[this.props.current] !== prevProps[this.props.current]) {
      this.generateDropdown();
    }
    // flag to detect offline fetch
    if (prevProps.offlineFromLink !== this.props.offlineFromLink && this.props.offlineFromLink) {
      const appIndex = this.props[this.props.current].apps.findIndex(element => element.name === this.offlineAppConfig);
      this.props.setOfflineFromLink(false);
      if (appIndex !== -1) {
        this.props.changeOfflineAppConfig(this.props.current, this.offlineAppConfig, appIndex);
      } else {
        toast.error('App Config. não encontrado. Verifique se ele foi salvo localmente.');
      }
    }
    if (prevProps.deletionControl !== this.props.deletionControl && !!this.props.deletionControl) {
      this.props.deleteAppConfig(null, null);
    }
    if (prevProps.changeControl !== this.props.changeControl && !!this.props.changeControl) {
      this.changeAppConfig(this.state.lastAppIndex, this.state.lastAppName);
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    this.hasOfflineApp = urlParams.get('offline');
    this.offlineAppType = urlParams.get('appType');
    this.offlineAppConfig = urlParams.get('appConfig');

    const offlineOnMount = !!(this.hasOfflineApp && this.offlineAppType && this.offlineAppConfig);
    if (offlineOnMount) {
      // this param means that after fetchOfflineApps, offlineFromLink will be turned into true, so change will be able to occur
      this.props.fetchOfflineApps(true);
    } else {
      this.props.fetchAppConfig(null);
    }
  }

  generateDropdown() {
    this.appDropdown = [];
    this.props[this.props.current].apps.forEach((app, index) => {
      this.appDropdown.push({
        children: <div className='dropdown-item'>{app.name}</div>,
        onClick: () => {
          this.setState({ lastAppIndex: index, lastAppName: app.name }, () => this.props.appConfigChangeControl(true));
        },
      });
      this.appDropdown.push('separator');
    });
    this.setState({ option: this.props[this.props.current].current?.name || 'App Config.' });
  }

  changeAppConfig(appIndex, appName) {
    this.props.setLoadingButton(0, this.props.current);
    if (this.props.applicationIsOnline) {
      this.props.changeAppConfig(null, appName, appIndex);
    } else {
      this.props.changeOfflineAppConfig(this.props.current, appName, appIndex);
    }
  }
  //<div className='flex flex-center'><div>salvar</div><div className='fitted'>*</div></div>
  render() {
    const actions = this.props.pendingSave
      ? [
          'novo',
          <div className='flex flex-center'>
            {`salvar `} <div style={{ fontSize: '22px', height: '24px' }}>*</div>
          </div>,
          'excluir',
        ]
      : ['novo', 'salvar', 'excluir'];

    return !!this.props.current && this.props.current !== 'apps' && this.props.current !== 'localConfig' ? (
      <div className='flex' style={{ padding: '0 6px' }}>
        <NewAppDialog />
        {this.props.savingApp[this.props.current][0] === 1 ? (
          <div className='out-button flex flex-center select-app' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
            <CircularProgress id={`appbarDropdown`} className='determinate-example-circular appbar-action' centered={true} style={{ borderColor: 'white', height: '85%' }} />
          </div>
        ) : (
          <DropdownMenu
            disabled={!!this.props.savingApp[this.props.current].find(el => el !== 0) || this.appDropdown.length < 1}
            style={{ fontWeight: 'bold', color: 'white', textTransform: 'unset' }}
            className={`out-button select-app flex-between ${!this.props.applicationIsOnline ? 'online-mode-dropdown' : ''}`}
            themeType='outline'
            id='dropdown-apps'
            anchor={{ x: 'inner-right', y: 'below' }}
            items={this.appDropdown}
            dropdownIcon={this.props.applicationIsOnline ? <ArrowDropDownSVGIcon /> : null}
          >
            <p className='dropdown-content'>{this.state.option}</p>
          </DropdownMenu>
        )}
        {this.props.applicationIsOnline &&
          actions.map((action, index) => {
            return (
              <div key={index}>
                {this.props.savingApp[this.props.current][index + 1] === 1 ? (
                  <div className='out-button flex flex-center' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
                    <CircularProgress
                      id={`appbarAction${index}`}
                      className='determinate-example-circular appbar-action hide-when-small'
                      centered={true}
                      style={{ borderColor: 'white', height: '85%' }}
                    />
                  </div>
                ) : (
                  <Button
                    disabled={this.props.savingApp[this.props.current][index + 1] === -1 || ((index === 1 || index === 2) && this.state.option === 'App Config.')}
                    themeType='outline'
                    className='out-button hide-when-small'
                    onClick={() => this.onClickCallBacks[index](index)}
                  >
                    {action}
                  </Button>
                )}
              </div>
            );
          })}
      </div>
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  current: state.main.current,
  savingApp: state.savingApp,

  appGrid: state.appGridSelectApp,
  appFace: state.appFaceSelectApp,
  appCode: state.appCodeSelectApp,
  appEPI: state.appEPISelectApp,
  appClass: state.appClassSelectApp,
  appQuality: state.appQualitySelectApp,

  applicationIsOnline: state.main.applicationIsOnline,
  offlineFromLink: state.main.offlineFromLink,
  pendingSave: state[state.main.current].pendingSave,
  deletionControl: state.main.deletionControl,
  changeControl: state.main.changeControl,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      handleNewAppDialog,
      createAppConfig,
      setLoadingButton,
      deleteAppConfig,
      fetchAppConfig,
      changeAppConfig,
      changeOfflineAppConfig,
      fetchOfflineApps,
      setOfflineFromLink,
      appConfigDeletionControl,
      appConfigChangeControl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppBarButtons);
