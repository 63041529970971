import React from 'react';
import { TextIconSpacing, Button } from 'react-md';
import { AccountCircleFontIcon, ExitToAppFontIcon, PersonFontIcon } from '@react-md/material-icons';

export default class MenuHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className='menu-avatar-container flex flex-center flex-wrap'>
          {this.props.user.logged ? (
            <>
              <PersonFontIcon style={{ color: 'white' }} className='user-icon w-100' />
              <>
                <div className='menu-item w-100 flex flex-center' style={{ margin: '4px 0' }}>
                  {this.props.user.firstName}
                </div>
                <Button className='menu-item' style={{ margin: '4px 0', textTransform: 'none' }} onClick={() => this.props.logoutUser()} variant='contained' color='secondary'>
                  <TextIconSpacing icon={<ExitToAppFontIcon className='default-green' />}>Sair</TextIconSpacing>
                </Button>
              </>
            </>
          ) : (
            <AccountCircleFontIcon style={{ color: 'white' }} className='user-icon w-100' />
          )}
        </div>
      </>
    );
  }
}
