import React from 'react';
import { Schedule } from '../../schedule';
import { updateCanvasDimension } from '../../camera';

export default class PreProcessingEngine extends React.Component {
  cameraSchedule = null;
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
    };
  }
  proc = () => {
    let out = this.props.cameraRef?.captureStreamImg();
    if (!!out) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }
    // Pre Processing effects
    if (!!this.props.processor) {
      out = this.props.processor.process(out);
    }
    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
    window.cv.imshow(this.canvasRef.current, out);
    out.delete();

    if (!this.allowApplySettings) {
      this.allowApplySettings = true;
      this.settingsToSave = { ...this.props.settings };
    }
  };

  setCanvasDimension() {
    const newDimensions = updateCanvasDimension(this.props.cameraRef, this.state.canvasWidth, this.state.canvasHeight);
    this.setState(newDimensions);
  }

  componentDidMount() {
    if (!!this.props.cameraRef) {
      if (this.cameraSchedule) {
        this.cameraSchedule.running = false;
      }
      this.cameraSchedule = new Schedule(this.proc, this.props.cameraRef?.getInterval());
    } else {
      if (this.cameraSchedule) {
        this.cameraSchedule.running = false;
      }
    }
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }

  render() {
    return (
      <div className='flex w-100' style={{ ...this.props.divStyles }}>
        <canvas className='w-100' style={{ height: 'auto', ...this.props.canvasStyles }} ref={this.canvasRef} width={this.state.canvasWidth} height={this.state.canvasHeight} />
      </div>
    );
  }
}
