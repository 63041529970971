import axios from 'axios';

export function startClassTrain(warmStart) {
  return async (dispatch, getState) => {
    const state = getState();
    const url = state.appGrid.url;
    const apiKey = state.appClass.apiKey;
    const totalImages = state.appClass.classes.map(element => element.images.length).reduce((accum, curr) => accum + curr);

    const totalClasses = state.appClass.classes.filter(element => element.images.length > 0);
    const currentAppName = state[`appClassSelectApp`].current?.name;
    const modelClassNames = totalClasses.map(element => element.name);

    let postProcessingData = null;
    if (state.cameraConfig.postProcessingProcessor) {
      postProcessingData = state.cameraConfig.postProcessingProcessor.serialize();
      postProcessingData.stateZero.active = true;
      postProcessingData.stateOne.active = false;
      postProcessingData.stateTwo.active = false;
      postProcessingData.stateThree.active = false;
    }

    const defaultObject = {
      camera: state.cameraConfig?.currentCamera?.name || 'default',
      apptype: 'appClass',
      preprocessing: !!state.cameraConfig.preProcessingProcessor ? { data: state.cameraConfig.preProcessingProcessor.serialize() } : { data: null },
      customdata: {
        appGrid: {
          thumbs: null,
          grids: null,
          modelUrl: '',
        },
        appFace: {
          people: null,
        },
        appCode: {
          scriptContent: null,
        },
        appClass: {
          classes: state.appClass.classes,
          modelUrl: state.appClass.modelUrl,
        },
      },
      postprocessing: { data: postProcessingData },
      output: !!state.cameraConfig.outputProcessor ? { data: state.cameraConfig.outputProcessor.serialize() } : { data: null },
    };

    try {
      // Start Train  \\
      const startTrainResponse = await axios
        .post(`${url}/api/clf/start_train`, {
          api_key: apiKey,
          total_images: totalImages,
          train_type: 'clf',
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPCLASS_START_TRAIN', file: err, error: true });
          return Promise.reject('StartTrainError');
        });
      dispatch({ type: 'APPCLASS_START_TRAIN', file: startTrainResponse.data });

      const imageRequests = [];
      //  Send Images \\
      state.appClass.classes.forEach((someClass, classIndex) => {
        someClass.images.forEach((image, imageIndex) => {
          imageRequests.push({ img: image.split(',')[1], cls_img: classIndex });
        });
      });

      for (const [index, image] of imageRequests.entries()) {
        const sendImageResponse = await axios
          .post(`${url}/api/clf/send_image`, {
            api_key: apiKey,
            train_key: startTrainResponse.data.train_key,
            img: image.img,
            cls_img: image.cls_img,
          })
          .catch(err => {
            console.warn(err);
            dispatch({ type: 'APPCLASS_SEND_IMAGE', file: err, error: true });
            return Promise.reject('SendImageError');
          });
        dispatch({ type: 'APPCLASS_SEND_IMAGE', file: sendImageResponse.data });
      }

      // Prepare \\
      const prepareResponse = await axios
        .post(`${url}/api/clf/prepare`, {
          api_key: apiKey,
          train_key: startTrainResponse.data.train_key,
          model_params: state.appClass.modelParams,
          dataset_params: state.appClass.datasetParams,
          warm_start: warmStart,
          model_url: !!warmStart ? state.appClass.modelUrl : null,
          n_classes: totalClasses.length,
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPCLASS_PREPARE', file: err, error: true });
          return Promise.reject('PrepareError');
        });
      dispatch({ type: 'APPCLASS_PREPARE', file: prepareResponse.data });

      // Epoch  \\
      var epochResponse = await axios
        .post(`${url}/api/clf/train_epoch`, {
          api_key: apiKey,
          train_key: startTrainResponse.data.train_key,
          nepochs: state.appClass.numEpochs,
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPCLASS_EPOCHS_TRAINING', file: err, error: true });
          return Promise.reject('EpochError');
        });
      dispatch({ type: 'APPCLASS_EPOCHS_TRAINING', file: epochResponse.data });

      // wait for done \\
      function modelPoll(fn, timeout = 2000, interval = 100) {
        const endTime = Number(new Date()) + timeout;
        // interval = interval || 100;

        const checkCondition = (resolve, reject) => {
          let ajax = fn();
          ajax.then(response => {
            if (response.data.model_data.model_state === 'done') {
              resolve(response.data);
            } else if (response.data.model_data.model_state === 'failed_model') {
              reject(new Error('Modelo falhou no treinamento'));
            } else if (Number(new Date()) < endTime) {
              // console.log(`Model not done, waiting ${interval}`);
              // console.log(`${response.data.model_data.epoch}`);
              // updating current epoch for state
              dispatch({ type: 'APPCLASS_MODEL_STATUS_TRAINING', file: response.data.model_data });
              setTimeout(checkCondition, interval, resolve, reject);
            } else {
              reject(new Error('Tempo limite alcançado'));
            }
          });
        };
        return new Promise(checkCondition);
      }
      const modelStatusResponse = await modelPoll(
        () => {
          return axios.post(`${url}/api/clf/model_status`, {
            api_key: apiKey,
            train_key: startTrainResponse.data.train_key,
          });
        },
        6000000,
        1000
      )
        .then(res => {
          dispatch({ type: 'APPCLASS_MODEL_STATUS_TRAINING', file: res.model_data });
          return res;
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPCLASS_MODEL_STATUS_TRAINING', file: err, error: true });
          return Promise.reject('ModelStatusError');
        });
      // Finish Train \\
      const finishTrainResponse = await axios
        .post(`${url}/api/clf/finish`, {
          api_key: apiKey,
          train_key: startTrainResponse.data.train_key,
        })
        .catch(err => {
          console.warn(err);
          dispatch({ type: 'APPCLASS_FINISH_TRAINING', file: err, error: true });
          return Promise.reject('FinishTrainingError');
        });
      dispatch({ type: 'APPCLASS_FINISH_TRAINING', file: finishTrainResponse.data });

      if (currentAppName) {
        defaultObject.customdata.appClass.modelUrl = finishTrainResponse.data.url;
        defaultObject.customdata.appClass.modelClassNames = modelClassNames;

        const customAppConfigResponse = axios.put(`${state.appGrid.url}/api/appconfig/appClass/${currentAppName}`, defaultObject).catch(err => {
          dispatch({
            type: 'APPCLASS_SAVE_MODEL_CLASS_NAMES',
            file: err,
            error: true,
          });
          return Promise.reject('CustomAppConfigSaveError');
        });
      }
      dispatch({ type: 'APPCLASS_SAVE_MODEL_CLASS_NAMES', file: modelClassNames });
    } catch (e) {
      console.warn(e);
    }
  };
}

export function captureImage(classIndex, className, newImage) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_CAPTURE_IMAGE',
      file: { classIndex, className, newImage },
    });
  };
}

export function changeClassName(classIndex, className) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_CHANGE_CLASS_NAME',
      file: { classIndex, className },
    });
  };
}

export function setCurrentClassIndex(classIndex) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_SET_CURRENT_CLASS_INDEX',
      file: { classIndex },
    });
  };
}

export function setThumbData(thumbIndex, thumbImage) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_SET_THUMB_DATA',
      file: { thumbIndex, thumbImage },
    });
  };
}

export function deleteThumb() {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_DELETE_THUMB',
      file: true,
    });
  };
}

export function changeThumbClass(thumbIndex, oldClassIndex, newClassIndex) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_CHANGE_THUMB_CLASS',
      file: { thumbIndex, oldClassIndex, newClassIndex },
    });
  };
}

export function resetData() {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_RESET_DATA',
      file: true,
    });
  };
}

export function setClassParams(lr, freeze_percent, batch_norm, batch_size, numEpochs) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPCLASS_SET_CLASS_PARAMS',
      file: {
        modelParams: { lr: lr, freeze_percent: freeze_percent, batch_norm: batch_norm ? 1 : 0 },
        datasetParams: { batch_size: batch_size },
        numEpochs: numEpochs,
      },
    });
  };
}

export function storeClassThumbs(images) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_STORE_CLASS_THUMBS',
      file: images,
    });
  };
}

export function setClassModelUrl(url) {
  return dispatch => {
    dispatch({
      type: 'APPCLASS_SET_CLASS_MODEL_URL',
      file: url,
    });
  };
}

export function resetModelParams() {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPCLASS_RESET_MODEL_PARAMS',
      file: true,
    });
  };
}

export function setClassPreTraining(param) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPCLASS_SET_CLASS_PRE_TRAINING',
      file: param,
      needsConfirmation: true,
    });
  };
}
