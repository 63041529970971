import React from 'react';
import { Schedule } from '../../../schedule';
import { cvMatToTensor } from '../../../camera';
import * as tf from '@tensorflow/tfjs';

import { updateCanvasDimension } from '../../../camera';

export default class ClassEngine extends React.Component {
  model = null;
  cameraSchedule = null;
  capturing = false;

  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
    };
    this.initDefaultCam();
  }
  initDefaultCam() {
    this.props.setCamera({ name: '///', data: { type: 'systemCam', id: null, interval: null } });
  }
  proc = async () => {
    let data = {
      classIndex: -1,
      classLabel: '',
      modelClassNames: this.props.modelClassNames,
      timestamp: { type: this.props.cameraRef?.type, value: this.props.cameraRef.getTimestamp() },
    };

    let out = this.props.cameraRef?.captureStreamImg();
    if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }

    if (this.props.preProcessingProcessor) {
      out = this.props.preProcessingProcessor.process(out);
      if (this.props.preProcessingProcessor.data.roi && (this.state.cols !== out.cols || this.state.rows !== out.rows)) {
        this.setState({ cols: out.cols, rows: out.rows });
      }
    }

    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current?.width, this.canvasRef.current?.height), 0, 0, window.cv.INTER_AREA);

    if (this.props.testing && !!this.model) {
      const input = new window.cv.Mat();
      window.cv.cvtColor(out, out, window.cv.COLOR_BGRA2BGR);
      window.cv.resize(out, input, new window.cv.Size(224, 224), 0, 0, window.cv.INTER_AREA);

      let tensor = tf.tidy(() => {
        let tensor = cvMatToTensor(input);
        const predict = this.model.predict(tf.expandDims(tensor, 0));
        tensor = tf.argMax(predict, 1);
        return tensor;
      });
      let output = await tensor.data();
      tensor.dispose();

      // getResult
      if (this.props.modelClassNames) {
        this.props.prediction(this.props.modelClassNames[output[0]]);
        data.classIndex = output[0];
        data.classLabel = this.props.modelClassNames[output[0]];
      }

      // Post Processing
      let postProcessingResponse = null;
      if (!!this.props.postProcessingProcessor) {
        // { classIndex: { label: 'Número da Classe', type: 'integer', max: 5, min: 1 } }
        postProcessingResponse = this.props.postProcessingProcessor.process(data);
      }
      // Output
      if (this.props.outputProcessor) {
        this.props.outputProcessor.process(postProcessingResponse, this.props.activeCircle, data);
      }
      // Update
      if (!!this.props.postProcessingProcessor) {
        this.props.setActiveCircle(postProcessingResponse?.activeState);
      }

      window.cv.imshow(this.canvasRef.current, out);
      input.delete();
      out.delete();
    } else {
      if (this.capturing) {
        const base64Data = this.props.cameraRef?.capture(out);
        this.props.sendImage(base64Data);
        this.capturing = false;
        this.props.disablePicTrigger();
      }
      window.cv.imshow(this.canvasRef.current, out);
      out.delete();
    }

    if (!!this.allowApplySettings) {
      this.props.applyVideoSettings(this.settingsToSave);
      this.allowApplySettings = false;
      this.settingsToSave = {};
    }
  };

  setCanvasDimension(preProcessWidth, preProcessHeight) {
    const newDimensions = updateCanvasDimension(this.props.cameraRef, this.state.canvasWidth, this.state.canvasHeight, preProcessWidth, preProcessHeight);
    this.setState(newDimensions);
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (!!nextProps.settings && JSON.stringify(nextProps.settings) !== JSON.stringify(this.props.settings) && !this.allowApplySettings) {
      this.allowApplySettings = true;
      this.settingsToSave = { ...nextProps.settings };
    }
    if (this.props.modelUrl !== nextProps.modelUrl && !!nextProps.modelUrl) {
      tf.loadLayersModel(nextProps.modelUrl, { strict: false })
        .then(res => {
          this.model = res;
          this.props.modelFetched(true);
        })
        .catch(err => {
          console.log('fetchClassError', err);
          this.props.modelFetched(false);
        });
    }
    if (this.props.picTrigger !== nextProps.picTrigger && nextProps.picTrigger) {
      this.capturing = true;
      return false;
    }
    if (this.props.picTrigger !== nextProps.picTrigger && nextProps.picTrigger) {
      this.capturing = true;
    }
    if (this.state.canvasHeight === 0 && this.state.canvasWidth === 0 && nextState.canvasHeight !== 0 && nextState.canvasWidth !== 0) {
      this.props.cameraIsRendered();
    }

    if (this.state.cols !== nextState.cols || this.state.rows !== nextState.rows) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (!!this.props.preProcessingProcessor?.data.roi && !nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension();
      return true;
    }
    if (!this.props.preProcessingProcessor?.data.roi && !!nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }

    if (this.state.canvasWidth !== nextState.canvasWidth || this.state.canvasHeight !== nextState.canvasHeight) {
      return true;
    }
    if (this.canvasRef.current?.width > 0 && this.canvasRef.current?.height > 0 && this.props.cameraRef?.type === nextProps.cameraRef?.type) {
      if (this.props.modelFetched !== nextProps.modelFetched) {
        return false;
      }
      if (this.props.disablePicTrigger !== nextProps.disablePicTrigger) {
        return false;
      }
      if (this.props.cameraIsRendered !== nextProps.cameraIsRendered) {
        return false;
      }
      if (this.props.setActiveCircle !== nextProps.setActiveCircle) {
        return false;
      }
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.cameraRef?.type !== this.props.cameraRef?.type) {
      this.setCanvasDimension();
      if (!!this.props.cameraRef) {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
        this.cameraSchedule = new Schedule(this.proc, this.props.cameraRef?.getInterval());
      } else {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
      }
    }
  }

  render() {
    return <canvas className='w-100' style={{ height: 'auto', objectFit: 'contain' }} ref={this.canvasRef} width={this.state.canvasWidth} height={this.state.canvasHeight} />;
  }
}
