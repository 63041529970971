import produce from 'immer';
import { toast } from 'react-toastify';
import React from 'react';

export default function appGridReducer(state, action) {
  if (action.error) {
    toast.error(`Ocorreu um erro: ${action.file.message}. Treine novamente o modelo.`);
    return produce(state, draft => {
      draft.appGrid.training = false;
      draft.appGrid.trainId = '';
      draft.appGrid.sentImages = 0;
      draft.appGrid.prepared = false;
      draft.appGrid.epochs = false;
      draft.appGrid.someError = true;
      draft.appGrid.preTraining = false;
    });
  } else {
    switch (action.type) {
      case 'APPGRID_STORE_THUMB':
        let newThumbs = [...state.appGrid.thumbs];
        newThumbs = newThumbs.concat(action.file);
        let newGrids = [...state.appGrid.grids];
        const arraysToPush = action.file.map(() => Array(state.appGrid.gridSize * state.appGrid.gridSize).fill(0));
        newGrids = newGrids.concat(arraysToPush);
        return produce(state, draft => {
          draft.appGrid.thumbs = newThumbs;
          draft.appGrid.grids = newGrids;
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_STORE_GRID':
        const myNewGrids = [...state.appGrid.grids];
        myNewGrids[action.file.index] = action.file.grid;
        return produce(state, draft => {
          draft.appGrid.grids = myNewGrids;
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_REMOVE_THUMB':
        const thumbs = [...state.appGrid.thumbs];
        const grids = [...state.appGrid.grids];
        thumbs.splice(action.file, 1);
        grids.splice(action.file, 1);
        let currentThumbImg = '';
        let currentThumbIndex = -1;

        if (thumbs.length >= 1) {
          currentThumbIndex = state.appGrid.currentThumbIndex < thumbs.length ? state.appGrid.currentThumbIndex : thumbs.length - 1;
          currentThumbImg = thumbs[currentThumbIndex];
        }

        return produce(state, draft => {
          draft.appGrid.thumbs = thumbs;
          draft.appGrid.grids = grids;
          draft.appGrid.currentThumbImg = currentThumbImg;
          draft.appGrid.currentThumbIndex = currentThumbIndex;
          draft.appGrid.noCapture = thumbs.length === 0;
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_MARK_THEM_ALL':
        const myGrids = [...state.appGrid.grids].map(grid => {
          return grid.map((value, index) => (action.file[index] !== 0 ? action.file[index] : 0));
        });
        return produce(state, draft => {
          draft.appGrid.grids = myGrids;
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_START_TRAIN':
        return produce(state, draft => {
          draft.appGrid.training = true;
          draft.appGrid.trainId = action.file.train_key;
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_SEND_IMAGE':
        return produce(state, draft => {
          draft.appGrid.sentImages = state.appGrid.sentImages + 1;
        });
      case 'APPGRID_PREPARE':
        return produce(state, draft => {
          draft.appGrid.prepared = true;
        });
      case 'APPGRID_EPOCHS_TRAINING':
        return produce(state, draft => {
          draft.appGrid.epochCurrent = 0;
        });
      case 'APPGRID_MODEL_STATUS_TRAINING':
        return produce(state, draft => {
          draft.appGrid.modelFit = true;
          draft.appGrid.epochs = action.file.epoch || 0; // if undefined sets to zero
          draft.appGrid.currentAccuracy = action.file.accuracy;
        });
      case 'APPGRID_FINISH_TRAINING':
        toast.info(
          <div className='flex flex-wrap'>
            <div className='w-100'>Aplicação Grid:</div>
            <div className='w-100'>Treinamento finalizado.</div>
          </div>
        );
        return produce(state, draft => {
          draft.appGrid.training = false;
          draft.appGrid.trainId = '';
          draft.appGrid.sentImages = 0;
          draft.appGrid.prepared = false;
          draft.appGrid.epochs = -1;
          draft.appGrid.modelFit = false;
          draft.appGrid.modelUrl = action.file.url;
          draft.appGrid.preTraining = false;
        });
      case 'APPGRID_SAVE_GRID_AT_BACKGROUND':
        return produce(state, draft => {
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_SET_GRID_MODEL_URL':
        return produce(state, draft => {
          draft.appGrid.modelUrl = action.file;
          draft.appGrid.pendingSave = true;
        });
      case 'APPGRID_SET_PARAMS':
        return produce(state, draft => {
          draft.appGrid.modelParams = action.file.modelParams;
          draft.appGrid.datasetParams = action.file.datasetParams;
          draft.appGrid.numEpochs = action.file.numEpochs;
        });
      case 'APPGRID_UPDATE_THUMB_DATA':
        return produce(state, draft => {
          draft.appGrid.noCapture = action.file.noCapture;
          draft.appGrid.currentThumbImg = action.file.currentThumbImg;
          draft.appGrid.currentThumbIndex = action.file.currentThumbIndex;
        });
      case 'APPGRID_REMOVE_THUMB_DATA':
        return produce(state, draft => {
          draft.appGrid.noCapture = true;
          draft.appGrid.currentThumbImg = '';
          draft.appGrid.currentThumbIndex = -1;
        });
      case 'APPGRID_RESET_MODEL_PARAMS':
        return produce(state, draft => {
          draft.appGrid.modelParams = {};
          draft.appGrid.datasetParams = {};
          draft.appGrid.numEpochs = 15;
        });
      case 'APPGRID_SET_GRID_PRE_TRAINING':
        return produce(state, draft => {
          draft.appGrid.preTraining = true;
        });
      default:
        return state;
    }
  }
}
