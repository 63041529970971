import React from 'react';
import { Schedule } from '../../../schedule';
import { updateCanvasDimension } from '../../../camera';
import { Rnd } from 'react-rnd';
import ReactResizeDetector from 'react-resize-detector';

export default class QualityEngine extends React.Component {
  cameraSchedule = null;
  settingsToSave = {};
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,

      imageWidth: '100%',
      imageHeight: '100%',
      containerWidth: '100%',
      containerHeight: '100%',
    };
    this.initDefaultCam();
  }
  initDefaultCam() {
    this.props.setCamera({ name: '///', data: { type: 'systemCam', id: null, interval: null } });
  }
  proc = async () => {
    let out = this.props.cameraConfigRef?.captureStreamImg();
    if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }

    this.props.tabsRefs.some((ref, index) => {
      if (index > this.props.currentTab) {
        return true;
      }
      if (ref !== null) {
        out = ref.process(out);
        return false;
      } else return index !== 0;
      //////////////////////////////////////
      //////// TODO:  MUST CHANGE //////////
      //////// AFTER "CONFIG" TAB //////////
      //////// IS IMPLEMENTED //////////////
    });

    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
    window.cv.imshow(this.canvasRef.current, out);
    out.delete();
  };

  setCanvasDimension(preProcessWidth, preProcessHeight) {
    const newDimensions = updateCanvasDimension(this.props.cameraConfigRef, this.state.canvasWidth, this.state.canvasHeight, preProcessWidth, preProcessHeight);
    this.setState(newDimensions, () => this.calculateImageDimensions());
  }

  fitContainer() {
    this.setState({ containerWidth: 'fit-content', containerHeight: 'fit-content' });
  }

  calculateImageDimensions() {
    if (this.state.canvasWidth > 0 && this.state.canvasHeight > 0) {
      this.setState({ containerWidth: '100%', containerHeight: '100%' }, () => {
        const pw = this.props.parentsWidth || 1;
        const ph = this.props.parentsHeight || 1;

        const ir = this.state.canvasWidth / this.state.canvasHeight;
        const pr = pw / ph;

        if (ir >= pr) {
          this.setState({ imageWidth: `${Math.round(pw)}px`, imageHeight: 'auto' }, this.fitContainer);
        } else {
          this.setState({ imageWidth: 'auto', imageHeight: `${Math.round(ph)}px` }, this.fitContainer);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.cols !== nextState.cols || this.state.rows !== nextState.rows) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (!!this.props.preProcessingProcessor?.data.roi && !nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension();
      return true;
    }
    if (!this.props.preProcessingProcessor?.data.roi && !!nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (this.state.canvasWidth !== nextState.canvasWidth || this.state.canvasHeight !== nextState.canvasHeight) {
      return true;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!this.props.cameraConfigRef && JSON.stringify(prevProps.cameraConfigRef) !== JSON.stringify(this.props.cameraConfigRef)) {
      this.setCanvasDimension();
      if (!!this.props.cameraConfigRef) {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
        this.cameraSchedule = new Schedule(this.proc, this.props.cameraConfigRef?.getInterval());
      } else {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
      }
    }
    if (this.props.parentsWidth !== prevProps.parentsWidth || this.props.parentsHeight !== prevProps.parentsHeight) {
      this.calculateImageDimensions();
    }
  }

  render() {
    return (
      <div className='flex flex-center grid-marking-container' style={{ width: this.state.containerWidth, height: this.state.containerHeight }}>
        <ReactResizeDetector>
          {({ width, height }) => (
            <>
              <canvas
                className='flex'
                style={{ width: this.state.imageWidth, height: this.state.imageHeight }}
                ref={this.canvasRef}
                width={this.state.canvasWidth}
                height={this.state.canvasHeight}
              />

              {this.props.currentTab === 1 && this.state.canvasWidth > 0 && this.state.canvasHeight && !this.props.blockedRoi && (
                <QualityEngineRoi
                  imageWidth={width}
                  imageHeight={height}
                  roiValues={this.props.temporaryRoi}
                  updateRoiValues={(position, coordinates) => this.props.updateRoiValues(position, coordinates)}
                  setRoiValues={position => this.props.updateRoiValues(position, null)}
                />
              )}
            </>
          )}
        </ReactResizeDetector>
      </div>
    );
  }
}

class QualityEngineRoi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roiX: props.roiValues.roiX,
      roiY: props.roiValues.roiY,
      roiWidth: props.roiValues.roiWidth,
      roiHeight: props.roiValues.roiHeight,
    };
  }
  getCoordinates(x, y, w, h) {
    const y1 = Number(y / this.props.imageHeight).toFixed(2);
    const y2 = Number((y + h) / this.props.imageHeight).toFixed(2);
    const x1 = Number(x / this.props.imageWidth).toFixed(2);
    const x2 = Number((x + w) / this.props.imageWidth).toFixed(2);
    return [x1, y1, x2, y2];
  }
  updateRoi(x, y, w, h) {
    let coordinates = this.getCoordinates(x, y, w, h);
    if (x !== this.props.roiValues.roiX || y !== this.props.roiValues.roiY || w !== this.props.roiValues.roiWidth || h !== this.props.roiValues.roiHeight) {
      this.props.updateRoiValues({ roiX: x, roiY: y, roiWidth: w, roiHeight: h }, coordinates);
    }
  }
  render() {
    return (
      <div id='QualityEngineRoiContainer' className='w-100 h-100' style={{ position: 'absolute', top: 0, left: 0 }}>
        <Rnd
          className='roi'
          bounds='parent'
          size={{
            width: this.props.roiValues.roiWidth,
            height: this.props.roiValues.roiHeight,
          }}
          position={{ x: this.props.roiValues.roiX, y: this.props.roiValues.roiY }}
          onDrag={(e, d) => {
            e.preventDefault();
            e.stopPropagation();
            this.updateRoi(d.x, d.y, this.props.roiValues.roiWidth, this.props.roiValues.roiHeight);
          }}
          onDragStop={(e, d) => {
            this.props.setRoiValues({ roiX: d.x, roiY: d.y, roiWidth: this.props.roiValues.roiWidth, roiHeight: this.props.roiValues.roiHeight });
          }}
          onResize={(e, direction, ref, delta, position) => {
            this.updateRoi(position.x, position.y, ref.offsetWidth, ref.offsetHeight);
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            this.props.setRoiValues({ roiX: position.x, roiY: position.y, roiWidth: ref.offsetWidth, roiHeight: ref.offsetHeight });
          }}
        />
      </div>
    );
  }
}
