import React from 'react';
import QualityEngine from '../qualityEngine';
import { Card, CardContent } from 'react-md';
import { bindActionCreators } from 'redux';
import { changeCamera } from '../../../../actions/cameraConfig';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { setRedSquarePositionAndCoordinates } from '../../../../actions/appQuality';

class QualityPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className='h-100 grown quality-container'>
        <Card className='full-size'>
          <CardContent className='h-100'>
            <div className='full-size flex flex-column grown' style={{ alignItems: 'center' }}>
              <div className='w-100 flex grown' style={{ maxHeight: '100%', background: '#545454' }}>
                <ReactResizeDetector>
                  {({ width, height }) => (
                    <div className='flex w-100 flex-center' style={{ maxHeight: '100%', backgroundColor: '#545454' }}>
                      <QualityEngine
                        cameraConfigRef={this.props.newCameraRef}
                        setCamera={camera => this.props.changeCamera(camera)}
                        parentsWidth={width}
                        parentsHeight={height}
                        currentTab={this.props.currentTab}
                        temporaryRoi={this.props.temporaryRoi}
                        blockedRoi={this.props.temporaryRoi.blockedRoi}
                        updateRoiValues={(redSquarePosition, coordinates) => this.props.setRedSquarePositionAndCoordinates(redSquarePosition, coordinates)}
                        tabsRefs={this.props.tabsRefs}
                      />
                    </div>
                  )}
                </ReactResizeDetector>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  newCameraRef: state.cameraConfig.newCameraRef,
  tabsRefs: state.appQuality.tabsRefs,
  preprocessing: state.appQuality.preprocessing,
  temporaryRoi: state.appQuality.temporaryRoi,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeCamera,
      setRedSquarePositionAndCoordinates,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(QualityPreview);
