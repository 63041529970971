import React from 'react';
import './tabs/tabs.scss';
import { Card, Button } from 'react-md';
import { TabsManager, Tabs, TabPanels, TabPanel } from '@react-md/tabs';
import ConfigTab from './tabs/configTab';
import PreprocessingTab from './tabs/preprocessingTab';
import IdentificationTab from './tabs/identificationTab';
import DatabaseTab from './tabs/databaseTab';
import TrainingTab from './tabs/trainingTab';
import PostprocessingTab from './tabs/postprocessingTab';
import OutputTab from './tabs/outputTab';

export default class QualityOptions extends React.Component {
  // tabs = ['CONFIGURAÇÕES', 'PRÉ PROCESSAMENTO', 'IDENTIFICAÇÃO', 'BANCO DE DADOS', 'TREINAMENTO', 'PÓS PROCESSAMENTO', 'OUTPUTS']
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
    };
  }
  changeIndexByButton(key, tabsLength) {
    if (key.keyCode === 37 || key.which === 37) {
      const newIndex = this.state.tabIndex - 1 < 0 ? tabsLength - 1 : this.state.tabIndex - 1;
      this.setState({ tabIndex: newIndex });
    } else if (key.keyCode === 39 || key.which === 39) {
      const newIndex = this.state.tabIndex + 1 > tabsLength - 1 ? 0 : this.state.tabIndex + 1;
      this.setState({ tabIndex: newIndex });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.tabIndex !== this.state.tabIndex) {
      this.props.indexChanged(this.state.tabIndex);
    }
  }

  render() {
    const tabs = [
      { children: <CustomTabButton buttonName='CONFIGURAÇÕES' tabIsActive={this.state.tabIndex === 0} /> },
      { children: <CustomTabButton buttonName='PRÉ PROCESSAMENTO' tabIsActive={this.state.tabIndex === 1} /> },
      { children: <CustomTabButton buttonName='IDENTIFICAÇÃO' tabIsActive={this.state.tabIndex === 2} /> },
      { children: <CustomTabButton buttonName='BANCO DE DADOS' tabIsActive={this.state.tabIndex === 3} /> },
      { children: <CustomTabButton buttonName='TREINAMENTO' tabIsActive={this.state.tabIndex === 4} /> },
      { children: <CustomTabButton buttonName='PÓS PROCESSAMENTO' tabIsActive={this.state.tabIndex === 5} /> },
      { children: <CustomTabButton buttonName='OUTPUTS' tabIsActive={this.state.tabIndex === 6} /> },
    ];
    return (
      <div className='h-100 grown quality-container' style={{ maxWidth: `${tabs.length * 150}px` }}>
        <Card className='full-size'>
          <TabsManager tabs={tabs} tabsId='qualityTabsManager' activeIndex={this.state.tabIndex} onActiveIndexChange={index => this.setState({ tabIndex: index })}>
            <Tabs disableTransition={true} automatic={true} padded={true} />
            <TabPanels id='qualityTabPanels' disableTransition={true} persistent={true}>
              <TabPanel className='grown'>
                <ConfigTab myIndex={0} currentIndex={this.state.tabIndex} />
              </TabPanel>
              <TabPanel className='grown'>
                <PreprocessingTab myIndex={1} currentIndex={this.state.tabIndex} />
              </TabPanel>
              <TabPanel className='grown'>
                <IdentificationTab myIndex={2} currentIndex={this.state.tabIndex} />
              </TabPanel>
              <TabPanel className='grown'>
                <DatabaseTab myIndex={3} currentIndex={this.state.tabIndex} />
              </TabPanel>
              <TabPanel className='grown'>
                <TrainingTab myIndex={4} currentIndex={this.state.tabIndex} />
              </TabPanel>
              <TabPanel className='grown'>
                <PostprocessingTab myIndex={5} currentIndex={this.state.tabIndex} />
              </TabPanel>
              <TabPanel className='grown'>
                <OutputTab myIndex={6} currentIndex={this.state.tabIndex} />
              </TabPanel>
            </TabPanels>
          </TabsManager>
          <div className='w-100 flex'>
            <Button
              theme='primary'
              themeType='contained'
              className={`flex flex-center custom-tab-button output-button change-tab-button`}
              onClick={() => this.changeIndexByButton({ keyCode: 37 }, tabs.length)}
              onKeyDown={key => this.changeIndexByButton(key, tabs.length)}
            >
              {'<'}
            </Button>
            <Button
              theme='primary'
              themeType='contained'
              className={`flex flex-center custom-tab-button output-button change-tab-button`}
              onClick={() => this.changeIndexByButton({ keyCode: 39 }, tabs.length)}
              onKeyDown={key => this.changeIndexByButton(key, tabs.length)}
            >
              {'>'}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

class CustomTabButton extends React.Component {
  render() {
    return (
      <div className={`h-100 flex flex-center custom-tab-button output-button ${this.props.tabIsActive ? 'selected-tab' : 'inactive-output-button'}`}>{this.props.buttonName}</div>
    );
  }
}
