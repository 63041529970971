import React from 'react';
import { Schedule } from '../../../schedule';

import { updateCanvasDimension } from '../../../camera';

export default class CameraConfigEngine extends React.Component {
  cameraSchedule = null;
  settingsToSave = {};
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = {
      canvasWidth: 0,
      canvasHeight: 0,
    };
  }
  proc = async () => {
    let out = this.props.cameraConfigRef?.captureStreamImg();
    if (!!out && (this.canvasRef.current?.width === 0 || this.canvasRef.current?.height === 0)) {
      this.setCanvasDimension();
    }
    if (out === null) {
      return null;
    }

    if (this.props.preProcessingProcessor) {
      out = this.props.preProcessingProcessor.process(out);
      if (this.props.preProcessingProcessor.data.roi && (this.state.cols !== out.cols || this.state.rows !== out.rows)) {
        this.setState({ cols: out.cols, rows: out.rows });
      }
    }
    window.cv.resize(out, out, new window.cv.Size(this.canvasRef.current.width, this.canvasRef.current.height), 0, 0, window.cv.INTER_AREA);
    window.cv.imshow(this.canvasRef.current, out);
    out.delete();

    if (!!this.allowGetCapabilities) {
      this.props.getCapabilitiesFromVideo();
      this.allowGetCapabilities = false;
    }
    if (!!this.allowApplySettings) {
      this.props.applyVideoSettings(this.settingsToSave);
      this.allowApplySettings = false;
      this.settingsToSave = {};
    }
  };

  setCanvasDimension(preProcessWidth, preProcessHeight) {
    const newDimensions = updateCanvasDimension(this.props.cameraConfigRef, this.state.canvasWidth, this.state.canvasHeight, preProcessWidth, preProcessHeight);
    this.setState(newDimensions);
  }

  componentWillUnmount() {
    if (this.cameraSchedule) {
      this.cameraSchedule.running = false;
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.state.cols !== nextState.cols || this.state.rows !== nextState.rows) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (!!this.props.preProcessingProcessor?.data.roi && !nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension();
      return true;
    }
    if (!this.props.preProcessingProcessor?.data.roi && !!nextProps.preProcessingProcessor?.data.roi) {
      this.setCanvasDimension(nextState.cols, nextState.rows);
      return true;
    }
    if (this.state.canvasWidth !== nextState.canvasWidth || this.state.canvasHeight !== nextState.canvasHeight) {
      return true;
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!!this.props.settings && JSON.stringify(prevProps.settings) !== JSON.stringify(this.props.settings) && !this.allowApplySettings) {
      this.allowApplySettings = true;
      this.settingsToSave = { ...this.props.settings };
    }
    if (!!this.props.cameraConfigRef && JSON.stringify(prevProps.cameraConfigRef) !== JSON.stringify(this.props.cameraConfigRef) && !this.allowGetCapabilities) {
      this.allowGetCapabilities = true;
      this.setCanvasDimension();
      if (!!this.props.cameraConfigRef) {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
        this.cameraSchedule = new Schedule(this.proc, this.props.cameraConfigRef?.getInterval());
      } else {
        if (this.cameraSchedule) {
          this.cameraSchedule.running = false;
        }
      }
    }
  }

  render() {
    return <canvas className='w-100' style={{ height: 'auto', objectFit: 'contain' }} ref={this.canvasRef} width={this.state.canvasWidth} height={this.state.canvasHeight} />;
  }
}
