import React from 'react';
import { Form, Select, TextField } from 'react-md';
import { Password } from '@react-md/form';

export default class Http extends React.Component {
  dropDownOptions = ['Nula', 'Bearer'];
  render() {
    return (
      <div className='tab-content-wrapper'>
        <Form className='w-100'>
          <div className='w-100 flex flex-between vertical-items-top' style={{ margin: '16px 0 0 0' }}>
            <div className='flex flex-wrap lineContainer'>
              <TextField
                disabled={!this.props.active}
                style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Endereco') && this.props.active ? 'error-field' : ''}`}
                id='httpAddress'
                label='Endereço IP'
                type='text'
                theme='outline'
                autoComplete='off'
                value={this.props.active ? this.props.address : ''}
                onChange={event => (this.props.active ? this.props.setDialogState({ httpAddress: event.target.value }) : null)}
                onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                onBlur={() => this.props.validateHttpFields('Endereco')}
              />
              <div className={`${this.props.httpFieldError.find(el => el.path === 'Endereco')?.error ? 'error-info' : 'hidden-error'}`}>
                {this.props.active && this.props.httpFieldError.find(el => el.path === 'Endereco')?.error}
              </div>
            </div>
            <div className='flex flex-wrap lineContainer'>
              <Select
                disabled={!this.props.active}
                className='w-100'
                style={{ height: '56px', marginBottom: '12px' }}
                theme='outline'
                id='httpRequestTypeDropdown'
                options={['POST', 'PUT']}
                value={this.props.requestType}
                name='httpRequestTypeDropdown'
                label='Requisição da saída'
                onChange={val => this.props.setDialogState({ httpRequestType: val })}
                disableMovementChange={true}
              />
            </div>
          </div>
          <div className='w-100 flex flex-between vertical-items-top'>
            <div className='flex flex-wrap lineContainer'>
              <Select
                disabled={!this.props.active}
                className='w-100'
                style={{ height: '56px', marginBottom: '12px' }}
                theme='outline'
                id='httpDropdown'
                options={this.dropDownOptions}
                value={this.props.httpAuthType}
                name='httpDropdown'
                label='Autenticação'
                onChange={val => this.props.setAuthType(val)}
                disableMovementChange={true}
              />
            </div>
            {this.props.httpAuthType !== 'Nula' ? (
              <div className='flex flex-wrap lineContainer'>
                <Select
                  disabled={!this.props.active}
                  className='w-100'
                  style={{ height: '56px', marginBottom: '12px' }}
                  theme='outline'
                  id='httpTokenRequestTypeDropdown'
                  options={['POST', 'PUT', 'GET']}
                  value={this.props.tokenRequestType}
                  name='httpRequestTypeDropdown'
                  label='Requisição de token'
                  onChange={val => this.props.setDialogState({ httpTokenRequestType: val })}
                  disableMovementChange={true}
                />
              </div>
            ) : null}
          </div>
          {this.props.httpAuthType !== 'Nula' ? (
            <>
              <div className='w-100 flex flex-between vertical-items-top'>
                <div className='flex flex-wrap lineContainer'>
                  <TextField
                    disabled={!this.props.active}
                    style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                    className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Endereco_Token') && this.props.active ? 'error-field' : ''}`}
                    id='httpTokenAddress'
                    label='Endereço de autenticação'
                    type='text'
                    theme='outline'
                    autoComplete='off'
                    value={this.props.active ? this.props.tokenAddress : ''}
                    onChange={event => (this.props.active ? this.props.setDialogState({ httpTokenAddress: event.target.value }) : null)}
                    onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                    onBlur={() => this.props.validateHttpFields('Endereco_Token')}
                  />
                  <div className={`${this.props.httpFieldError.find(el => el.path === 'Endereco_Token')?.error ? 'error-info' : 'hidden-error'}`}>
                    {this.props.active && this.props.httpFieldError.find(el => el.path === 'Endereco_Token')?.error}
                  </div>
                </div>
                <div className='flex flex-wrap lineContainer'>
                  <TextField
                    disabled={!this.props.active}
                    style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                    className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Token_Alias') && this.props.active ? 'error-field' : ''}`}
                    id='httpTokenAlias'
                    label='Token Alias'
                    type='text'
                    theme='outline'
                    autoComplete='off'
                    value={this.props.active ? this.props.tokenAlias : ''}
                    onChange={event => (this.props.active ? this.props.setDialogState({ httpTokenAlias: event.target.value }) : null)}
                    onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                    onBlur={() => this.props.validateHttpFields('Token_Alias')}
                  />
                  <div className={`${this.props.httpFieldError.find(el => el.path === 'Token_Alias')?.error ? 'error-info' : 'hidden-error'}`}>
                    {this.props.active && this.props.httpFieldError.find(el => el.path === 'Token_Alias')?.error}
                  </div>
                </div>
              </div>
              <div className='w-100 flex flex-between vertical-items-top'>
                <div className='flex flex-wrap lineContainer'>
                  <TextField
                    disabled={!this.props.active}
                    style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                    className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Login') && this.props.active ? 'error-field' : ''}`}
                    id='httpLogin'
                    label='Login'
                    type='text'
                    theme='outline'
                    autoComplete='off'
                    value={this.props.active ? this.props.login : ''}
                    onChange={event => (this.props.active ? this.props.setDialogState({ httpLogin: event.target.value }) : null)}
                    onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                    onBlur={() => this.props.validateHttpFields('Login')}
                  />
                  <div className={`${this.props.httpFieldError.find(el => el.path === 'Login')?.error ? 'error-info' : 'hidden-error'}`}>
                    {this.props.active && this.props.httpFieldError.find(el => el.path === 'Login')?.error}
                  </div>
                </div>
                <div className='flex flex-wrap lineContainer'>
                  <TextField
                    disabled={!this.props.active}
                    style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                    className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Login_Alias') && this.props.active ? 'error-field' : ''}`}
                    id='httpAliasLogin'
                    label='Login Alias'
                    type='text'
                    theme='outline'
                    autoComplete='off'
                    value={this.props.active ? this.props.loginAlias : ''}
                    onChange={event => (this.props.active ? this.props.setDialogState({ httpLoginAlias: event.target.value }) : null)}
                    onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                    onBlur={() => this.props.validateHttpFields('Login_Alias')}
                  />
                  <div className={`${this.props.httpFieldError.find(el => el.path === 'Login_Alias')?.error ? 'error-info' : 'hidden-error'}`}>
                    {this.props.active && this.props.httpFieldError.find(el => el.path === 'Login_Alias')?.error}
                  </div>
                </div>
              </div>
              <div className='w-100 flex flex-between vertical-items-top'>
                <div className='flex flex-wrap lineContainer'>
                  <Password
                    visibilityStyle={{ color: this.props.active ? '#223f54' : '#9e9e9e' }}
                    disabled={!this.props.active}
                    style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                    className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Senha') && this.props.active ? 'error-field' : ''}`}
                    id='httpPassword'
                    label='Senha'
                    type='password'
                    theme='outline'
                    autoComplete='off'
                    value={this.props.active ? this.props.password : ''}
                    onChange={event => (this.props.active ? this.props.setDialogState({ httpPassword: event.target.value }) : null)}
                    onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                    onBlur={() => this.props.validateHttpFields('Senha')}
                  />
                  <div className={`${this.props.httpFieldError.find(el => el.path === 'Senha')?.error ? 'error-info' : 'hidden-error'}`}>
                    {this.props.active && this.props.httpFieldError.find(el => el.path === 'Senha')?.error}
                  </div>
                </div>
                <div className='flex flex-wrap lineContainer'>
                  <TextField
                    disabled={!this.props.active}
                    style={{ pointerEvents: this.props.active ? 'all' : 'none' }}
                    className={`fields ${!!this.props.httpFieldError.find(el => el.path === 'Senha_Alias') && this.props.active ? 'error-field' : ''}`}
                    id='httpPasswordLogin'
                    label='Senha Alias'
                    type='text'
                    theme='outline'
                    autoComplete='off'
                    value={this.props.active ? this.props.passwordAlias : ''}
                    onChange={event => (this.props.active ? this.props.setDialogState({ httpPasswordAlias: event.target.value }) : null)}
                    onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.props.saveChanges() : null)}
                    onBlur={() => this.props.validateHttpFields('Senha_Alias')}
                  />
                  <div className={`${this.props.httpFieldError.find(el => el.path === 'Senha_Alias')?.error ? 'error-info' : 'hidden-error'}`}>
                    {this.props.active && this.props.httpFieldError.find(el => el.path === 'Senha_Alias')?.error}
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </Form>
      </div>
    );
  }
}
