import React from 'react';
import { AppBarNav, AppBarTitle, MenuSVGIcon, AppBar, Divider, Sheet } from 'react-md';
import { bindActionCreators } from 'redux';
import * as mainActions from '../../../actions/main';
import { connect } from 'react-redux';
import AppBarButtons from './appBarButtons';
import MenuHeader from '../menu/menuHeader';
import MenuRoutes from '../menu/menuRoutes';
import axios from 'axios';
import * as userActions from '../../../actions/user';
import * as appBarActions from '../../../actions/appBar';
import { toast } from 'react-toastify';
import http from 'axios';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@react-md/dialog';
import { Button } from '@react-md/button';
import { ConfirmationDialog } from '../../confirmationDialog/confirmationDialog';

function storeToken(access, refresh) {
  try {
    if (access) {
      localStorage.setItem('accessToken', access);
    }
    if (refresh) {
      localStorage.setItem('refreshToken', refresh);
    }
    return true;
  } catch (e) {
    return false;
  }
}

function removeToken() {
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    return true;
  } catch (e) {
    return false;
  }
}

async function setInterceptor(accessToken, forbiddenTokenRoutes) {
  return axios.interceptors.request.use(
    config => {
      config.headers['accept-language'] = 'pt-br';
      if (!forbiddenTokenRoutes.find(el => !!config.url.endsWith(el))) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

class AppBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canOpenSheet: false,
    };
  }
  componentDidMount() {
    axios.interceptors.response.use(
      async response => {
        if (response.config.url.endsWith(`${this.props.url}/api/user/auth`)) {
          await axios.interceptors.request.eject(this.props.interceptorInstance);
          const interceptor = await setInterceptor(response.data.access, this.props.forbiddenTokenRoutes);
          response.data = { ...response.data, interceptor: interceptor };
          const tokenSaved = await storeToken(response.data.access, response.data.refresh);
          if (!tokenSaved) {
            toast.error('Não foi possível liberar seu acesso. Autentique-se novamente.');
            return Promise.reject({ data: false });
          }
          this.props.fetchUserInfo();
        } else if (response.config.url.endsWith(`${this.props.url}/api/user/refresh`)) {
          await axios.interceptors.request.eject(this.props.interceptorInstance);
          const interceptor = await setInterceptor(response.data.access, this.props.forbiddenTokenRoutes);
          const tokenUpdated = await storeToken(response.data.access, null);
          response.data = { ...response.data, interceptor: interceptor };
          if (!tokenUpdated) {
            toast.error('Não foi possível liberar o acesso à plataforma. Autentique-se novamente.');
            return Promise.reject({ data: false });
          }
        } else if (response.config.url.endsWith(`${this.props.url}/api/user`)) {
          toast.success(
            <>
              <div>{`Olá ${response.data.first_name}!`}</div>
              <br />
              <div>Bem vindo(a) à Plataforma Mvisia.</div>
              {this.props.user.expirationDate ? (
                <>
                  <br />
                  <div>Assinatura válida até {this.props.user.expirationDate}</div>
                  <br />
                </>
              ) : (
                <br />
              )}
            </>
          );
        }
        return response;
      },
      async error => {
        const originalRequest = error.config;
        if (
          !error.response.config.url.endsWith(`${this.props.url}/api/user/auth`) &&
          !error.response.config.url.endsWith(`${this.props.url}/api/signup`) &&
          !error.response.config.url.endsWith(`${this.props.url}/api/user/refresh`) &&
          !this.props.forbiddenTokenRoutes.find(el => !!error.response.config.url.endsWith(el)) &&
          error.response.data.code === 'token_not_valid' &&
          error.response.status === 401
        ) {
          const refresh = await this.props.refreshToken();
          if (!!refresh && this.props.requireTokenRoutes.find(req => !!error.response.config.url.includes(req))) {
            return axios(originalRequest);
          }
        } else if (error.response.config.url.endsWith(`${this.props.url}/api/user/refresh`)) {
          await removeToken();
          toast.error('Sua sessão expirou. Autentique-se novamente.');
        } else {
          if (!!error.response.data?.detail) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Erro na requisição.');
          }
        }
        return Promise.reject(error);
      }
    );

    if (this.props.user.accessToken) {
      const interceptorInstance = axios.interceptors.request.use(
        config => {
          config.headers['accept-language'] = 'pt-br';
          if (!this.props.forbiddenTokenRoutes.find(el => !!config.url.endsWith(el))) {
            config.headers.Authorization = `Bearer ${this.props.user.accessToken}`;
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );
      this.props.setInterceptorInstance(interceptorInstance);
      this.props.fetchUserInfo();
    }

    const httpOutputAxios = http.create();
    httpOutputAxios.interceptors.request.use(
      config => {
        config.headers['accept-language'] = 'pt-br';
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    this.props.setHttpOutputAxios(httpOutputAxios);
    setTimeout(this.setSheetAbleToBeOpened.bind(this), 1000);
  }

  setSheetAbleToBeOpened() {
    this.setState({ canOpenSheet: true });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.current !== this.props.current) {
      let searchArray = [];
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const proceed = !!(urlParams.get('offline') && urlParams.get('id') && urlParams.get('appType') && urlParams.get('appConfig'));
        searchArray = JSON.parse(localStorage.getItem('searchArray'));
        let found = false;
        if (!!searchArray && Array.isArray(searchArray)) {
          found = searchArray.find(urlSearch => urlSearch === window.location.search);
        } else {
          searchArray = [];
        }
        if (!!window.location.search && proceed && !found) {
          searchArray.push(window.location.search);
          localStorage.setItem('searchArray', JSON.stringify(searchArray));
          window.location.reload();
        }
      } catch (e) {
        console.log('Não foi possível carregar os parâmetros necessários para salvar seus dados. Por favor, recarregue a página.');
      }
    }
  }

  render() {
    const confirmationDialog = (
      <ConfirmationDialog
        confirmationDialogVisible={this.props.confirmationDialogVisible}
        confirmationDialogText={this.props.confirmationDialogText}
        onCancel={() => this.props.setConfirmationInfo(false, this.props.confirmationDialogText, false)}
        onConfirm={() => this.props.setConfirmationInfo(false, this.props.confirmationDialogText, true)}
      />
    );
    let buttonsComponent = <></>;
    if (!!this.props.availableApps.find(app => app === this.props.current)) {
      buttonsComponent = (
        <AppBarButtons
        // allSelectApps = {
        //   this.props.availableApps.map(el => {return {[el]: 'state.'+el+'SelectApp'}}).reduce((result, item) => {
        //   const key = Object.keys(item)[0];
        //   result[key] = item[key];
        //   return result;
        // }, {})}
        />
      );
    }

    return (
      <>
        {this.props.confirmationDialogVisible ? confirmationDialog : null}
        <Sheet id='sideBar8000' position='left' visible={this.props.menuEnabled} onRequestClose={() => this.props.handleMenu(false)}>
          <MenuHeader
            user={this.props.user}
            logoutUser={() => {
              this.props.handleMenu(false);
              this.props.logoutUser();
            }}
          />
          <Divider style={{ width: '70%', margin: '16px auto 16px', borderColor: '#0dad9f' }} />
          <MenuRoutes
            current={this.props.current}
            logged={this.props.user.logged}
            setCurrent={app => this.props.changeCurrent(app)}
            camerasFetched={this.props.camerasFetched}
            user={this.props.user}
          />
        </Sheet>
        <AppBar theme='primary' color='white' className='flex flex-between' style={{ position: 'relative' }}>
          <div className='flex flex-center'>
            {this.props.user.logged && this.state.canOpenSheet ? (
              <AppBarNav style={{ margin: '0', zIndex: '2' }} onClick={() => this.props.handleMenu(true)} aria-label='Navigation' id='normal-nav'>
                <MenuSVGIcon />
              </AppBarNav>
            ) : null}
          </div>
          <AppBarTitle className='flex flex-center w-100 h-100 appbar-title'>
            <img
              alt='Mvisia low code'
              src={`${process.env.PUBLIC_URL}/img/logo_white.png`}
              style={{ margin: '0', height: '36px', width: 'auto', cursor: 'pointer' }}
              onClick={() => (!this.props.user.logged ? this.props.changeCurrent('Login') : null)}
            />
          </AppBarTitle>
          <div style={{ minWidth: '48px' }}>{buttonsComponent}</div>
          {/*<AppBarAction first aria-label='Search' id='normal-search'>*/}
          {/*  <SearchSVGIcon />*/}
          {/*</AppBarAction>*/}
          {/*<AppBarAction last aria-label='Actions' id='normal-actions'>*/}
          {/*  <MoreVertSVGIcon />*/}
          {/*</AppBarAction>*/}
        </AppBar>
      </>
    );
  }
}

const mapStateToProps = state => ({
  current: state.main.current,
  availableApps: state.main.availableApps,
  user: state.user,
  menuEnabled: state.main.menuEnabled,
  forbiddenTokenRoutes: state.main.forbiddenTokenRoutes,
  requireTokenRoutes: state.main.requireTokenRoutes,
  interceptorInstance: state.main.interceptorInstance,
  url: state.appGrid.url,

  confirmationDialogVisible: state.main.confirmationDialogVisible,
  confirmationDialogText: state.main.confirmationDialogText,
  confirmationDialogAnswer: state.main.confirmationDialogAnswer,

  camerasFetched: state.cameraConfig.camerasFetched,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...mainActions,
      ...userActions,
      ...appBarActions,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppBarComponent);
