import { toast } from 'react-toastify';
import React from 'react';
import * as lodash from 'lodash';

export class PostProcessingProcessor {
  constructor(data) {
    this.data = lodash.cloneDeep(data);
    this.toastId = null;
    this.states = ['stateZero', 'stateOne', 'stateTwo', 'stateThree'];
    this.currentTransition = '';
    this.activeState = '';
    this.outputCounter = {
      stateZero_stateOne: 0,
      stateZero_stateTwo: 0,
      stateZero_stateThree: 0,
      stateOne_stateZero: 0,
      stateOne_stateTwo: 0,
      stateOne_stateThree: 0,
      stateThree_stateZero: 0,
      stateThree_stateOne: 0,
      stateThree_stateTwo: 0,
      stateTwo_stateZero: 0,
      stateTwo_stateOne: 0,
      stateTwo_stateThree: 0,
    };
    this.latestFrames = {
      stateZero_stateOne: null,
      stateZero_stateTwo: null,
      stateZero_stateThree: null,
      stateOne_stateZero: null,
      stateOne_stateTwo: null,
      stateOne_stateThree: null,
      stateThree_stateZero: null,
      stateThree_stateOne: null,
      stateThree_stateTwo: null,
      stateTwo_stateZero: null,
      stateTwo_stateOne: null,
      stateTwo_stateThree: null,
    };
    // dynamic array, must now latestFrames size to create it - this.latestFrames = [];

    this.varOptions = {
      gridMask: { label: 'Máscara', type: 'gridArray' },
      hasFace: { label: 'Tem rosto', type: 'bool' },
      hasEPI: { label: 'Tem epi', type: 'bool' },
      classIndex: { label: 'Número da Classe', type: 'integer' },
    };
  }
  serialize() {
    return JSON.parse(JSON.stringify(this.data));
  }
  process(input) {
    return this.checkVar(input);
  }
  setNewActiveCircle(transition) {
    this.currentTransition = transition;
    Object.keys(this.latestFrames).forEach(transition => {
      this.latestFrames[transition] = null;
    });
    this.states.forEach(el => {
      if (el === transition.split('_')[1]) {
        this.data[el].active = true;
        this.activeState = el;
      } else {
        this.data[el].active = false;
      }
    });
    this.triggerOutput = this.data.transitions[transition].triggerOutput;
    if (this.data.transitions[transition].triggerOutput) this.outputCounter[transition] += 1;
  }
  checkMaskOperation(operatorIndex, gridRectangles, transition) {
    const statements = [
      () => gridRectangles[this.data.transitions[transition].maskColor + 1] > this.data.transitions[transition].maskQuantity,
      () => gridRectangles[this.data.transitions[transition].maskColor + 1] < this.data.transitions[transition].maskQuantity,
      () => gridRectangles[this.data.transitions[transition].maskColor + 1] === this.data.transitions[transition].maskQuantity,
      () => gridRectangles[this.data.transitions[transition].maskColor + 1] !== this.data.transitions[transition].maskQuantity,
    ];
    if (statements[operatorIndex]()) {
      this.latestFrames[transition] = [...this.latestFrames[transition].slice(1), 1];
      if (this.latestFrames[transition].reduce((val, acc) => val + acc) === this.data.transitions[transition].minimumFrames) {
        this.setNewActiveCircle(transition);
        return true;
      }
    } else {
      this.latestFrames[transition] = [...this.latestFrames[transition].slice(1), 0];
    }
    return false;
  }
  checkMaskRule(gridMask, transition) {
    const gridRectangles = [null, null, null, null, null].map((el, index) => gridMask.filter(el => el === index).length);
    // ['Maior que', 'Menor que', 'Igual', 'Diferente]
    switch (this.data.transitions[transition].maskRule) {
      case 0:
        return this.checkMaskOperation(0, gridRectangles, transition);
      case 1:
        return this.checkMaskOperation(1, gridRectangles, transition);
      case 2:
        return this.checkMaskOperation(2, gridRectangles, transition);
      case 3:
        return this.checkMaskOperation(3, gridRectangles, transition);
      default:
        return false;
    }
  }

  checkBooleanRule(input, currentVar, transition) {
    if (this.data.transitions[transition].booleanValue === input[currentVar]) {
      this.latestFrames[transition] = [...this.latestFrames[transition].slice(1), 1];
      if (this.latestFrames[transition].reduce((val, acc) => val + acc) === this.data.transitions[transition].minimumFrames) {
        this.setNewActiveCircle(transition);
        return true;
      }
    } else {
      this.latestFrames[transition] = [...this.latestFrames[transition].slice(1), 0];
    }
    return false;
  }

  checkIntegerOperation(operatorIndex, input, transition, currentVar) {
    const statements = [
      () => input[currentVar] > this.data.transitions[transition].integerValue - 1,
      () => input[currentVar] < this.data.transitions[transition].integerValue - 1,
      () => input[currentVar] === this.data.transitions[transition].integerValue - 1,
      () => input[currentVar] !== this.data.transitions[transition].integerValue - 1,
    ];

    if (statements[operatorIndex]()) {
      this.latestFrames[transition] = [...this.latestFrames[transition].slice(1), 1];
      if (this.latestFrames[transition].reduce((val, acc) => val + acc) === this.data.transitions[transition].minimumFrames) {
        this.setNewActiveCircle(transition);
        return true;
      }
    } else {
      this.latestFrames[transition] = [...this.latestFrames[transition].slice(1), 0];
    }
    return false;
  }

  checkIntegerRule(input, currentVar, transition) {
    if (currentVar === 'classIndex' && this.data.transitions[transition].integerValue > input.modelClassNames.length) {
      const msg = (
        <div>
          <div>O modelo treinado possui {input.modelClassNames.length} classes.</div>
          <div>Portanto não é possível acessar a classe de número {this.data.transitions[transition].integerValue}</div>
          <div>É necessário redefinir o valor da classe nas condições de detecção.</div>
        </div>
      );
      toast.isActive(this.toastId) ? toast.update(this.toastId, { render: msg }) : (this.toastId = toast.error(msg));
      return false;
    } else {
      switch (this.data.transitions[transition].integerRule) {
        case 0:
          return this.checkIntegerOperation(0, input, transition, currentVar);
        case 1:
          return this.checkIntegerOperation(1, input, transition, currentVar);
        case 2:
          return this.checkIntegerOperation(2, input, transition, currentVar);
        case 3:
          return this.checkIntegerOperation(3, input, transition, currentVar);
        default:
          return false;
      }
    }
  }

  checkVar(input) {
    this.currentTransition = '';
    const states = {
      stateZero: this.data.stateZero,
      stateOne: this.data.stateOne,
      stateTwo: this.data.stateTwo,
      stateThree: this.data.stateThree,
    };
    this.activeState = Object.keys(states).find(el => this.data[el].active === true);
    if (this.activeState) {
      Object.keys(this.data.transitions).some(transition => {
        this.triggerOutput = false;
        let response = false;
        if (transition.startsWith(this.activeState) && this.data.transitions[transition] !== null) {
          if (!this.latestFrames[transition]) {
            this.latestFrames[transition] = Array(this.data.transitions[transition].latestFrames).fill(0);
          }
          switch (this.varOptions[this.data.transitions[transition].currentVar]?.type) {
            case 'gridArray':
              response = this.checkMaskRule(input.gridMask, transition);
              break;
            case 'bool':
              response = this.checkBooleanRule(input, this.data.transitions[transition].currentVar, transition);
              break;
            case 'integer':
              response = this.checkIntegerRule(input, this.data.transitions[transition].currentVar, transition);
              break;
            default:
              return false;
          }
          // console.log(transition.split('_')[0], this.latestFrames[transition]?.reduce((val, acc) => val+acc))
          return response;
        }
        return false;
      });
      return {
        activeState: this.activeState,
        triggerOutput: this.triggerOutput,
        currentTransition: this.currentTransition,
        outputCounter: this.outputCounter,
        data: this.data,
      };
    }
  }
}
