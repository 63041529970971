import React from 'react';
import { Card, CardContent, CircularProgress } from 'react-md';
import CameraConfigEngine from '../cameraConfigEngine';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ArrowDropDownSVGIcon } from '@react-md/material-icons';
import { DropdownMenu } from '@react-md/menu';
import { changeCamera, fetchCameras, setCapabilities } from '../../../../actions/cameraConfig';

class CameraPreview extends React.Component {
  capabilitiesInterval = null;
  constructor(props) {
    super(props);
    this.state = {
      currentCamera: '',
      lastCamera: null,
      camerasDropdown: [],
      loading: false,
    };
  }

  generateDropdown() {
    let camerasDropdown = [];
    this.props.allCameras.forEach((camera, index) => {
      camerasDropdown.push({
        children: <div className='dropdown-item'>{camera.name}</div>,
        onClick: () => {
          this.setState({ loading: true }, () => this.props.changeCamera(camera, true));
        },
      });
      camerasDropdown.push('separator');
    });
    this.setState({ camerasDropdown: camerasDropdown });
  }

  getCapabilitiesFromVideo() {
    if (this.props.alreadyGotCapabilities) {
      clearInterval(this.capabilitiesInterval);
    }
    if (!this.props.alreadyGotCapabilities && this.props.newCameraRef?.type === 'systemCam') {
      const videoProperties = this.props.newCameraRef.getVideoCapabilities();
      let newOrderedProperties = {
        capabilities: {},
        settings: {},
      };
      this.props.capabilitiesAllowed.forEach(capability => {
        if (videoProperties['capabilities'].hasOwnProperty(capability.name) && videoProperties['settings'].hasOwnProperty(capability.name)) {
          newOrderedProperties['capabilities'][capability.name] = {};
          newOrderedProperties['capabilities'][capability.name].data = videoProperties['capabilities'][capability.name];
          newOrderedProperties['capabilities'][capability.name].label = capability.label;
          newOrderedProperties['capabilities'][capability.name].type = capability.type;
          newOrderedProperties['settings'][capability.name] = videoProperties['settings'][capability.name];
        }
      });
      this.props.setCapabilities(newOrderedProperties);
    }
  }

  applyVideoSettings(settingsToSave) {
    if (!!this.props.newCameraRef && this.props.newCameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.newCameraRef.applySettings(settingsToSave);
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps.allCameras) !== JSON.stringify(this.props.allCameras)) {
      this.generateDropdown();
    }
    if (this.props.currentCamera !== null && JSON.stringify(prevProps.currentCamera) !== JSON.stringify(this.props.currentCamera)) {
      if (this.props.cameraError) {
        this.setState({ currentCamera: { name: '\\_CamError_/', data: { type: 'systemCam', id: null } } });
      } else {
        this.setState({ currentCamera: this.props.currentCamera });
      }
    }
    if (prevProps.cameraAppliedSuccess !== this.props.cameraAppliedSuccess) {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.props.changeCamera({ name: '///', data: { type: 'systemCam', id: null, interval: null } });
    this.generateDropdown();
  }

  render() {
    let cameraType = '';
    switch (this.props.newCameraRef?.type) {
      case 'systemCam':
        cameraType = 'Integrada';
        break;
      case 'IPCam':
        cameraType = 'IP';
        break;
      case 'imagesCam':
        cameraType = 'de Imagens';
        break;
      case 'videoCam':
        cameraType = 'de Vídeo';
        break;
      default:
        cameraType = '';
        break;
    }
    return (
      <div className='camera-preview flex flex-center small-preview'>
        <Card className='camera-card-preview'>
          <div className='title-card-text w-100 flex flex-between small-card-title' style={{ alignItems: 'center' }}>
            {`Câmera ${cameraType}`}
            <div style={{ position: 'relative' }}>
              <DropdownMenu
                disabled={this.state.camerasDropdown.length < 1}
                style={{
                  color: this.state.currentCamera?.name === '\\_CamError_/' ? '#b20029' : '#223f53',
                  border: this.state.currentCamera?.name === '\\_CamError_/' ? '3px solid #b20029' : '2px solid #223f53',
                  display: this.state.loading ? 'none' : 'flex',
                  fontWeight: 'bold',
                  textTransform: 'unset',
                  height: '36px',
                  width: '130px',
                }}
                className='out-button flex-between camera-preview-dropdown-button'
                themeType='outline'
                id='dropdownCameras'
                anchor={{ x: 'inner-right', y: 'below' }}
                items={this.state.camerasDropdown}
                dropdownIcon={<ArrowDropDownSVGIcon />}
              >
                <p className='dropdown-content'>{this.state.currentCamera?.name || 'Cam Config.'}</p>
              </DropdownMenu>
              {this.state.loading && (
                <div
                  className='flex flex-center'
                  style={{
                    borderRadius: '0.5rem',
                    cursor: 'not-allowed',
                    width: '130px',
                    height: '36px',
                    backgroundColor: 'transparent',
                    border: '2px solid #223f53',
                  }}
                >
                  <CircularProgress
                    id={`cameraDialogSaveButton`}
                    className='determinate-example-circular camera-preview-loading'
                    centered={true}
                    style={{ borderColor: 'white', height: '85%' }}
                  />
                </div>
              )}
            </div>
          </div>
          <CardContent className='camera-preview-content'>
            <div className='full-size flex flex-column grown' style={{ alignItems: 'center' }}>
              <div className='w-100 flex grown' style={{ maxHeight: '100%', background: 'black' }}>
                <div className='flex w-100' style={{ maxHeight: '100%', backgroundColor: 'black' }}>
                  <CameraConfigEngine
                    cameraConfigRef={this.props.newCameraRef}
                    getCapabilitiesFromVideo={() => {
                      if (this.capabilitiesInterval === null && !this.props.alreadyGotCapabilities) {
                        this.getCapabilitiesFromVideo();
                        this.capabilitiesInterval = setInterval(this.getCapabilitiesFromVideo.bind(this), 1000);
                      }
                    }}
                    settings={this.props.settings}
                    applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
                  />
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentCamera: state.cameraConfig.currentCamera,
  allCameras: state.cameraConfig.allCameras,
  newCameraRef: state.cameraConfig.newCameraRef,
  cameraError: state.cameraConfig.cameraError,
  loadingButtons: state.cameraConfig.loadingButtons,
  capabilitiesAllowed: state.cameraConfig.capabilitiesAllowed,
  settings: state.cameraConfig.settings,
  cameraAppliedSuccess: state.cameraConfig.cameraAppliedSuccess,
  alreadyGotCapabilities: state.cameraConfig.alreadyGotCapabilities,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCameras,
      changeCamera,
      setCapabilities,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CameraPreview);
