import React from 'react';
import GeneralCamConfigContent from './generalCamConfigContent';
import InputRange from 'react-input-range';
import Toggle from 'react-toggle';
import { get_devices } from '../../../../../camera';
import { Select, ExpandMoreFontIcon, ExpandLessFontIcon } from 'react-md';

export default class SystemCamConfig extends React.Component {
  toastId = null;

  constructor(props) {
    super(props);
    this.state = {
      devices: [],
      advancedOptionsExpanded: false,
    };
    this.changeAdvancedOptionsExpansion = this.changeAdvancedOptionsExpansion.bind(this);
  }

  changeAdvancedOptionsExpansion() {
    this.setState(prevState => ({ advancedOptionsExpanded: !prevState.advancedOptionsExpanded }));
  }

  getCameraData(newParamsObject) {
    let content = { data: {} };
    content.data.type = 'systemCam';
    content.data.interval = this.props.systemCamInterval;
    content.data.id = newParamsObject.id || null;
    content.data = { ...content.data, ...newParamsObject };
    return content;
  }
  setCamera(buttonToLoad, cameraId) {
    const content = this.getCameraData({ id: cameraId });
    content.data.settings = this.props.settings;
    this.props.setCamera(this.props.camName, content, 0, buttonToLoad);
  }

  componentDidMount() {
    get_devices().then(res => {
      const newRes = [...res];
      newRes.forEach((device, index) => {
        device.value = device.deviceId;
      });
      this.setState({ devices: newRes });
    });
  }

  render() {
    const { advancedOptionsExpanded } = this.state;
    const { nonImageCameraSpeed } = this.props;
    const content = (
      <>
        <Select
          className='w-100 camera-card-text-field'
          disabled={this.state.devices.length < 1}
          style={{ height: '56px' }}
          theme='outline'
          id='systemCamConfigDeviceIdSelect'
          options={this.state.devices}
          value={this.props.currentCamera?.data?.id || '//'}
          name='capabilitiesSelect'
          label='Dispositivo'
          onChange={value => {
            if (value !== this.props.currentCamera?.data?.id) {
              this.props.applyCameraConfig(0, { ...this.getCameraData({ id: value }), name: this.props.camName });
            }
          }}
          disableMovementChange={true}
        />

        <div className='w-100 flex flex-center flex-wrap' id='systemCamConfigContent'>
          <span className='w-100 flex flex-start capabilities-span' style={{ alignItems: 'center' }}>
            Quantidade de quadros por segundo
          </span>
          <InputRange
            id='systemFPS'
            labelContainer='systemFPS'
            name='systemFPS'
            formatLabel={value => `${nonImageCameraSpeed[value]?.label || 'Média'}`}
            minValue={0}
            maxValue={4}
            value={
              nonImageCameraSpeed.findIndex(element => this.props.systemCamInterval === element.interval) === -1
                ? 2
                : nonImageCameraSpeed.findIndex(element => this.props.systemCamInterval === element.interval)
            }
            onChange={value => this.props.setParentState({ systemCamInterval: nonImageCameraSpeed[value]?.interval || 2 })}
            onChangeComplete={value =>
              this.props.applyCameraConfig(0, { ...this.getCameraData({ interval: nonImageCameraSpeed[value]?.interval || 2 }), name: this.props.camName })
            }
          />

          {/*VERSAO EM DROPDOWN*/}

          {/*<Select*/}
          {/*  id='systemCameraVisualizerSpeedSelect'*/}
          {/*  className='w-100 camera-card-text-field'*/}
          {/*  label='Tempo de visualização do quadro'*/}
          {/*  options={nonImageCameraSpeed.map(element => element.label)}*/}
          {/*  value={nonImageCameraSpeed.find(element => element.interval === this.props.systemCamInterval)?.label || 'Médio'}*/}
          {/*  onChange={value => {*/}
          {/*    const speed = nonImageCameraSpeed.find(element => element.label === value)?.interval || 15;*/}
          {/*    this.props.setParentState({ systemCamInterval: speed });*/}
          {/*    this.props.applyCameraConfig(0, { ...this.getCameraData({ interval: speed }), name: this.props.camName });*/}
          {/*  }}*/}
          {/*/>*/}
        </div>
        <div className={`advanced-camera-container`}>
          <div className='advanced-camera-title flex flex-center' onClick={this.changeAdvancedOptionsExpansion}>
            Opções avançadas
            {advancedOptionsExpanded ? <ExpandLessFontIcon /> : <ExpandMoreFontIcon />}
          </div>
          {advancedOptionsExpanded && this.props.currentCamera?.data?.type !== 'IPCam' ? (
            <div id='camConfigCapabilitiesContainer' className='w-100 flex flex-center flex-wrap system-cam-capabilities advanced-camera-properties'>
              {!!this.props.capabilities &&
                Object.keys(this.props.capabilities)?.map((key, index) => {
                  const element = this.props.capabilities[key];
                  if (element.type === 'array') {
                    return (
                      <div className='w-100 flex flex-start camera-config-zoom-80' key={index} style={{ margin: '0 0 16px 0', alignItems: 'center' }}>
                        <label
                          className='capabilities-span pointer text-ellipsis'
                          style={{ width: 'calc(100% - 70px)', alignItems: 'center', height: 'fit-content' }}
                          htmlFor={`${element.label}Toggle`}
                        >
                          {element.label}
                        </label>
                        <Toggle
                          id={`${element.label}Toggle`}
                          disabled={element.data.length < 1}
                          className='custom-toggle'
                          checked={this.props.settings[key] === 'continuous'}
                          icons={false}
                          onChange={() => this.props.setSettings({ ...this.props.settings, [key]: this.props.settings[key] === 'manual' ? 'continuous' : 'manual' })}
                        />
                      </div>
                    );
                  } else if (element.type === 'range') {
                    return (
                      <div className='w-100 flex flex-center flex-wrap' key={index}>
                        <span className='w-100 flex flex-start capabilities-span camera-config-zoom-80' style={{ alignItems: 'center' }}>
                          {element.label}
                        </span>
                        <InputRange
                          id='capabilitiesRange'
                          disabled={
                            (key === 'colorTemperature' && this.props.settings['whiteBalanceMode'] === 'continuous') ||
                            (key === 'exposureTime' && this.props.settings['exposureMode'] === 'continuous')
                          }
                          labelContainer={element.label}
                          name={key}
                          formatLabel={value => `${value}`}
                          step={element.data?.step}
                          maxValue={element.data?.max}
                          minValue={element.data?.min}
                          value={this.props.settings[key] || Math.round((element.data?.max - element.data?.min) / 2)}
                          onChange={value => this.props.setSettings({ ...this.props.settings, [key]: Number(value) })}
                        />
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
            </div>
          ) : (
            <></>
          )}
        </div>
      </>
    );

    return (
      <GeneralCamConfigContent
        parentContent={content}
        cameraType='Câmera Integrada'
        camVariable='systemCam'
        camName={this.props.camName}
        setParentState={content => this.props.setParentState(content)}
        cameraToParent={buttonToLoad => this.setCamera(buttonToLoad, this.props.currentCamera?.data?.id || null)}
        deleteCamera={() => this.props.deleteCamera(this.props.camName)}
        allCameras={this.props.allCameras}
        applyCameraConfig={() => this.props.applyCameraConfig(0, { ...this.getCameraData({ id: this.props.currentCamera?.data?.id }), name: this.props.camName })}
        loadingButtons={this.props.loadingButtons}
        card={0}
        cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
      />
    );
  }
}
