import * as lodash from 'lodash';

export class PreProcessingProcessor {
  constructor(data) {
    this.data = lodash.cloneDeep(data);
  }

  blur(mat, content) {
    let ksize = new window.cv.Size(content[0], content[1]);
    window.cv.GaussianBlur(mat, mat, ksize, content[2], content[3], window.cv.BORDER_DEFAULT);
    return mat;
  }

  clahe(mat, content) {
    window.cv.cvtColor(mat, mat, window.cv.COLOR_RGBA2GRAY, 0);
    let tileGridSize = new window.cv.Size(content[0], content[1]);
    let clahe = new window.cv.CLAHE(content[2], tileGridSize);
    clahe.apply(mat, mat);
    clahe.delete();
    return mat;
  }

  roi(mat, content) {
    // content = [x,y,w,h]
    let rect = new window.cv.Rect(
      Math.floor(content[0] * mat.cols),
      Math.floor(content[1] * mat.rows),
      Math.floor((content[2] - content[0]) * mat.cols),
      Math.floor((content[3] - content[1]) * mat.rows)
    );
    let newReference = mat.roi(rect);
    mat.delete();
    return newReference;
  }

  serialize() {
    return this.data;
  }

  process(mat) {
    if (this.data) {
      if (this.data.roi) {
        mat = this.roi(mat, this.data.roi);
      }
      if (this.data.clahe) {
        mat = this.clahe(mat, this.data.clahe);
      }
      if (this.data.blur) {
        mat = this.blur(mat, this.data.blur);
      }
    }
    return mat;
  }
}
