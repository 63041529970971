import axios from 'axios';
import { cameraDeserialize } from '../camera';
import { toast } from 'react-toastify';

export function setLoadingButton(buttonIndex, current) {
  return {
    type: 'APPBAR_SET_LOADING_BUTTON',
    file: { buttonIndex, current },
  };
}

export function createAppConfig(myCurrent, name) {
  return (dispatch, getState) => {
    const state = getState();
    const current = myCurrent || state.main.current;
    const appName = name || state[`${current}SelectApp`].current?.name;

    let postProcessingData = null;
    if (state.cameraConfig.postProcessingProcessor) {
      postProcessingData = state.cameraConfig.postProcessingProcessor.serialize();
      postProcessingData.stateZero.active = true;
      postProcessingData.stateOne.active = false;
      postProcessingData.stateTwo.active = false;
      postProcessingData.stateThree.active = false;
    }
    const defaultObject = {
      camera: state.cameraConfig?.currentCamera?.name || 'default',
      apptype: current,
      preprocessing: !!state.cameraConfig.preProcessingProcessor ? { data: state.cameraConfig.preProcessingProcessor.serialize() } : { data: null },
      customdata: {
        appGrid: {
          thumbs: current === 'appGrid' ? state.appGrid.thumbs : null,
          grids: current === 'appGrid' ? state.appGrid.grids : null,
          modelUrl: current === 'appGrid' ? state.appGrid.modelUrl : '',
        },
        appFace: {
          people: current === 'appFace' ? state.appFace.people : null,
        },
        appCode: {
          scriptContent: current === 'appCode' ? state.appCode.scriptContent : null,
        },
        appClass: {
          classes: current === 'appClass' ? state.appClass.classes : null,
          modelUrl: current === 'appClass' ? state.appClass.modelUrl : '',
          modelClassNames: current === 'appClass' ? state.appClass.modelClassNames : null,
        },
        appQuality: {
          preprocessing: current === 'appQuality' ? state.appQuality.preprocessing : null,
          identification: current === 'appQuality' ? state.appQuality.identification : null,
          temporaryRoi: current === 'appQuality' ? { roiX: 0, roiY: 0, roiWidth: 100, roiHeight: 50, blockedRoi: state.appQuality.temporaryRoi.blockedRoi } : null,
        },
      },
      postprocessing: { data: postProcessingData },
      output: !!state.cameraConfig.outputProcessor ? { data: state.cameraConfig.outputProcessor.serialize() } : { data: null },
    };

    axios
      .put(`${state.appGrid.url}/api/appconfig/${current}/${appName}`, defaultObject)
      .then(res => {
        res.data = { ...res.data, newAppName: appName, current: current };
        dispatch({
          type: 'APPBAR_CREATE_APP_CONFIG',
          file: res,
        });
      })
      .catch(err => {
        err.data = { ...err.data, current: current };
        dispatch({
          type: 'APPBAR_CREATE_APP_CONFIG',
          file: err,
          error: true,
        });
      });
  };
}

export function appConfigDeletionControl(param) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPBAR_APP_CONFIG_DELETION_CONTROL',
      file: param,
      needsConfirmation: true,
    });
  };
}

export function deleteAppConfig(myCurrent, name) {
  return (dispatch, getState) => {
    const current = myCurrent || getState().main.current;
    const selectApp = `${current}SelectApp`;
    const appConfigName = name || getState()[selectApp].current?.name;
    axios
      .delete(`${getState().appGrid.url}/api/appconfig/${current}/${appConfigName}`)
      .then(res => {
        res.data = { ...res.data, current: current, selectApp: selectApp, appConfigName: appConfigName };
        dispatch({
          type: 'APPBAR_DELETE_APP_CONFIG',
          file: res,
        });
      })
      .catch(err => {
        err.data = { ...err.data, current: current };
        dispatch({
          type: 'APPBAR_DELETE_APP_CONFIG',
          file: err,
          error: true,
        });
      });
  };
}

export function appConfigChangeControl(param) {
  return (dispatch, getState) => {
    dispatch({
      type: 'APPBAR_APP_CONFIG_CHANGE_CONTROL',
      file: param,
      needsConfirmation: true,
    });
  };
}

export function changeAppConfig(myCurrent, appName, appIndex) {
  return (dispatch, getState) => {
    const current = myCurrent || getState().main.current;
    const allCameras = getState().cameraConfig.allCameras;
    const selectApp = `${current}SelectApp`;
    axios
      .get(`${getState().appGrid.url}/api/appconfig/${current}/${appName}`)
      .then(res => {
        res.data = { ...res.data, current: current, selectApp: selectApp, appIndex: appIndex };
        let newCamera = allCameras.find(camera => camera.name === res.data.camera);
        if (!newCamera || !newCamera.data || Object.keys(newCamera.data).length === 0) {
          newCamera = {};
          newCamera.name = 'default';
          newCamera.data = { type: 'systemCam', id: null, interval: null };
        }
        res.data.newCurrentCamera = newCamera;
        cameraDeserialize(newCamera.data)
          .then(cameraRef => {
            res.data.cameraRef = cameraRef;
            dispatch({
              type: 'APPBAR_CHANGE_APP_CONFIG',
              file: res,
            });
          })
          .catch(errorMessage => {
            toast.error(errorMessage);
            res.data.camError = { message: errorMessage, current: current };
            dispatch({
              type: 'APPBAR_CHANGE_APP_CONFIG',
              file: res,
            });
          });
      })
      .catch(err => {
        err.data = { ...err.data, current: current };
        dispatch({
          type: 'APPBAR_CHANGE_APP_CONFIG',
          file: err,
          error: true,
        });
      });
  };
}

export function fetchAppConfig(param) {
  return async (dispatch, getState) => {
    try {
      const current = param || getState().main.current;
      axios
        .get(`${getState().appGrid.url}/api/appconfig?apptype=${current}`)
        .then(res => {
          const data = { name: res.data, current: current };
          dispatch({
            type: 'APPBAR_FETCH_APPCONFIG',
            file: { data },
          });
          return Promise.resolve(true);
        })
        .catch(err => {
          // err.data = { ...err.data, current: current };
          const data = { current: current };
          dispatch({
            type: 'APPBAR_FETCH_APPCONFIG',
            file: { data },
            error: true,
          });
          return Promise.reject(false);
        });
    } catch (e) {
      return false;
    }
  };
}

export function resetCurrentAppConfig(current) {
  return dispatch => {
    dispatch({
      type: 'APPBAR_RESET_CURRENT_APP_CONFIG',
      file: `${current}SelectApp`,
    });
  };
}
