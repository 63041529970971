import { defaultScript, requireTokenRoutes, forbiddenTokenRoutes, capabilitiesAllowed } from './initValues';

export const defaultState = {
  main: {
    appConnected: null, // means that app is currently connected to the Internet
    applicationIsOnline: null, // means that we are in 'offlineMode', using url with offline param
    offlineFromLink: false,
    availableApps: ['appGrid', 'appFace', 'appCode', 'appEPI', 'appClass', 'appQuality'],
    cvloaded: false,
    current: 'apps',
    menuEnabled: false,
    newAppDialogEnabled: false,
    tutorialDone: false,
    creatingAppConfig: false,
    interceptorInstance: null,
    requireTokenRoutes: [...requireTokenRoutes],
    forbiddenTokenRoutes: [...forbiddenTokenRoutes],
    httpOutputAxios: null,
    // postProcessingVarOptions => controlled at PostProcessingProcessor class
    localDataBaseUpdated: -1,
    localLoadingApp: -1,
    localLoadingButton: -1,
    confirmationDialogVisible: false,
    confirmationDialogAnswer: null,
    confirmationDialogText: '',
    deletionControl: false,
    changeControl: false,
  },
  cameraConfig: {
    allCameras: [],
    currentCamera: null,
    newCameraRef: null,
    pendingSave: false,
    cameraError: false,
    // loading button to each cameraType
    loadingButtons: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
    capabilities: null,
    settings: null,
    capabilitiesAllowed: capabilitiesAllowed,
    cameraAppliedSuccess: 0,
    preProcessingProcessor: null,
    postProcessingProcessor: null,
    outputProcessor: null,
    cameraDeletionControl: false,
    alreadyGotCapabilities: false,
    camerasFetched: false,
    temporaryInputImages: [],
    temporaryVideoInput: null,
    cameraToastId: null,
    cameraCardSaveDisabled: [true, true, true, true],
  },
  user: {
    logged: false,
    accessToken: '',
    refreshToken: '',
    firstName: '',
    email: '',
    uuid: '',
    isStaff: false,
    expirationDate: '',
  },
  appGrid: {
    gridSize: 15,
    thumbs: [],
    grids: [],
    numEpochs: 15,
    url: 'REACT_APP_BACKEND_URL' in process.env ? process.env.REACT_APP_BACKEND_URL : 'https://backend-staging.mvisia.com.br',
    apiKey: 'aw984ra9wigjqw984tq9q28g9q8jf2938jfc',
    training: false,
    trainId: '',
    sentImages: 0,
    prepared: false,
    epochs: -1, // model not set for training
    modelFit: false,
    modelUrl: '',
    trainingCompleted: true,
    someError: false,
    modelData: {}, // mensagem e a flag que indica
    // model and dataset parameter selection
    modelParams: {},
    datasetParams: {},
    noCapture: true,
    currentThumbImg: '',
    currentThumbIndex: -1,
    currentAccuracy: 0,
    pendingSave: false,
    preTraining: false,
  },
  appFace: {
    people: [],
    pendingSave: false,
  },
  appCode: {
    defaultScript: defaultScript,
    scriptContent: defaultScript,
    pendingSave: false,
  },
  appEPI: {
    pendingSave: false,
  },
  appQuality: {
    pendingSave: false,
    tabsRefs: [null, null, null, null, null, null, null],
    temporaryRoi: { roiX: 0, roiY: 0, roiWidth: 100, roiHeight: 50, blockedRoi: false },
    preprocessing: {
      blur: { active: true, kernel0: 3, kernel1: 3, sigmaX: 1.5, sigmaY: 1.5 },
      clahe: { active: true, grid0: 15, grid1: 15, clipLimit: 2 },
      roi: { active: false, roiCoordinates: [] },
    },
    identification: {
      binarization: {
        active: true,
        currentBinarizationMethod: 'rgbBinarization',
        red: { min: 0, max: 255 },
        green: { min: 0, max: 255 },
        blue: { min: 0, max: 162 },
      },
      filtering: {
        active: true,
        currentMorphologicalOperator: 'erosion',
        currentMorphologicalElement: 'ellipsis',
        morphologicalSizeX: 1,
        morphologicalSizeY: 1,
      },
      detection: {
        active: true,
        minimalArea: 2,
        currentMovementDirection: 'upDown',
        linePosition: 50,
        maxDistanceX: 10,
        maxDistanceY: 10,
      },
    },
  },
  appClass: {
    currentClassIndex: 0,
    noCapture: true,
    currentThumbImg: '',
    currentThumbIndex: -1,
    showingImageClassIndex: -1,
    classes: [
      { name: 'Classe1', images: [] },
      { name: 'Classe2', images: [] },
      { name: 'Classe3', images: [] },
      { name: 'Classe4', images: [] },
      { name: 'Classe5', images: [] },
    ],
    modelClassNames: [],
    numEpochs: 15,
    apiKey: 'aw984ra9wigjqw984tq9q28g9q8jf2938jfc',
    training: false,
    trainId: '',
    sentImages: 0,
    prepared: false,
    epochs: -1,
    modelFit: false,
    modelUrl: '',
    trainingCompleted: true,
    someError: false,
    modelData: {},
    modelParams: {},
    datasetParams: {},
    currentAccuracy: 0,
    pendingSave: false,
    preTraining: false,
  },
  //  0 => idle
  //  1 => requesting
  //  -1 => forbidden
  savingApp: {
    appGrid: [0, 0, 0, 0],
    appFace: [0, 0, 0, 0],
    appCode: [0, 0, 0, 0],
    appEPI: [0, 0, 0, 0],
    appClass: [0, 0, 0, 0],
    appQuality: [0, 0, 0, 0],
  },
  appGridSelectApp: {
    current: null,
    apps: [],
    lastAppConfigLocallySaved: null,
  },
  appFaceSelectApp: {
    current: null,
    apps: [],
    lastAppConfigLocallySaved: null,
  },
  appCodeSelectApp: {
    current: null,
    apps: [],
    lastAppConfigLocallySaved: null,
  },
  appEPISelectApp: {
    current: null,
    apps: [],
    lastAppConfigLocallySaved: null,
  },
  appClassSelectApp: {
    current: null,
    apps: [],
    lastAppConfigLocallySaved: null,
  },
  appQualitySelectApp: {
    current: null,
    apps: [],
    lastAppConfigLocallySaved: null,
  },
  colors: ['rgba(0,0,0,0)', 'rgba(0,0,255,0.25)', 'rgba(255,0,0,0.25)', 'rgba(0,255,0,0.25)', 'rgba(255,255,0,0.25)'],
  licenses: {
    groupsList: undefined,
    licensesList: undefined,
  },
};
