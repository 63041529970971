import React from 'react';
import { bindActionCreators } from 'redux';
import * as appGridActions from '../../../../actions/appGrid';
import { connect } from 'react-redux';

class Thumb extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.currentThumbIndex !== this.props.index && nextProps.currentThumbIndex !== this.props.index) {
      if (this.props.thumb !== nextProps.thumb || this.props.grid !== nextProps.grid) {
        return true;
      }
      return false;
    }
    return true;
  }

  render() {
    return (
      <div
        className='fitted'
        id={`secondStep-${this.props.index}`}
        style={{ position: 'relative', cursor: 'pointer' }}
        onClick={() => {
          this.props.updateThumbData({
            currentThumbImg: this.props.thumb,
            currentThumbIndex: this.props.index,
            noCapture: false,
          });
          if (this.props.interactWithTour) {
            this.props.nextStep();
          }
        }}
      >
        <img
          className='thumb-image-four flex'
          style={{
            maxWidth: '100px',
            maxHeight: '100px',
            height: 'auto',
            border: this.props.currentThumbIndex === this.props.index ? '3px solid black' : 'none',
          }}
          src={this.props.thumb}
        />
        {this.props.grid !== null ? (
          <div className={`grid-${this.props.gridSize} full-size`} style={{ borderRadius: '6px' }}>
            {this.props.grid.map((el, index) => {
              return <div style={{ background: this.props.colors[el] }} key={index} />;
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  colors: state.colors,
  gridSize: state.appGrid.gridSize,
  currentThumbIndex: state.appGrid.currentThumbIndex,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...appGridActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Thumb);
