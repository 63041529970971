import React from 'react';
import '../../applyingModel.scss';
import './classSection.scss';
import { Card, CardContent, Button, TextField, DropdownMenu, ArrowBackSVGIcon, ArrowForwardSVGIcon, MoreVertSVGIcon, AddSVGIcon, BlockSVGIcon } from 'react-md';
import { DesktopOnly, MobileOnly } from '@react-md/utils';
import ClassEngine from '../classEngine';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import CameraDialog from '../../../cameraDialog/cameraDialog';
import PreProcessingDialog from '../../../preProcessing/preProcessingDialog';
import { captureImage, setCurrentClassIndex, changeClassName, setClassModelUrl } from '../../../../actions/appClass';
import PostProcessingDialog from '../../../postProcessing/postProcessingDialog';
import OutputDialog from '../../../output/outputDialog';
import { changeCamera } from '../../../../actions/cameraConfig';

class ClassSection extends React.Component {
  toastId = null;
  msg = 'Nome de classe já existente.';
  constructor(props) {
    super(props);
    this.state = {
      classNames: props.classNames,
      modelFetched: false,
      testingModel: false,
      cameraRendered: false,
      picTrigger: false,
      cameraModalEnabled: false,
      preProcessingModalEnabled: false,
      outputModalEnabled: false,
      postProcessingModalEnabled: false,
      activeCircle: 'StateZero',
      controlledModelUrl: '',
      predictedClass: '',
    };
    this.captureImageButtonRef = React.createRef();
  }
  applyVideoSettings(settingsToSave) {
    if (!!this.props.cameraRef && this.props.cameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.cameraRef.applySettings(settingsToSave);
    }
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.classNames !== nextProps.classNames) {
      this.setState({ classNames: nextProps.classNames });
    }
    if (this.props.modelUrl !== nextProps.modelUrl) {
      if (!!nextProps.modelUrl) {
        this.setState({ modelFetched: false, controlledModelUrl: nextProps.modelUrl });
      } else {
        this.setState({ testingModel: false, controlledModelUrl: nextProps.modelUrl });
      }
    }
    return true;
  }

  componentDidMount() {
    if (this.props.modelUrl) {
      this.setState({ controlledModelUrl: this.props.modelUrl });
    }
  }

  render() {
    const dropItems = !this.props.cameraRef
      ? [{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]
      : [
          { children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) },
          { children: 'Pré-processamento', onClick: () => this.setState({ preProcessingModalEnabled: true }) },
          { children: 'Condições de Detecção', onClick: () => this.setState({ postProcessingModalEnabled: true }) },
          { children: 'Configurações de saída', onClick: () => this.setState({ outputModalEnabled: true }) },
        ];
    return (
      <>
        {this.state.cameraModalEnabled ? (
          <CameraDialog saveThumbsFromImageCam='appClass' enabled={this.state.cameraModalEnabled} close={() => this.setState({ cameraModalEnabled: false })} />
        ) : null}

        <PreProcessingDialog enabled={this.state.preProcessingModalEnabled} close={() => this.setState({ preProcessingModalEnabled: false })} />
        <PostProcessingDialog
          activeCircle={this.state.activeCircle}
          // Example:
          // gridMask: {label: 'Máscara', type: 'gridArray'},
          // hasFace: {label: 'Tem rosto', type: 'bool'},
          // hasEPI: {label: 'Tem epi', type: 'bool'},
          // hasLALA: {label: 'Tem LALA', type: 'bool'},
          // classIndex: {label: 'Número da Classe', type: 'integer', max: 5, min: 1}
          // classIndex: {label: 'Número da Classe', type: 'integer'} => min value will be 0 and max value will be unlimited

          varOptions={{ classIndex: { label: 'Número da Classe', type: 'integer', max: 5, min: 1 } }}
          enabled={this.state.postProcessingModalEnabled}
          close={() => this.setState({ postProcessingModalEnabled: false })}
        />
        <OutputDialog enabled={this.state.outputModalEnabled} close={() => this.setState({ outputModalEnabled: false })} />
        <Card className={`flex curve-border-2 text-center flex-column h-100 scroll appClass-class-section fill-when-small ${this.props.applicationIsOnline ? '' : 'w-100'}`}>
          <div className='title-card-text w-100 flex flex-between'>
            <MobileOnly>
              Inferência
              <DropdownMenu
                id='camOptions'
                buttonType='icon'
                aria-label='camOptions'
                items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
              >
                <MoreVertSVGIcon />
              </DropdownMenu>
            </MobileOnly>
            <DesktopOnly>
              {this.props.applicationIsOnline ? (
                <>
                  Exibição
                  <DropdownMenu id='camOptions' buttonType='icon' aria-label='camOptions' items={dropItems}>
                    <MoreVertSVGIcon />
                  </DropdownMenu>
                </>
              ) : (
                <>
                  Inferência
                  <DropdownMenu
                    id='camOptions'
                    buttonType='icon'
                    aria-label='camOptions'
                    items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
                  >
                    <MoreVertSVGIcon />
                  </DropdownMenu>
                </>
              )}
            </DesktopOnly>
          </div>
          <CardContent className='flex flex-column grown' style={{ height: 'calc(100% - 70px)' }}>
            <div className='full-size flex flex-column grown' style={{ alignItems: 'center' }}>
              <div className='w-100 flex grown' style={{ height: 'calc(100% - 148px)', background: 'black' }}>
                <div className='flex w-100' style={{ backgroundColor: 'black' }}>
                  <ClassEngine
                    picTrigger={this.state.picTrigger}
                    testing={this.state.testingModel}
                    disablePicTrigger={() => this.setState({ picTrigger: false })}
                    modelFetched={value => {
                      if (value) {
                        this.setState({ modelFetched: value });
                      } else {
                        this.props.setClassModelUrl('');
                        toast.error('Não foi possível buscar o modelo. Tente novamente. ');
                      }
                    }}
                    cameraIsRendered={() => this.setState({ cameraRendered: true })}
                    modelUrl={this.state.controlledModelUrl}
                    cameraRef={this.props.cameraRef}
                    sendImage={image => this.props.captureImage(this.props.currentClassIndex, this.state.classNames[this.props.currentClassIndex], image)}
                    preProcessingProcessor={this.props.preProcessingProcessor}
                    postProcessingProcessor={this.props.postProcessingProcessor}
                    outputProcessor={this.props.outputProcessor}
                    activeCircle={this.state.activeCircle}
                    setActiveCircle={newActiveCircle => this.setState({ activeCircle: newActiveCircle })}
                    setCamera={camera => this.props.changeCamera(camera)}
                    modelClassNames={this.props.modelClassNames}
                    prediction={className => this.setState({ predictedClass: className })}
                    settings={this.props.settings}
                    applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
                  />
                </div>
              </div>
              <div className='w-100 flex flex-wrap buttons-container'>
                {this.state.testingModel ? (
                  <div className='flex flex-center w-100 flex-wrap predict-container'>
                    <div className='flex w-100 flex-center predicted-title'>Resultado</div>
                    <div className='flex flex-center predicted-class'>{this.state.predictedClass}</div>
                  </div>
                ) : (
                  <div className={`bottom-container flex w-100 flex-between hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}>
                    <Button
                      theme='secondary'
                      themeType='contained'
                      disabled={this.props.currentClassIndex < 1}
                      className='change-class-button'
                      onClick={() => {
                        if (this.props.currentClassIndex > 0) {
                          this.props.setCurrentClassIndex(this.props.currentClassIndex - 1);
                        }
                      }}
                    >
                      <ArrowBackSVGIcon />
                    </Button>
                    <TextField
                      id='classNameID'
                      autoComplete='off'
                      label={`Classe ${this.props.currentClassIndex + 1}`}
                      value={this.state.classNames[this.props.currentClassIndex]}
                      onChange={event => {
                        const newClasses = [...this.state.classNames];
                        newClasses[this.props.currentClassIndex] = event.target.value;
                        this.setState({ classNames: newClasses });
                      }}
                      onBlur={() => {
                        if (!this.state.classNames[this.props.currentClassIndex].replace(/\s+/g, '')) {
                          const newClasses = [...this.state.classNames];
                          newClasses[this.props.currentClassIndex] = this.props.classNames[this.props.currentClassIndex];
                          this.setState({ classNames: newClasses }, () =>
                            this.props.changeClassName(this.props.currentClassIndex, this.state.classNames[this.props.currentClassIndex])
                          );
                        } else {
                          const canUpdate = !this.props.classNames.find(
                            (element, index) => element === this.state.classNames[this.props.currentClassIndex].replace(/\s+/g, '') && index !== this.props.currentClassIndex
                          );
                          if (canUpdate) {
                            this.props.changeClassName(this.props.currentClassIndex, this.state.classNames[this.props.currentClassIndex].replace(/\s+/g, ''));

                            const newClasses = [...this.state.classNames];
                            newClasses[this.props.currentClassIndex] = newClasses[this.props.currentClassIndex].replace(/\s+/g, '');
                            this.setState({ classNames: newClasses });
                          } else {
                            toast.isActive(this.toastId) ? toast.update(this.toastId, { render: this.msg }) : (this.toastId = toast.error(this.msg));
                            this.setState({ classNames: this.props.classNames });
                          }
                        }
                      }}
                    />
                    <Button
                      theme='secondary'
                      themeType='contained'
                      disabled={this.props.currentClassIndex > 3 || this.props.classesImagesLength[this.props.currentClassIndex] === 0}
                      className='change-class-button'
                      onClick={() => {
                        if (this.props.currentClassIndex < 4 && this.props.classesImagesLength[this.props.currentClassIndex] > 0) {
                          this.props.setCurrentClassIndex(this.props.currentClassIndex + 1);
                        }
                      }}
                    >
                      {this.props.classesImagesLength[this.props.currentClassIndex + 1] > 0 ? (
                        <ArrowForwardSVGIcon />
                      ) : this.props.currentClassIndex + 1 !== this.props.classesImagesLength.length ? (
                        <AddSVGIcon />
                      ) : (
                        <BlockSVGIcon />
                      )}
                    </Button>
                  </div>
                )}

                <div className='w-100 flex flex-between capture-button-container'>
                  <Button
                    ref={this.captureImageButtonRef}
                    className={`appClass-capture-section-button hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}
                    disabled={
                      this.state.testingModel ||
                      this.state.picTrigger ||
                      !this.state.cameraRendered ||
                      !this.state.classNames[this.props.currentClassIndex].trim() ||
                      this.props.cameraRef?.type === 'imagesCam'
                    }
                    onClick={() => this.setState({ picTrigger: !this.state.picTrigger })}
                    theme='primary'
                    themeType='contained'
                  >
                    capturar
                  </Button>

                  {!!this.state.controlledModelUrl && !this.state.modelFetched ? (
                    <Button
                      disabled={true}
                      theme='primary'
                      themeType='contained'
                      className={`applyingModel appClass-capture-section-button fill-when-small ${this.props.applicationIsOnline ? '' : 'w-100'}`}
                    >
                      Aplicando
                      <span className='dot' />
                      <span className='dot' />
                      <span className='dot' />
                    </Button>
                  ) : (
                    <Button
                      className={`appClass-capture-section-button fill-when-small ${this.props.applicationIsOnline ? '' : 'w-100'}`}
                      onClick={() => this.setState({ testingModel: !this.state.testingModel })}
                      disabled={!this.state.modelFetched || !this.state.controlledModelUrl || !this.state.cameraRendered}
                      theme='primary'
                      themeType='contained'
                    >
                      {this.state.testingModel ? 'Interromper' : 'Rodar Modelo'}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  modelUrl: state.appClass.modelUrl,
  cameraRef: state.cameraConfig.newCameraRef,
  preProcessingProcessor: state.cameraConfig.preProcessingProcessor,
  postProcessingProcessor: state.cameraConfig.postProcessingProcessor,
  outputProcessor: state.cameraConfig.outputProcessor,
  currentClassIndex: state.appClass.currentClassIndex,
  classNames: state.appClass.classes.map(element => element.name),
  modelClassNames: state.appClass.modelClassNames,
  classesImagesLength: state.appClass.classes.map(element => element.images.length),
  applicationIsOnline: state.main.applicationIsOnline,
  settings: state.cameraConfig.settings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeCamera,
      captureImage,
      changeClassName,
      setCurrentClassIndex,
      setClassModelUrl,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ClassSection);
