import React from 'react';
import './appGrid.scss';
import CaptureSection from './sections/captureSection';
import DataSection from './sections/dataSection';
import MarkingSection from './sections/markingSection';
import Tour from 'reactour';
import { Button } from 'react-md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetCurrentAppConfig } from '../../../actions/appBar';
import { setPreProcessingProcessor } from '../../../actions/preProcessing';
import { setPostProcessingProcessor } from '../../../actions/postProcessing';
import { setOutputProcessor } from '../../../actions/output';
import { dismissOldCam, resetCameraConfig } from '../../../actions/cameraConfig';
import { removeThumbData, resetModelParams } from '../../../actions/appGrid';
import { finishTutorial } from '../../../actions/main';

class AppGrid extends React.Component {
  canEnableTour = true;
  secondStepUnlocked = false;
  steps = [
    {
      // step0 => centered on screen, no selector needed
      selector: '',
      content: ({ goTo, inDOM }) => (
        <div className='flex flex-center flex-wrap'>
          <h3>Tutorial: Plataforma Mvisia</h3>
          <p>Neste tutorial iremos desenvolver uma aplicação para identificar seu rosto. Siga os próximos passos para entender como nossa plataforma funciona.</p>
          <p>Pedimos que você responda o formulário ao final para nos ajudar a desenvolver este produto. Seu feedback é muito importante para nós!</p>
          <Button themeType='contained' theme='secondary' onClick={() => this.setState({ tourStep: 1 })}>
            Próximo
          </Button>
        </div>
      ),
    },
    {
      selector: '.firstStep',
      content: (
        <div>
          <p>Capture uma imagem clicando neste botão.</p>
          {/*<div*/}
          {/*  onClick={() => {*/}
          {/*    console.log('going to final');*/}
          {/*    this.setState({ tourStep: 9, isTourOpen: false });*/}
          {/*  }}*/}
          {/*  className='skip-tutorial'*/}
          {/*>*/}
          {/*  skip >>*/}
          {/*</div>*/}
        </div>
      ),
    },
    {
      selector: '#secondStep-0',
      content: <p>Clique na imagem capturada para treinar o algoritmo.</p>,
    },
    {
      selector: '#thirdStep',
      content: <p>Use uma cor (por exemplo a "vermelha") para pintar o background (tudo que não for o seu rosto). </p>,
    },
    {
      selector: '.fourthStep',
      content: ({ goTo, inDOM }) => (
        <div className='flex flex-center flex-wrap'>
          <p>Siga o exemplo abaixo:</p>
          <img alt='red painting example' style={{ maxWidth: '100%', borderRadius: '3px' }} src={process.env.PUBLIC_URL + '/img/redBackground.png'} />
          <Button style={{ margin: '1em' }} disabled={this.state.proceedButtons !== 4} themeType='contained' theme='secondary' onClick={() => this.setState({ tourStep: 5 })}>
            Próximo
          </Button>
        </div>
      ),
    },
    {
      selector: '#fifthStep',
      content: <p>Use uma cor (por exemplo a "azul") para pintar o seu rosto.</p>,
    },
    {
      selector: '.sixthStep',
      content: ({ goTo, inDOM }) => (
        <div className='flex flex-center flex-wrap'>
          <p>Siga novamente o exemplo abaixo:</p>
          <img alt='blue paiting example' style={{ maxWidth: '100%', borderRadius: '3px' }} src={process.env.PUBLIC_URL + '/img/redBackgroundBlueFace.png'} />
          <Button style={{ margin: '1em' }} disabled={this.state.proceedButtons !== 6} themeType='contained' theme='secondary' onClick={() => this.setState({ tourStep: 7 })}>
            Próximo
          </Button>
        </div>
      ),
    },
    {
      selector: '.seventhStep',
      content: ({ goTo, inDOM }) => (
        <div className='flex flex-center flex-wrap'>
          <p>Capture mais algumas fotos (mínimo 12) e repita o procedimento anterior, pintando fundo (vermelho) e rosto (azul).</p>
          <Button themeType='contained' theme='secondary' onClick={() => this.setState({ tourStep: 8 })}>
            Próximo
          </Button>
        </div>
      ),
      stepInteraction: false,
    },
    {
      selector: '#eighthStep',
      content: ({ goTo, inDOM }) => (
        <div className='flex flex-center flex-wrap'>
          <p>Após marcar todas as imagens, clique aqui para treinar o algoritmo (pode demorar alguns minutos).</p>
          <Button themeType='contained' theme='secondary' onClick={() => this.setState({ tourStep: 9 })}>
            Próximo
          </Button>
        </div>
      ),
      stepInteraction: false,
    },
    {
      selector: '#ninthStep',
      content: ({ goTo, inDOM }) => (
        <div className='flex flex-center flex-wrap'>
          <p>Quando o treinamento estiver concluído, clique aqui para rodar o modelo e ver o resultado.</p>
          <Button
            themeType='contained'
            theme='secondary'
            onClick={() => {
              localStorage.setItem('trial', 'over');
              this.props.finishTutorial();
              this.setState({ tourStep: 10, isTourOpen: false });
            }}
          >
            Finalizar tutorial
          </Button>
        </div>
      ),
    },
    // ...
  ];
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: false,
      tourStep: 0,
      proceedButtons: -1,
    };
  }

  componentWillUnmount() {
    this.props.resetCurrentAppConfig('appGrid');
    this.props.setPreProcessingProcessor(null);
    this.props.setPostProcessingProcessor(null);
    this.props.setOutputProcessor(null);
    this.props.removeThumbData();
    this.props.resetModelParams();
    this.props.dismissOldCam();
    this.props.resetCameraConfig();
  }

  render() {
    return (
      <div className='background'>
        <div className='w-100 h-100 flex flex-between'>
          <CaptureSection
            interactWithTour={!this.props.tutorialDone}
            isTourOpen={this.state.isTourOpen}
            cameraIsRendered={() => {
              if (this.canEnableTour) {
                this.canEnableTour = false;
                this.setState({ isTourOpen: true });
              }
            }}
            unlockSecondStep={() => (this.secondStepUnlocked = true)}
          />
          <DataSection interactWithTour={!this.props.tutorialDone && this.state.isTourOpen} nextStep={() => this.setState({ tourStep: this.state.tourStep + 1 })} />
          <MarkingSection
            interactWithTour={!this.props.tutorialDone && this.state.isTourOpen}
            nextStep={() => {
              this.setState({ tourStep: this.state.tourStep + 1 });
            }}
            unlockProceedButton={() => {
              this.setState({ proceedButtons: this.state.tourStep });
            }}
          />
        </div>
        {!this.props.tutorialDone && this.state.isTourOpen ? (
          <Tour
            className='mask'
            accentColor={'#1a9c90'}
            badgeContent={(curr, tot) => `${curr}/${tot}`}
            steps={this.steps}
            isOpen={this.state.isTourOpen}
            showCloseButton={false}
            showButtons={false}
            showNavigation={false}
            disableDotsNavigation={true}
            disableKeyboardNavigation={true}
            rounded={4}
            goToStep={this.state.tourStep}
            maskSpace={6}
            onRequestClose={() => console.log('no')}
            highlightedMaskClassName={this.state.tourStep === 7 || this.state.tourStep === 8 || this.state.tourStep === 9 ? 'not-allowed' : ''}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tutorialDone: state.main.tutorialDone,
  logged: state.user.logged,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetCurrentAppConfig,
      setPreProcessingProcessor,
      setPostProcessingProcessor,
      setOutputProcessor,
      dismissOldCam,
      removeThumbData,
      finishTutorial,
      resetModelParams,
      resetCameraConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppGrid);
