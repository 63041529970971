export const requireTokenRoutes = [
  '/api/user',
  '/api/appconfig',
  '/api/appconfig?apptype=appGrid',
  '/api/appconfig?apptype=appFace',
  '/api/appconfig?apptype=appCode',
  '/api/appconfig?apptype=appEPI',
  '/api/appconfig?apptype=appClass',
  '/api/appconfig?apptype=appQuality',
  '/api/store_image',
  '/api/user/email_notification',
  '/api/camconfig',
  '/api/grid/get_lite_model',
  '/api/clf/get_lite_model',
  '/api/work-group',
];

export const forbiddenTokenRoutes = [
  // appClass
  '/clf/finish',
  '/clf/model_status',
  '/clf/train_epoch',
  '/clf/prepare',
  '/clf/send_image',
  '/clf/start_train',
  // appGrid
  '/grid/finish',
  '/grid/model_status',
  '/grid/train_epoch',
  '/grid/prepare',
  '/grid/send_image',
  '/grid/start_train',
  // main
  '/api/user/auth',
  // in future maybe...
  '/api/user/signup',
];

export const defaultScript =
  `// Bem Vindo(a)!\n// Utilize o template a seguir como base para seu desenvolvimento.\n` +
  '// ctrl+s para rodar o script e ctrl+p para interromper.\n' +
  '\nconst fps = 15\n\n' +
  'const loop = async ()  => {\n' +
  '  try {\n' +
  "    const img = readImage();\n    //Seu código começa aqui\n    console.log('fps', fps)\n    console.warn('fps', fps)\n\n\n\n\n" +
  '    //Exemplo: transforma a imagem para escala de cinza\n' +
  '    cv.cvtColor(img, img, cv.COLOR_RGBA2GRAY, 0);\n\n\n\n    \n    //Fim do código\n' +
  '    writeImage(img)\n' +
  '    img.delete()\n' +
  '  } catch(error) {\n    console.error(error.message)\n  }\n' +
  '  logRegister()\n' +
  '}\n\n' +
  'loopSchedule(loop, fps)';

export const capabilitiesAllowed = [
  { name: 'exposureMode', label: 'Exposição Automática', type: 'array' },
  { name: 'exposureTime', label: 'Tempo de Exposição', type: 'range' },
  { name: 'whiteBalanceMode', label: 'Balanço de Branco Automático', type: 'array' },
  { name: 'colorTemperature', label: 'Balanço de Branco', type: 'range' },
  { name: 'focusMode', label: 'Autofoco Automático', type: 'array' },
  { name: 'focusDistance', label: 'Posição Focal', type: 'range' },
  { name: 'brightness', label: 'Brilho', type: 'range' },
  { name: 'sharpness', label: 'Nitidez', type: 'range' },
  { name: 'contrast', label: 'Contraste', type: 'range' },
  { name: 'saturation', label: 'Saturação', type: 'range' },
];
