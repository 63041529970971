import React from 'react';
import FaceSection from './sections/faceSection/faceSection';
import InfoSection from './sections/infoSection/infoSection';
import { bindActionCreators } from 'redux';
import * as appBarActions from '../../../actions/appBar';
import * as preProcessingActions from '../../../actions/preProcessing';
import { connect } from 'react-redux';
import * as postProcessingActions from '../../../actions/postProcessing';
import * as outputActions from '../../../actions/output';
import './appFace.scss';
import { dismissOldCam, resetCameraConfig } from '../../../actions/cameraConfig';

class AppFace extends React.Component {
  componentWillUnmount() {
    this.props.resetCurrentAppConfig('appFace');
    this.props.setPreProcessingProcessor(null);
    this.props.setPostProcessingProcessor(null);
    this.props.setOutputProcessor(null);
    this.props.dismissOldCam();
    this.props.resetCameraConfig();
  }

  render() {
    return (
      <div className='background flex flex-between'>
        <div className='flex h-100 flex-column appFace-face-section fill-when-small'>
          <FaceSection />
        </div>
        <div className='h-100 flex flex-column appFace-info-section hide-when-small'>
          <InfoSection />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...appBarActions,
      ...preProcessingActions,
      ...postProcessingActions,
      ...outputActions,
      dismissOldCam,
      resetCameraConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppFace);
