import React from 'react';
import { Button, TextField } from 'react-md';
import { toast } from 'react-toastify';

export default class Clahe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grid0: props.clahe ? props.clahe[0].toString() : '15',
      grid1: props.clahe ? props.clahe[1].toString() : '15',
      clipLimit: props.clahe ? props.clahe[2].toString() : '2',
    };
  }
  removeTool() {
    this.props.setDialogState(null);
  }
  sendDataToDialog() {
    if (this.state.grid0 < 2 || this.state.grid1 < 2 || this.state.grid0 > 30 || this.state.grid1 > 30 || this.state.clipLimit < 0 || this.state.clipLimit > 5) {
      toast.error('Valores fora dos limites.');
    } else {
      this.props.setDialogState([Number(this.state.grid0), Number(this.state.grid1), Number(this.state.clipLimit)]);
    }
  }
  render() {
    return (
      <>
        <div>
          <div className='flex flex-wrap '>
            <div className='w-100 text-center' style={{ fontWeight: '600', color: 'dimgray', margin: '4px 0' }}>
              Title Grid Size
            </div>
            <div className='flex w-100 flex-center'>
              <TextField
                style={{ margin: '4px 4px 4px 0', flexGrow: '1' }}
                id='grid0'
                type='number'
                min={2}
                max={30}
                theme='outline'
                placeholder='Ex: 8'
                label='Grid'
                value={this.state.grid0}
                onChange={event => this.setState({ grid0: event.target.value })}
              />
              x
              <TextField
                style={{ margin: '4px 0 4px 4px', flexGrow: '1' }}
                id='grid1'
                type='number'
                min={2}
                max={30}
                theme='outline'
                placeholder='Ex: 8'
                label='Grid'
                value={this.state.grid1}
                onChange={event => this.setState({ grid1: event.target.value })}
              />
            </div>
          </div>
          <TextField
            style={{ margin: '8px 0 4px 0' }}
            id='clipLimit'
            type='number'
            min={0}
            max={5}
            theme='outline'
            placeholder='Ex: 40'
            label='Clip Limit'
            value={this.state.clipLimit}
            onChange={event => this.setState({ clipLimit: event.target.value })}
          />
        </div>
        <div className='flex flex-between w-100'>
          <Button style={{ width: '49%' }} theme='error' themeType='contained' onClick={() => this.removeTool()} disabled={this.props.clahe === null}>
            Remover
          </Button>
          <Button style={{ width: '49%' }} theme='secondary' themeType='contained' onClick={() => this.sendDataToDialog()}>
            Aplicar
          </Button>
        </div>
      </>
    );
  }
}
