import React from 'react';
import { Card } from 'react-md';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ImageGallery from 'react-image-gallery';
import * as appFaceActions from '../../../../../actions/appFace';
import './infoSection.scss';

class InfoSection extends React.Component {
  customRenderItem(data) {
    return (
      <div className='flex flex-center thumbnail-custom-container' style={{ position: 'relative' }}>
        <img className='w-100 h-100' src={data.thumbnail} />
        <img src={process.env.PUBLIC_URL + '/img/trash.svg'} className='trash' style={{ position: 'absolute', color: 'red', width: '70px', height: '70px' }} />
      </div>
    );
  }
  render() {
    return (
      <Card className='w-100 h-100 flex grown flex-wrap' style={{ alignContent: 'flex-start' }}>
        <div className='title-card-text w-100 flex flex-center'>Capturas</div>
        <div className='scroll w-100' style={{ height: 'calc(100% - 70px)' }}>
          {this.props.people.map((person, personIndex) => {
            return person.base64Faces.length > 0 ? (
              <div className='flex flex-wrap flex-center' style={{ height: 'fit-content', margin: '30px auto', width: 'calc(100% - 10px)' }} key={personIndex}>
                <div className='w-100 flex flex-start person-name'>{person.name}</div>
                <ImageGallery
                  disableSwipe={true}
                  additionalClass='w-100'
                  thumbnailPosition='top'
                  showFullscreenButton={false}
                  useBrowserFullscreen={false}
                  showPlayButton={false}
                  autoPlay={true}
                  showIndex={true}
                  showBullets={false}
                  items={person.base64Faces.map(photo => {
                    return { thumbnail: photo, renderThumbInner: this.customRenderItem.bind(this) };
                  })}
                  onThumbnailClick={(event, index) => {
                    event.preventDefault();
                    this.props.removeFace(personIndex, index);
                  }}
                />
              </div>
            ) : null;
          })}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  people: state.appFace.people,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...appFaceActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InfoSection);
