import React from 'react';
import { bindActionCreators } from 'redux';
import './outputDialog.scss';
import * as outputActions from '../../actions/output';
import { connect } from 'react-redux';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'react-md';
import Email from './options/email';
import Http from './options/http';
import { Checkbox } from '@react-md/form';
import { setLocale } from 'yup';
import yupLocation from '../yupLocation';
import { toast } from 'react-toastify';
import { OutputProcessor } from './outputProcessor';
import Notification from './options/notification';

let yup = require('yup');
setLocale(yupLocation);
const yupOptions = {
  abortEarly: false,
};

class OutputDialog extends React.Component {
  toastId = null;
  emailSchema = yup.object().shape({
    // Endereco: yup.string().required().email(),
    Assunto: yup.string().required(),
    Conteudo: yup.string().required(),
  });

  httpSchema = yup.object().shape({
    authNeeded: yup.boolean(),
    Endereco: yup.string().required(),
    Login: yup.string().when('authNeeded', {
      is: true,
      then: yup.string().required(),
    }),
    Senha: yup.string().when('authNeeded', {
      is: true,
      then: yup.string().required(),
    }),
    Login_Alias: yup.string().when('authNeeded', {
      is: true,
      then: yup.string().required(),
    }),
    Senha_Alias: yup.string().when('authNeeded', {
      is: true,
      then: yup.string().required(),
    }),
    Endereco_Token: yup.string().when('authNeeded', {
      is: true,
      then: yup.string().required(),
    }),
    Token_Alias: yup.string().when('authNeeded', {
      is: true,
      then: yup.string().required(),
    }),
  });
  constructor(props) {
    super(props);
    const output = props.output?.serialize();
    this.state = {
      currentTab: 'Notificacao',
      checkValue: { Email: output?.email.active || false, Http: output?.http.active || false, Notificacao: output?.notification.active || false },
      // email
      // emailAddress: '',
      emailTitle: output?.email.emailTitle || '',
      emailContent: output?.email.emailTitle || '',
      emailFieldError: [],
      // http
      httpAddress: output?.http.httpAddress || '',
      httpAuthType: output?.http.httpAuthType || 'Nula',
      httpLogin: output?.http.httpLogin || '',
      httpPassword: output?.http.httpPassword || '',
      httpLoginAlias: output?.http.httpLoginAlias || '',
      httpPasswordAlias: output?.http.httpPasswordAlias || '',
      httpTokenAlias: output?.http.httpTokenAlias || '',
      httpRequestType: output?.http.httpRequestType || 'POST',
      httpTokenAddress: output?.http.httpTokenAddress || '',
      httpTokenRequestType: output?.http.httpRequestType || 'POST',
      httpFieldError: [],
      //  Notification
      notificationPosition: output?.notification.notificationPosition || { 'top-left': 'Esquerda superior' },
    };
  }

  discardChanges() {
    this.setState({ httpFieldError: [], emailFieldError: [] });
    this.props.close();
  }
  sendData() {
    const sendingObject = { email: { active: false }, http: { active: false }, notification: { active: false } };
    sendingObject.email.active = this.state.checkValue.Email;
    // sendingObject.email.emailAddress = this.state.emailAddress;
    sendingObject.email.emailTitle = this.state.emailTitle;
    sendingObject.email.emailContent = this.state.emailContent;

    sendingObject.http.active = this.state.checkValue.Http;
    sendingObject.http.httpAddress = this.state.httpAddress;
    sendingObject.http.httpAuthType = this.state.httpAuthType;
    sendingObject.http.httpLogin = this.state.httpLogin;
    sendingObject.http.httpPassword = this.state.httpPassword;
    sendingObject.http.httpLoginAlias = this.state.httpLoginAlias;
    sendingObject.http.httpPasswordAlias = this.state.httpPasswordAlias;
    sendingObject.http.httpTokenAlias = this.state.httpTokenAlias;
    sendingObject.http.httpRequestType = this.state.httpRequestType;
    sendingObject.http.httpTokenAddress = this.state.httpTokenAddress;
    sendingObject.http.httpTokenRequestType = this.state.httpTokenRequestType;

    sendingObject.notification.active = this.state.checkValue.Notificacao;
    sendingObject.notification.notificationPosition = this.state.notificationPosition;
    this.props.setOutputProcessor(new OutputProcessor(JSON.parse(JSON.stringify(sendingObject))));
    toast.success('Configurações salvas com sucesso.');
    this.props.close();
  }
  async saveChanges() {
    // if (!this.state.checkValue['Email'] && !this.state.checkValue['Http'] ) {
    //   toast.isActive(this.toastId) ?
    //     toast.update('Ative pelo menos uma configuração de saída para poder salvar.', {type: 'error', autoClose: 4000})
    //     :
    //     this.toastId = toast.error('Ative pelo menos uma configuração de saída para poder salvar.');
    // }
    // else {
    const pass = [null, null, true];
    if (this.state.checkValue['Email']) {
      pass[0] = await this.validateEmailFields();
    }
    if (this.state.checkValue['Http']) {
      pass[1] = await this.validateHttpFields();
    }
    if (pass.find(el => el === false) !== false) {
      this.sendData();
    } else {
      toast.isActive(this.toastId)
        ? toast.update(this.toastId, { render: 'Saídas ativas contém campos inválidos.', type: 'error', autoClose: 4000 })
        : (this.toastId = toast.error('Saídas ativas contém campos inválidos.'));
    }
    // }
  }

  onVerify(data, typeFieldError, currentField) {
    let errorFound = false;
    if (currentField) {
      data.inner.some(el => {
        if (el.path === currentField) {
          errorFound = true;
          return true;
        }
        return false;
      });
    }
    if (!currentField || errorFound) {
      const errors = data.errors;
      const errorData = [];
      data.inner.forEach((el, index) => {
        errorData.push({ path: el.path, error: errors[index] });
      });
      this.setState({ [typeFieldError]: errorData });
    } else {
      this.setState({ [typeFieldError]: [] });
    }
  }
  validateEmailFields(currentField) {
    return this.emailSchema
      .validate(
        {
          // Endereco: this.state.emailAddress,
          Assunto: this.state.emailTitle,
          Conteudo: this.state.emailContent,
        },
        yupOptions
      )
      .then(data => {
        this.setState({ emailFieldError: [] });
        return true;
      })
      .catch(data => {
        this.onVerify(data, 'emailFieldError', currentField);
        return false;
      });
  }

  validateHttpFields(currentField) {
    return this.httpSchema
      .validate(
        {
          authNeeded: this.state.httpAuthType !== 'Nula',
          Endereco: this.state.httpAddress,
          Login: this.state.httpLogin,
          Senha: this.state.httpPassword,
          Login_Alias: this.state.httpLoginAlias,
          Senha_Alias: this.state.httpPasswordAlias,
          Token_Alias: this.state.httpTokenAlias,
          Endereco_Token: this.state.httpTokenAddress,
        },
        yupOptions
      )
      .then(data => {
        this.setState({ httpFieldError: [] });
        return true;
      })
      .catch(data => {
        this.onVerify(data, 'httpFieldError', currentField);
        return false;
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.enabled !== this.props.enabled && this.props.enabled) {
      const output = this.props.outputProcessor?.serialize();
      this.setState({
        checkValue: { Email: output?.email.active || false, Http: output?.http.active || false, Notificacao: output?.notification.active || false },
        // emailAddress: '',
        emailTitle: output?.email.emailTitle || '',
        emailContent: output?.email.emailTitle || '',
        // http
        httpAddress: output?.http.httpAddress || '',
        httpAuthType: output?.http.httpAuthType || this.state.httpAuthType,
        httpLogin: output?.http.httpLogin || '',
        httpPassword: output?.http.httpPassword || '',
        httpLoginAlias: output?.http.httpLoginAlias || '',
        httpPasswordAlias: output?.http.httpPasswordAlias || '',
        httpTokenAlias: output?.http.httpTokenAlias || '',
        httpRequestType: output?.http.httpRequestType || 'POST',
        httpTokenAddress: output?.http.httpTokenAddress || '',
        httpTokenRequestType: output?.http.httpTokenRequestType || 'POST',
        //  Notification
        notificationPosition: output?.notification.notificationPosition || { 'top-left': 'Esquerda superior' },
      });
    }
  }

  render() {
    let content;
    switch (this.state.currentTab) {
      case 'Email':
        content = (
          <Email
            active={this.state.checkValue['Email']}
            address={this.props.user.email}
            title={this.state.emailTitle}
            content={this.state.emailContent}
            setDialogState={async obj => {
              await this.setState(obj);
              if (this.state.emailFieldError.length > 0) {
                this.validateEmailFields();
              }
            }}
            saveChanges={() => this.saveChanges()}
            validateEmailFields={currentField => this.validateEmailFields(currentField)}
            emailFieldError={this.state.emailFieldError}
          />
        );
        break;
      case 'Http':
        content = (
          <Http
            active={this.state.checkValue['Http']}
            httpAuthType={this.state.httpAuthType}
            address={this.state.httpAddress}
            login={this.state.httpLogin}
            password={this.state.httpPassword}
            setAuthType={val => this.setState({ httpAuthType: val })}
            setDialogState={async obj => {
              await this.setState(obj);
              if (this.state.httpFieldError.length > 0) {
                this.validateHttpFields();
              }
            }}
            saveChanges={() => this.saveChanges()}
            validateHttpFields={validateEmailFields => this.validateHttpFields(validateEmailFields)}
            httpFieldError={this.state.httpFieldError}
            loginAlias={this.state.httpLoginAlias}
            passwordAlias={this.state.httpPasswordAlias}
            tokenAlias={this.state.httpTokenAlias}
            requestType={this.state.httpRequestType}
            tokenAddress={this.state.httpTokenAddress}
            tokenRequestType={this.state.httpTokenRequestType}
          />
        );
        break;
      case 'Notificacao':
        content = <Notification active={this.state.checkValue['Notificacao']} setDialogState={obj => this.setState(obj)} notificationPosition={this.state.notificationPosition} />;
        break;
      default:
        content = null;
        break;
    }
    return (
      <>
        <Dialog
          id='simple-dialog-output'
          visible={this.props.enabled}
          onRequestClose={() => {
            this.discardChanges();
          }}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader className='flex flex-center'>
            <DialogTitle className='w-100 flex flex-center'>Configurações de Saída</DialogTitle>
          </DialogHeader>
          <DialogContent className='flex flex-wrap' style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <div className='w-100'>
              <div className='w-100 flex flex-evenly'>
                <Button
                  theme='secondary'
                  themeType='contained'
                  className={`output-button ${this.state.currentTab === 'Notificacao' ? 'selected-tab' : ''} ${
                    !this.state.checkValue['Notificacao'] ? 'inactive-output-button' : ''
                  }`}
                  onClick={() => this.setState({ currentTab: 'Notificacao' })}
                >
                  Notificação
                </Button>
                <Button
                  theme='secondary'
                  themeType='contained'
                  className={`output-button ${this.state.currentTab === 'Email' ? 'selected-tab' : ''} ${!this.state.checkValue['Email'] ? 'inactive-output-button' : ''}`}
                  onClick={() => this.setState({ currentTab: 'Email' })}
                >
                  Email
                </Button>
                <Button
                  theme='secondary'
                  themeType='contained'
                  className={`output-button ${this.state.currentTab === 'Http' ? 'selected-tab' : ''} ${!this.state.checkValue['Http'] ? 'inactive-output-button' : ''}`}
                  onClick={() => this.setState({ currentTab: 'Http' })}
                >
                  Http
                </Button>
                <Button theme='secondary' themeType='contained' className='output-button' disabled={true}>
                  USB
                </Button>
              </div>
              <div className='flex flex-wrap'>{content}</div>
            </div>
          </DialogContent>
          <DialogFooter>
            <div className='w-100 flex' style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <div className='upper-button'>
                  <Checkbox
                    id='activeOutputCheckbox'
                    style={{ color: '#223f54', fontWeight: '600' }}
                    className='grid-checkbox'
                    checked={this.state.checkValue[this.state.currentTab]}
                    onClick={() => {
                      const newCheck = { ...this.state.checkValue };
                      newCheck[this.state.currentTab] = !newCheck[this.state.currentTab];
                      this.setState({ checkValue: newCheck });
                    }}
                    name='activeOutput'
                    label={`${this.state.currentTab} ativo`}
                    theme='secondary'
                  />
                  {/*<Button disabled={!this.state.checkValue[this.state.currentTab]} className='w-100' theme='secondary' themeType='contained' onClick={() => this.saveChanges()}>*/}
                  {/*  Testar*/}
                  {/*</Button>*/}
                </div>
                <div className='upper-button' />
              </div>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <Button className='upper-button' style={{ color: 'white' }} theme='error' themeType='contained' onClick={() => this.discardChanges()}>
                  cancelar
                </Button>
                <Button className='upper-button' theme='primary' themeType='contained' onClick={() => this.saveChanges()}>
                  salvar
                </Button>
              </div>
            </div>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  outputProcessor: state.cameraConfig.outputProcessor,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...outputActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OutputDialog);
