import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'react-md';
import React from 'react';
import { bindActionCreators } from 'redux';
import * as postProcessingActions from '../../actions/postProcessing';
import { connect } from 'react-redux';
import './postProcessingDialog.scss';
import PostProcessingStates from './options/postProcessingStates';
import PostProcessingConfig from './options/postProcessingConfig/postProcessingConfig';
import { Checkbox } from '@react-md/form';
import { PostProcessingProcessor } from './postProcessingProcessor';
import { toast } from 'react-toastify';

class PostProcessingDialog extends React.Component {
  defaultTransitions = {
    stateZero_stateOne: null,
    stateZero_stateTwo: null,
    stateZero_stateThree: null,
    stateOne_stateZero: null,
    stateOne_stateTwo: null,
    stateOne_stateThree: null,
    stateTwo_stateZero: null,
    stateTwo_stateOne: null,
    stateTwo_stateThree: null,
    stateThree_stateZero: null,
    stateThree_stateOne: null,
    stateThree_stateTwo: null,
  };
  constructor(props) {
    super(props);
    this.state = {
      // postProcessingProcessInstance: null,
      stateZero: { name: !!props.postProcessingProcessor ? props.postProcessingProcessor.serialize().stateZero.name : 'Estado Inicial', active: true },
      stateOne: { name: !!props.postProcessingProcessor ? props.postProcessingProcessor.serialize().stateOne.name : 'Estado 1', active: false },
      stateTwo: { name: !!props.postProcessingProcessor ? props.postProcessingProcessor.serialize().stateTwo.name : 'Estado 3', active: false },
      stateThree: { name: !!props.postProcessingProcessor ? props.postProcessingProcessor.serialize().stateThree.name : 'Estado 2', active: false },
      transitions: !!props.postProcessingProcessor ? props.postProcessingProcessor.serialize().transitions : this.defaultTransitions,
      currentVar: '',
      currentArrow: '',
      maskRules: ['Maior que', 'Menor que', 'Igual', 'Diferente'],
      maskRule: 0, // index from array above
      maskColor: 0, // [blue, red, green, yellow]
      maskQuantity: 0, // rectangles of a chosen color at each frame
      minimumFrames: 1, // at least minimumFrames of latestFrames to trigger output
      latestFrames: 1, // at least minimumFrames of latestFrames to trigger output
      triggerOutput: false,
      booleanValue: false,
      // integerRules: same as maskRules,
      integerRule: 0,
      integerValue: 0,
    };
  }

  // default to save new values at transition
  resetObject() {
    return {
      maskRule: 0,
      maskColor: 0,
      maskQuantity: 0,
      minimumFrames: 1,
      latestFrames: 1,
      booleanValue: false,
      integerRule: 0,
      integerValue: 0,
      triggerOutput: false,
    };
  }

  resetConfig(obj) {
    this.setState({
      ...obj,
      currentArrow: '',
      maskRule: 0,
      maskColor: 0,
      maskQuantity: 0,
      minimumFrames: 1,
      latestFrames: 1,
      triggerOutput: false,
      booleanValue: false,
      integerRule: 0,
      integerValue: 0,
    });
  }
  closeDialog() {
    this.resetConfig({ transitions: { ...this.defaultTransitions } });
    this.props.close();
  }
  applyTransition() {
    switch (this.props.varOptions[this.state.currentVar]?.type) {
      case 'gridArray':
        const maskConfig = {
          currentVar: this.state.currentVar,
          maskRule: this.state.maskRule,
          maskQuantity: this.state.maskQuantity,
          minimumFrames: this.state.minimumFrames,
          latestFrames: this.state.latestFrames,
          triggerOutput: this.state.triggerOutput,
          maskColor: this.state.maskColor,
        };
        const newTransitionsMask = { ...this.state.transitions };
        newTransitionsMask[this.state.currentArrow] = { ...newTransitionsMask[this.state.currentArrow], ...maskConfig };
        this.resetConfig({ transitions: newTransitionsMask, currentVar: '' });
        break;
      case 'bool':
        const booleanConfig = {
          currentVar: this.state.currentVar,
          triggerOutput: this.state.triggerOutput,
          booleanValue: this.state.booleanValue,
          minimumFrames: this.state.minimumFrames,
          latestFrames: this.state.latestFrames,
        };
        const newTransitionsBoolean = { ...this.state.transitions };
        newTransitionsBoolean[this.state.currentArrow] = { ...newTransitionsBoolean[this.state.currentArrow], ...booleanConfig };
        this.resetConfig({ transitions: newTransitionsBoolean, currentVar: '' });
        break;
      case 'integer':
        const integerConfig = {
          currentVar: this.state.currentVar,
          triggerOutput: this.state.triggerOutput,
          integerRule: this.state.integerRule,
          integerValue: this.state.integerValue,
          minimumFrames: this.state.minimumFrames,
          latestFrames: this.state.latestFrames,
        };
        const newTransitionsInteger = { ...this.state.transitions };
        newTransitionsInteger[this.state.currentArrow] = { ...newTransitionsInteger[this.state.currentArrow], ...integerConfig };
        this.resetConfig({ transitions: newTransitionsInteger, currentVar: '' });
        break;
      default:
        break;
    }
  }

  resetTransition() {
    const newTransitions = { ...this.state.transitions };
    newTransitions[this.state.currentArrow] = null;
    this.resetConfig({ transitions: newTransitions });
  }

  saveChanges() {
    const sendingData = {
      stateZero: this.state.stateZero,
      stateOne: this.state.stateOne,
      stateTwo: this.state.stateTwo,
      stateThree: this.state.stateThree,
      transitions: this.state.transitions,
      // ... TODO: other apps properties
    };

    sendingData.stateZero.active = true;
    sendingData.stateOne.active = false;
    sendingData.stateTwo.active = false;
    sendingData.stateThree.active = false;

    const instance = new PostProcessingProcessor(JSON.parse(JSON.stringify(sendingData)));
    this.props.setPostProcessingProcessor(instance);
    this.props.close();
    toast.success('Configurações salvas com sucesso!');
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.enabled !== this.props.enabled && !!this.props.postProcessingProcessor) {
      this.setState({ ...this.props.postProcessingProcessor.serialize() });
    }
  }

  render() {
    return (
      <>
        <Dialog
          // style={{ position: 'absolute', right: '0' }}
          id='postProcessingDialog'
          visible={this.props.enabled}
          onRequestClose={() => this.closeDialog()}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader className='flex flex-center'>
            <DialogTitle className='w-100 flex flex-center'>Condições de Detecção</DialogTitle>
          </DialogHeader>
          <DialogContent className='flex flex-column flex-wrap' style={{ justifyContent: 'space-between', alignItems: 'flex-start', width: '688px', height: '438px' }}>
            <div className='flex flex-wrap grown' style={{ width: '320px', height: '440px', justifyContent: 'center', alignContent: 'space-between', position: 'relative' }}>
              <PostProcessingStates
                stateZero={this.state.stateZero}
                stateOne={this.state.stateOne}
                stateTwo={this.state.stateTwo}
                stateThree={this.state.stateThree}
                setDialogState={obj => this.setState(obj)}
                currentArrow={this.state.currentArrow}
                transitions={this.state.transitions}
                updateTransitionContent={currentArrow => {
                  const valuesToUpdate = { ...(this.state.transitions[currentArrow] || this.resetObject()) };
                  const triggerOutput =
                    !!this.state.transitions[currentArrow] && this.state.transitions[currentArrow].triggerOutput !== undefined
                      ? this.state.transitions[currentArrow].triggerOutput
                      : false;
                  this.setState({ triggerOutput, currentArrow: currentArrow, currentVar: valuesToUpdate.currentVar || '', ...valuesToUpdate });
                }}
                activeCircle={this.props.activeCircle}
              />
              <div className='flex flex-wrap' style={{ justifyContent: 'flex-start', width: '90%', height: '80px', position: 'absolute', bottom: '0' }}>
                <Checkbox
                  id='postProcessingTriggerOutput'
                  style={{ color: '#0d423d', margin: '6px' }}
                  className={`grid-checkbox w-100 ${!!this.state.currentArrow && !!this.state.transitions[this.state.currentArrow] ? 'selected-checkbox' : ''}`}
                  onChange={event => this.setState({ triggerOutput: !this.state.triggerOutput })}
                  name='postProcessingTriggerOutput'
                  label='Gatilho de saída '
                  theme={'primary'}
                  disabled={!this.state.currentArrow || !this.state.currentVar}
                  checked={this.state.triggerOutput}
                />
                <div className='flex w-100 flex-between'>
                  <Button
                    disabled={!this.state.currentArrow || !this.state.transitions[this.state.currentArrow]}
                    className='upper-button'
                    theme='error'
                    themeType='contained'
                    onClick={() => this.resetTransition()}
                  >
                    remover
                  </Button>
                  <Button
                    disabled={!this.state.currentArrow || !this.state.currentVar}
                    className='upper-button'
                    theme='secondary'
                    themeType='contained'
                    onClick={() => this.applyTransition()}
                  >
                    aplicar
                  </Button>
                </div>
              </div>
            </div>
            <div className='flex flex-wrap grown' style={{ width: '320px', justifyContent: 'center', alignContent: 'space-between' }}>
              <PostProcessingConfig
                stateZero={this.state.stateZero}
                stateOne={this.state.stateOne}
                stateTwo={this.state.stateTwo}
                stateThree={this.state.stateThree}
                varOptions={this.props.varOptions}
                currentVar={this.state.currentVar}
                currentArrow={this.state.currentArrow}
                setDialogState={obj => this.setState(obj)}
                maskRules={this.state.maskRules}
                maskRule={this.state.maskRule}
                maskColor={this.state.maskColor}
                maskQuantity={this.state.maskQuantity}
                minimumFrames={this.state.minimumFrames}
                latestFrames={this.state.latestFrames}
                booleanValue={this.state.booleanValue}
                integerRule={this.state.integerRule}
                integerValue={this.state.integerValue}
                updateCurrentVar={nextVar => {
                  this.setState({ currentVar: nextVar });
                }}
              />
            </div>
          </DialogContent>
          <DialogFooter>
            <div className='w-100 flex flex-wrap' style={{ justifyContent: 'space-around' }}>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <Button className='upper-button' style={{ color: 'white' }} theme='error' themeType='contained' onClick={() => this.closeDialog()}>
                  cancelar
                </Button>
                <Button className='upper-button' theme='primary' themeType='contained' onClick={() => this.saveChanges()}>
                  salvar
                </Button>
              </div>
            </div>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = state => ({
  postProcessingProcessor: state.cameraConfig.postProcessingProcessor,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...postProcessingActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PostProcessingDialog);
