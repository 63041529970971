import React from 'react';
import Graph from 'react-graph-vis';
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, TextField } from 'react-md';
import { toast } from 'react-toastify';

// let lastTransition = ''

export default class PostProcessingStates extends React.Component {
  transitionToastId = null;
  options = {
    layout: {
      hierarchical: true,
    },
    nodes: {
      widthConstraint: { minimum: 65, maximum: 65 },
      shape: 'circle',
      color: {
        border: '#d3efec',
        hover: {
          border: '#57d0c4',
          background: '#71d2c7',
        },
      },
    },
    edges: {
      smooth: { enabled: true, type: 'curvedCW', roundness: 0.2 },
      chosen: {
        edge: function (values, id, selected, hovering) {
          if (hovering) {
            values.color = '#a0a0a0';
            values.width = 6;
          }
        },
      },
    },
    autoResize: false,
    height: '100%',
    width: '100%',
    manipulation: {
      enabled: false,
      initiallyActive: false,
      editEdge: false,
      deleteNode: false,
      deleteEdge: false,
    },
    interaction: {
      dragNodes: false,
      dragView: false,
      hideEdgesOnDrag: false,
      hideNodesOnDrag: false,
      hover: true,
      hoverConnectedEdges: false,
      keyboard: {
        enabled: false,
      },
      multiselect: false,
      navigationButtons: false,
      selectable: true,
      selectConnectedEdges: false,
      zoomView: false,
    },
  };

  events = {
    // select: function (event) {
    //   const { nodes, edges } = event;
    //   console.log('select', event)
    // },
    click: event => {
      if (event.edges.length === 1) {
        this.props.updateTransitionContent(event.edges[0]);
      } else {
        this.props.updateTransitionContent('');
      }
    },
    doubleClick: event => {
      if (event.nodes.length === 1) {
        this.setState({ addNameDialogEnabled: true, currentNodeId: event.nodes[0] });
      } else if (event.edges.length === 1) {
        if (this.props.transitions[event.edges[0]] !== null) {
          this.setState({ addNameDialogEnabled: true });
        } else if (!toast.isActive(this.transitionToastId)) {
          this.transitionToastId = toast.error('Somente transições aplicadas podem ser renomeadas.');
        }
      }
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      addNameDialogEnabled: false,
      currentNodeId: null,
    };
  }
  appliedEdge(transitionName) {
    let edgeConfig = {};
    if (transitionName !== this.props.currentArrow) {
      if (this.props.transitions[transitionName] !== null) {
        edgeConfig = { width: 5, color: { color: '#00a85f' } };
      } else {
        edgeConfig = { width: 3, color: { color: '#aaaaaa' } };
      }
    } else {
      edgeConfig = { width: 5, color: { color: '#223f54' } };
    }
    // if(transitionName === this.lastTransition){
    //   edgeConfig = { width: 5, color: { color: '#aa003b' } };
    // }
    return edgeConfig;
  }
  activeCircle(circleName) {
    if (this.props.activeCircle === circleName) {
      return { color: { background: '#50ef97', highlight: { border: '#57d0c4', background: '#50ef97' } } };
    }
    return { color: { background: '#d3efec', highlight: { border: '#57d0c4', background: '#71d2c7' } } };
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if(prevProps.activeCircle !== this.props.activeCircle){
  //     lastTransition = prevProps.activeCircle+'_'+this.props.activeCircle
  //   }
  // }

  render() {
    const statesName = [null, null];
    if (this.props.currentArrow) {
      statesName[0] = this.props[this.props.currentArrow.split('_')[0]].name;
      statesName[1] = this.props[this.props.currentArrow.split('_')[1]].name;
    }
    return (
      <div className='flex flex-wrap' style={{ width: '100%', height: '350px', position: 'relative' }}>
        <AddNameDialog
          enabled={this.state.addNameDialogEnabled}
          close={() => this.setState({ addNameDialogEnabled: false, currentNodeId: null })}
          saveName={name => {
            if (!!this.state.currentNodeId) {
              // Editing node's name
              const newState = { ...this.props[this.state.currentNodeId] };
              newState.name = name || newState.name;
              this.props.setDialogState({ [this.state.currentNodeId]: newState });
              this.setState({ addNameDialogEnabled: false, currentNodeId: null });
            } else {
              // Editing transition name
              const newState = { ...this.props.transitions };
              !!name ? (newState[this.props.currentArrow].name = name) : delete newState[this.props.currentArrow].name;
              this.props.setDialogState({ transitions: newState });
              this.setState({ addNameDialogEnabled: false });
            }
          }}
        />
        {statesName[0] !== null && statesName[1] !== null ? (
          this.props.transitions[this.props.currentArrow] !== null && !!this.props.transitions[this.props.currentArrow].name ? (
            <h3 className='flex w-100 flex-center transition-title' style={{ position: 'absolute', top: '0', left: '0', zIndex: '2' }}>
              {this.props.transitions[this.props.currentArrow].name}
            </h3>
          ) : (
            <div className='flex w-100 flex-center' style={{ position: 'absolute', top: '0', left: '0', zIndex: '2' }}>
              <h3 className='transition-title text-ellipsis' style={{ width: 'calc(50% - 15px)', padding: '0 10px', textAlign: 'end' }}>{`${statesName[0]}`}</h3>
              <img className='flex' style={{ width: '30px', height: '20px' }} alt='transition arrow' src={`${process.env.PUBLIC_URL}/img/arrowForward.svg`} />
              <h3 className=' transition-title text-ellipsis' style={{ width: 'calc(50% - 15px)', padding: '0 10px', textAlign: 'start' }}>{`${statesName[1]}`}</h3>
            </div>
          )
        ) : (
          <h3 className='flex w-100 flex-center transition-title' style={{ position: 'absolute', top: '0', left: '0', zIndex: '2' }}>
            Selecione uma transição
          </h3>
        )}
        <Graph
          graph={{
            nodes: [
              { id: 'stateZero', label: this.props.stateZero.name, title: '', level: 0, ...this.activeCircle('stateZero') },
              { id: 'stateOne', label: this.props.stateOne.name, title: '', level: 0, ...this.activeCircle('stateOne') },
              { id: 'stateTwo', label: this.props.stateTwo.name, title: '', level: 1, ...this.activeCircle('stateTwo') },
              { id: 'stateThree', label: this.props.stateThree.name, title: '', level: 1, ...this.activeCircle('stateThree') },
            ],
            edges: [
              { id: 'stateZero_stateOne', from: 'stateZero', to: 'stateOne', ...this.appliedEdge('stateZero_stateOne') },
              { id: 'stateZero_stateTwo', from: 'stateZero', to: 'stateTwo', ...this.appliedEdge('stateZero_stateTwo') },
              {
                id: 'stateZero_stateThree',
                from: 'stateZero',
                to: 'stateThree',
                ...this.appliedEdge('stateZero_stateThree'),
              },

              { id: 'stateOne_stateZero', from: 'stateOne', to: 'stateZero', ...this.appliedEdge('stateOne_stateZero') },
              { id: 'stateOne_stateTwo', from: 'stateOne', to: 'stateTwo', ...this.appliedEdge('stateOne_stateTwo') },
              { id: 'stateOne_stateThree', from: 'stateOne', to: 'stateThree', ...this.appliedEdge('stateOne_stateThree') },

              { id: 'stateTwo_stateZero', from: 'stateTwo', to: 'stateZero', ...this.appliedEdge('stateTwo_stateZero') },
              { id: 'stateTwo_stateOne', from: 'stateTwo', to: 'stateOne', ...this.appliedEdge('stateTwo_stateOne') },
              { id: 'stateTwo_stateThree', from: 'stateTwo', to: 'stateThree', ...this.appliedEdge('stateTwo_stateThree') },

              {
                id: 'stateThree_stateZero',
                from: 'stateThree',
                to: 'stateZero',
                ...this.appliedEdge('stateThree_stateZero'),
              },
              { id: 'stateThree_stateOne', from: 'stateThree', to: 'stateOne', ...this.appliedEdge('stateThree_stateOne') },
              { id: 'stateThree_stateTwo', from: 'stateThree', to: 'stateTwo', ...this.appliedEdge('stateThree_stateTwo') },
            ],
          }}
          options={this.options}
          events={this.events}
          getNetwork={network => {
            // console.log('network', network);
            //  if you want access to vis.js network api you can set the state in a parent component using this property
          }}
        />
      </div>
    );
  }
}

class AddNameDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }
  discardChanges() {
    this.setState({ name: '' });
    this.props.close();
  }
  saveName() {
    this.setState({ name: '' });
    this.props.saveName(this.state.name.trim());
  }

  render() {
    return (
      <>
        <Dialog
          // style={{ position: 'absolute', right: '176px' }}
          id='addNameDialog'
          visible={this.props.enabled}
          onRequestClose={() => this.discardChanges()}
          aria-labelledby='dialog-title'
          className='curve-border-2'
        >
          <DialogHeader className='flex flex-center'>
            <DialogTitle className='w-100 flex flex-center'>Definição de nome</DialogTitle>
          </DialogHeader>
          <DialogContent className='flex'>
            <TextField
              id='addNameInput'
              theme='outline'
              label='Novo nome'
              placehold=''
              className='w-100'
              onChange={event => this.setState({ name: event.target.value })}
              onKeyPress={key => (key.keyCode === 13 || key.which === 13 ? this.saveName(this.state.name) : null)}
            />
          </DialogContent>
          <DialogFooter>
            <div className='w-100 flex' style={{ justifyContent: 'space-around', flexWrap: 'wrap' }}>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <Button className='upper-button' style={{ color: 'white' }} theme='error' themeType='contained' onClick={() => this.discardChanges()}>
                  cancelar
                </Button>
                <Button className='upper-button' theme='primary' themeType='contained' onClick={() => this.saveName()}>
                  salvar
                </Button>
              </div>
            </div>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}
