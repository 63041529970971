import React from 'react';

import './loading.scss';

class Loading extends React.Component {
  render() {
    return (
      <div className='Loading'>
        <header className='Loading-header'>
          <img src={process.env.PUBLIC_URL + 'img/leaf.svg'} className='Loading-logo' alt='logo' />
          <p>Carregando...</p>
        </header>
      </div>
    );
  }
}

export default Loading;
