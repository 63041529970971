import React from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle, Button } from 'react-md';
import { TextField } from 'react-md';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { format } from 'date-fns';
registerLocale('ptBR', ptBR);

export class CreateLicenseDialog extends React.Component {
  emptyFields = -1;
  constructor(props) {
    super(props);
    this.state = {
      licenseCamId: '',
      licenseHWId: '',
      selectedDate: '',
      calendarLabelVisibility: 'hidden-calendar-label',
      calendarLabelColor: 'gray-label',
      dialogHeight: '460px',
    };
    this.updateLicenseCamId = this.updateLicenseCamId.bind(this);
    this.updateLicenseHWId = this.updateLicenseHWId.bind(this);
    this.updateSelectedDate = this.updateSelectedDate.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCalendarBlur = this.onCalendarBlur.bind(this);
    this.onCalendarFocus = this.onCalendarFocus.bind(this);
  }

  onCalendarFocus() {
    this.setState({ calendarLabelVisibility: 'visible-calendar-label', dialogHeight: '690px', calendarLabelColor: 'blue-label' });
  }

  onCalendarBlur() {
    const { selectedDate } = this.state;
    const objToSave = { dialogHeight: '460px', calendarLabelColor: 'gray-label' };
    if (!selectedDate) {
      objToSave.calendarLabelVisibility = 'hidden-calendar-label';
      this.setState(objToSave);
    } else {
      this.setState(objToSave);
    }
  }

  updateSelectedDate(selectedDate) {
    this.setState({ selectedDate, dialogHeight: '460px', calendarLabelColor: 'gray-label' });
  }

  onCancel() {
    this.setState(
      {
        licenseCamId: '',
        licenseHWId: '',
        selectedDate: '',
        calendarLabelVisibility: 'hidden-calendar-label',
        calendarLabelColor: 'gray-label',
        dialogHeight: '460px',
      },
      () => this.props.onCancel()
    );
  }

  onConfirm() {
    const { licenseCamId, licenseHWId, selectedDate } = this.state;
    if (licenseCamId !== '' && licenseHWId !== '' && selectedDate) {
      this.setState(
        {
          licenseCamId: '',
          licenseHWId: '',
          selectedDate: '',
          calendarLabelVisibility: 'hidden-calendar-label',
          calendarLabelColor: 'gray-label',
          dialogHeight: '460px',
        },
        () => this.props.onConfirm(licenseCamId, licenseHWId, this.props.group, format(new Date(selectedDate), 'yyyy-MM-dd'))
      );
    } else {
      const msg = 'Os campos não podem ser vazios';
      toast.isActive(this.emptyFields) ? toast.update(this.emptyFields, { render: msg }) : (this.emptyFields = toast.error(msg));
    }
  }

  updateLicenseCamId(event) {
    this.setState({ licenseCamId: event.target.value });
  }

  updateLicenseHWId(event) {
    this.setState({ licenseHWId: event.target.value });
  }

  render() {
    const { licenseCamId, licenseHWId, selectedDate, calendarLabelVisibility, calendarLabelColor, dialogHeight } = this.state;
    const { group } = this.props;

    const expirationDate = group?.membership?.membership_expiration;

    const membershipExpired = new Date(expirationDate) <= new Date();

    return (
      <Dialog
        id='confirmationDialog'
        style={{ maxWidth: '400px', height: membershipExpired ? '370px' : dialogHeight }}
        visible={this.props.creationDialogVisible}
        onRequestClose={() => console.log('no')}
        className='curve-border-2'
        aria-labelledby='dialog-title'
      >
        <DialogHeader>
          <DialogTitle id='confirmationDialogTitle' className='w-100 flex flex-center'>
            ATENÇÃO
          </DialogTitle>
        </DialogHeader>
        {membershipExpired ? (
          <>
            <DialogContent style={{ textAlign: 'center', color: 'dimgrey', fontWeight: '600' }}>
              <p style={{ color: 'dimgrey', margin: '0 0 16px 0' }}>O plano deste grupo expirou, portanto não é possível gerar novas licenças no momento.</p>
              <p style={{ color: 'dimgrey', margin: '0 0 16px 0' }}>Entre em contato com o nosso time para mais informações.</p>
              <p>
                <a href='mailto:contato@mvisia.com.br'>contato@mvisia.com.br</a>
              </p>
            </DialogContent>
            <DialogFooter>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <Button className='w-100' theme='primary' themeType='contained' onClick={this.onCancel}>
                  fechar
                </Button>
              </div>
            </DialogFooter>
          </>
        ) : (
          <>
            <DialogContent style={{ textAlign: 'center', color: 'dimgrey', fontWeight: '600' }}>
              <p style={{ color: 'dimgrey', margin: '0 0 16px 0' }}>
                Esta licença é permanente. Tenha certeza de que os valores inseridos estão corretos antes de confirmar esta ação.
              </p>
              <TextField
                style={{ marginBottom: '12px' }}
                id='newLicenseTextFieldCamId'
                autoComplete='off'
                type='number'
                label='Camera ID'
                value={licenseCamId}
                onChange={this.updateLicenseCamId}
              />
              <TextField
                id='newLicenseTextFieldHWId'
                style={{ marginBottom: '12px' }}
                autoComplete='off'
                type='number'
                label='Hardware ID'
                value={licenseHWId}
                onChange={this.updateLicenseHWId}
              />
              <div className='my-calendar-container'>
                <DatePicker
                  locale='ptBR'
                  dateFormat='dd/MM/yyyy'
                  selected={selectedDate}
                  calendarClassName='my-license-calendar'
                  placeholderText='Data de expiração'
                  filterDate={date => date > new Date() && date < new Date(expirationDate || new Date())}
                  onChange={this.updateSelectedDate}
                  onFocus={this.onCalendarFocus}
                  onBlur={this.onCalendarBlur}
                />
                <label className={`${calendarLabelVisibility} ${calendarLabelColor}`}>Data de expiração</label>
              </div>
            </DialogContent>
            <DialogFooter>
              <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
                <Button className='upper-button' theme='error' themeType='contained' onClick={this.onCancel}>
                  cancelar
                </Button>
                <Button className='upper-button' theme='primary' themeType='contained' onClick={this.onConfirm}>
                  confirmar
                </Button>
              </div>
            </DialogFooter>
          </>
        )}
      </Dialog>
    );
  }
}
