import React from 'react';
import FileHandler from '../../fileHandler';
import GeneralCamConfigContent from './generalCamConfigContent';
import InputRange from 'react-input-range';
import { Select } from 'react-md';

export default class VideoCamConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      responseText: '',
    };
  }

  getCameraData(newParamsObject) {
    let content = { data: {} };
    content.data.type = 'videoCam';
    content.data.interval = this.props.videoCamInterval;
    content.data.frameRate = this.props.videoCamFrameRate;
    content.data = { ...content.data, ...newParamsObject };
    return content;
  }
  setCamera(buttonToLoad) {
    this.props.setCamera(this.props.camName, this.getCameraData(), 3, buttonToLoad);
  }
  render() {
    const { nonImageCameraSpeed } = this.props;
    const content = (
      <div id='videoCamConfigContent'>
        <div className='w-100 flex flex-center flex-wrap'>
          <span className='w-100 flex flex-start capabilities-span' style={{ alignItems: 'center' }}>
            Quantidade de quadros por segundo
          </span>
          <InputRange
            id='videoFPS'
            labelContainer='videoFPS'
            name='videoFPS'
            formatLabel={value => `${nonImageCameraSpeed[value]?.label || 'Média'}`}
            minValue={0}
            maxValue={4}
            value={
              nonImageCameraSpeed.findIndex(element => this.props.videoCamInterval === element.interval) === -1
                ? 2
                : nonImageCameraSpeed.findIndex(element => this.props.videoCamInterval === element.interval)
            }
            onChange={value => this.props.setParentState({ videoCamInterval: nonImageCameraSpeed[value]?.interval || 2 })}
            onChangeComplete={value =>
              this.props.applyCameraConfig(3, { ...this.getCameraData({ interval: nonImageCameraSpeed[value]?.interval || 2 }), name: this.props.camName })
            }
          />

          {/*VERSAO EM DROPDOWN*/}

          {/*<Select*/}
          {/*  id='videoCameraVisualizerSpeedSelect'*/}
          {/*  className='w-100'*/}
          {/*  label='Tempo de visualização do quadro'*/}
          {/*  options={nonImageCameraSpeed.map(element => element.label)}*/}
          {/*  value={nonImageCameraSpeed.find(element => element.interval === this.props.videoCamInterval)?.label || 'Médio'}*/}
          {/*  onChange={value => {*/}
          {/*    const speed = nonImageCameraSpeed.find(element => element.label === value)?.interval || 15;*/}
          {/*    this.props.setParentState({ videoCamInterval: speed });*/}
          {/*    this.props.applyCameraConfig(3, { ...this.getCameraData({ interval: speed }), name: this.props.camName });*/}
          {/*  }}*/}
          {/*/>*/}
        </div>

        <div className='w-100 flex flex-center flex-wrap'>
          <span className='w-100 flex flex-start capabilities-span' style={{ alignItems: 'center' }}>
            Playback Rate
          </span>
          <InputRange
            id='playbackRate'
            disabled={this.props.newCameraRef?.type !== 'videoCam'}
            labelContainer='videoFrameRate'
            name='videoFrameRate'
            formatLabel={value => `${value.toFixed(1)}`}
            step={0.05}
            maxValue={4.0}
            minValue={0.1}
            value={this.props.videoCamFrameRate}
            onChange={value => this.props.setParentState({ videoCamFrameRate: Math.round(value * 10) / 10 })}
            onChangeComplete={value => this.props.applyCameraConfig(3, { ...this.getCameraData({ frameRate: Math.round(value * 10) / 10 }), name: this.props.camName })}
          />
        </div>

        <FileHandler
          type='video'
          setText={responseText => this.setState({ responseText: responseText })}
          setVideoInput={file => {
            this.setState({ file: file });
            this.props.saveTemporaryFiles('video', file);
          }}
        />
        <div className='w-100' style={{ textAlign: 'center' }}>
          {this.state.responseText}
        </div>
        <div className='w-100' style={{ textAlign: 'center', backgroundColor: 'moccasin' }}>
          Formatos aceitos: MP4 | OGG <br></br>
          Codecs aceitos: H264 | H265 | MJPEG | VP8 | VP9
        </div>
      </div>
    );

    return (
      <GeneralCamConfigContent
        parentContent={content}
        cameraType='Câmera de vídeo'
        camVariable='videoCam'
        camName={this.props.camName}
        setParentState={content => this.props.setParentState(content)}
        cameraToParent={buttonToLoad => this.setCamera(buttonToLoad)}
        deleteCamera={() => this.props.deleteCamera(this.props.camName)}
        allCameras={this.props.allCameras}
        applyCameraConfig={() => this.props.applyCameraConfig(3, { ...this.getCameraData(), name: this.props.camName })}
        loadingButtons={this.props.loadingButtons}
        card={3}
        files={!!this.state.file}
        cameraCardSaveDisabled={this.props.cameraCardSaveDisabled}
      />
    );
  }
}
