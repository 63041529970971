export function saveFace(personName, matFace, base64Face) {
  return {
    type: 'APPFACE_SAVE_FACE',
    file: { personName: personName, matFace: matFace, base64Face: base64Face },
  };
}
export function removeFace(personIndex, faceIndex) {
  return {
    type: 'APPFACE_REMOVE_FACE',
    file: { personIndex: personIndex, faceIndex: faceIndex },
  };
}
