import React from 'react';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@react-md/dialog';
import { Button } from '@react-md/button';

export class ConfirmationDialog extends React.Component {
  render() {
    return (
      <Dialog
        id='confirmationDialog'
        style={{ maxWidth: '400px' }}
        visible={this.props.confirmationDialogVisible}
        onRequestClose={() => console.log('no')}
        className='curve-border-2'
        aria-labelledby='dialog-title'
      >
        <DialogHeader>
          <DialogTitle id='confirmationDialogTitle' className='w-100 flex flex-center'>
            Atenção
          </DialogTitle>
        </DialogHeader>
        <DialogContent style={{ textAlign: 'center', color: 'dimgrey', fontWeight: '600' }}>{this.props.confirmationDialogText}</DialogContent>
        <DialogFooter>
          <div className='w-100 flex flex-around' style={{ flexWrap: 'wrap' }}>
            <Button className='upper-button' theme='error' themeType='contained' onClick={() => this.props.onCancel()}>
              cancelar
            </Button>
            <Button className='upper-button' theme='primary' themeType='contained' onClick={() => this.props.onConfirm()}>
              confirmar
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    );
  }
}
