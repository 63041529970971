import React from 'react';
import EditorSection from './sections/editorSection';
import LogSection from './sections/logSection';
import StreamSection from './sections/streamSection';
import { bindActionCreators } from 'redux';
import * as appBarActions from '../../../actions/appBar';
import * as preProcessingActions from '../../../actions/preProcessing';
import * as postProcessingActions from '../../../actions/postProcessing';
import { connect } from 'react-redux';
// import { ErrorBoundary } from '../../errorBoundary';
import './appCode.scss';
import * as outputActions from '../../../actions/output';
import { dismissOldCam, resetCameraConfig } from '../../../actions/cameraConfig';

class AppCode extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scriptContent: '',
      runningScript: false,
      logs: [],
    };
  }

  componentWillUnmount() {
    this.props.resetCurrentAppConfig('appCode');
    this.props.setPreProcessingProcessor(null);
    this.props.setPostProcessingProcessor(null);
    this.props.setOutputProcessor(null);
    this.props.dismissOldCam();
    this.props.resetCameraConfig();
  }

  render() {
    return (
      <div className='background flex flex-between'>
        <div className='flex flex-column appCode-stream-section'>
          <StreamSection runningScript={this.state.runningScript} scriptContent={this.state.scriptContent} scriptLogs={logs => this.setState({ logs: logs })} />
          <LogSection logs={this.state.logs} />
        </div>
        <div className='flex flex-column appCode-editor-section'>
          <EditorSection
            runScript={scriptContent => this.setState({ runningScript: true, scriptContent: scriptContent })}
            stopScript={() => this.setState({ runningScript: false })}
            runningScript={this.state.runningScript}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...appBarActions,
      ...preProcessingActions,
      ...postProcessingActions,
      ...outputActions,
      dismissOldCam,
      resetCameraConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppCode);
