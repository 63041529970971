import React from 'react';
import { Card, TextField, CardContent, Button } from 'react-md';
import { CircularProgress } from '@react-md/progress';
import { toast } from 'react-toastify';

export default class GeneralCamConfigContent extends React.Component {
  toastId = null;
  render() {
    return (
      <Card id='camera-config-general-params-card' className='camera-card-config curve-border-2 small-card-params'>
        <div className='title-card-text w-100 flex flex-center small-card-title'>{this.props.cameraType}</div>
        <CardContent className='flex flex-column flex-between small-card-content'>
          <div className='flex-center-top w-100 camera-params-fields'>
            <TextField
              autoComplete='off'
              className='w-100 camera-card-text-field'
              id={`cameraNameField-${this.props.cameraType}`}
              label='Nome da câmera'
              value={this.props.camName}
              onChange={event => {
                const forbiddenChar = ['/', '\\', ' '];
                const charArray = event.target.value.split('');
                let newValue = '';
                charArray.forEach(char => {
                  if (!forbiddenChar.find(fchar => fchar === char)) {
                    newValue += char;
                  }
                });
                this.props.setParentState({ [this.props.camVariable + 'Name']: newValue });
              }}
            />
            {this.props.parentContent}
          </div>
          <div className='w-100'>
            <div className='w-100 flex flex-between'>
              {this.props.loadingButtons[this.props.card][0] === 1 ? (
                <div className='local-button flex flex-center camera-card-button' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
                  <CircularProgress id={`cameraConfigDeleteButton`} className='camera-config-loading appbar-action' centered={true} style={{ borderColor: 'white' }} />
                </div>
              ) : (
                <Button
                  disabled={
                    !this.props.allCameras.find(camera => camera.name === this.props.camName) ||
                    !!this.props.loadingButtons.find(innerArray => !!innerArray.find(element => element === 1))
                  }
                  theme='error'
                  themeType='contained'
                  className='camera-card-button'
                  onClick={() => this.props.deleteCamera()}
                >
                  deletar
                </Button>
              )}
              {this.props.loadingButtons[this.props.card][1] === 1 ? (
                <div className='local-button flex flex-center camera-card-button' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
                  <CircularProgress id={`cameraConfigApplyButton`} className='camera-config-loading appbar-action' centered={true} style={{ borderColor: 'white' }} />
                </div>
              ) : (
                <Button
                  id='cameraTestButton'
                  disabled={
                    !this.props.camName || !!this.props.loadingButtons.find(innerArray => !!innerArray.find(element => element === 1)) || (this.props.card > 1 && !this.props.files)
                  }
                  theme='secondary'
                  themeType='contained'
                  className='camera-card-button'
                  onClick={() => this.props.applyCameraConfig()}
                >
                  Visualizar
                </Button>
              )}
              {this.props.loadingButtons[this.props.card][2] === 1 ? (
                <div className='local-button flex flex-center camera-card-button' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
                  <CircularProgress id={`cameraConfigSaveButton`} className='camera-config-loading appbar-action' centered={true} style={{ borderColor: 'white' }} />
                </div>
              ) : (
                <Button
                  disabled={
                    !!this.props.loadingButtons.find(innerArray => !!innerArray.find(element => element === 1)) ||
                    (!!this.props.allCameras.find(camera => camera.name === this.props.camName) && this.props.cameraCardSaveDisabled[this.props.card])
                  }
                  onClick={() => {
                    if (this.props.camName) {
                      this.props.cameraToParent(2);
                    } else {
                      const msg = 'Nome da câmera não pode ser vazio.';
                      toast.isActive(this.toastId)
                        ? toast.update(this.toastId, { type: 'error', render: msg, autoClose: 3000 })
                        : (this.toastId = toast.error(msg, { autoClose: 3000 }));
                    }
                  }}
                  theme='primary'
                  themeType='contained'
                  className='camera-card-button'
                >
                  {!this.props.allCameras.find(camera => camera.name === this.props.camName) ? 'criar' : 'salvar'}
                </Button>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}
