import produce from 'immer';
import { toast } from 'react-toastify';
import React from 'react';

export default function appClassReducer(state, action) {
  if (action.error) {
    toast.error(`Ocorreu um erro: ${action.file.message}. Treine novamente o modelo.`);
    return produce(state, draft => {
      draft.appClass.training = false;
      draft.appClass.trainId = '';
      draft.appClass.sentImages = 0;
      draft.appClass.prepared = false;
      draft.appClass.epochs = false;
      draft.appClass.someError = true;
      draft.appClass.preTraining = false;
    });
  } else {
    switch (action.type) {
      case 'APPCLASS_START_TRAIN':
        return produce(state, draft => {
          draft.appClass.training = true;
          draft.appClass.trainId = action.file.train_key;
          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_SEND_IMAGE':
        return produce(state, draft => {
          draft.appClass.sentImages = state.appClass.sentImages + 1;
        });
      case 'APPCLASS_PREPARE':
        return produce(state, draft => {
          draft.appClass.prepared = true;
        });
      case 'APPCLASS_EPOCHS_TRAINING':
        return produce(state, draft => {
          draft.appClass.epochCurrent = 0;
        });
      case 'APPCLASS_MODEL_STATUS_TRAINING':
        return produce(state, draft => {
          draft.appClass.modelFit = true;
          draft.appClass.epochs = action.file.epoch || 0; // if undefined sets to zero
          draft.appClass.currentAccuracy = action.file.accuracy;
        });
      case 'APPCLASS_FINISH_TRAINING':
        toast.info(
          <div className='flex flex-wrap'>
            <div className='w-100'>Classificador de imagens:</div>
            <div className='w-100'>Treinamento finalizado.</div>
          </div>
        );
        return produce(state, draft => {
          draft.appClass.training = false;
          draft.appClass.trainId = '';
          draft.appClass.sentImages = 0;
          draft.appClass.prepared = false;
          draft.appClass.epochs = -1;
          draft.appClass.modelFit = false;
          draft.appClass.modelUrl = action.file.url;
          draft.appClass.preTraining = false;
        });
      case 'APPCLASS_SAVE_MODEL_CLASS_NAMES':
        return produce(state, draft => {
          draft.appClass.modelClassNames = action.file;
        });
      case 'APPCLASS_CAPTURE_IMAGE':
        return produce(state, draft => {
          draft.appClass.classes[action.file.classIndex].name = action.file.className;
          draft.appClass.classes[action.file.classIndex].images.push(action.file.newImage);
          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_CHANGE_CLASS_NAME':
        return produce(state, draft => {
          draft.appClass.classes[action.file.classIndex].name = action.file.className;
          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_SET_CURRENT_CLASS_INDEX':
        return produce(state, draft => {
          draft.appClass.currentClassIndex = action.file.classIndex;
        });
      case 'APPCLASS_SET_THUMB_DATA':
        return produce(state, draft => {
          draft.appClass.noCapture = false;
          draft.appClass.currentThumbImg = action.file.thumbImage;
          draft.appClass.currentThumbIndex = action.file.thumbIndex;
          draft.appClass.showingImageClassIndex = state.appClass.currentClassIndex;
        });
      case 'APPCLASS_DELETE_THUMB':
        const thumbs = [...state.appClass.classes[state.appClass.currentClassIndex].images];
        thumbs.splice(state.appClass.currentThumbIndex, 1);

        let currentThumbImg = '';
        let currentThumbIndex = -1;

        if (thumbs.length >= 1) {
          currentThumbIndex = state.appClass.currentThumbIndex < thumbs.length ? state.appClass.currentThumbIndex : thumbs.length - 1;
          currentThumbImg = thumbs[currentThumbIndex];
        }

        return produce(state, draft => {
          draft.appClass.classes[state.appClass.currentClassIndex].images = thumbs;
          draft.appClass.currentThumbImg = currentThumbImg;
          draft.appClass.currentThumbIndex = currentThumbIndex;
          draft.appClass.noCapture = thumbs.length === 0;
          draft.appClass.pendingSave = true;
        });

      case 'APPCLASS_CHANGE_THUMB_CLASS':
        const oldClassImages = [...state.appClass.classes[action.file.oldClassIndex].images];
        const removedElement = oldClassImages.splice(action.file.thumbIndex, 1);
        const newClassImages = [...state.appClass.classes[action.file.newClassIndex].images, ...removedElement];

        return produce(state, draft => {
          draft.appClass.classes[action.file.oldClassIndex].images = oldClassImages;
          draft.appClass.classes[action.file.newClassIndex].images = newClassImages;

          draft.appClass.currentClassIndex = action.file.newClassIndex;
          draft.appClass.currentThumbIndex = newClassImages.length - 1;
          draft.appClass.currentThumbImg = newClassImages[newClassImages.length - 1];
          draft.appClass.showingImageClassIndex = action.file.newClassIndex;

          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_RESET_DATA':
        return produce(state, draft => {
          draft.appClass.currentClassIndex = 0;
          draft.appClass.noCapture = true;
          draft.appClass.currentThumbImg = '';
          draft.appClass.currentThumbIndex = -1;
          draft.appClass.showingImageClassIndex = -1;
          draft.appClass.classes = [
            { name: 'Classe1', images: [] },
            { name: 'Classe2', images: [] },
            { name: 'Classe3', images: [] },
            { name: 'Classe4', images: [] },
            { name: 'Classe5', images: [] },
          ];
          draft.appClass.modelClassNames = [];
          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_SET_CLASS_PARAMS':
        return produce(state, draft => {
          draft.appClass.modelParams = action.file.modelParams;
          draft.appClass.datasetParams = action.file.datasetParams;
          draft.appClass.numEpochs = action.file.numEpochs;
        });
      case 'APPCLASS_STORE_CLASS_THUMBS':
        let newThumbs = [...state.appClass.classes[state.appClass.currentClassIndex].images];
        newThumbs = newThumbs.concat(action.file);
        return produce(state, draft => {
          draft.appClass.classes[state.appClass.currentClassIndex].images = newThumbs;
          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_SET_CLASS_MODEL_URL':
        return produce(state, draft => {
          draft.appClass.modelUrl = action.file;
          draft.appClass.pendingSave = true;
        });
      case 'APPCLASS_RESET_MODEL_PARAMS':
        return produce(state, draft => {
          draft.appClass.modelParams = {};
          draft.appClass.datasetParams = {};
          draft.appClass.numEpochs = 15;
        });
      case 'APPCLASS_SET_CLASS_PRE_TRAINING':
        return produce(state, draft => {
          draft.appClass.preTraining = true;
        });
      default:
        return state;
    }
  }
}
