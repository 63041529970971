import { toast, Zoom } from 'react-toastify';
import React from 'react';
import { sendEmailNotification, getHttpToken, sendNoAuthHttpData } from '../../actions/output';
import { store } from '../../index';
import { captureImage } from '../../camera';
import * as lodash from 'lodash';
let lastId = null;

export class OutputProcessor {
  constructor(data) {
    this.data = lodash.cloneDeep(data);
    this.toastInfoId = null;
    // this.running = true;
  }
  serialize() {
    return JSON.parse(JSON.stringify(this.data));
  }
  process(postProcessingResponse, activeCircle, engineData) {
    let transitionTriggered = '';
    if (!!postProcessingResponse && !!postProcessingResponse.currentTransition) {
      if (
        postProcessingResponse.data.transitions[postProcessingResponse.currentTransition] &&
        postProcessingResponse.data.transitions[postProcessingResponse.currentTransition].name
      ) {
        transitionTriggered = `${postProcessingResponse.data.transitions[postProcessingResponse.currentTransition].name}`;
      } else {
        transitionTriggered = `${postProcessingResponse.data[postProcessingResponse.currentTransition.split('_')[0]].name} => ${
          postProcessingResponse.data[postProcessingResponse.currentTransition.split('_')[1]].name
        }`;
      }
      this.showInfoToast(postProcessingResponse, activeCircle, engineData);
      this.sendEmail(postProcessingResponse, activeCircle, engineData, transitionTriggered);
      this.sendHttp(postProcessingResponse, activeCircle, engineData, transitionTriggered);
    }
  }

  sendHttp(postProcessingResponse, activeCircle, engineData, transitionTriggered) {
    if (this.data.http.active && postProcessingResponse.triggerOutput && postProcessingResponse.activeState !== activeCircle) {
      let tokenRequestUrl = '';
      let tokenRequestData = {};

      const dataToSend = {
        transition: transitionTriggered,
        timestamp: engineData.timestamp.value,
      };
      Object.keys(engineData).forEach((key, index) => {
        switch (key) {
          case 'img':
            dataToSend[`img`] = captureImage(engineData[key]).split(',')[1];
            break;
          case 'maskImg':
            dataToSend[`maskImg`] = captureImage(engineData[key]).split(',')[1];
            break;
          case 'rectImg':
            dataToSend[`rectImg`] = captureImage(engineData[key]).split(',')[1];
            break;
          case 'classIndex':
            dataToSend.classIndex = engineData.classIndex;
            break;
          case 'classLabel':
            dataToSend.classLabel = engineData.classLabel;
            break;
          default:
            break;
        }
      });
      if (this.data.http.httpAuthType !== 'Nula') {
        if (this.data.http.httpTokenRequestType === 'GET') {
          tokenRequestUrl = `${this.data.http.httpTokenAddress}?${this.data.http.httpLoginAlias}=${this.data.http.httpLogin}&${this.data.http.httpPasswordAlias}=${this.data.http.httpPassword}`;
        } else {
          tokenRequestUrl = this.data.http.httpTokenAddress;
          tokenRequestData = {
            [this.data.http.httpLoginAlias]: this.data.http.httpLogin,
            [this.data.http.httpPasswordAlias]: this.data.http.httpPassword,
          };
        }
        store.dispatch(getHttpToken(tokenRequestUrl, tokenRequestData, dataToSend, this.data.http));
      } else {
        store.dispatch(sendNoAuthHttpData(this.data.http, dataToSend));
      }
    }
  }

  sendEmail(postProcessingResponse, activeCircle, engineData, transitionTriggered) {
    if (this.data.email.active && postProcessingResponse.triggerOutput && postProcessingResponse.activeState !== activeCircle) {
      const dataToSend = {
        title: this.data.email.emailTitle,
        body: {
          text1: this.data.email.emailContent,
          text2: '',
          text4: `Instante de ativação: ${this.convertTimestamp(engineData.timestamp)}`,
        },
      };
      Object.keys(engineData).forEach((key, index) => {
        switch (key) {
          case 'img':
          case 'maskImg':
          case 'rectImg':
            dataToSend.body[`img${index}`] = captureImage(engineData[key]).split(',')[1];
            break;
          case 'classIndex':
            dataToSend.body.text5 = `Número da classe: ${engineData.classIndex + 1}`;
            break;
          case 'classLabel':
            dataToSend.body.text6 = `Nome da classe: ${engineData.classLabel}`;
            break;
          //TODO: other cases
          default:
            break;
        }
      });
      dataToSend.body.text2 = `\nTransição: ${transitionTriggered}`;
      store.dispatch(sendEmailNotification(dataToSend));
    }
  }

  showInfoToast(postProcessingResponse, activeCircle, engineData) {
    if (this.data.notification.active && postProcessingResponse.activeState !== activeCircle) {
      if (postProcessingResponse.triggerOutput) {
        const msg = (
          <div>
            <div className='flex flex-center w-100' style={{ fontSize: '20px' }}>
              Output ativado!
            </div>
            <br />
            <div className='flex flex-center w-100'>Transição de ativação</div>
            <div className='flex w-100 flex-center' style={{ margin: '4px 0', fontSize: '14px' }}>
              {postProcessingResponse.data.transitions[postProcessingResponse.currentTransition].name || (
                <>
                  {postProcessingResponse.data[postProcessingResponse.currentTransition.split('_')[0]].name}
                  <img style={{ width: '30px', height: '20px', margin: '0 6px' }} alt='transition arrow' src={`${process.env.PUBLIC_URL}/img/arrowForward.svg`} />
                  {postProcessingResponse.data[postProcessingResponse.currentTransition.split('_')[1]].name}
                </>
              )}
            </div>
            <br />
            <div className='flex flex-center w-100'>Instante de ativação</div>
            <div className='flex w-100 flex-center' style={{ margin: '4px 0', fontSize: '14px' }}>
              {`${this.convertTimestamp(engineData.timestamp)}`}
            </div>
            <br />
            <br />
            <div className='flex flex-center w-100'>Ativações</div>
            <div style={{ width: 'fit-content', margin: '0 auto' }}>
              {Object.keys(postProcessingResponse.outputCounter).map(transitionKey => {
                if (postProcessingResponse.outputCounter[transitionKey] > 0) {
                  return (
                    <div key={transitionKey} className='flex w-100' style={{ margin: '4px 0', fontSize: '14px', alignItems: 'center' }}>
                      {postProcessingResponse.data.transitions[transitionKey].name ? (
                        `${postProcessingResponse.data.transitions[transitionKey].name}: `
                      ) : (
                        <>
                          {postProcessingResponse.data[transitionKey.split('_')[0]].name}
                          <img style={{ width: '30px', height: '20px', margin: '0 6px' }} alt='transition arrow' src={`${process.env.PUBLIC_URL}/img/arrowForward.svg`} />
                          {postProcessingResponse.data[transitionKey.split('_')[1]].name}:
                        </>
                      )}
                      <div className='flex grown flex-end' style={{ margin: '0 0 0 14px' }}>{`${postProcessingResponse.outputCounter[transitionKey]}`}</div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        );
        let newPosition = Object.keys(this.data.notification.notificationPosition)[0].toString();
        if (this.toastInfoId === null && !toast.isActive(this.toastInfoId)) {
          if (lastId) toast.dismiss(lastId);
          this.toastInfoId = toast.info(msg, { position: newPosition || 'top-left', transition: Zoom, autoClose: false, onClose: this.dismissToast.bind(this) });
          lastId = this.toastInfoId;
        } else {
          toast.update(this.toastInfoId, {
            render: msg,
            position: newPosition,
            autoClose: false,
            onClose: this.dismissToast.bind(this),
          });
        }
      }
    } else if (!this.data.notification.active) {
      if (lastId) toast.dismiss(lastId);
    }
  }

  dismissToast() {
    toast.dismiss(this.toastInfoId);
    this.toastInfoId = null;
  }

  convertTimestamp(timestamp) {
    switch (timestamp.type) {
      case 'systemCam':
      case 'IPCam':
        return new Date(timestamp.value * 1000).toLocaleString('pt-BR');
      case 'videoCam':
        return `${timestamp.value.toFixed(1)} s`;
      case 'imagesCam':
        return `-`;
      default:
        return `${timestamp.value.toFixed(1)} s`;
    }
  }
}
