import { Card, CardContent } from '@react-md/card';
import GridEngine from '../gridEngine';
import { Button } from '@react-md/button';
import React from 'react';
import { bindActionCreators } from 'redux';
import * as appGridActions from '../../../../actions/appGrid';
import { connect } from 'react-redux';
import { DropdownMenu, MoreVertSVGIcon } from 'react-md';
import CameraDialog from '../../../cameraDialog/cameraDialog';
import PreProcessingDialog from '../../../preProcessing/preProcessingDialog';
import * as mainActions from '../../../../actions/main';
import '../../applyingModel.scss';
import { toast } from 'react-toastify';
import OutputDialog from '../../../output/outputDialog';
import PostProcessingDialog from '../../../postProcessing/postProcessingDialog';
import { MobileOnly, DesktopOnly } from '@react-md/utils';
import { changeCamera } from '../../../../actions/cameraConfig';

class CaptureSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testingModel: false,
      picTrigger: false,
      cameraModalEnabled: false,
      preProcessingModalEnabled: false,
      postProcessingModalEnabled: false,
      cameraRendered: false,
      modelFetched: false,
      outputModalEnabled: false,
      activeCircle: 'StateZero',
      controlledModelUrl: '',
    };
    this.captureImageButtonRef = React.createRef();
  }
  applyVideoSettings(settingsToSave) {
    if (!!this.props.cameraRef && this.props.cameraRef.type === 'systemCam' && !!settingsToSave) {
      this.props.cameraRef.applySettings(settingsToSave);
    }
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.modelUrl !== nextProps.modelUrl) {
      if (!!nextProps.modelUrl) {
        this.setState({ modelFetched: false, controlledModelUrl: nextProps.modelUrl });
      } else {
        this.setState({ testingModel: false, controlledModelUrl: nextProps.modelUrl });
      }
    }
    return true;
  }

  componentDidMount() {
    if (this.props.modelUrl) {
      this.setState({ controlledModelUrl: this.props.modelUrl });
    }
  }

  componentWillUnmount() {
    this.props.setGridModelUrl('');
  }

  render() {
    const dropItems = !this.props.cameraRef
      ? [{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]
      : [
          { children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) },
          { children: 'Pré-processamento', onClick: () => this.setState({ preProcessingModalEnabled: true }) },
          { children: 'Condições de Detecção', onClick: () => this.setState({ postProcessingModalEnabled: true }) },
          { children: 'Configurações de saída', onClick: () => this.setState({ outputModalEnabled: true }) },
        ];

    return (
      <>
        {this.state.cameraModalEnabled ? (
          <CameraDialog saveThumbsFromImageCam='appGrid' enabled={this.state.cameraModalEnabled} close={() => this.setState({ cameraModalEnabled: false })} />
        ) : null}
        <PreProcessingDialog enabled={this.state.preProcessingModalEnabled} close={() => this.setState({ preProcessingModalEnabled: false })} />
        <OutputDialog enabled={this.state.outputModalEnabled} close={() => this.setState({ outputModalEnabled: false })} />
        <PostProcessingDialog
          activeCircle={this.state.activeCircle}
          // gridMask: {label: 'Máscara', type: 'gridArray'},
          // hasFace: {label: 'Tem rosto', type: 'bool'},
          // hasEPI: {label: 'Tem epi', type: 'bool'},
          // hasLALA: {label: 'Tem LALA', type: 'bool'},
          // classIndex: {label: 'Número da Classe', type: 'integer', max: 5, min: 1}
          // classIndex: {label: 'Número da Classe', type: 'integer'} => min value will be 0 and max value will be unlimited

          // Object data at PostProcessingProcessor class, not at store

          varOptions={{ gridMask: { label: 'Máscara', type: 'gridArray' } }}
          enabled={this.state.postProcessingModalEnabled}
          close={() => this.setState({ postProcessingModalEnabled: false })}
        />

        <Card className={`flex curve-border-2 text-center flex-column h-100 scroll appGrid-capture-section fill-when-small ${this.props.applicationIsOnline ? '' : 'w-100'}`}>
          <div className='title-card-text w-100 flex flex-between'>
            <MobileOnly>
              Inferência
              <DropdownMenu
                id='camOptions'
                buttonType='icon'
                aria-label='camOptions'
                items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
              >
                <MoreVertSVGIcon />
              </DropdownMenu>
            </MobileOnly>
            <DesktopOnly>
              {this.props.applicationIsOnline ? (
                <>
                  Exibição
                  <DropdownMenu id='camOptions' buttonType='icon' aria-label='camOptions' items={dropItems}>
                    <MoreVertSVGIcon />
                  </DropdownMenu>
                </>
              ) : (
                <>
                  Inferência
                  <DropdownMenu
                    id='camOptions'
                    buttonType='icon'
                    aria-label='camOptions'
                    items={[{ children: 'Seleção de câmera', onClick: () => this.setState({ cameraModalEnabled: true }) }]}
                  >
                    <MoreVertSVGIcon />
                  </DropdownMenu>
                </>
              )}
            </DesktopOnly>
          </div>
          <CardContent className='flex flex-column grown' style={{ height: 'calc(100% - 70px)' }}>
            <div className='full-size flex flex-column grown' style={{ alignItems: 'center' }}>
              <div className='w-100 flex grown' style={{ maxHeight: 'calc(100% - 52px)', background: 'black' }}>
                <div className='flex w-100' style={{ backgroundColor: 'black' }}>
                  <GridEngine
                    picTrigger={this.state.picTrigger}
                    testing={this.state.testingModel}
                    disablePicTrigger={() => this.setState({ picTrigger: false })}
                    modelFetched={value => {
                      if (value) {
                        this.setState({ modelFetched: value });
                      } else {
                        this.props.setGridModelUrl('');
                        toast.error('Não foi possível buscar o modelo. Tente novamente. ');
                      }
                    }}
                    cameraIsRendered={() => {
                      this.setState({ cameraRendered: true });
                      if (this.props.interactWithTour && getComputedStyle(this.captureImageButtonRef.current).display !== 'none') this.props.cameraIsRendered();
                    }}
                    modelUrl={this.state.controlledModelUrl}
                    cameraRef={this.props.cameraRef}
                    storeThumb={thumbs => this.props.storeThumb(thumbs)}
                    preProcessingProcessor={this.props.preProcessingProcessor}
                    postProcessingProcessor={this.props.postProcessingProcessor}
                    activeCircle={this.state.activeCircle}
                    setActiveCircle={newActiveCircle => this.setState({ activeCircle: newActiveCircle })}
                    outputProcessor={this.props.outputProcessor}
                    setCamera={camera => this.props.changeCamera(camera)}
                    settings={this.props.settings}
                    applyVideoSettings={settingsToSave => this.applyVideoSettings(settingsToSave)}
                  />
                </div>
              </div>
              <div className='w-100 flex appGrid-capture-section-button-container'>
                <Button
                  ref={this.captureImageButtonRef}
                  className={`firstStep seventhStep appGrid-capture-section-button hide-when-small ${this.props.applicationIsOnline ? '' : 'none'}`}
                  disabled={this.state.testingModel || this.state.picTrigger || !this.state.cameraRendered}
                  onClick={() => {
                    this.setState({ picTrigger: !this.state.picTrigger });
                    if (this.props.isTourOpen) {
                      this.props.unlockSecondStep();
                    }
                  }}
                  theme='primary'
                  themeType='contained'
                >
                  capturar
                </Button>

                {!!this.state.controlledModelUrl && !this.state.modelFetched ? (
                  <Button disabled={true} theme='primary' themeType='contained' className='applyingModel appGrid-capture-section-button fill-when-small'>
                    Aplicando
                    <span className='dot' />
                    <span className='dot' />
                    <span className='dot' />
                  </Button>
                ) : (
                  <Button
                    id='ninthStep'
                    className={`appGrid-capture-section-button fill-when-small ${this.props.applicationIsOnline ? '' : 'w-100'}`}
                    onClick={() => this.setState({ testingModel: !this.state.testingModel })}
                    disabled={!this.state.modelFetched || !this.state.controlledModelUrl || !this.state.cameraRendered}
                    theme='primary'
                    themeType='contained'
                  >
                    {this.state.testingModel ? 'Interromper' : 'Rodar Modelo'}
                  </Button>
                )}
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => ({
  modelUrl: state.appGrid.modelUrl,
  cameraRef: state.cameraConfig.newCameraRef,
  preProcessingProcessor: state.cameraConfig.preProcessingProcessor,
  postProcessingProcessor: state.cameraConfig.postProcessingProcessor,
  outputProcessor: state.cameraConfig.outputProcessor,
  applicationIsOnline: state.main.applicationIsOnline,
  settings: state.cameraConfig.settings,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...appGridActions,
      ...mainActions,
      changeCamera,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CaptureSection);
