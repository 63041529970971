import * as tf from '@tensorflow/tfjs';
import React from 'react';

const globalCanvas = document.createElement('canvas');

export async function cameraDeserialize(data) {
  switch (data.type) {
    case 'systemCam':
      const ref0 = new SystemCamera(data.id, data.interval);
      const ref0Response = await ref0.init();
      if (ref0Response === true) {
        return ref0;
      } else {
        console.warn('Error on systemCamera');
        return Promise.reject(ref0Response);
      }
    // case 'imagesCam':
    //   const ref1 = new SystemCamera(data.id);
    //   return await ref1
    //     .init()
    //     .then(res => ref1)
    //     .catch(err => Promise.reject(err));
    // case 'videoCam':
    //   const ref2 = new SystemCamera(data.id);
    //   return await ref2
    //     .init()
    //     .then(res => ref2)
    //     .catch(err => Promise.reject(err));
    case 'IPCam':
      const ref3 = new IPCamera(data.url, data.interval);
      const ref3Response = await ref3.init();
      if (ref3Response === true) {
        return ref3;
      } else {
        console.warn('Error on cameraIP');
        return Promise.reject(ref3Response);
      }
    default:
      const ref4 = new SystemCamera(data.id);
      const ref4Response = await ref4.init();
      if (ref4Response === true) {
        return ref4;
      } else {
        console.warn('Error on systemCamera');
        return Promise.reject(ref4Response);
      }
  }
}

export function updateCanvasDimension(cameraRef, canvasWidth, canvasHeight, preProcessWidth, preProcessHeight) {
  if (!!cameraRef) {
    if (cameraRef.type === 'imagesCam') {
      const index = cameraRef.index % cameraRef.dimensionsArray.length;
      if (index > -1 && (canvasWidth !== cameraRef.dimensionsArray[index]['width'] || canvasHeight !== cameraRef.dimensionsArray[index]['height'])) {
        return { canvasWidth: cameraRef.dimensionsArray[index]['width'], canvasHeight: cameraRef.dimensionsArray[index]['height'] };
      }
    } else if (cameraRef.type === 'IPCam') {
      const IPWidth = preProcessWidth || cameraRef.width;
      const IPHeight = preProcessHeight || cameraRef.height;
      if (canvasWidth !== IPWidth || canvasHeight !== IPHeight) {
        return { canvasWidth: IPWidth, canvasHeight: IPHeight };
      }
    } else {
      const videoWidth = preProcessWidth || cameraRef.video.width;
      const videoHeight = preProcessHeight || cameraRef.video.height;
      if (canvasWidth !== videoWidth || canvasHeight !== videoHeight) {
        return { canvasWidth: videoWidth, canvasHeight: videoHeight };
      }
    }
  }
}

export async function get_devices() {
  let devices = await navigator.mediaDevices?.enumerateDevices();
  let videoDevices = [];

  if (!!devices) {
    for (let el in devices) {
      if (devices[el].kind === 'videoinput' && !!devices[el].deviceId) videoDevices.push(devices[el]);
    }
  }
  return videoDevices;
}

export function captureImage(image) {
  window.cv.imshow(globalCanvas, image);
  return globalCanvas.toDataURL('image/jpeg', 0.95);
}

export function cvMatToTensor(mat) {
  if (mat.type() === window.cv.CV_8UC4) window.cv.cvtColor(mat, mat, window.cv.COLOR_RGBA2BGR);
  let data = new Float32Array(mat.data);
  return tf.tensor(data, [mat.rows, mat.cols, 3], 'float32');
}

export function applyGridMask(img, mask) {
  let ret = new window.cv.Mat();
  let gridSz = Math.sqrt(mask.length);
  let nmask = new window.cv.Mat.zeros(gridSz, gridSz, window.cv.CV_8UC3);
  let data = nmask.data;

  for (let i = 0; i < gridSz * gridSz; i++) {
    let idx = i * 3;

    switch (mask[i]) {
      case 1:
        data[idx] = 0;
        data[idx + 1] = 32;
        data[idx + 2] = 128;
        break;
      case 2:
        data[idx] = 152;
        data[idx + 1] = 11;
        data[idx + 2] = 11;
        break;
      case 3:
        data[idx] = 15;
        data[idx + 1] = 134;
        data[idx + 2] = 15;
        break;
      case 4:
        data[idx] = 222;
        data[idx + 1] = 222;
        data[idx + 2] = 18;
        break;
    }
  }
  window.cv.resize(nmask, nmask, new window.cv.Size(img.cols, img.rows), 0, 0, window.cv.INTER_NEAREST);
  window.cv.addWeighted(img, 1, nmask, 0.65, 0, ret);
  nmask.delete();
  return ret;
}

export class SystemCamera {
  interval = 40;
  videoCapabilities = null;
  constructor(device = null, fps = 25) {
    this.type = 'systemCam';
    this.device = device;
    this.interval = Math.round(1000 / (fps || 25)) || 40;
    this.video = document.createElement('video');
  }
  async init() {
    try {
      this.device = await navigator.mediaDevices.getUserMedia({ video: { deviceId: this.device, facingMode: 'environment' } });
      this.video.srcObject = this.device;
      this.video.play();
      this.video.onloadedmetadata = this.loadMetadata.bind(this);
      this.videoStream = this.device.getVideoTracks()[0];

      // this.photo = new ImageCapture(this.videoStream);
    } catch (e) {
      let message = 'Ocorreu um erro. Tente novamente.';
      if (e.message === 'Permission denied' || 'Requested device not found') {
        message = (
          <>
            <p className='w-100'>Não foi detectada nenhuma câmera integrada.</p>
            <p className='w-100'>Outros tipos de câmera podem ser configurados pelo menu do painel de Exibição</p>
          </>
        );
      }
      return message;
    }
    return true;
  }
  getInterval() {
    return this.interval;
  }
  captureStreamImg = () => {
    const mat = new window.cv.Mat(this.video.videoHeight, this.video.videoWidth, window.cv.CV_8UC4);
    if (!this.hasOwnProperty('cap')) {
      return null;
    }
    this.cap.read(mat);
    return mat;
  };
  captureSnapshotImg = this.captureStreamImg;
  capture = captureImage;
  serialize() {
    return {
      type: 'systemCam',
      id: this.device,
    };
  }
  getTimestamp() {
    return Date.now() / 1000;
  }
  dismissCam() {
    if (!!this.video) {
      this.stopTrack();
      this.video.removeEventListener('loadedmetadata', this.loadMetadata.bind(this));
      this.video.remove();
      this.video = null;
    }
  }
  loadMetadata() {
    if (!!this.video) {
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      this.cap = new window.cv.VideoCapture(this.video);
    }
  }
  stopTrack() {
    if (!!this.videoStream) {
      this.videoStream.stop();
    }
  }
  getVideoCapabilities() {
    this.videoCapabilities = this.videoStream.getCapabilities();
    this.videoSettings = this.videoStream.getSettings();
    return { capabilities: this.videoCapabilities, settings: this.videoSettings };
  }
  applySettings(settings) {
    this.videoStream
      .applyConstraints({ advanced: [settings] })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  }
}

export class VideoCamera {
  interval = 40;
  constructor(fps = 25, frameRate) {
    this.type = 'videoCam';
    this.interval = 1000 / (fps || 25) || 40;
    this.frameRate = frameRate || 1;
  }
  async init(input) {
    let filesSize = 0;
    let maxVideoSize = 70; //Mbs
    if (!!input) {
      if (input.type.split('/')[0] !== 'video') {
        return 'Formato inválido. Este modo suporta apenas um arquivo de vídeo.';
      }
      filesSize = input.size * 0.000001024;
      if (filesSize > maxVideoSize) {
        return 'Tamanho do vídeo excedeu o limite de ' + String(maxVideoSize) + 'Mbs.';
      }
      try {
        this.video = document.createElement('video');
        this.video.src = URL.createObjectURL(input);
        this.video.id = String(new Date());
        this.video.onloadedmetadata = this.loadMetadata.bind(this);
        this.video.onended = this.videoEnded.bind(this);
        this.video.loop = false;
        this.video.muted = true;
        this.video.load();
        return true;
      } catch {
        return 'Ocorreu um erro. Tente novamente.';
      }
    } else {
      return 'Insira 1 arquivo de vídeo para poder prosseguir.';
    }
  }
  getInterval() {
    return this.interval;
  }

  captureStreamImg = () => {
    if (this.video) {
      const mat = new window.cv.Mat(this.video.videoHeight, this.video.videoWidth, window.cv.CV_8UC4);
      if (!this.hasOwnProperty('cap')) {
        return null;
      }
      this.cap.read(mat);
      return mat;
    } else {
      return null;
    }
  };
  captureSnapshotImg = this.captureStreamImg;
  capture = captureImage;
  serialize = () => {
    return {
      type: 'videoCam',
    };
  };
  dismissCam() {
    if (!!this.video) {
      this.video.pause();
      this.video.removeEventListener('loadedmetadata', this.loadMetadata.bind(this));
      this.video.removeEventListener('ended', this.videoEnded.bind(this));
      this.video.remove();
      this.video = null;
    }
  }
  loadMetadata() {
    if (!!this.video) {
      this.video.width = this.video.videoWidth;
      this.video.height = this.video.videoHeight;
      this.cap = new window.cv.VideoCapture(this.video);
      this.video.playbackRate = this.frameRate;
    }
  }
  videoEnded() {
    if (!!this.video) {
      this.video.play();
    }
  }
  getTimestamp() {
    return this.video?.currentTime;
  }
}

export class ImagesCamera {
  interval = 2000;
  constructor(fps = 0.5) {
    this.type = 'imagesCam';
    this.interval = Math.round(1000 / (fps || 0.5)) || 2000;
    this.matArray = [];
    this.dimensionsArray = [];
    this.index = -1;
  }
  getInterval() {
    return this.interval;
  }
  async init(files) {
    let filesSize = 0;
    let maxImagesSize = 70; //Mbs
    if (!!files) {
      if (!!files.find(file => file.type.split('/')[0] !== 'image')) {
        return 'Formato de arquivos inválido. Esta câmera suporta apenas imagens.';
      }
      files.forEach(file => {
        filesSize += file.size * 0.000001024;
      });
      console.log(filesSize);
      if (filesSize > maxImagesSize) {
        return 'Tamanho das imagens excedeu o limite de 100Mbs.';
      }
      const promisesArray = [];
      try {
        files.forEach(file => {
          const image = new Image();
          image.src = URL.createObjectURL(file);
          promisesArray.push(this.onImageLoad(image));
        });
        return await Promise.all(promisesArray)
          .then(response => {
            const newMat = [];
            const newDimensions = [];
            let okImages = 0;
            response.forEach(element => {
              if (element !== null) {
                newMat.push(element[0]);
                newDimensions.push(element[1]);
                okImages += 1;
              }
            });
            this.matArray = this.matArray.concat(newMat);
            this.dimensionsArray = this.dimensionsArray.concat(newDimensions);

            return okImages > 0 ? true : 'Não foi possível carregar as imagens. Verifique a integridade dos arquivos inseridos e se o formato deles é suportado.';
          })
          .catch(err => {
            return err;
          });
      } catch {
        return 'Ocorreu um erro. Tente novamente.';
      }
    }
    return 'Insira pelo menos uma imagem para prosseguir.';
  }
  onImageLoad(image) {
    return new Promise((resolve, reject) => {
      let timeout = null;
      timeout = setTimeout(out, 5000);
      image.onload = () => {
        clearTimeout(timeout);
        resolve([window.cv.imread(image), { width: image.naturalWidth, height: image.naturalHeight }]);
      };
      function out() {
        clearTimeout(timeout);
        resolve(null);
      }
    });
  }
  captureStreamImg = () => {
    this.index = this.index += 1;
    if (!!this.matArray[this.index % this.matArray.length]) {
      return this.matArray[this.index % this.matArray.length].clone();
    }
    return null;
  };

  async getBase64Images(array = this.matArray) {
    const canvas = document.createElement('canvas');
    const blobs = [];
    if (!!array) {
      for (const [index, img] of array.entries()) {
        blobs.push(captureImage(img));
      }
    }
    canvas.remove();
    return blobs;
  }
  captureSnapshotImg() {
    if (!!this.matArray[this.index % this.matArray.length]) {
      return this.matArray[this.index % this.matArray.length].clone();
    }
    return null;
  }
  capture = captureImage;
  serialize() {
    return {
      type: 'imagesCam',
    };
  }
  getTimestamp() {
    return null;
  }
  dismissCam() {}
}

export class IPCamera {
  interval = 30;
  static streams = {};
  streamImage = null;
  constructor(url, fps = 33) {
    this.type = 'IPCam';
    this.url = url;
    this.interval = Math.round(1000 / (fps || 33)) || 30;
  }
  getInterval() {
    return this.interval;
  }
  async init() {
    if (!this.url) {
      return 'Entre com um endereço válido.';
    }
    if (!!IPCamera.streams[this.url]) {
      this.streamImage = IPCamera.streams[this.url];
      this.width = this.streamImage.naturalWidth;
      this.height = this.streamImage.naturalHeight;
      this.streamType = 'img';
      return true;
    }
    this.streamImage = new Image();
    this.streamImage.src = this.url;
    this.streamImage.crossOrigin = 'Anonymous';
    return this.onImageLoad(this.streamImage)
      .then(res => {
        this.width = this.streamImage.naturalWidth;
        this.height = this.streamImage.naturalHeight;
        IPCamera.streams[this.url] = this.streamImage;
        this.streamType = 'img';
        return !!this.streamImage && !!this.width && !!this.height;
      })
      .catch(err => {
        return this.checkVideoStream()
          .then(() => {
            this.streamType = 'video';
            return true;
          })
          .catch(() => err);
      });
  }
  checkVideoStream() {
    return new Promise((resolve, reject) => {
      try {
        this.streamVideo = document.createElement('video');
        this.streamVideo.src = this.url;
        this.streamVideo.crossOrigin = 'anonymous';
        this.streamVideo.onloadedmetadata = this.loadMetadata.bind(this);
        this.streamVideo.loop = false;
        this.streamVideo.muted = true;
        this.streamVideo.load();
        return this.streamVideo
          .play()
          .then(() => resolve())
          .catch(() => reject());
      } catch (e) {
        reject();
      }
    });
  }
  loadMetadata() {
    if (!!this.streamVideo) {
      this.streamVideo.height = this.streamVideo.videoHeight;
      this.streamVideo.width = this.streamVideo.videoWidth;
      this.cap = new window.cv.VideoCapture(this.streamVideo);
    }
  }
  captureStreamImg = () => {
    if (this.streamType === 'img') {
      return window.cv.imread(this.streamImage);
    } else if (!!this.streamVideo) {
      const mat = new window.cv.Mat(this.streamVideo.height, this.streamVideo.width, window.cv.CV_8UC4);
      if (!this.hasOwnProperty('cap')) {
        return null;
      }
      this.cap.read(mat);
      return mat;
    }
    return null;
  };
  onImageLoad(image) {
    return new Promise((resolve, reject) => {
      let timeout = null;
      timeout = setTimeout(out, 5000);
      image.onload = () => {
        clearTimeout(timeout);
        resolve();
      };
      function out() {
        clearTimeout(timeout);
        reject('Tempo de conexão excedido. Verifique se o endereço está correto ou se o CORS está devidamente configurado.');
      }
    });
  }
  captureSnapshotImg = this.captureStreamImg;
  capture = captureImage;
  serialize() {
    return {
      type: 'IPCam',
      url: this.url,
    };
  }
  getTimestamp() {
    return Date.now() / 1000;
  }
  dismissCam() {
    if (this.streamType === 'video') {
      if (!!this.streamVideo) {
        this.streamVideo.pause();
        this.streamVideo.removeEventListener('loadedmetadata', this.loadMetadata.bind(this));
        this.streamVideo.remove();
        this.streamVideo = null;
      }
    }
  }
}
