import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { VisibilityFontIcon, VisibilityOffFontIcon, DeleteForeverFontIcon } from 'react-md';
import { withResizeDetector } from 'react-resize-detector';
import { deleteLicense } from '../../../actions/licenses';
import { ConfirmationDialog } from '../../confirmationDialog/confirmationDialog';

class LicenseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleLicense: -1,
      confirmationDialogVisible: false,
      groupIndexToDelete: -1,
      groupIdToDelete: -1,
      licenseToDelete: {},
    };
  }

  deleteLicense() {
    const { groupIdToDelete, groupIndexToDelete, licenseToDelete } = this.state;
    this.props.deleteLicense(groupIdToDelete, groupIndexToDelete, licenseToDelete);
    this.cancelDeletion();
  }

  cancelDeletion() {
    this.setState({ groupIndexToDelete: -1, groupIdToDelete: -1, licenseToDelete: {}, confirmationDialogVisible: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.width !== this.props.width) {
      this.props.getLicenseWidth(this.props.width);
    }
  }

  handleLicense(licenseIndex) {
    this.setState(prevState => ({ visibleLicense: licenseIndex === prevState.visibleLicense ? -1 : licenseIndex }));
  }

  render() {
    const { visibleLicense, confirmationDialogVisible, creationDialogVisible } = this.state;
    const { licensesList, groupIndex, groupId, width, height } = this.props;
    return (
      <div className='license-rows-container'>
        <ConfirmationDialog
          confirmationDialogVisible={confirmationDialogVisible}
          confirmationDialogText='Deseja realmente deletar essa licença?'
          onCancel={() => this.cancelDeletion()}
          onConfirm={() => this.deleteLicense()}
        />
        {licensesList !== undefined &&
          licensesList[groupIndex].map((license, licenseIndex) => {
            const date = new Date(license.expires_at);
            return (
              <div key={`license ${licenseIndex}`}>
                <div className='w-100 license-row'>
                  <div className='license-col col1'>
                    <div>{license.created_by.email}</div>
                  </div>
                  <div className='license-col col2'>
                    <div>{license.cam_id}</div>
                  </div>
                  <div className='license-col col3'>
                    <div>{license.hw_id}</div>
                  </div>
                  <div className='license-col col4'>
                    <div>{date ? `${String(date.getUTCDate()).padStart(2, '0')}/${String(date.getUTCMonth() + 1).padStart(2, '0')}/${String(date.getFullYear())}` : '-'}</div>
                  </div>
                  <div className='license-col col5'>
                    {visibleLicense === licenseIndex ? (
                      <div>{license.key}</div>
                    ) : (
                      <span className='license-dots-container'>
                        {Array.from(Array(10)).map((_, dotIndex) => (
                          <div key={dotIndex} className='license-dots'>
                            .
                          </div>
                        ))}
                      </span>
                    )}
                  </div>
                  <div className='license-col col6'>
                    <div>
                      {visibleLicense === licenseIndex ? (
                        <VisibilityFontIcon className='pointer visibility-icon' onClick={() => this.handleLicense(licenseIndex)} />
                      ) : (
                        <VisibilityOffFontIcon className='pointer visibility-icon' onClick={() => this.handleLicense(licenseIndex)} />
                      )}
                    </div>
                  </div>
                  <div className='license-col col7'>
                    <div>
                      <DeleteForeverFontIcon
                        onClick={() => this.setState({ groupIndexToDelete: groupIndex, groupIdToDelete: groupId, licenseToDelete: license, confirmationDialogVisible: true })}
                        className='pointer remove-license-icon'
                      />
                    </div>
                  </div>
                </div>
                <div className='license-row-separator' />
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  licensesList: state.licenses.licensesList,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteLicense,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withResizeDetector(LicenseList));
