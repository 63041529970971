import React from 'react';
import { Card } from 'react-md';
import { Console } from 'console-feed';
// import { ErrorBoundary } from '../../../errorBoundary';

export default class LogSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  componentDidMount() {}

  render() {
    return (
      <Card className='w-100 flex grown flex-wrap log-container'>
        <div className='w-100 flex flex-center' style={{ height: '30px', fontWeight: 600, color: '#f44336' }}>
          |____LOG____|
        </div>
        <div className='scroll w-100' style={{ height: 'calc(100% - 30px)' }}>
          <Console logs={this.props.logs} variant='dark' />
        </div>
      </Card>
    );
  }
}
