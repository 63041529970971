import React from 'react';
import { bindActionCreators } from 'redux';
import { changeCurrent } from '../../../actions/main';
import { connect } from 'react-redux';

class ForgotPassword extends React.Component {
  render() {
    return <iframe style={{ border: 'none', height: '240px' }} title='Cadastro' className='signup-iframe w-100 h-100' src={`${this.props.url}/api/user/password_reset`} />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ changeCurrent }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
