import React from 'react';
import { Button, Select } from 'react-md';
import MaskConfig from './maskConfig';
import TimeFilter from './timeFilter';
import IntegerConfig from './integerConfig';
import * as lodash from 'lodash';

export default class PostProcessingConfig extends React.Component {
  render() {
    let firstConfigBox = null;
    let secondConfigBox = null;
    if (this.props.currentArrow && this.props.currentVar) {
      secondConfigBox = <TimeFilter setDialogState={obj => this.props.setDialogState(obj)} minimumFrames={this.props.minimumFrames} latestFrames={this.props.latestFrames} />;
      switch (this.props.varOptions[this.props.currentVar]?.type) {
        case 'gridArray':
          firstConfigBox = (
            <MaskConfig
              maskRules={this.props.maskRules}
              maskRule={this.props.maskRule}
              maskColor={this.props.maskColor}
              maskQuantity={this.props.maskQuantity}
              setDialogState={obj => this.props.setDialogState(obj)}
            />
          );
          break;
        case 'bool':
          firstConfigBox = (
            <div className='flex flex-wrap config-box first-config-box'>
              <h3 className='flex w-100 flex-center' style={{ margin: '4px 0px 14px' }}>
                Condição para ativação
              </h3>
              <div className='flex w-100 flex-between' style={{ margin: '4px 0' }}>
                Valor
                <Button
                  className='post-processing-config-height'
                  style={{ backgroundColor: '#223f54', color: 'white', width: '130px' }}
                  onClick={() => this.props.setDialogState({ booleanValue: !this.props.booleanValue })}
                >
                  {`${this.props.booleanValue ? 'Verdadeiro' : 'Falso'}`}
                </Button>
              </div>
            </div>
          );
          break;
        case 'integer':
          firstConfigBox = (
            <IntegerConfig
              maskRules={this.props.maskRules}
              integerRule={this.props.integerRule}
              integerValue={this.props.integerValue}
              setDialogState={obj => this.props.setDialogState(obj)}
              max={this.props.varOptions[this.props.currentVar].max}
              min={this.props.varOptions[this.props.currentVar].min}
            />
          );
          break;
        default:
          firstConfigBox = null;
          break;
      }
    }
    return (
      <>
        {!!this.props.varOptions ? (
          <Select
            disabled={!this.props.varOptions || !this.props.currentArrow}
            style={{ height: '50px', width: '90%' }}
            theme='outline'
            id='postProcessingVarSelect'
            options={Object.keys(this.props.varOptions).map(key => this.props.varOptions[key].label)}
            value={this.props.currentVar ? this.props.varOptions[this.props.currentVar]?.label : ''}
            name='postProcessingVarSelect'
            label='Variável'
            onChange={label => {
              const currentVar = lodash.findKey(this.props.varOptions, { label: label });
              if (this.props.currentVar !== currentVar) {
                if (this.props.varOptions[currentVar]?.type === 'integer' && this.props.varOptions[currentVar]?.min) {
                  this.props.setDialogState({ currentVar: currentVar, integerValue: this.props.varOptions[currentVar].min });
                } else {
                  this.props.setDialogState({ currentVar: currentVar });
                }
              }
            }}
            disableMovementChange={true}
          />
        ) : null}
        {firstConfigBox}
        {secondConfigBox}
      </>
    );
  }
}
