import React from 'react';
import { Card } from 'react-md';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/snippets/javascript';
import 'ace-builds/src-noconflict/theme-dracula';
import { Checkbox } from '@react-md/form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appCodeActions from '../../../../actions/appCode';

class EditorSection extends React.Component {
  customCommands = [
    {
      name: 'saveScript',
      bindKey: { win: 'ctrl-S', mac: 'Command-S' },
      exec: () => {
        this.props.runScript(this.props.scriptContent);
      },
    },
    {
      name: 'stopScript',
      bindKey: { win: 'ctrl-P', mac: 'Command-P' },
      exec: () => {
        this.props.stopScript();
      },
    },
    // {
    //   name: 'vim',
    //   bindKey: {
    //     win: 'ctrl-m ctrl-i ctrl-v',
    //     mac: 'Command-m Command-i Command-v',
    //   },
    //   exec: () => {
    //     this.editorRef.current.editor.setKeyboardHandler('ace/keyboard/vim');
    //   },
    // },
  ];
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
  }
  onSuperCodeEditorLoad() {}
  onSuperCodeEditorChange(newValue, options) {
    // this.setState(() => ({ editorContent: newValue }));
    this.props.setScriptContent(newValue);
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if ((this.props.runScript !== nextProps.runScript || this.props.stopScript !== nextProps.stopScript) && this.props.runningScript === nextProps.runningScript) {
      return false;
    }
    return true;
  }
  componentDidMount() {
    // this.props.setScriptContent(this.editorContent);
  }

  render() {
    return (
      <Card className='w-100 flex flex-wrap super-code-editor-card'>
        <div className='w-100 flex flex-start super-code-editor-run-code'>
          <Checkbox
            id='gridCheckbox'
            style={{ color: 'white' }}
            className='grid-checkbox'
            checked={this.props.runningScript}
            onChange={val => (!this.props.runningScript ? this.props.runScript(this.props.scriptContent) : this.props.stopScript())}
            name='Executar'
            label='Rodar script'
            theme='primary'
          />
        </div>
        <AceEditor
          ref={this.editorRef}
          className='super-code-editor'
          style={{ width: '100%', height: 'calc(100% - 40px)' }}
          placeholder='Construa seu código aqui'
          mode='javascript'
          theme={'dracula'}
          name='superCodeEditor'
          onLoad={this.onSuperCodeEditorLoad.bind(this)}
          onChange={this.onSuperCodeEditorChange.bind(this)}
          fontSize={16}
          showPrintMargin={true}
          showGutter={true}
          highlightActiveLine={true}
          value={this.props.scriptContent}
          wrapEnabled={true}
          commands={this.customCommands}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showLineNumbers: true,
            tabSize: 2,
            spellcheck: true,
          }}
        />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userName: state.user.firstName,
  scriptContent: state.appCode.scriptContent,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...appCodeActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditorSection);
