import produce from 'immer';
import { PreProcessingProcessor } from '../components/preProcessing/preProcessingProcessor';
import { PostProcessingProcessor } from '../components/postProcessing/postProcessingProcessor';
import { OutputProcessor } from '../components/output/outputProcessor';

function compare(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export default function appBarReducer(state, action) {
  if (action.error) {
    switch (action.type) {
      case 'APPBAR_CREATE_APP_CONFIG':
        return produce(state, draft => {
          draft.main.creatingAppConfig = false;
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
        });
      case 'APPBAR_FETCH_APPCONFIG':
        const mySelectApp = `${action.file.data.current}SelectApp`;
        return produce(state, draft => {
          draft[mySelectApp].current = null;
          draft[mySelectApp].apps = [];
          draft.main.creatingAppConfig = false;
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
        });
      case 'APPBAR_CHANGE_APP_CONFIG':
      case 'APPBAR_DELETE_APP_CONFIG':
        return produce(state, draft => {
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
          draft[action.file.data.current].pendingSave = false;

          draft.main.changeControl = false;
        });
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case 'APPBAR_SET_LOADING_BUTTON':
        return produce(state, draft => {
          draft.savingApp[action.file.current].forEach((element, index) => {
            index === action.file.buttonIndex ? (draft.savingApp[action.file.current][index] = 1) : (draft.savingApp[action.file.current][index] = -1);
          });
        });
      case 'APPBAR_CREATE_APP_CONFIG':
        const selectApp = `${action.file.data.current}SelectApp`;
        let currentApps = [...state[selectApp].apps];
        if (!currentApps.find(app => app.name === action.file.data.newAppName)) {
          currentApps.push({ name: action.file.data.newAppName, apptype: action.file.data.current });
        }
        return produce(state, draft => {
          draft[selectApp].apps = currentApps;
          draft[selectApp].current = { name: action.file.data.newAppName, apptype: action.file.data.current };
          draft.main.newAppDialogEnabled = false;
          draft.main.creatingAppConfig = false;
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
          draft[action.file.data.current].pendingSave = false;
        });
      case 'APPBAR_APP_CONFIG_DELETION_CONTROL':
        return produce(state, draft => {
          draft.main.deletionControl = action.file;
        });
      case 'APPBAR_DELETE_APP_CONFIG':
        return produce(state, draft => {
          draft[action.file.data.selectApp].apps = draft[action.file.data.selectApp].apps.filter(item => item.name !== action.file.data.appConfigName);
          draft[action.file.data.selectApp].current = null;
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
          draft[action.file.data.current].pendingSave = false;
          draft.main.deletionControl = false;
        });
      case 'APPBAR_APP_CONFIG_CHANGE_CONTROL':
        return produce(state, draft => {
          draft.main.changeControl = action.file;
        });
      case 'APPBAR_CHANGE_APP_CONFIG':
        const newApp = state[action.file.data.selectApp].apps[action.file.data.appIndex];
        const responseApp = action.file.data.apptype;
        const newForbiddenRoutes = [...state.main.forbiddenTokenRoutes];
        if (action.file.data.output.data?.http.active) {
          if (!newForbiddenRoutes.find(el => el === action.file.data.output.data.http.httpTokenAddress)) {
            newForbiddenRoutes.push(action.file.data.output.data.http.httpTokenAddress);
          }
          if (!newForbiddenRoutes.find(el => el === action.file.data.output.data.http.httpAddress)) {
            newForbiddenRoutes.push(action.file.data.output.data.http.httpAddress);
          }
        }
        return produce(state, draft => {
          draft[responseApp] = { ...draft[responseApp], ...action.file.data.customdata[responseApp] };
          if (!!action.file.data.cameraRef && !action.file.data.camError) {
            draft.cameraConfig.currentCamera = action.file.data.newCurrentCamera;
            draft.cameraConfig.newCameraRef = action.file.data.cameraRef;
          }
          if (action.file.data.newCurrentCamera?.data?.settings) {
            draft.cameraConfig.settings = action.file.data.newCurrentCamera.data.settings;
          }
          draft.cameraConfig.preProcessingProcessor = action.file.data.preprocessing.data ? new PreProcessingProcessor(action.file.data.preprocessing.data) : null;
          draft.cameraConfig.postProcessingProcessor = action.file.data.postprocessing.data ? new PostProcessingProcessor(action.file.data.postprocessing.data) : null;
          draft.cameraConfig.outputProcessor = action.file.data.output.data ? new OutputProcessor(action.file.data.output.data) : null;
          draft[action.file.data.selectApp].current = newApp;
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
          draft.main.forbiddenTokenRoutes = newForbiddenRoutes;

          draft.appGrid.noCapture = true;
          draft.appGrid.currentThumbImg = '';
          draft.appGrid.currentThumbIndex = -1;

          draft.appClass.noCapture = true;
          draft.appClass.currentThumbImg = '';
          draft.appClass.currentThumbIndex = -1;
          draft.appClass.currentClassIndex = 0;
          draft.appClass.showingImageClassIndex = -1;

          draft[responseApp].pendingSave = false;

          draft.main.changeControl = false;
        });
      case 'APPBAR_FETCH_APPCONFIG':
        const mySelectApp = `${action.file.data.current}SelectApp`;
        if (!!action.file.data.name) {
          return produce(state, draft => {
            draft[mySelectApp].apps = action.file.data.name.sort(compare);
            draft.main.localDataBaseUpdated *= -1;
          });
        }
        return state;
      case 'APPBAR_RESET_CURRENT_APP_CONFIG':
        return produce(state, draft => {
          draft[action.file].current = null;
          draft.appGrid.grids = [];
          draft.appGrid.thumbs = [];
          draft.appFace.people = [];
          draft.appCode.scriptContent = draft.appCode.defaultScript;
        });
      case 'APPBAR_RESET_APP_BUTTON_LOADING':
        return produce(state, draft => {
          draft.savingApp[action.file.data.current] = [0, 0, 0, 0];
        });
      default:
        return state;
    }
  }
}
