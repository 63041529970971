import axios from 'axios';
import { ImagesCamera, IPCamera, SystemCamera, VideoCamera } from '../camera';
import { toast } from 'react-toastify';

export function setCardSaveDisabled(position, value) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CAMERACONFIG_SET_CARD_SAVE_DISABLED',
      file: { position, value },
    });
  };
}

export function fetchCameras() {
  return (dispatch, getState) => {
    const state = getState();
    axios
      .get(`${state.appGrid.url}/api/camconfig`)
      .then(res => {
        dispatch({
          type: 'CAMERACONFIG_FETCH_CAMERAS',
          file: res,
        });
      })
      .catch(err => {
        dispatch({
          type: 'CAMERACONFIG_FETCH_CAMERAS',
          file: err,
          error: true,
        });
      });
  };
}

export function saveCamera(cameraName, content) {
  return (dispatch, getState) => {
    const state = getState();
    let cameraToastId = state.cameraConfig.cameraToastId;
    axios
      .put(`${state.appGrid.url}/api/camconfig/${cameraName}`, content)
      .then(res => {
        const msg = 'Dados de câmera salvos com sucesso.';
        toast.isActive(cameraToastId) ? toast.update(cameraToastId, { type: 'success', render: msg, autoClose: 4000 }) : (cameraToastId = toast.success(msg, { autoClose: 4000 }));
        const newRes = { ...res, cameraToastId };
        dispatch({
          type: 'CAMERACONFIG_SAVE_CAMERA',
          file: newRes,
        });
      })
      .catch(err => {
        dispatch({
          type: 'CAMERACONFIG_SAVE_CAMERA',
          file: err,
          error: true,
        });
      });
  };
}

export function changeCamera(camera, blockSaveButton) {
  return async (dispatch, getState) => {
    const state = getState();
    let cameraToastId = state.cameraConfig.cameraToastId;
    const current = state.main.current;
    const temporaryImages = state.cameraConfig.temporaryInputImages;
    const temporaryVideo = state.cameraConfig.temporaryVideoInput;
    const oldCameraRef = state.cameraConfig.newCameraRef;
    let newCameraRef = null;
    let cameraError = false;
    const allCameras = [...getState().cameraConfig.allCameras];
    const defaultCamera = allCameras.find(camera => camera.name === 'default');
    let newCamera = { ...camera };

    if (newCamera.name === '///' && !!defaultCamera && !!defaultCamera.data && Object.keys(defaultCamera.data).length > 0) {
      newCamera = { ...defaultCamera };
    } else if (newCamera.name === '///') {
      newCamera.name = 'default';
    }

    if (!!oldCameraRef && oldCameraRef.type === 'systemCam') {
      oldCameraRef.stopTrack();
    } else if (!!oldCameraRef && (oldCameraRef.type === 'videoCam' || oldCameraRef.type === 'IPCam')) {
      oldCameraRef.dismissCam();
    }

    let cameraResponse;

    switch (newCamera.data?.type) {
      case 'systemCam':
        newCameraRef = new SystemCamera(newCamera.data?.id, newCamera.data?.interval);
        cameraResponse = await newCameraRef.init();
        break;
      case 'IPCam':
        newCameraRef = new IPCamera(newCamera.data?.url, newCamera.data?.interval);
        cameraResponse = await newCameraRef.init();
        break;
      case 'imagesCam':
        if (temporaryImages.length > 0) {
          newCameraRef = new ImagesCamera(newCamera.data?.interval);
          cameraResponse = await newCameraRef.init(temporaryImages);
          break;
        }
        newCameraRef = new SystemCamera(null, null);
        cameraResponse = await newCameraRef.init();
        break;
      case 'videoCam':
        if (!!temporaryVideo) {
          newCameraRef = new VideoCamera(newCamera.data?.interval, newCamera.data?.frameRate);
          cameraResponse = await newCameraRef.init(temporaryVideo);
          break;
        }
        newCameraRef = new SystemCamera(null, null);
        cameraResponse = await newCameraRef.init();
        break;
      default:
        newCameraRef = new SystemCamera(null, null);
        cameraResponse = await newCameraRef.init();
        break;
    }
    let imagesToUpload = [];
    if (cameraResponse === true) {
      let camerasOk = true;
      if (newCameraRef.type === 'imagesCam') {
        imagesToUpload = await newCameraRef.getBase64Images();
      } else if (newCameraRef.type === 'videoCam') {
        try {
          await newCameraRef.video.play();
        } catch (e) {
          console.warn(e);
          camerasOk = false;
        }
      }
      if (camerasOk) {
        const msg = 'Câmera instanciada com sucesso.';
        toast.isActive(cameraToastId) ? toast.update(cameraToastId, { type: 'success', render: msg, autoClose: 4000 }) : (cameraToastId = toast.success(msg, { autoClose: 4000 }));
        if (blockSaveButton) {
          switch (newCamera.data?.type) {
            case 'systemCam':
              dispatch(setCardSaveDisabled(0, true));
              break;
            case 'IPCam':
              dispatch(setCardSaveDisabled(1, true));
              break;
            case 'imagesCam':
              dispatch(setCardSaveDisabled(2, true));
              break;
            case 'videoCam':
              dispatch(setCardSaveDisabled(3, true));
              break;
            default:
              break;
          }
        }
      } else {
        const msg = 'Não foi possível executar o vídeo inserido. Verifique sua integridade e se seu formato é suportado. ';
        toast.isActive(cameraToastId) ? toast.update(cameraToastId, { type: 'error', render: msg, autoClose: 4000 }) : (cameraToastId = toast.error(msg, { autoClose: 4000 }));
      }
    } else {
      newCameraRef = null;
      cameraError = true;
      toast.isActive(cameraToastId)
        ? toast.update(cameraToastId, { type: 'error', render: cameraResponse, autoClose: 4000 })
        : (cameraToastId = toast.error(cameraResponse, { autoClose: 4000 }));
    }
    dispatch({
      type: 'CAMERACONFIG_CHANGE_CAMERA',
      file: { current, newCamera, newCameraRef, cameraError, imagesToUpload, cameraToastId },
    });
  };
}

export function deleteCamera(cameraName) {
  return async (dispatch, getState) => {
    const state = getState();
    let cameraToastId = state.cameraConfig.cameraToastId;
    const oldCameraRef = getState().cameraConfig.newCameraRef;
    const res = await axios.delete(`${state.appGrid.url}/api/camconfig/${cameraName}`).catch(err => {
      dispatch({
        type: 'CAMERACONFIG_DELETE_CAMERA',
        file: err,
        error: true,
      });
      return null;
    });

    if (!!oldCameraRef && oldCameraRef.type === 'systemCam') {
      oldCameraRef.stopTrack();
    }

    const newCameraRef = new SystemCamera(null, 40);
    const newCameraResponse = await newCameraRef.init();

    if (newCameraResponse !== true) {
      const msg = newCameraResponse;
      toast.isActive(cameraToastId) ? toast.update(cameraToastId, { type: 'error', render: msg, autoClose: 4000 }) : (cameraToastId = toast.error(msg, { autoClose: 4000 }));
      const newRes = { ...res, cameraToastId };
      dispatch({
        type: 'CAMERACONFIG_DELETE_CAMERA',
        file: newRes,
        error: true,
      });
      return null;
    }

    const msg = 'Câmera deletada com sucesso.';
    toast.isActive(cameraToastId) ? toast.update(cameraToastId, { type: 'success', render: msg, autoClose: 4000 }) : (cameraToastId = toast.success(msg, { autoClose: 4000 }));

    res.data = { ...res.data, cameraName, newCameraRef };
    const newRes = { ...res, cameraToastId };
    dispatch({
      type: 'CAMERACONFIG_DELETE_CAMERA',
      file: newRes,
    });
  };
}

export function setLoadingButton(card, button) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CAMERACONFIG_SET_LOADING_BUTTON',
      file: { card, button },
    });
  };
}

export function setCapabilities(capabilities) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CAMERACONFIG_SET_CAPABILITIES',
      file: capabilities,
    });
  };
}

export function setSettings(settingsObj) {
  return (dispatch, getState) => {
    dispatch(setCardSaveDisabled(0, false));
    dispatch({
      type: 'CAMERACONFIG_SET_SETTINGS',
      file: settingsObj,
    });
  };
}

export function resetCameraConfig() {
  return (dispatch, getState) => {
    dispatch({
      type: 'CAMERACONFIG_RESET_CAMERA_CONFIG',
      file: true,
    });
  };
}

export function dismissOldCam() {
  return (dispatch, getState) => {
    const oldCameraRef = getState().cameraConfig.newCameraRef;
    if (!!oldCameraRef && oldCameraRef.type === 'systemCam') {
      oldCameraRef.stopTrack();
    }
    dispatch({
      type: 'CAMERACONFIG_DISMISS_OLD_CAM',
      file: true,
    });
  };
}

export function deleteCameraControl(param) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CAMERACONFIG_CAMERA_DELETION_CONTROL',
      file: param,
      needsConfirmation: true,
    });
  };
}

export function saveTemporaryFiles(type, files) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CAMERACONFIG_SAVE_TEMPORARY_FILES',
      file: { type, files },
    });
  };
}
