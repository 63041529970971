import produce from 'immer';

export default function outputReducer(state, action) {
  if (action.error) {
    return state;
  } else {
    switch (action.type) {
      case 'OUTPUT_SET_PROCESSOR':
        const apps = state.main.availableApps;
        const newForbiddenRoutes = [...state.main.forbiddenTokenRoutes];
        if (action.file?.data.http.active) {
          newForbiddenRoutes.push(action.file.data.http.httpTokenAddress);
          newForbiddenRoutes.push(action.file.data.http.httpAddress);
        }
        return produce(state, draft => {
          draft.cameraConfig.outputProcessor = action.file;
          draft.main.forbiddenTokenRoutes = newForbiddenRoutes;
          if (apps.find(app => app === state.main.current)) {
            draft[state.main.current].pendingSave = true;
          }
        });
      case 'OUTPUT_SEND_EMAIL_NOTIFICATION':
        return state;
      case 'OUTPUT_SEND_HTTP_NOTIFICATION':
        return state;
      default:
        return state;
    }
  }
}
