import produce from 'immer';
// import { toast } from 'react-toastify';

export default function appQualityReducer(state, action) {
  if (action.error) {
    // toast.error(`Ocorreu um erro. Tente novamente.`);
    // return produce(state, draft => {
    // });
    return state;
  } else {
    switch (action.type) {
      case 'APPQUALITY_SET_TAB_REF':
        const newTabsRefs = [...state.appQuality.tabsRefs];
        newTabsRefs[action.file.refIndex] = action.file.ref;
        return produce(state, draft => {
          // draft.appQuality.tabsRefs[action.file.refIndex] = action.file.ref
          draft.appQuality.tabsRefs = newTabsRefs;
        });
      case 'APPQUALITY_SET_RED_SQUARE_POSITION_AND_COORDINATES':
        return produce(state, draft => {
          draft.appQuality.temporaryRoi = { ...action.file.redSquarePosition };
          if (action.file.coordinates !== null) {
            draft.appQuality.preprocessing.roi.roiCoordinates = action.file.coordinates;
          }
        });
      case 'APPQUALITY_SET_IDENTIFICATION_VALUES_AT_STORE':
        return produce(state, draft => {
          if (action.file.params !== null && action.file.key !== null) {
            draft.appQuality.identification[action.file.key] = action.file.params;
          } else if (action.file.params !== null) {
            draft.appQuality.identification = action.file.params;
          }
        });
      case 'APPQUALITY_SET_PREPROCESSING_VALUES_AT_STORE':
        return produce(state, draft => {
          if (action.file.params !== null && action.file.key !== null) {
            draft.appQuality.preprocessing[action.file.key] = action.file.params;
          } else if (action.file.params !== null) {
            draft.appQuality.preprocessing = action.file.params;
          }
        });
      case 'APPQUALITY_SET_TEMPORARY_ROI':
        return produce(state, draft => {
          if (action.file.redSquarePosition !== null) {
            draft.appQuality.temporaryRoi = { ...action.file.redSquarePosition, blockedRoi: action.file.blockedRoi };
          } else {
            draft.appQuality.temporaryRoi.blockedRoi = action.file.blockedRoi;
          }
        });
      default:
        return state;
    }
  }
}
