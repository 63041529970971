import { AutoComplete } from 'react-md';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class FaceAutocomplete extends React.Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.setPersonName === nextProps.setPersonName;
  }
  render() {
    return (
      <AutoComplete
        style={{ width: '100%' }}
        className='hide-when-small'
        id='faceAppNamesAutocomplete'
        label='Nome'
        placeholder='Ex: Jõao da Silva'
        data={this.props.people.map((el, index) => el.name)}
        onChange={val => this.props.setPersonName(val.target.value)}
        onAutoComplete={val => this.props.setPersonName(val.value)}
      />
    );
  }
}

const mapStateToProps = state => ({
  people: state.appFace.people,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FaceAutocomplete);
