import React from 'react';
import CameraPreview from './sections/cameraPreview';
import CameraParams from './sections/cameraParams';
import './cameraConfig.scss';
import { bindActionCreators } from 'redux';
import { resetCameraConfig } from '../../../actions/cameraConfig';
import { connect } from 'react-redux';

class CameraConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillUnmount() {
    this.props.resetCameraConfig();
  }
  render() {
    return (
      <div className='main-content camera-config h-100 flex'>
        <CameraPreview />
        <CameraParams />
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetCameraConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CameraConfig);
