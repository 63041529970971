import produce from 'immer';

export default function licensesReducer(state, action) {
  if (action.error) {
    switch (action.type) {
      case 'LICENSES_GET_GROUPS':
        return produce(state, draft => {
          draft.licenses.groupsList = action.file.groups;
          draft.licenses.licensesList = action.file.licenses;
        });
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case 'LICENSES_GET_GROUPS':
        return produce(state, draft => {
          draft.licenses.groupsList = action.file.groups;
          draft.licenses.licensesList = action.file.licenses;
        });
      case 'LICENSES_CLEAN_LICENSE_INFO':
        return produce(state, draft => {
          draft.licenses.groupsList = undefined;
          draft.licenses.licensesList = undefined;
        });
      case 'LICENSES_DELETE_LICENSE':
        const allLicenses = [...state.licenses.licensesList];
        const targetLicenses = [...allLicenses[action.file.groupIndex]];
        targetLicenses.splice(action.file.licenseToDeleteIndex, 1);
        allLicenses[action.file.groupIndex] = targetLicenses;
        return produce(state, draft => {
          draft.licenses.licensesList = allLicenses;
        });
      case 'LICENSES_CREATE_LICENSE':
        return produce(state, draft => {
          draft.licenses.licensesList[action.file.groupIndex].unshift(action.file.newLicense);
        });
      default:
        return state;
    }
  }
}
