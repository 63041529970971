import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Card, CardContent } from '@react-md/card';
import ReactResizeDetector from 'react-resize-detector';
import './localConfig.scss';
import { Dialog, DialogContent, DialogHeader, DialogTitle, Select, TextField } from 'react-md';
import { fetchAppConfig } from '../../../actions/appBar';
import { Button } from '@react-md/button';
import { CloudFontIcon, PlayArrowFontIcon, CheckCircleFontIcon, ReportFontIcon, DeleteForeverFontIcon, SaveFontIcon, FileDownloadFontIcon } from '@react-md/material-icons';
import { saveLocalAppConfig, deleteLocalAppConfig, setLoadingButton, fetchOfflineApps, addRouteToForbidden } from '../../../actions/localConfig';
import { CircularProgress } from '@react-md/progress';
import { changeCurrent, setConnectedApp } from '../../../actions/main';
import { DialogFooter } from '@react-md/dialog';
import axios from 'axios';
import { toast } from 'react-toastify';

class LocalConfig extends React.Component {
  getAppConfigToastId = null;
  deployableApps = { appClass: 'clf', appGrid: 'grid' };
  apps = [
    { name: 'Classificação de imagens', src: process.env.PUBLIC_URL + '/img/tigerFinal.png', path: 'appClass', needStaff: false, isCommon: true },
    { name: 'Aplicação em grade', src: process.env.PUBLIC_URL + '/img/grid-girl.png', path: 'appGrid', needStaff: false, isCommon: true },
    { name: 'Download Runner', src: process.env.PUBLIC_URL + '/img/code.png', path: 'appGrid', needStaff: false, isCommon: false },
    // { name: 'Aplicação em grade', src: process.env.PUBLIC_URL + '/img/grid-girl.png', path: 'appGrid', needStaff: false },
    // { name: 'Aplicação em grade', src: process.env.PUBLIC_URL + '/img/grid-girl.png', path: 'appGrid', needStaff: false },
    // { name: 'Detecção de máscaras', src: process.env.PUBLIC_URL + '/img/covidMask.png', path: 'appEPI', needStaff: false },
    // { name: 'Reconhecimento facial', src: process.env.PUBLIC_URL + '/img/face.png', path: 'appFace', needStaff: true },
    // { name: 'Geração de código', src: process.env.PUBLIC_URL + '/img/code.png', path: 'appCode', needStaff: true },
  ];
  appWidth = 360;
  appMargin = 20;
  canFetch = true;
  googleFetchInterval = null;
  constructor(props) {
    super(props);
    this.state = {
      processingDownload: false,
      edgeDeployFinished: false,
      currentDevice: '',
      deployExecutionDialogEnabled: false,
      // set when user clicks on "executar"
      currentAppType: '',
      appGridCurrent: '',
      appClassCurrent: '',
      appGridApps: [],
      appClassApps: [],
    };
    this.verifyConnection();
    this.googleFetchInterval = setInterval(this.verifyConnection.bind(this), 3000);
  }

  verifyConnection() {
    if (this.canFetch) {
      this.canFetch = false;
      fetch('https://www.google.com', { mode: 'no-cors' })
        .then(res => {
          this.canFetch = true;
          console.log('on');
          this.props.setConnectedApp(true);
        })
        .catch(err => {
          this.canFetch = true;
          console.log('off');
          this.props.setConnectedApp(false);
        });
    } else {
      console.log('...');
    }
  }

  componentDidMount() {
    if (this.props.appConnected) {
      this.apps.forEach((app, index) => {
        this.props.fetchAppConfig(app.path);
      });
    } else {
      this.props.fetchOfflineApps();
    }
  }

  componentWillUnmount() {
    clearInterval(this.googleFetchInterval);
  }

  saveData(appType, appIndex) {
    this.props.setLoadingButton(appIndex, 1);
    this.props.saveLocalAppConfig(appType, this.state[appType + 'Current']);
  }
  deleteData(appType, appConfigName, appIndex) {
    this.props.setLoadingButton(appIndex, 0);
    this.setState({ [`${appType}Current`]: '' }, () => this.props.deleteLocalAppConfig(appType, appConfigName));
  }

  downloadRunner() {
    const url = 'https://drive.google.com/u/0/uc?export=download&confirm=-gsA&id=1UuBqtcMIpbSuiuCp5wMFo7QKqk2L1EMq';
    //const url = "https://drive.google.com/uc?export=download&id=1UuBqtcMIpbSuiuCp5wMFo7QKqk2L1EMq";
    window.open(url, '_blank');
  }

  async getAppConfigData() {
    const appConfigResponse = await axios.get(`${this.props.url}/api/appconfig/${this.state.currentAppType}/${this.state[this.state.currentAppType + 'Current']}`).catch(err => {
      const msg = 'Falha ao buscar dados do appConfig selecionado. Tente novamente.';
      toast.isActive(this.getAppConfigToastId)
        ? toast.update(this.getAppConfigToastId, { render: msg, autoClose: 2500 })
        : (this.getAppConfigToastId = toast.error(msg, { autoClose: 2500 }));
      return null;
    });
    return appConfigResponse.data;
  }

  async getBase64TfModel(appConfigData) {
    const requestObject = { app_name: this.state[this.state.currentAppType + 'Current'] };
    if (appConfigData.apptype === 'appClass' && appConfigData?.customdata?.appClass?.modelClassNames?.length) {
      requestObject['n_classes'] = appConfigData.customdata.appClass.modelClassNames.length;
    }
    const urlResponse = await axios.post(`${this.props.url}/api/${this.deployableApps[this.state.currentAppType]}/get_lite_model`, requestObject).catch(err => {
      return Promise.reject('Não foi possível buscar a url do modelo.');
    });

    const fetchModelResponse = await fetch(urlResponse.data.url).catch(err => {
      return Promise.reject('Não foi possível buscar o modelo. Faça um novo treinamento e tente novamente.');
    });

    const blobResponse = await fetchModelResponse.blob().catch(err => {
      const stringErr = typeof err === 'string' ? err : null;
      const stringErrMsg = err.hasOwnProperty('message') && typeof err.message === 'string' ? err.message : null;
      return Promise.reject(stringErr || stringErrMsg || 'Não foi possível enviar o modelo.');
    });

    const reader = new FileReader();
    reader.readAsDataURL(blobResponse);
    return await this.blobTo64Load(reader).catch(err => Promise.reject(err));
  }

  async blobTo64Load(reader) {
    return new Promise((resolve, reject) => {
      let timeout = null;
      timeout = setTimeout(out, 5000);
      reader.onloadend = () => {
        clearTimeout(timeout);
        resolve(reader.result);
      };
      function out() {
        clearTimeout(timeout);
        reject('Não foi possível converter o modelo.');
      }
    });
  }

  async runAppConfig(currentDevice, edgeAddress, download) {
    // const host = window.location.host.toString()
    if (currentDevice === 'plataforma') {
      const appType = this.state.currentAppType;
      const appConfigName = this.state[appType + 'Current'];

      const customPath = `?offline=true&id=${this.props.user.uuid}&appType=${appType}&appConfig=${appConfigName}`;
      if (window.history.pushState) {
        window.history.pushState({}, null, customPath);
      } else {
        window.location.href = customPath;
      }
      this.props.changeCurrent(appType);
    } else if (currentDevice === 'edge') {
      let newEdgeAddress = edgeAddress;
      if (edgeAddress.endsWith('/')) {
        newEdgeAddress = edgeAddress.slice(0, -1);
      }
      const sendingObject = {
        output_defs: {
          gridMask: 'gridArray',
          hasFace: 'bool',
          hasEPI: 'bool',
          classIndex: 'integer',
          img: 'image',
          maskImg: 'image',
        },
        app_name: this.state[this.state.currentAppType + 'Current'],
      };
      const appConfigData = await this.getAppConfigData();

      if (appConfigData !== null) {
        sendingObject.preprocessing = appConfigData.preprocessing.data;
        sendingObject.postprocessing = appConfigData.postprocessing.data;
      } else {
        return null;
      }
      const base64ModelWithHeader = await this.getBase64TfModel(appConfigData).catch(err =>
        typeof err === 'string' ? toast.error(err) : toast.error('Não foi possível enviar os dados.')
      );

      const withoutHeader = base64ModelWithHeader.split(',')[1];

      if (!download) {
        if (!!withoutHeader) {
          sendingObject['b64_model'] = withoutHeader;

          const requestResponse = await axios.post(`${newEdgeAddress}/api/platform/deploy/${this.state.currentAppType}`, sendingObject).catch(err => console.warn(err));

          if (requestResponse) {
            toast.success('Deploy realizado com sucesso.');
          } else {
            toast.error('Erro ao realizar deploy no dispositivo EDGE.');
          }
        }

        this.setState({ edgeDeployFinished: true }, () => this.setState({ edgeDeployFinished: false }));
      } else {
        if (withoutHeader) {
          sendingObject['b64_model'] = withoutHeader;
        }

        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(sendingObject));
        const anchorElem = document.createElement('a');
        anchorElem.setAttribute('href', dataStr);
        anchorElem.setAttribute('download', `${this.state.currentAppType}-${this.state[this.state.currentAppType + 'Current']}`);
        anchorElem.click();

        this.setState({ processingDownload: false });
      }
    }
  }

  // Must watch for menu changes, if it change we do nothing. It works.
  // If we dont do that, icons will be wrongly updated
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return this.props.menuEnabled === nextProps.menuEnabled;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.apps.forEach(appObject => {
      if (prevProps[appObject.path + 'Apps'] !== this.props[appObject.path + 'Apps']) {
        this.setState({ [appObject.path + 'Apps']: this.props[appObject.path + 'Apps'] });
      }
    });
    if (prevProps.localDataBaseUpdated !== this.props.localDataBaseUpdated) {
      this.apps.forEach(appObject => {
        const newApps = [...this.props[appObject.path + 'Apps']];
        newApps.forEach((app, index) => {
          const localResponse = localStorage.getItem(`${this.props.user.uuid}/${appObject.path}/${app.label}`);
          const storedAppConfig = !!localResponse ? JSON.parse(localResponse) : null;
          if (storedAppConfig) {
            if (storedAppConfig.created && newApps[index].created && new Date(storedAppConfig.created).getTime() >= new Date(newApps[index].created).getTime()) {
              newApps[index].leftAddon = <CheckCircleFontIcon className='flex flex-center' style={{ color: '#223f53', fontSize: '22px' }} />;
            } else {
              newApps[index].leftAddon = <ReportFontIcon className='flex flex-center' style={{ color: 'orange', fontSize: '22px' }} />;
            }
          } else {
            newApps[index].leftAddon = <CloudFontIcon className='flex flex-center' style={{ color: 'gray', fontSize: '22px' }} />;
          }
        });
        this.setState({ [appObject.path + 'Apps']: newApps });
      });
    }

    if (prevProps.appConnected !== this.props.appConnected) {
      if (this.props.appConnected) {
        this.apps.forEach((app, index) => {
          this.props.fetchAppConfig(app.path);
        });
      } else {
        const variables = {};
        Object.keys(this.deployableApps).forEach(appKey => {
          variables[appKey + 'Current'] = '';
        });
        this.setState(variables);
        this.props.fetchOfflineApps();
      }
    }
  }

  render() {
    return (
      <div className='main-content flex flex-column flex-center h-100 local-container'>
        <DeployExecutionDialogConnected
          currentDevice={this.state.currentDevice}
          run={(currentDevice, edgeAddress, download) => this.runAppConfig(currentDevice, edgeAddress, download)}
          enabled={this.state.deployExecutionDialogEnabled}
          close={() => this.setState({ deployExecutionDialogEnabled: false })}
          edgeDeployFinished={this.state.edgeDeployFinished}
          changeDevice={device => this.setState({ currentDevice: device })}
        />
        <ReactResizeDetector>
          {({ width, height }) => {
            const rows = Math.ceil(this.apps.length / Math.floor(width / this.appWidth));
            const maxColumns = Math.floor(width / this.appWidth) > this.apps.length ? this.apps.length : Math.floor(width / this.appWidth);
            return (
              <div className='w-100 h-100 flex flex-wrap flex-center scroll' style={{ fontSize: '18px' }}>
                <div className='flex flex-wrap local-cards-container' style={{ width: `${this.appWidth * maxColumns}px` }}>
                  {this.apps.map((el, index) => {
                    return (
                      <Card key={index} className='local-inner-card' style={{ maxHeight: `${Math.floor(height / rows) - this.appMargin}px` }}>
                        <div className='local-title-and-image-container'>
                          <div className='local-inner-card-title'>{this.apps[index].name}</div>
                          <div className='flex local-app-image-container'>
                            <img src={this.apps[index].src} alt='app logo' />
                          </div>
                        </div>
                        <CardContent className='local-inner-card-content flex flex-wrap'>
                          {el.isCommon ? (
                            <Select
                              disabled={this.state[el.path + 'Apps'].length < 1 || this.props.localLoadingButton !== -1}
                              style={{ margin: '20px auto 10px', width: '100%' }}
                              listboxClassName='appConfig-list'
                              displayLabelClassName='appConfig-label'
                              theme='outline'
                              id={`${el.path}Apps`}
                              options={this.state[el.path + 'Apps']}
                              value={this.state[el.path + 'Current']}
                              name='gridAppConfig'
                              label='App Config.'
                              onChange={val => this.setState({ [el.path + 'Current']: val })}
                              placeholder={'App Config.'}
                            />
                          ) : (
                            <Select
                              disabled={this.state[el.path + 'Apps'].length < 1 || this.props.localLoadingButton !== -1}
                              style={{ margin: '20px auto 10px', width: '100%' }}
                              listboxClassName='appConfig-list'
                              displayLabelClassName='appConfig-label'
                              theme='outline'
                              id={`${el.path}Apps`}
                              options={['Windows']}
                              value={'Windows'}
                              name='gridAppConfig'
                              label='Sistema Operacional'
                              onChange={val => this.setState({ [el.path + 'Current']: val })}
                              placeholder={'App Config.'}
                            />
                          )}

                          <div className='local-card-footer w-100 flex flex-wrap'>
                            {this.props.appConnected && (
                              <>
                                {el.isCommon && (
                                  <div className='w-100 flex flex-between button-row'>
                                    {this.props.localLoadingApp === index && this.props.localLoadingButton === 0 ? (
                                      <div className='local-button flex flex-center circular-container' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
                                        <CircularProgress id={`localDeleteButton`} className='appbar-action' centered={true} style={{ borderColor: 'white' }} />
                                      </div>
                                    ) : (
                                      <Button
                                        disabled={!this.state[el.path + 'Current'] || this.props.localLoadingButton !== -1 || !this.props.appConnected}
                                        className='local-button flex-start'
                                        theme='error'
                                        themeType='contained'
                                        onClick={() => this.deleteData(el.path, this.state[el.path + 'Current'], index)}
                                      >
                                        <DeleteForeverFontIcon style={{ fontSize: '28px', margin: '0 4px 0 0' }} />
                                        <p className='text-ellipsis' style={{ margin: '0' }}>
                                          remover
                                        </p>
                                      </Button>
                                    )}
                                    {this.props.localLoadingApp === index && this.props.localLoadingButton === 1 ? (
                                      <div className='local-button flex flex-center circular-container' style={{ borderRadius: '0.5rem', cursor: 'not-allowed' }}>
                                        <CircularProgress id={`localSaveButton`} className='appbar-action' centered={true} style={{ borderColor: 'white' }} />
                                      </div>
                                    ) : (
                                      <Button
                                        disabled={!this.state[el.path + 'Current'] || this.props.localLoadingButton !== -1 || !this.props.appConnected}
                                        className='local-button flex-start'
                                        theme='secondary'
                                        themeType='contained'
                                        onClick={() => this.saveData(el.path, index)}
                                      >
                                        <SaveFontIcon style={{ fontSize: '26px', margin: '0 6px 0 0' }} />
                                        <p className='text-ellipsis' style={{ margin: '0' }}>
                                          armazenar
                                        </p>
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                            {el.isCommon && (
                              <div className='w-100 flex flex-between button-row'>
                                {this.state.processingDownload && el.path === this.state.currentAppType ? (
                                  <div className='flex flex-center circular-container' style={{ cursor: 'not-allowed', backgroundColor: '#cccccc', width: '49%' }}>
                                    <CircularProgress id={`cameraDialogSaveButton`} className='appbar-action w-100' centered={true} style={{ borderColor: 'white' }} />
                                  </div>
                                ) : (
                                  <Button
                                    disabled={!this.state[el.path + 'Current']}
                                    className='local-button flex-start'
                                    theme='secondary'
                                    themeType='contained'
                                    onClick={() => {
                                      this.setState({ currentAppType: el.path, processingDownload: true }, () => this.runAppConfig('edge', '', true));
                                    }}
                                  >
                                    <FileDownloadFontIcon style={{ fontSize: '28px', margin: '0 4px 0 0' }} />
                                    <p className='text-ellipsis' style={{ margin: '0' }}>
                                      Baixar
                                    </p>
                                  </Button>
                                )}
                                {/* <Button
                                disabled={!this.state[el.path + 'Current'] || el.path !== 'appRunner'}

                                className='local-button flex-start'
                                theme='primary'
                                themeType='contained'
                                //onClick={() => this.setState({ deployExecutionDialogEnabled: true, currentAppType: el.path, currentDevice: 'plataforma' })}
                                onClick={() => this.downloadRunner()}
                              >
                                <PlayArrowFontIcon style={{ fontSize: '28px', margin: '0 4px 0 0' }} />
                                <p className='text-ellipsis' style={{ margin: '0' }}>
                                  DEPLOY
                                </p>
                              </Button> */}
                              </div>
                            )}

                            {el.isCommon ? (
                              <h1></h1>
                            ) : (
                              <div className='w-100 flex flex-between button-row'>
                                <Button
                                  //disabled={!this.state[el.path + 'Current'] || el.path !== 'appRunner'}

                                  className='local-button flex-start'
                                  theme='primary'
                                  themeType='contained'
                                  //onClick={() => this.setState({ deployExecutionDialogEnabled: true, currentAppType: el.path, currentDevice: 'plataforma' })}
                                  onClick={() => this.downloadRunner()}
                                >
                                  <PlayArrowFontIcon style={{ fontSize: '28px', margin: '0 4px 0 0' }} />
                                  <p className='text-ellipsis' style={{ margin: '0' }}>
                                    BAIXAR
                                  </p>
                                </Button>
                              </div>
                            )}
                          </div>
                        </CardContent>
                      </Card>
                    );
                  })}
                </div>
              </div>
            );
          }}
        </ReactResizeDetector>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  url: state.appGrid.url,
  menuEnabled: state.main.menuEnabled,
  localDataBaseUpdated: state.main.localDataBaseUpdated,
  localLoadingApp: state.main.localLoadingApp,
  localLoadingButton: state.main.localLoadingButton,
  appConnected: state.main.appConnected,
  appGridApps: state.appGridSelectApp.apps.map((app, index) => ({
    value: app.name,
    label: app.name,
    created: app.created,
    children: <span className='text-ellipsis'>{`${app.user ? app.user.firstName + ' - ' : ''}${app.name}`}</span>,
    leftAddon: <CheckCircleFontIcon className='flex flex-center' style={{ color: '#223f53', fontSize: '22px' }} />,
  })),
  appClassApps: state.appClassSelectApp.apps.map((app, index) => ({
    value: app.name,
    label: app.name,
    created: app.created,
    children: <span className='text-ellipsis'>{`${app.user ? app.user.firstName + ' - ' : ''}${app.name}`}</span>,
    leftAddon: <CheckCircleFontIcon className='flex flex-center' style={{ color: '#223f53', fontSize: '22px' }} />,
  })),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAppConfig,
      saveLocalAppConfig,
      deleteLocalAppConfig,
      setLoadingButton,
      fetchOfflineApps,
      changeCurrent,
      setConnectedApp,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocalConfig);

class DeployExecutionDialog extends React.Component {
  toastConnectId = null;
  constructor(props) {
    super(props);
    this.state = {
      edgeAddress: '',
      edgeLogin: '',
      edgePassword: '',
      connectionEstablished: false,
      tryingToConnect: false,
    };
  }
  closeDialog() {
    this.setState({
      edgeAddress: '',
      edgeLogin: '',
      edgePassword: '',
      connectionEstablished: false,
      tryingToConnect: false,
    });
    this.props.close();
  }
  tryConnection() {
    this.setState({ tryingToConnect: true });
    axios
      .post(`${this.state.edgeAddress}`)
      .then(res => {
        toast.success('Conexão com dispositivo EDGE estabelecida.', { autoClose: 2000 });
        this.props.addRouteToForbidden(this.state.edgeAddress);
        this.setState({ connectionEstablished: true, tryingToConnect: false });
      })
      .catch(err => {
        const msg = 'Não foi possível conectar ao dispositivo EDGE informado. Verifique o endereço e tente novamente.';
        toast.isActive(this.toastConnectId) ? toast.update(this.toastConnectId, { render: msg }) : (this.toastConnectId = toast.error(msg));
        this.setState({ tryingToConnect: false });
      });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.edgeDeployFinished !== this.props.edgeDeployFinished && !!this.props.edgeDeployFinished) {
      this.closeDialog();
    }
  }

  runDeploy() {
    this.setState({ tryingToConnect: true });
    this.props.run(this.props.currentDevice, this.state.edgeAddress, false);
  }

  render() {
    const msg =
      this.props.currentDevice === 'plataforma'
        ? 'Ao clicar em "Executar", você será redirecionado para um link a partir do qual será possivel rodar a aplicação diretamente, sem acesso à Internet, desde que o devido App Config. já esteja armazenado localmente.'
        : 'Nesta seção você poderá definir o endereço de um dispositivo EDGE para se conectar e em seguida realizar o deploy de uma aplicação.';
    return (
      <>
        <Dialog
          className='curve-border-2 deploy-card'
          id='DeployExecutionDialog'
          visible={this.props.enabled}
          onRequestClose={() => this.closeDialog()}
          aria-labelledby='dialog-title'
        >
          <DialogHeader className='flex flex-center'>
            <DialogTitle>Deploy</DialogTitle>
          </DialogHeader>

          <DialogContent style={{ color: 'dimgrey', fontWeight: '600' }}>
            <div className='w-100 flex'>
              <Button
                theme='secondary'
                themeType='contained'
                className={`output-button ${this.props.currentDevice === 'plataforma' ? 'selected-tab' : 'inactive-output-button'}`}
                onClick={() => this.props.changeDevice('plataforma')}
                style={{ width: '50%' }}
              >
                Plataforma
              </Button>
              <Button
                theme='secondary'
                themeType='contained'
                className={`output-button ${this.props.currentDevice === 'edge' ? 'selected-tab' : 'inactive-output-button'}`}
                onClick={() => this.props.changeDevice('edge')}
                style={{ width: '50%' }}
              >
                EDGE
              </Button>
            </div>

            <p>{msg}</p>
          </DialogContent>
          <DialogFooter>
            <div className='w-100 flex flex-between flex-wrap button-row'>
              {this.props.currentDevice === 'edge' ? (
                <div className='w-100 flex-wrap'>
                  <TextField
                    className='w-100'
                    style={{ marginBottom: '12px' }}
                    id='deployEdgeAddress'
                    label='Endereço IP - EDGE'
                    value={this.state.edgeAddress}
                    onChange={event => this.setState({ edgeAddress: event.target.value })}
                    onKeyPress={key => {
                      if ((key.keyCode === 13 || key.which === 13) && !!this.state.edgeAddress) {
                        if (this.props.currentDevice === 'edge' && this.state.connectionEstablished === false) {
                          this.tryConnection();
                        } else {
                          this.runDeploy();
                        }
                      }
                    }}
                  />
                  {/* AUTHENTICATION */}
                  {/*<ExpansionPanel*/}
                  {/*  id='edgeLoginPanel'*/}
                  {/*  expanded={this.state.connectionEstablished}*/}
                  {/*  header={this.state.connectionEstablished ? 'login' : ''}*/}
                  {/*  style={this.state.connectionEstablished ? { border: '1px solid #d7d7d7' } : {}}*/}
                  {/*  headerStyle={{*/}
                  {/*    textTransform: 'uppercase',*/}
                  {/*    justifyContent: 'center',*/}
                  {/*    fontWeight: 'bold',*/}
                  {/*    color: '#223f54',*/}
                  {/*  }}*/}
                  {/*  onClick={() => null}*/}
                  {/*  onExpandClick={() => null}*/}
                  {/*>*/}
                  {/*  <div className='w-100 flex-wrap'>*/}
                  {/*    <TextField*/}
                  {/*      className='w-100 '*/}
                  {/*      id='deployEdgeLogin'*/}
                  {/*      label='Usuário'*/}
                  {/*      value={this.state.edgeLogin}*/}
                  {/*      onChange={event => this.setState({ edgeLogin: event.target.value })}*/}
                  {/*      style={{ marginBottom: '10px' }}*/}
                  {/*      onKeyPress={key => {*/}
                  {/*        if (key.keyCode === 13 || key.which === 13) {*/}
                  {/*          this.props.run();*/}
                  {/*        }*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*    <Password*/}
                  {/*      className='w-100'*/}
                  {/*      visibilityStyle={{ color: '#223f54' }}*/}
                  {/*      id='deployEdgePassword'*/}
                  {/*      label='Senha'*/}
                  {/*      value={this.state.edgePassword}*/}
                  {/*      type='password'*/}
                  {/*      onChange={event => this.setState({ edgePassword: event.target.value })}*/}
                  {/*      onKeyPress={key => {*/}
                  {/*        if (key.keyCode === 13 || key.which === 13) {*/}
                  {/*          this.props.run();*/}
                  {/*        }*/}
                  {/*      }}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</ExpansionPanel>*/}
                </div>
              ) : null}
              {this.state.tryingToConnect ? (
                <div className='flex flex-center circular-container w-100' style={{ borderRadius: '0.5rem', cursor: 'not-allowed', backgroundColor: '#cccccc' }}>
                  <CircularProgress id={`cameraDialogSaveButton`} className='appbar-action w-100' centered={true} style={{ borderColor: 'white' }} />
                </div>
              ) : (
                <Button
                  className='w-100'
                  disabled={this.props.currentDevice === 'edge' && !this.state.edgeAddress}
                  theme={this.props.currentDevice === 'edge' && this.state.connectionEstablished === false ? 'secondary' : 'primary'}
                  themeType='contained'
                  onClick={() => {
                    this.props.currentDevice === 'edge' && this.state.connectionEstablished === false ? this.tryConnection() : this.runDeploy();
                  }}
                >
                  {this.props.currentDevice === 'edge' && this.state.connectionEstablished === false ? 'conectar' : 'executar'}
                </Button>
              )}
            </div>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

const dialogStateToProps = state => ({});

const dialogDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addRouteToForbidden,
    },
    dispatch
  );

const DeployExecutionDialogConnected = connect(dialogStateToProps, dialogDispatchToProps)(DeployExecutionDialog);
