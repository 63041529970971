import produce from 'immer';

export default function mainReducer(state, action) {
  if (action.error) {
    return state;
  } else {
    switch (action.type) {
      case 'MAIN_CHANGE_CURRENT':
        const apps = state.main.availableApps;
        return produce(state, draft => {
          draft.main.current = action.file;
          draft.main.menuEnabled = false;
          apps.forEach(app => {
            draft[app].pendingSave = false;
          });
        });
      case 'MAIN_CV_LOADED':
        return produce(state, draft => {
          draft.main.cvloaded = true;
        });
      case 'MAIN_HANDLE_MENU':
        return produce(state, draft => {
          draft.main.menuEnabled = action.file;
        });
      case 'MAIN_HANDLE_NEW_APP_DIALOG':
        return produce(state, draft => {
          draft.main.newAppDialogEnabled = action.file;
        });
      case 'MAIN_SET_CREATING_APP_CONFIG':
        return produce(state, draft => {
          draft.main.creatingAppConfig = action.file;
        });
      case 'MAIN_SET_INTERCEPTOR_INSTANCE':
        return produce(state, draft => {
          draft.main.interceptorInstance = action.file;
        });
      case 'MAIN_SET_HTTP_OUTPUT_AXIOS':
        return produce(state, draft => {
          draft.main.httpOutputAxios = action.file;
        });
      case 'MAIN_FINISH_TUTORIAL':
        return produce(state, draft => {
          draft.main.tutorialDone = true;
        });
      case 'MAIN_SET_ONLINE_STATE':
        if (action.file !== state.main.applicationIsOnline) {
          return produce(state, draft => {
            draft.main.applicationIsOnline = action.file;
          });
        }
        return state;
      case 'MAIN_SET_CONNECTED_APP':
        if (action.file !== state.main.appConnected) {
          return produce(state, draft => {
            draft.main.appConnected = action.file;
          });
        }
        return state;
      case 'MAIN_SET_CONFIRMATION_INFO':
        return produce(state, draft => {
          draft.main.confirmationDialogVisible = action.file.confirmationDialogVisible;
          draft.main.confirmationDialogText = action.file.confirmationDialogText;
          draft.main.confirmationDialogAnswer = action.file.confirmationDialogAnswer;
          draft.main.menuEnabled = false;
        });
      default:
        return state;
    }
  }
}
