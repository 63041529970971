import React from 'react';
import { Card, CardContent } from 'react-md';

export default class NoGroupMessage extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <Card className='no-group-message-container'>
        <div className='w-100 title-card-text flex flex-center no-group-title'>Olá, {user.firstName}</div>
        <CardContent className='no-group-message-card-content'>
          <div className='w-100 no-group-message-text'>Você ainda não possui nenhum grupo, por isso ainda não pode gerenciar suas licenças.</div>
          <hr className='no-group-separator' />
          <div className='w-100 no-group-message-text'>Para criar seu grupo ou participar de algum outro, entre em contato com a gente através do nosso email.</div>
        </CardContent>
        <div className='no-group-message-footer flex flex-center'>
          <div>Email para contato:</div>
          <a href='mailto:contato@mvisia.com.br'>contato@mvisia.com.br</a>
        </div>
      </Card>
    );
  }
}
