import appGridReducer from './appGrid';
import mainReducer from './main';
import appFaceReducer from './appFace';
import preProcessingReducer from './preProcessing';
import postProcessingReducer from './postProcessing';
import appBarReducer from './appBar';
import userReducer from './user';
import appCodeReducer from './appCode';
import outputReducer from './output';
import appClassReducer from './appClass';
import localConfigReducer from './localConfig';
import cameraConfigReducer from './cameraConfig';
import appQualityReducer from './appQuality';
import licensesReducer from './licenses';

export default function appReducer(state, action) {
  if (action.type.startsWith('MAIN')) {
    return mainReducer(state, action);
  } else if (action.type.startsWith('APPGRID')) {
    return appGridReducer(state, action);
  } else if (action.type.startsWith('CAMERACONFIG')) {
    return cameraConfigReducer(state, action);
  } else if (action.type.startsWith('APPFACE')) {
    return appFaceReducer(state, action);
  } else if (action.type.startsWith('PREPROCESSING')) {
    return preProcessingReducer(state, action);
  } else if (action.type.startsWith('POSTPROCESSING')) {
    return postProcessingReducer(state, action);
  } else if (action.type.startsWith('APPBAR')) {
    return appBarReducer(state, action);
  } else if (action.type.startsWith('USER')) {
    return userReducer(state, action);
  } else if (action.type.startsWith('APPCODE')) {
    return appCodeReducer(state, action);
  } else if (action.type.startsWith('OUTPUT')) {
    return outputReducer(state, action);
  } else if (action.type.startsWith('APPCLASS')) {
    return appClassReducer(state, action);
  } else if (action.type.startsWith('LOCALCONFIG')) {
    return localConfigReducer(state, action);
  } else if (action.type.startsWith('APPQUALITY')) {
    return appQualityReducer(state, action);
  } else if (action.type.startsWith('LICENSES')) {
    return licensesReducer(state, action);
  }
  return state;
}
