//////////////////////////////
////////// GLOBAL ////////////
//////////////////////////////

export function setTabRef(ref, refIndex) {
  return (dispatch, getState) => {
    dispatch({ type: 'APPQUALITY_SET_TAB_REF', file: { ref, refIndex } });
  };
}

export function setRedSquarePositionAndCoordinates(redSquarePosition, coordinates) {
  return (dispatch, getState) => {
    dispatch({ type: 'APPQUALITY_SET_RED_SQUARE_POSITION_AND_COORDINATES', file: { redSquarePosition, coordinates } });
  };
}

export function setTemporaryRoi(redSquarePosition, blockedRoi) {
  return (dispatch, getState) => {
    dispatch({ type: 'APPQUALITY_SET_TEMPORARY_ROI', file: { redSquarePosition, blockedRoi } });
  };
}

//////////////////////////////
//////// PREPROCESSING ///////
//////////////////////////////

export function setPreprocessingValuesAtStore(key, params) {
  return (dispatch, getState) => {
    dispatch({ type: 'APPQUALITY_SET_PREPROCESSING_VALUES_AT_STORE', file: { key, params } });
  };
}

//////////////////////////////
//////// IDENTIFICATION //////
//////////////////////////////

export function setIdentificationValuesAtStore(key, params) {
  return (dispatch, getState) => {
    dispatch({ type: 'APPQUALITY_SET_IDENTIFICATION_VALUES_AT_STORE', file: { key, params } });
  };
}
