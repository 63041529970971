import React from 'react';
import { bindActionCreators } from 'redux';
import * as appGridActions from '../../../../actions/appGrid';
import { connect } from 'react-redux';

class GridMarking extends React.Component {
  lastDragIndex = -1;
  isMouseDown = false;
  lastTouchedGridCell = '';

  constructor(props) {
    super(props);
    this.state = {
      gridArray: Array(props.gridSize * props.gridSize).fill(0),
      gridArrayToThemAll: Array(props.gridSize * props.gridSize).fill(0),
      imageWidth: '100%',
      imageHeight: '100%',
      containerWidth: '100%',
      containerHeight: '100%',
    };
    this.imageRef = React.createRef();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.gridArray !== this.props.gridArray) {
      this.setState({ gridArray: this.props.gridArray, gridArrayToThemAll: this.props.gridArray });
    } else if (prevProps.markFullGridTrigger !== this.props.markFullGridTrigger && typeof this.props.markFullGridTrigger === 'number') {
      this.markAll().then(() => console.log(''));
    } else if (prevProps.markAllImagesChecked !== this.props.markAllImagesChecked) {
      this.setState({ gridArrayToThemAll: Array(this.props.gridSize * this.props.gridSize).fill(0) });
    } else if (prevProps.markAllImagesTrigger !== this.props.markAllImagesTrigger && typeof this.props.markAllImagesTrigger === 'number') {
      this.markThemAll();
    }
    if (
      this.props.currentThumbIndex !== prevProps.currentThumbIndex ||
      this.props.currentThumbThumb !== prevProps.currentThumbThumb ||
      this.props.parentsWidth !== prevProps.parentsWidth ||
      this.props.parentsHeight !== prevProps.parentsHeight
    ) {
      this.calculateImageDimensions();
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return true;
  }

  markGrid(index, target) {
    if (this.state[target][index] !== this.props.color && index !== this.lastDragIndex) {
      const newGridArray = [...this.state[target]];
      newGridArray[index] = this.props.color;
      this.setState({ [target]: newGridArray, lastDragIndex: index });
    }
  }
  storeGrid() {
    if (JSON.stringify(this.state.gridArray) !== JSON.stringify(this.props.gridArray)) {
      this.props.storeGrid(this.props.currentThumbIndex, this.state.gridArray);
      if (this.props.interactWithTour) {
        this.props.unlockProceedButton();
      }
    }
  }

  markThemAll() {
    this.props.markThemAll(this.state.gridArrayToThemAll);
  }

  async markAll() {
    if (!this.props.markAllImagesChecked) {
      let newGridArray = [...this.state.gridArray].map((val, idx) => {
        return this.props.color;
      });
      await this.setState({ gridArray: newGridArray });
      this.storeGrid();
    } else {
      let newGridArray = [...this.state.gridArrayToThemAll].map((val, idx) => {
        return this.props.color;
      });
      await this.setState({ gridArrayToThemAll: newGridArray });
    }
  }

  calculateImageDimensions() {
    this.setState({ containerWidth: '100%', containerHeight: '100%' }, () => {
      if (!this.props.noCapture) {
        const pw = this.props.parentsWidth || 1;
        const ph = this.props.parentsHeight || 1;

        // ratios
        const ir = this.imageRef.current.naturalWidth / this.imageRef.current.naturalHeight;
        const pr = pw / ph;

        if (ir >= pr) {
          this.setState({ imageWidth: `${Math.round(pw)}px`, imageHeight: 'auto' }, this.fitContainer);
        } else {
          this.setState({ imageWidth: 'auto', imageHeight: `${Math.round(ph)}px` }, this.fitContainer);
        }
      }
    });
  }

  fitContainer() {
    this.setState({ containerWidth: 'fit-content', containerHeight: 'fit-content' });
  }

  render() {
    return (
      <div className='flex flex-center grid-marking-container' style={{ width: this.state.containerWidth, height: this.state.containerHeight }}>
        {this.props.noCapture ? <img alt='no capture' className='flex w-100 h-100' style={{ objectFit: 'contain' }} src={process.env.PUBLIC_URL + '/img/noImg.png'} /> : null}
        <img
          alt='selected thumbnail'
          style={{ width: this.state.imageWidth, height: this.state.imageHeight, display: !!this.props.currentThumbThumb ? 'flex' : 'none' }}
          src={this.props.currentThumbThumb}
          ref={this.imageRef}
        />
        {!this.props.noCapture ? (
          <div className={`grid-${this.props.gridSize} full-size`}>
            {!this.props.markAllImagesChecked ? (
              !!this.state.gridArray ? (
                this.state.gridArray.map((el, index) => {
                  return (
                    <div
                      id={`gridElement_${index}`}
                      // We must make sure that Drag events will not trigger
                      onDragStart={event => {
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      onMouseDown={event => {
                        this.isMouseDown = true;
                        this.markGrid(index, 'gridArray');
                        event.preventDefault();
                      }}
                      onMouseOver={event => {
                        if (this.isMouseDown) {
                          this.markGrid(index, 'gridArray');
                        }
                        event.preventDefault();
                      }}
                      onMouseUp={() => {
                        this.isMouseDown = false;
                        this.storeGrid();
                      }}
                      onTouchMove={event => {
                        const myLocation = event.changedTouches[0];
                        const realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
                        if (realTarget.id !== this.lastTouchedGridCell) {
                          this.lastTouchedGridCell = realTarget.id;
                          const currentIndex = realTarget.id.split('_')[1];
                          this.markGrid(currentIndex, 'gridArray');
                        }
                      }}
                      style={{ background: this.props.colors[el] }}
                      className={this.props.gridChecked ? 'grid-border-child grid-child' : 'grid-child'}
                      key={index}
                    />
                  );
                })
              ) : (
                <></>
              )
            ) : this.state.gridArrayToThemAll ? (
              this.state.gridArrayToThemAll.map((el, index) => {
                return (
                  <div
                    id={`gridElementToThemAll_${index}`}
                    onDragStart={event => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                    onMouseDown={event => {
                      this.isMouseDown = true;
                      this.markGrid(index, 'gridArrayToThemAll');
                      event.preventDefault();
                    }}
                    onMouseOver={event => {
                      if (this.isMouseDown) {
                        this.markGrid(index, 'gridArrayToThemAll');
                      }
                      event.preventDefault();
                    }}
                    onMouseUp={() => {
                      this.isMouseDown = false;
                    }}
                    onTouchMove={event => {
                      const myLocation = event.changedTouches[0];
                      const realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);
                      if (realTarget.id !== this.lastTouchedGridCell) {
                        this.lastTouchedGridCell = realTarget.id;
                        const currentIndex = realTarget.id.split('_')[1];
                        this.markGrid(currentIndex, 'gridArrayToThemAll');
                      }
                    }}
                    style={{ background: this.props.colors[el] }}
                    className={this.props.gridChecked ? 'grid-border-child grid-child' : 'grid-child'}
                    key={index}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  colors: state.colors,
  gridSize: state.appGrid.gridSize,
  currentThumbThumb: state.appGrid.currentThumbImg,
  currentThumbIndex: state.appGrid.currentThumbIndex,
  noCapture: state.appGrid.noCapture,
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...appGridActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GridMarking);
